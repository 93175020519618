<template>
  <form v-if="validationSchema" @submit.prevent="onSubmit">
    <slot name="hidden"></slot>
    <template v-if="!currentSchema.spec?.meta?.noHeader">
      <div v-if="headerType == 'create'" class="w-full py-4 px-4 flex bg-surface-lvl-00 z-10">
        <Button type="button" iconOnly class="mr-2" variant="tertiary" @click="exit">
          <Icon src="close" size="medium" class="cursor-pointer text-secondary" />
        </Button>
        <p class="text-quarterary text-title-body mt-1.5">{{ name }}</p>
        <Divider class="mx-2" rotation="vertical"></Divider>
        <p class="text-secondary text-title-body mt-1.5">{{ stepNames[currentStepIdx] }}</p>
      </div>
      <div v-if="headerType == 'default'" class="flex py-2 pl-4 pr-2.5 w-full bg-surface-lvl-00 z-10">
        <p class="text-secondary text-body-lg-heavy mt-1.5">{{ name }}</p>
        <Button type="button" class="ml-auto" iconOnly variant="tertiary" @click="exit">
          <Icon src="close" size="medium" class="cursor-pointer" />
        </Button>
      </div>
      <div v-if="headerType == 'login'" class="flex mb-2 items-center bg-surface-lvl-00 z-10">
        <slot name="image" />
      </div>
    </template>
    <div class="px-4 flex min-h-0 h-full form-content">
      <slot>
        <FormStep v-for="(schema, idx) in validationSchema">
          <slot :name="`step-${stepKey}`" :schema="schema" :canSubmit="canSubmit">
            <FormBuilderVeeStep :schema="schema">
              <template #header>
                <div v-if="hideTitle" class="invisible"></div>
                <slot :name="`step-${stepKey}-header`" />
              </template>

              <template v-for="field in schemaFields(schema)" #[`${field}`]>
                <slot :name="`step-${stepKey}-${field}`" :schema="schema" :field="field"></slot>
              </template>

              <template v-for="field in schemaFields(schema)" #[`${field}-label`]>
                <slot :name="`step-${stepKey}-${field}-label`" :schema="schema" :field="field"></slot>
              </template>
            </FormBuilderVeeStep>
          </slot>
        </FormStep>
      </slot>
    </div>
    <slot name="footer">
      <template v-if="!currentSchema.spec?.meta?.noFooter">
        <div v-if="footerType == 'create'" class="flex px-6 py-4 bg-surface-lvl-00 z-10 justify-between relative">
          <Button
            type="button"
            class="w-full max-w-max mr-auto"
            :class="hasPrevious ? 'opacity-100' : 'opacity-0'"
            @click="goToPrev"
            variant="tertiary"
            size="default"
            >{{ t("previous") }}</Button
          >
          <div class="ml-auto flex gap-2">
            <slot name="footer-buttons"></slot>
            <Button
              type="submit"
              :variant="isLastStep && lastStepPositive ? 'positive' : 'primary'"
              size="default"
              :loading="loading"
              v-if="hasContinueButton && canSubmit"
            >
              {{ continueButtonText }}
            </Button>
          </div>
          <div v-if="showProgress && !currentSchema.spec.meta?.hideProgressBar" class="flex py-2.5 px-4 absolute left-0 right-0 justify-center -z-10">
            <div v-for="(_, idx) in validationSchema" :key="idx">
              <div
                v-if="!_.spec.meta?.autoskip && !_.spec.meta?.hideInProgressBar"
                class="h-0.5 transition-all duration-300 mt-2"
                :class="[idx <= currentStepIdx ? 'bg-inversed-00' : 'bg-02', idx == currentStepIdx ? 'w-10' : 'w-5', { 'mx-1': idx == 0, 'mr-1': idx !== 0 }]"
              ></div>
            </div>
          </div>
        </div>
        <div v-if="footerType == 'default'" class="p-4 flex bg-surface-lvl-00 z-10">
          <Button v-if="hasPrevious" type="button" class="w-full max-w-max" @click="goToPrev" variant="tertiary" size="default">{{ t("previous") }}</Button>
          <div class="flex gap-2 ml-auto">
            <Button type="button" v-if="canCancel" variant="default" @click="exit">
              {{ t("cancel") }}
            </Button>
            <Button type="button" class="w-full max-w-max" variant="default" size="default" @click="skip" v-if="hasSkip">{{ t("skip_this_step") }}</Button>
            <Button
              type="submit"
              class="w-full max-w-max"
              :disabled="!canSubmit || hasSkip"
              :variant="isLastStep && lastStepPositive ? 'positive' : 'primary'"
              size="default"
              :loading="loading"
              v-if="hasContinueButton"
            >
              {{ continueButtonText }}
            </Button>
          </div>
        </div>
        <div v-if="footerType == 'login'" class="flex flex-col gap-5 bg-surface-lvl-00 z-10">
          <Button
            type="submit"
            class="w-full mt-5"
            :disabled="!canSubmit"
            :variant="isLastStep && lastStepPositive ? 'positive' : 'primary'"
            size="large"
            :loading="loading"
            v-if="hasContinueButton"
          >
            {{ continueButtonText }}
          </Button>
          <slot name="footer-buttons"></slot>
        </div>
      </template>
    </slot>
  </form>
</template>

<script setup lang="ts">
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    validationSchema?: any[];
    loading?: boolean;
    submitButtonText?: string;
    lastStepPositive?: boolean;
    showProgress?: boolean;
    splitFooter?: boolean;
    customFooter?: boolean;
    name?: string;
    stepNames?: string[];
    headerType?: "default" | "create" | "login";
    footerType?: "default" | "create" | "login";
    canCancel?: boolean;
    hideTitle?: boolean;
  }>(),
  {
    loading: false,
    submitButtonText: "",
    lastStepPositive: false,
    showProgress: false,
    splitFooter: false,
    name: "",
    customFooter: false,
    stepNames: () => [],
    headerType: "default",
    footerType: "default",
    canCancel: false,
    hideTitle: false,
  }
);

const emit = defineEmits(["submit", "exit", "skip", "next"]);
const {
  currentStepIdx,
  isLastStep,
  hasPrevious,
  hasSkip,
  stepKey,
  hasContinueButton,
  continueButtonText,
  schemaFields,
  goToPrev,
  goToNext,
  form,
  canSubmit,
  skip,
  exit,
  onSubmit,
  goToLastStep,
  resetForm,
  currentSchema,
  setTouched,
  setStep,
} = useFormBuilder({
  submitButtonText: props.submitButtonText,
  validationSchema: props.validationSchema,
  emit,
});

const { values, setValues, setFieldValue } = form;
defineExpose({
  values,
  currentStepIdx,
  isLastStep,
  hasPrevious,
  canSubmit,
  stepKey,
  setValues,
  setFieldValue,
  goToLastStep,
  goToNext,
  resetForm,
  currentSchema,
  setTouched,
  setStep,
  onSubmit,
});
</script>
