<template>
  <div class="px-2 pb-1 text-body-default text-tertiary">
    <div
      class="sticky top-0 flex items-center gap-2 bg-surface-lvl-00 py-1.5"
    >
      <Icon :src="icon" size="large" class="text-quarterary" />
      {{ title }}
    </div>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
  import type { SearchResultCategory } from "~/types/search";

  defineOptions({
    name: "GlobalSearchCategory",
  });

  const props = withDefaults(
    defineProps<{
      category: SearchResultCategory;
    }>(),
    {
      category: "recent" as SearchResultCategory,
    }
  );

  const { t } = useI18n();

  const title = computed(() => {
    return globalSearchCategoryTitle(props.category);
  });

  const icon = computed(() => {
    return globalSearchCategoryIcon(props.category);
  });
</script>
