<template>
  <div 
  class="text-body-sm flex items-center gap-1 leading-4"
  :class="{
    'text-quarterary': props.variant === 'default',
    'text-positive': props.variant === 'valid',
    'text-destructive': props.variant === 'invalid',
  }">
    <Icon v-if="iconName" :src="iconName" filled />
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    variant?: "default" | "valid" | "invalid";
  }>(),
  {
    variant: "default",
  }
);

const iconName = computed(() => {
  if (props.variant === "valid") return "check_circle";
  if (props.variant === "invalid") return "warning_diamond";
  return "";
});
</script>