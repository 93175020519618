<template>
  <Button
    :variant="buttonVariant"
    :size="buttonSize"
    :icon="triggerIconPrefix"
    ref="trigger"
    @click.prevent.stop="toggleDropdown"
    :active="showDropdown"
    :iconOnly="!triggerText"
    type="button"
  >
    <template #prefix v-if="triggerIconPrefix">
      <Icon :src="triggerIconPrefix" size="medium"/>
    </template>
    {{ triggerText }}
    <template #suffix v-if="triggerIconSuffix">
      <Icon :src="triggerIconSuffix" size="medium"/>
    </template>

  </Button>

  <Dropdown :toggle-element="trigger.el" ref="dropdown" v-model:open="showDropdown" v-if="trigger" :overlay="false" :calculatePosition="calculatePosition">
    <div
      class="overflow-y-auto p-1.5"
      :class="{
        'p-2 space-y-2': size === 'large',
      }"
      :style="{
        'max-height': maxHeight,
      }"
      ref="optionsWrapper"
    >
      <template :key="`${option ? option.value : 'divider_' + index}`" v-for="(option, index) in options">
        <Divider v-if="option == null" />

        <DropdownItem
          v-else
          :size="size"
          @click="() => selectOption(option.value)"
          @keydown.enter.prevent="() => selectOption(option.value)"
          @keydown.down.prevent="() => setFocusOnItem(index, 'down')"
          @keydown.up.prevent="() => setFocusOnItem(index, 'up')"
          :has-arrow="hasArrow"
          :title="option.label"
          :subtext="option.subtext"
          :disabled="option.disabled"
          :variant="option.destructive ? 'destructive' : 'default'"
        >
          <template #prefix v-if="option.icon">
            <Icon :src="option.icon" size="medium" />
          </template>
          <template #prefix v-else-if="option.image">
            <Avatar
              size="2xs"
              :entity="{
                image: option.image,
              }"
            />
          </template>
        </DropdownItem>
      </template>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    triggerText?: string;
    triggerIconPrefix?: string | null;
    triggerIconSuffix?: string | null;
    size?: "default" | "large";
    actions: DropdownOption[];
    maxHeight?: string;
    hasArrow?: boolean;
    buttonSize?: "default" | "small" | "large";
    buttonVariant?: ButtonVariant;
  }>(),
  {
    triggerIconPrefix: null,
    triggerIconSuffix: null,
    size: "default",
    maxHeight: "16rem",
    buttonSize: "small",
    buttonVariant: "tertiary",
  }
);

const { dropdown, trigger, showDropdown, toggleDropdown, calculatePosition } = useDropdown();
const modelValue = ref("");

const emit = defineEmits(["select", "update:modelValue"]);
const options = computed(() => props.actions);

const { optionsWrapper, setFocusOnItem, selectOption } = useSelect({
  options,
  dropdown,
  modelValue,
  emit,
  open: showDropdown,
  toggleDropdown,
});
</script>
