import { DateTime } from "luxon";

export interface Printer {
  name: string;
  id: number;
  alias: string;
  active_at: string;
  default: boolean;
}

export interface Printers {
  data: Printer[];
  label: string;
  value: string;
  subtext: string;
}

export default () => {
  const { t } = useI18n();
  const modalOptions = ref<{ show: boolean; entity: any }>({
    show: false,
    entity: null,
  });

  const getPrinters = async () => {
    const latestActiveAt30Minutes = DateTime.utc().minus({ minutes: 30 }).toFormat("yyyy-MM-dd HH:mm");
    const { t } = useI18n();
    const { data, error, pending, refresh } = useSsrApi("workspace/printers", {
      key: "select:printers",
      query: {
        filters: {
          active_at: {
            $or: [{ $gte: latestActiveAt30Minutes }],
          },
        },
        sort: {
          0: "'default':desc", //Must be wrapped in quotes to avoid it being parsed as keyword in the query
          1: "active_at:desc",
        },
      },
      transform: ({ data }) => {
        if (!data || !data?.length) return;
        return {
          printers: data.map((printer: Printer) => ({
            label: printer.name,
            subtext: t("last_seen", { date: printer.active_at }),
            value: printer.alias,
            data: printer,
          })) as Printers,
          fetchedAt: new Date(),
        };
      },
      getCachedData: (key: string) => getCachedData(key, 60 * 2),
      default: () => [],
      lazy: true,
    });
    //@ts-ignore
    const printers = computed<Printers[]>(() => data?.value?.printers || []);
    const hasPrinters = computed(() => printers.value.length > 0 && !pending.value);
    return {
      data: printers,
      hasPrinters,
      error,
      pending,
    };
  };

  const printLabel = async (printer: string, package_number: string) => {
    //generate a unique id for the toast group
    const toastId = Math.random().toString(36).substring(7);
    modalOptions.value.show = false;

    $fetch(`/api/shipments/print`, {
      method: "GET",
      query: {
        package_number,
        alias: printer,
      },
      silent: true,
    })
      .then((response) => {
        $toast.add({
          id: toastId,
          group: "print:shipment:label",
          title: t("request_sent"),
          text: t("printer.printing_label", 1),
          icon: "printer",
        });
      })
      .catch((error) => {
        $toast.add({
          id: toastId,
          group: "print:shipment:label",
          title: t("error"),
          text: t("printer.error_printing"),
          type: "error",
        });
      });
  };

  const printBulkLabels = async (printer: string, selectAll: boolean, selected: number[] | string[], filters: any) => {
    const body = buildBulkActionBody({ action: 'printer', selectAll, selected, filters, options: { printer } });

    //generate a unique id for the toast group
    const toastId = Math.random().toString(36).substring(7);

    modalOptions.value.show = false;

    $toast.add({
      id: toastId,
      group: "print:shipment:label:bulk",
      title: t("request_sent"),
      text: t("printer.printing_label", 99),
      icon: "printer",
    });

    $fetch
      .raw("/api/shipments/export", {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
        silent: true,
      })
      .catch((e) => {
        $toast.add({
          id: toastId,
          group: `export_failed`,
          title: t("error"),
          text: t("export.failed_description"),
          type: "error",
        });
      });
  };

  const togglePrintModal = (entity?: any) => {
    modalOptions.value.show = !modalOptions.value.show;
  
    if (modalOptions.value.show) {
      modalOptions.value.entity = entity;
    } else {
      modalOptions.value.entity = null;
    }
  };

  return {
    getPrinters,
    printLabel,
    modalOptions,
    togglePrintModal,
    printBulkLabels,
  };
};
