<template>
  <div class="grid grid-cols-5 gap-6 min-h-0 w-full h-full">
    <div class="flex flex-col gap-2 col-span-2 flex-grow overflow-auto flex-shrink-0 relative">
      <div v-for="market in marketsOrderByName" :key="market.value" class="bg-surface-lvl-00 border borde-00 shadow-lvl-01 rounded-lg">
        <div class="bg-00 px-3 py-2 text-secondary text-body-lg-heavy flex items-center gap-3">
          <img :src="market.image" alt="flag" class="w-4 h-4" v-if="market.image" />
          <Icon :src="market.icon" size="medium" v-else-if="market.icon" />
          {{ market.label }}
        </div>
        <AccordionList
          class="pb-0"
          v-if="shippingMethodsForMarket(market.value)?.length"
          :items="shippingMethodsForMarket(market.value)"
          expand-on-toggle
          @update:model-value="(val, entry, idx, active) => setSmProperty(idx, market.value, active, 'active')"
          :ref="elm => addRef(market.value, elm)"
        >
          <template #item="{ item, index }">
            <div class="w-full flex flex-col gap-4">
              <InputText
                :model-value="getSmProperty(index, market.value, 'name')"
                @update:model-value="(val) => setSmProperty(index, market.value, val, 'name')"
                :readonly="checkIfHasAdvancedRules(index, market.value)"
                :label="t('title')"
              />
              <InputText
                :model-value="getSmProperty(index, market.value, 'description')"
                @update:model-value="(val) => setSmProperty(index, market.value, val, 'description')"
                :readonly="checkIfHasAdvancedRules(index, market.value)"
                :label="t('description')"
              />
              <InputNumber
                :model-value="getSmProperty(index, market.value, 'rules[0].price')"
                @update:model-value="(val) => setSmProperty(index, market.value, val, 'rules[0].price')"
                :label="t('price')"
                :step="0.01"
                :readonly="checkIfHasAdvancedRules(index, market.value)"
                class="w-[100px]"
              >
              </InputNumber>
              <div class="flex flex-col gap-3">
                <div class="flex gap-3">
                  <div class="text-body-default">
                    {{ t("highlight_with_label") }}
                  </div>
                  <Toggle
                    :model-value="getSmProperty(index, market.value, 'label') !== null"
                    @update:model-value="(val) => setSmProperty(index, market.value, val ? t('fastest_delivery') : null, 'label')"
                    :disabled="checkIfHasAdvancedRules(index, market.value)"
                  />
                </div>
                <InputText
                  v-if="getSmProperty(index, market.value, 'label') !== null"
                  :model-value="getSmProperty(index, market.value, 'label')"
                  @update:model-value="(val) => setSmProperty(index, market.value, val, 'label')"
                  :placeholder="t('fastest_delivery')"
                  :readonly="checkIfHasAdvancedRules(index, market.value)"
                />
              </div>

              <div class="bg-00 px-4 py-3 rounded-xl flex items-center gap-5">
                <div class="flex flex-col gap-0.5">
                  <div class="w-full text-body-default-heavy">
                    {{ t("enable_address_validation") }}
                  </div>
                  <div class="text-quarterary text-body-sm">
                    {{ t("enable_address_validation_description") }}
                  </div>
                </div>

                <Toggle
                  :model-value="getSmProperty(index, market.value, 'address_validation')"
                  @update:model-value="(val) => setSmProperty(index, market.value, val, 'address_validation')"
                />
              </div>

              <Callout icon-filled icon="WarningDiamond" type="highlight" v-if="checkIfHasAdvancedRules(index, market.value)">
                <div class="flex flex-col gap-1 items-start">
                  <div>{{ t("shipping_method_has_advanced_rules") }}</div>
                  <Button variant="primary-link" @click="() => emit('advanced')">
                    {{ t("open_advanced_rules") }}<template #suffix><Icon src="bold/ArrowRight" /></template>
                  </Button>
                </div>
              </Callout>

              <Button class="self-end" variant="destructive-link" @click="() => deleteShippingMethod(item, market.value)" type="button">
                {{ t("delete_shipping_method") }}
              </Button>
            </div>
          </template>
        </AccordionList>

        <div class="p-3 flex flex-col gap-3">
          <Divider v-if="shippingMethodsForMarket(market.value)?.length" :spacing="false" />

          <DropdownActions :trigger-text="t('add_shipping_method')" :actions="getCpsForCountry(market.value)" button-variant="default" buttonSize="default">
          </DropdownActions>
        </div>
      </div>

      <Overlay v-if="fetchingCps" />
    </div>
    <div class="flex flex-col col-span-3 flex-grow-0 bg-00 rounded-xl p-5 gap-5 overflow-auto">
      <CheckoutPreview ref="preview" :markets="marketsOrderByName" :shippingMethods="value" />
    </div>
  </div>
</template>

<script setup lang="ts">
import _, { add } from "lodash";
import CheckoutPreview from "./CheckoutPreview.vue";

const { value, setValue }: { value: Ref<ICheckoutShippingMethod[]>; setValue: (val: any) => void } = useField(() => "shipping_methods", undefined, {
  syncVModel: true,
});

const formValues = inject("FORM_VALUES") as Record<string, any>;

const markets = computed(() => formValues.markets);
const checkoutId = computed(() => formValues.id);

const { t } = useI18n();

const preview = ref(null);
const countries = getCountrySelectOptions();
const cpsList = ref<Record<string, any>>({});
const fetchingCps = ref(false);
const shippingMethodRef = ref();

const addRef = (market: string, elm: any) => {
  if (!shippingMethodRef.value) shippingMethodRef.value = {};
  if (!shippingMethodRef.value[market]) shippingMethodRef.value[market] = {};
  shippingMethodRef.value[market] = elm;
};

onMounted(() => {
  fetchCps();
});

const marketsOrderByName = computed(() => {
  return [...markets.value]
    .sort((a: string, b: string) => {
      if (b === "GLOBAL") return 1;
      if (a === "GLOBAL") return -1;
      return getCountryName(a).localeCompare(getCountryName(b));
    })
    ?.map((a) => ({
      value: a,
      label: getCountryName(a),
      image: getCountryImage(a),
      icon: a === "GLOBAL" ? "regular/Globe" : null,
    }));
});

const shippingMethodsForMarket = (market: string) => {
  const sms = value.value ? [...value.value] : [];

  return sms
    .filter((sm: any) => countryFilter(sm, market))
    .map((sm) => ({
      value: sm.carrier_product_service.slug,
      label: cpsList.value[market]?.find((cps) => cps.slug == sm.carrier_product_service.slug)?.name,
      image: getCarrierLogoUrl(sm.carrier),
      active: sm.active,
      id: sm.id,
      cId: sm.cId,
    }));
};

const getCountryImage = (country: string) => {
  if (country === "GLOBAL") {
    return null;
  }

  return countries.find((c) => c.value === country)?.image;
};

const emptyShippingMethod = (cps: string, name: string, market: string) => {
  let cId = null;

  //generate a random id for the shipping method
  if (!value.value?.length) cId = 1;
  else cId = Math.max(...value.value.map((sm) => sm.cId || 0)) + 1;

  const base = {
    carrier_product_service: { slug: cps },
    address_validation: false,
    name,
    description: "",
    label: null,
    logo: "",
    carrier: cps.split("_")[0],
    active: true,
    cId: cId,
  };

  if (market === "GLOBAL") {
    return {
      ...base,
      rules: [
        {
          priority: 1,
          filters: {},
          price: 0,
          description: "",
        },
      ],
    };
  }

  return {
    ...base,
    rules: [
      {
        priority: 1,
        filters: {
          receiver_country: [
            {
              operator: "eq",
              value: market,
            },
          ],
        },
        price: 0,
        description: "",
      },
    ],
  };
};

const fetchCps = () => {
  fetchingCps.value = true;
  homeFetch("cps-by-country", {
    query: {
      sender_country: "DK",
      "receiver_countries[]": markets.value,
    },
  })
    .then((res) => {
      cpsList.value = res.data;
    })
    .finally(() => {
      fetchingCps.value = false;
    });
};

const getShippingMethod = (index: number, market: string) => {
  const sms = value.value.filter((sm) => countryFilter(sm, market));
  return sms[index];
};

const getSmProperty = (index: number, market: string, key: string) => {
  const shippingMethod = getShippingMethod(index, market);
  return _.get(shippingMethod, key);
};

const setSmProperty = (index: number, market: string, value: any, key: string) => {
  const shippingMethod = getShippingMethod(index, market);
  _.set(shippingMethod, key, value);

  if (preview.value) {
    preview.value.setPreviewCountry(market);
  }
};

const checkIfHasAdvancedRules = (index: number, market: string) => {
  const shippingMethod = getShippingMethod(index, market);

  //If the shipping method has no rules, return false
  if (!shippingMethod.rules?.length) return true;

  //Check if the shipping method has rules besides receiver_country
  if (shippingMethod.rules.some((rule) => Object.keys(rule.filters).some((k) => k !== "receiver_country"))) return true;

  //If the shipping method has rules, check if any of them has a receiver_country filter with "eq" operator
  if (shippingMethod.rules.some((rule) => rule.filters.receiver_country && rule.filters.receiver_country[0].operator !== "eq")) return true;

  return false;
};

const getCpsForCountry = (market: string) => {
  const list = cpsList.value[market] || [];
  return list.map((cps: any) => ({
    value: cps.slug,
    label: cps.name,
    image: getCarrierLogoUrl(cps.carrier),
    handler: () => {
      setValue([...value.value, emptyShippingMethod(cps.slug, cps.name, market)]);

      setTimeout(() => {
        const listItems = unref(shippingMethodRef.value[market]?.refs);
        //open the shipping method
        listItems[listItems.length - 1].expand();
      });
      if (preview.value) {
        preview.value.setPreviewCountry(market);
      }
    },
  }));
};

const deleteShippingMethod = (item: any, market: string) => {
  const sms = value.value ? [...value.value] : [];

  $confirm({
    message: t("delete_shipping_method_confirm"),
    onConfirm: () => {
      if (item.id) {
        return homeFetch(`workspace/checkouts/${checkoutId.value}/shipping-methods/${item.id}`, {
          method: "DELETE",
        }).then(() => {
          const index = sms.findIndex((sm) => sm.id === item.id);
          sms.splice(index, 1);
          setValue(sms);

          if (preview.value) {
            preview.value.setPreviewCountry(market);
          }
        });
      } else if (item.cId) {
        const index = sms.findIndex((sm) => sm.cId === item.cId);
        sms.splice(index, 1);
        setValue(sms);

        if (preview.value) {
          preview.value.setPreviewCountry(market);
        }
      }
    },
    cancelText: t("cancel"),
  });
};

const emit = defineEmits(["advanced"]);
</script>

<script lang="ts">
export const countryFilter = (sm: Record<string, any>, country: string) =>
  country === "GLOBAL"
    ? !_.get(sm, "rules[0].filters.receiver_country[0].value") || _.get(sm, "rules[0].filters.receiver_country[0].operator") !== "eq"
    : _.get(sm, "rules[0].filters.receiver_country[0].value") === country;
</script>
