<template>
  <div class="flex flex-col gap-1 min-h-0 w-full h-full rounded-xl overflow-auto">
    <div class="bg-surface-lvl-00 rounded-inherit border "
      :class="{
        'border-01': isShippingMethodOpen(sidx),
        'border-transparent hover:border-01': !isShippingMethodOpen(sidx),
      }"
    v-for="(shippingMethod, sidx) in value">
      <RuleShippingmethod
        :key="sidx" 
      :shippingMethod="shippingMethod"
        :name="shippingMethod.name"
        :description="shippingMethod.description"
        @update:name="(val: string) => updateShippingMethod(sidx, { ...shippingMethod, name: val })"
        @update:description="(val: string) => updateShippingMethod(sidx, { ...shippingMethod, description: val })"

        @toggle="() => toggleShippingMethod(sidx)"
        :active="isShippingMethodOpen(sidx)"
        @undo="resetShippingMethod(sidx)"
        
      />
      <div v-if="isShippingMethodOpen(sidx)" class="bg-surface-lvl-01 p-3 gap-1 flex flex-col rounded-inherit rounded-t-none">
        <Rule v-for="(rule, idx) in shippingMethod.rules" :rule="rule" @update:rule="(val) => updateRule(sidx, idx, val)" @delete="() => deleteRule(sidx, idx)" :group="idx"  :ref="val => setRuleRef(val, sidx, idx)"/>
        <Button variant="secondary" type="button" @click="() => addRule(sidx)">
          <template #prefix>
            <Icon src="bold/Plus" />
          </template>
          {{ t("add_rule") }}
        </Button>
      </div>
    </div>

    <Callout type="ghost" icon="WarningDiamond">
      {{ t("checkout_sorting_is_indicative") }}
    </Callout>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
const formValues = inject("FORM_VALUES") as Record<string,any>;

const { t } = useI18n();

const ruleRefs = ref<Record<string, any>>({});

const setRuleRef = (val: any, sidx: number, idx: number) => {
  ruleRefs.value[`${sidx}-${idx}`] = ref(val);
};


const { value, meta }: { value: Ref<ICheckoutShippingMethod[]>, meta: Record<string,any>} = useField(() => "shipping_methods", undefined, {
  validateOnMount: false,
  validateOnValueUpdate: false,
  initialValue: formValues.shipping_methods,
});

const description = ref(null);
provide("description", description);

const openShippingMethods = ref<number[]>([]);
const isShippingMethodOpen = (idx: number) => openShippingMethods.value.includes(idx);

const toggleShippingMethod = (idx: number) => {
  if (openShippingMethods.value.includes(idx)) {
    openShippingMethods.value = openShippingMethods.value.filter((i) => i !== idx);
  } else {
    openShippingMethods.value = [...openShippingMethods.value, idx];
  }
};

const updateShippingMethod = (idx: number, val: ICheckoutShippingMethod) => {
  let shippingMethods = _.cloneDeep(value.value);

  shippingMethods.splice(idx, 1, val);

  value.value = shippingMethods;
};

const updateRule = (sidx: number, idx: number, val: IRule) => {
  let shippingMethods = _.cloneDeep(value.value);
  let shippingMethod = shippingMethods[sidx];

  shippingMethod.rules.splice(idx, 1, val);

  updateShippingMethod(sidx, shippingMethod);
};

const addRule = (sidx: number) => {
  let shippingMethods = _.cloneDeep(value.value);
  let shippingMethod = shippingMethods[sidx];

  shippingMethod.rules.push({
    filters: {},
    price: 0,
    priority: 1,
    description: null,
  });

  shippingMethods.splice(sidx, 1, shippingMethod);

  value.value = shippingMethods;

  setTimeout(() => {
    ruleRefs.value[`${sidx}-${shippingMethod.rules.length - 1}`].open()

  }, 10);
};

const deleteRule = (sidx: number, idx: number) => {
  let shippingMethods = _.cloneDeep(value.value);
  let shippingMethod = shippingMethods[sidx];

  shippingMethod.rules.splice(idx, 1);

  shippingMethods.splice(sidx, 1, shippingMethod);

  value.value = shippingMethods;
};

const resetShippingMethod = (sidx: number) => {
  let shippingMethods = _.cloneDeep(value.value);

  shippingMethods.splice(sidx, 1, meta.initialValue[sidx]);

  value.value = shippingMethods;
};

const fetchFilters = () => {
  homeFetch("workspace/checkouts/describe").then((response) => {
    if (response?.data) {
      description.value = response.data;
    }
  });
};

fetchFilters();
</script>
