<template>
  <div class="flex flex-col gap-1" :class="[{ error: showError, 'text-disabled': readonly }, props.class]" @click="() => emit('click')">
    <label v-if="label" class="text-body-sm-heavy text-left text-secondary flex gap-2 items-center">{{ label }} <slot name="label_suffix"></slot></label>
    <div
      ref="wrapper"
      class="input-wrapper flex items-center transition-all duration-300"
      :class="[
        {
          'py-2 h-9 rounded-xl': size === 'default' && tag == 'input',
          'pl-4 pr-3.5': size !== 'small' && tag == 'input' && !equalPadding && !slots.button,
          'pl-4 pr-1': size !== 'small' && tag == 'input' && !equalPadding && slots.button,

          'px-2': (size === 'default' || size === 'large') && tag == 'input' && equalPadding,
          'px-3 py-[3px] rounded-lg': size === 'small' && tag == 'input',
          'py-3 h-11 rounded-xl': size === 'large' && tag == 'input',
        
        
          'pl-3 py-2 rounded-xl': tag == 'textarea',
          readonly,
        },
        !darkBg ? 'bg-00' : 'bg-01',
      ]"
    >
      <div v-if="prefix || slots.prefix" class="h-full flex items-center mr-2 text-sm font-normal text-quarterary" ref="prefixWrapper">
        <slot name="prefix">
          {{ prefix }}
        </slot>
      </div>
      <slot>
        <input
          :type="type"
          v-if="tag == 'input'"
          v-model="modelValue"
          v-bind="$attrs"
          :name="name"
          class="w-full h-5 truncate"
          :class="{
            'bg-00': !darkBg,
            'bg-01': darkBg,
            'mr-2': suffix || slots.suffix,
            'mr-1': !suffix && !slots.suffix,
          }"
          @blur="onBlur"
          ref="input"
          :readonly="readonly"
          @focus="hasFocus = true"
        />
      </slot>

      <div v-if="suffix || slots.suffix" class="h-full w-full max-w-max flex items-center ml-2 text-sm font-normal text-quarterary" ref="suffixWrapper">
        <slot name="suffix">
          {{ suffix }}
        </slot>
      </div>
      <slot name="button" />

      <textarea
        v-if="tag == 'textarea'"
        v-model="modelValue"
        v-bind="$attrs"
        class="w-full"
        :class="[
          !darkBg ? 'bg-00' : 'bg-01',
          size == 'large' ? 'h-[200px]' : 'h-[100px]',
          ]"
        @blur="() => emit('blur')"
        ref="input"
        :readonly="readonly"
      />
    </div>
    <Message v-if="showError" variant="invalid">{{ error }}</Message>
    <Message v-if="message" variant="default">{{ message }}</Message>

  </div>
</template>

<script setup lang="ts">
const slots = useSlots();
defineOptions({
  name: "InputWrapper",
});

const [modelValue, modifiers] = defineModel({
  set(value) {
    const m = getModifiers(modifiers, value);
    if (m.subdomain) {
      return companyNameSanitizer(value);
    }
    if (typeof value === "string") {
      if (m.trim) {
        return value.trim();
      }
      if (m.noWhitespace) {
        return value.replace(/\s/g, "");
      }
      if (m.multi) {
        //split on newline and if only one line, return string in array
        let v = [];
        if(props.type === 'text') {
          v = value.split(",").length > 1 ? value.split(",") : [value];
        } else {
          v = value.split("\n").length > 1 ? value.split("\n") : [value];
        }
        //remove empty lines except for the last one
        return v
          .map((line, idx) => {
            if (idx < v.length - 1) {
              return line.trim();
            }
            return line;
          })
          .filter((line, idx) => line || idx === v.length - 1);
      }
    }

    return value;
  },
  get(value) {
    if (!value) return value;
    const m = getModifiers(modifiers, value);

    if (m.multi && Array.isArray(value)) {
      if(props.type === 'text') {
        return value.join(",");
      }
      return value.join("\n");
    }
    return value;
  },
});
const input = ref<HTMLElement | null>(null);
const wrapper = ref<HTMLElement | null>(null);
const prefixWrapper = ref<HTMLElement | null>(null);
const suffixWrapper = ref<HTMLElement | null>(null);
const hasFocus = ref(false);
const props = withDefaults(
  defineProps<{
    type?:
      | "button"
      | "checkbox"
      | "color"
      | "date"
      | "datetime-local"
      | "email"
      | "file"
      | "hidden"
      | "image"
      | "month"
      | "number"
      | "password"
      | "radio"
      | "range"
      | "reset"
      | "search"
      | "submit"
      | "tel"
      | "text"
      | "time"
      | "url"
      | "week";
    tag?: "input" | "textarea";
    label?: string | null;
    error?: string | null;
    size?: InputSize;
    prefix?: string | null;
    suffix?: string | null;
    modifiers?: string[];
    class?: string | null | Object | Array<string | Object>;
    autofocus?: boolean;
    readonly?: boolean;
    darkBg?: boolean;
    name?: string;
    equalPadding?: boolean;
    message?: string;
  }>(),
  {
    type: "text",
    tag: "input",
    size: "default",
    darkBg: false,
    name: "",
    equalPadding: false,
    modifiers: () => [],
  }
);

const emit = defineEmits(["click", "blur"]);

const getModifiers = (modifiers, value): { [key: string]: any } => {
  //transform props.modifiers to object with true values for each modifier
  let m = props.modifiers
    ? props.modifiers.reduce((acc, mdf) => {
        acc[mdf] = true;
        return acc;
      }, {})
    : {};

  //merge with modelmodifiers
  m = Object.assign(m, modifiers.value, { value });

  return m;
};

onMounted(() => {
  if (props.autofocus) {
    setTimeout(() => {
      input.value?.focus();
    }, 0);
  }

  //If prefix or suffix is present, and they are not buttons or anchors, focus input on click
  if (prefixWrapper.value && !prefixWrapper.value.querySelector("button") && !prefixWrapper.value.querySelector("a")) {
    prefixWrapper.value.addEventListener("click", () => {
      input.value?.focus();
    });
  }
  if (suffixWrapper.value && !suffixWrapper.value.querySelector("button") && !suffixWrapper.value.querySelector("a")) {
    suffixWrapper.value.addEventListener("click", () => {
      input.value?.focus();
    });
  }
});

const onBlur = () => {
  emit("blur");
  hasFocus.value = false;
};

const showError = computed(() => {
  return !!props.error && !hasFocus.value && !props.readonly && modelValue.value !== "";
});

defineExpose({
  input,
  wrapper,
});
</script>

<style scoped>
input,
textarea,
select {
  @apply text-body-default text-primary placeholder:text-quarterary  hover:placeholder:text-tertiary read-only:text-inherit box-border;
}

.input-wrapper {
  @apply border border-disabled hover:border-01 focus-within:border-01;
}

.error .input-wrapper {
  @apply border-destructive placeholder:text-destructive text-destructive;
}

input,
textarea,
select {
  /** remove focus outline using tailwind */
  @apply focus-within:outline-none;
}

.input-wrapper:not(.readonly):focus-within {
  /** Add focus outline using tailwind */
  @apply ring-2 ring-core-100 ring-offset-1;
}

.error input,
.error textarea {
  @apply border-none;
}
</style>
