<template>
  <VueFinalModal
    class="flex items-center justify-center p-5"
    @opened="onOpened"
    @closed="onClosed"
    v-model="open"
    :contentClass="[
      `rounded-xl z-10 transition-all duration-[10ms] flex overflow-hidden box-border shadow-lvl-05`,
      {
        'opacity-0': !opaque,
        absolute: !!calculatePosition,
        'bg-surface-lvl-00 border-2 border-01' : bgset,
        ...contentClass
      },
      
    ]"
    :contentStyle="{
      'max-height': maxHeight,
      'min-width': minWidth,
      'max-width': maxWidth,
    }"
    overlayClass="touch-none pointer-events-none bg-modal-overlay"
    overlayTransition="vfm-fade"
    :hideOverlay="!overlay"
    :clickToClose="clickToClose"
  >
    <div ref="modalContent" class="flex flex-col w-full relative" :class="{
      'p-1.5': contentPadding,
    }">
      <slot />
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";

defineOptions({
  name: "Modal",
});

const props = withDefaults(
  defineProps<{
    center?: boolean;
    calculatePosition?: (cb) => void;
    overlay?: boolean;
    maxHeight?: string;
    minWidth?: string;
    maxWidth?: string;
    clickToClose?: boolean;
    contentPadding?: boolean;
    bgset?: boolean;
    contentClass?: Record<string, boolean>;
  }>(),
  {
    center: false,
    overlay: true,
    maxHeight: "100%",
    clickToClose: true,
    contentPadding: true,
    bgset: true,
    contentClass: () => ({}),
  }
);
const open = defineModel("open", {default: false});
const emit = defineEmits(["update:open", "opened", "closed", "confirm"]);
const closingDuration = 250;
const openingDuration = 900;
const opaque = ref(false);

const modal = ref<HTMLElement | null | undefined>(null);
const modalContent = ref<HTMLElement | null | undefined>(null);
const height = ref(0);
const width = ref(0);

const resizeListener = ref();

const onOpened = () => {
  //set modal.value to modalContent.value.parentElement
  modal.value = modalContent.value?.parentElement;
  getHeight();
  getWidth();

  nextTick(() => {
    if (props.calculatePosition) props.calculatePosition(() => setOpaque(true));
    else setOpaque(true);
  });

  if (props.calculatePosition) {
    window.addEventListener("resize", props.calculatePosition);
  }

  emit("opened");
};

const setOpaque = (value: boolean) => {
  opaque.value = value;
};

const onClosed = () => {
  if (props.calculatePosition) {
    if (resizeListener.value) window.removeEventListener("resize", props.calculatePosition);
  }

  setOpaque(false);
  emit("closed");
};

const getHeight = () => {
  if (!modalContent.value) return 0;
  
  const children = modalContent.value.children as HTMLCollectionOf<HTMLElement>;

  let h = 2;
  for (let i = 0; i < children.length; i++) {
    h += children[i].clientHeight;
  }

  //get padding of modalContent
  const style = window.getComputedStyle(modalContent.value);
  h += parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);

  height.value = h;
  return h;
};

const getWidth = () => {
  if (!modal.value) return 0;

  const w = modal.value.offsetWidth;
  width.value = w;
  return w;
};

const updateHeight = (newHeight?: number | null) => {
  nextTick(() => {
    const h = newHeight || getHeight();
    setStyleProperty("height", h + "px");
  });
};

const setStyleProperty = (property: string, value: string) => {
  modal.value?.style.setProperty(property, value);
};

defineExpose({
  closingDuration,
  openingDuration,
  height,
  width,
  open,
  modal,
  updateHeight,
  setStyleProperty,
});
</script>

