<template>
  <div class="flex flex-col @xl:gap-10 gap-4">
    <div class="flex gap-4 @xl:gap-10 @xl:px-10 flex-col @xl:flex-row">
      <div class="flex flex-col gap-3 flex-1">
        <div class="flex gap-3" v-for="point in definition.points">
          <Icon src="Check" size="large" />
          <div class="text-body-lg">{{ point }}</div>
        </div>
      </div>
      <div class="flex flex-col flex-1 gap-1.5">
        <template v-for="(tableEntry, idx) in definition.table">
          <div class="flex text-body-default text-tertiary gap-2 justify-between">
            <div>{{ tableEntry.left }}</div>
            <div class="text-right flex gap-1 items-center">
              {{ tableEntry.right }}
              <Icon src="Info" class="text-tertiary" v-if="tableEntry.infoLink" />
            </div>
          </div>
          <Divider v-if="idx !== definition.table.length - 1" />
        </template>
      </div>
    </div>
    <div class="flex gap-4 @xl:gap-10 @xl:px-10 flex-col @xl:flex-row">
      <div class="flex flex-col gap-3 flex-1">
        <div class="flex flex-col gap-1.5">
          <div class="text-body-default-heavy">
            {{ transferUnitTypeIsPalletLike ? t("settings.plan.pickup.default_unit_type.on") : t("settings.plan.pickup.default_unit_type.in") }}:
          </div>
          <Button class="w-full" @click="() => (showUnitModal = !showUnitModal)">
            {{ unitTypes.find((unitType) => unitType.value === transferUnitType)?.label }}
            <template #suffix>
              <Icon src="CaretRight" class="ml-auto" />
            </template>
          </Button>
        </div>
      </div>
      <div class="flex flex-col gap-3 flex-1">
        <div class="flex flex-col gap-1.5">
          <div class="text-body-default-heavy">{{ t("settings.plan.pickup.carrier") }}:</div>
          <Button class="w-full" disabled>
            {{ carriers.find((carrier) => carrier.value === transferCarrier)?.label }}
            <template #suffix>
              <Icon src="CaretRight" class="ml-auto" />
            </template>
          </Button>
        </div>
      </div>
    </div>
  </div>
  <UnitModal v-model:open="showUnitModal" />
</template>

<script setup lang="ts">
import UnitModal from "./UnitModal.vue";

const { t } = useI18n();
const showUnitModal = ref(false);

const carriers = ref([]);
const unitTypes = ref([
  {
    value: "BAG",
    label: t("bag", 99),
  },
  {
    value: "PALLET",
    label: t("pallet", 99),
  },
]);

const transferCarrier = computed({
  get: () => useAuth().accountOptions.value?.transfer?.transfer_pickup_carrier,
  set: (value: string) => {
    updateCarrier(value).then(() => useAuth().updateCapabilities());
  },
});

const transferUnitType = computed(() => useAuth().accountOptions.value?.transfer?.transfer_pickup_unit_type);

const transferUnitTypeIsPalletLike = computed(() => {
  return transferUnitType.value === "PALLET";
});

const getSettings = () => {
  return homeFetch("workspace/plan/settings").then((res) => {
    if (res.data) {
      carriers.value = res.data.carriers;
      //  unitTypes.value = res.data.unit_types;
    }
  });
};

onMounted(() => {
  setLoading(true);
  getSettings().finally(() => {
    setLoading(false);
  });
});

const updateCarrier = (carrier: string) => {
  return homeFetch("workspace/plan/carrier", {
    method: "PUT",
    body: {
      transfer_pickup_carrier: carrier,
    },
  });
};
const definition = ref({
  points: [],
  table: [],
});

const emits = defineEmits(["update:loading"]);
const setLoading = (l: boolean) => emits("update:loading", l);
usePickup().then((res) => {
  definition.value = res;
});
</script>

<script lang="ts">
export const usePickup = async (): Promise<Record<string, any>> => {
  return homeFetch("workspace/plan/pickup-time").then((res) => {
    if (res.time) {
      return {
        points: [
          "Afhentning anbefales hvis du sender mere end 10 pakker om dagen.",
          "Få afhentet dine forsendelser på din adresse.",
          "Afhentning kan ikke kombineres med indlevering.",
        ],
        table: [
          {
            left: "Afhentning eks. brændstof",
            right: "fra 85.00 DKK",
            infoLink: "",
          },
          {
            left: "Tidspunkt for afhentning",
            right: "kl. " + res.time,
            infoLink: "",
          },
          {
            left: "Pakkepriser inkl. gebyr",
            right: "fra 23,40 DKK",
            infoLink: "",
          },
          {
            left: "Automatisk optankning",
            right: "Påkrævet",
            infoLink: "https://help.homerunner.com/",
          },
          {
            left: "Geografisk dækning",
            right: "Hele EU",
            infoLink: "https://help.homerunner.com/",
          },
        ]};
    }

    return {
      points: [],
      table: [],
    };
  });
};
</script>
