<template>
    <Modal :clickToClose="false" v-model:open="noTransferOpen" maxWidth="1020px" :contentPadding="false" :center="true" anchor="top" ref="modal">
        <div class="relative w-[342px]">
            <div class=" p-4">
                <p class="text-secondary text-body-default mb-3">{{ t('your_profile_requires_transfer') }}</p>
                <a href="https://knowledge.homerunner.com/categories/freight/afhentning-med-danske-fragtmaend/aftale-med-danske-fragtmaend" target="_blank" rel="noopener noreferrer">
                    <Button variant="secondary-link" class="gap-1">
                        <template #prefix>
                            <Icon class="text-quarterary" src="bold/info" size="medium"></Icon>
                        </template>
                        <p>{{ t('read_more_transfer') }}</p>
                    </Button>
                </a>
            </div>
            <div class="p-4">
                <Button variant="primary" class="w-full mb-2" @click="goToTransfer">{{ t('book_a_transfer') }}</Button>
                <Button variant="default" class="w-full" @click="closeModal()">{{ t('cancel') }}</Button>
            </div>
        </div>
    </Modal>
</template>

<script setup lang="ts">
import { noTransferOpen } from "../service";
import EventBus from "@/plugins/eventbus";
const { t } = useI18n();
const router = useRouter();

const goToTransfer = () => {
    noTransferOpen.value = false;
    EventBus.$emit("NEWSHIPMENT_CLOSE");
    router.push({ path: "sortings", query: { openTransfer: 'true' } });
};

const closeModal = () => {
    noTransferOpen.value = false;
    EventBus.$emit("NEWSHIPMENT_CLOSE");
};
</script>