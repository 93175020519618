<template>
  <div class="text-title-section mt-10">{{ t("checkout_should_be_shown_on_market") }}</div>

  <div>
    <Button
      type="button"
      @click="() => toggleCountry(globalCountry)"
      class="w-full"
      active-checkmark
      :active="isCountrySelected(globalCountry)"
      :disabled="isInitialCountry(globalCountry)"
    >
      <template #prefix>
        <Icon src="regular/Globe" size="large"></Icon>
      </template>
      {{ globalCountry.label }}
    </Button>
    <div class="text-body-sm text-quarterary mt-1">
      {{ t("checkout_global_market_description") }}
    </div>
    <Divider :text="t('or')" class="w-full mt-4" />
  </div>
  <div class="flex flex-col gap-3 min-h-0">
    <InputText v-model="search" :autocomplete="'off'" :placeholder="t('search_country')" size="large" />
    <div class="overflow-auto flex flex-col gap-3">
      <div v-if="!search">
        <div class="text-body-sm-heavy mb-1">{{ t("popular_countries") }}</div>

        <div class="grid grid-cols-4 gap-2 overflow-auto">
          <div
            v-for="country in popularCountries"
            class="col-span-1 border border-00 rounded-xl p-3 h-24 flex flex-col justify-between select-none"
            :class="{
              'bg-00': isCountrySelected(country),
              'bg-surface-lvl-00 shadow-lvl-01': !isCountrySelected(country),
              'cursor-pointer': !isInitialCountry(country),
            }"
            @click="() => toggleCountry(country)"
          >
            <img :src="country.image" alt="flag" class="w-5" />
            <div class="text-body-default text-primary flex gap-1 items-center justify-between">
              <div class="text-ellipsis flex-nowrap">{{ country.label }}</div>
              <Icon src="bold/check" size="small" class="text-primary" v-if="isCountrySelected(country)" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col min-h-0">
        <div class="text-body-sm-heavy mb-1">{{ t("all_countries") }}</div>

        <div class="flex flex-col gap-2 min-h-0">
          <Button type="button" v-for="country in filteredCountries" :key="country.value" @click="() => toggleCountry(country)" :disabled="isInitialCountry(country)" :active="isCountrySelected(country)" active-checkmark>
            <template #prefix>
              <img :src="country.image" alt="flag" class="w-4 h-4" />
            </template>
            {{ country.label }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    mode?: "create" | "edit";
  }>(),
  {
    mode: "create",
  }
);
const { t } = useI18n();

const globalCountry = {
  value: "GLOBAL",
  label: t("global"),
};

const formMeta = inject("FORM_META") as Record<string,any>;
const setFieldValue = inject("FORM_SET_FIELD_VALUE") as (field: string, value: any) => void;
const formValues = inject("FORM_VALUES") as Record<string,any>;

const countries = getCountrySelectOptions();
const filteredCountries = computed(() => {
  return countries.filter((country) => country.label.toLowerCase().startsWith(search.value.toLowerCase()));
});

const isCountrySelected = (country: any) => {
  const selected = value.value || [];
  return selected.includes(country.value);
};

const value = computed(() => {
    return formValues.markets;
});

const initialMarkets = computed(() => {
  return formMeta.value.initialValues?.markets;
});

const isInitialCountry = (country: any) => {
  return initialMarkets.value?.includes(country.value);
};

const toggleCountry = (country: any) => {
  if (isInitialCountry(country)) {
    return;
  }

  const markets = value.value ? [...value.value] : [];
  const index = markets.indexOf(country.value);
  if (index === -1) {
    markets.push(country.value);
  } else {
    markets.splice(index, 1);
  }

  setFieldValue('markets', markets);
};

const popularCountries = computed(() => {
  const popular = ["DK", "NO", "SE", "FI", "DE", "NL", "GB", "FR"];
  const found = countries.filter((country) => popular.includes(country.value.toUpperCase()));

  //return found sorted by popular
  return found.sort((a, b) => popular.indexOf(a.value.toUpperCase()) - popular.indexOf(b.value.toUpperCase()));
});

const search = ref("");
</script>
