<template>
    <div class="text-center h-[60vh] overflow-y-auto py-3 px-6 flex gap-[60px]">
        <SideBar :selectedServices="selectedServices" :services="ref_services" @valueChange="val => selectService(val)" />
        <div class="w-full" v-for="service in ref_services" :class="visibleService.label == service.label ? 'block' : 'hidden'">
            <p class=" text-secondary text-title-section text-left mb-3">{{ service.label }}</p>
            <p class=" text-tertiary text-body-default text-left mb-6">{{ service.description }}</p>
            <div class="w-full mb-6">
                <SettingsToggle @update:model-value="val => addValidationRules(val, service)" :name="`services.${returnKey(service)}.isSet`" :text="t('add_unit_to_shipment', {unit: service.label})" />
            </div>
            <div class="w-full mb-6" v-if="service.type == 'select' && selectedServices.includes(returnKey(service))" >
                <SelectComponent :service="service" @dateUpdate="val => emit('dateUpdate', val)"></SelectComponent>
            </div>
            <div class="w-full mb-6" v-if="service.type == 'dot_delivery' && selectedServices.includes(returnKey(service))" >
                <DotDeliveryComponent :service="service" @dateUpdate="val => emit('dateUpdate', val)"></DotDeliveryComponent>
            </div>
            <div class="w-full mb-6" v-if="service.type == 'multi_select' && selectedServices.includes(returnKey(service))" >
                <MultiSelectComponent :service="service"></MultiSelectComponent>
            </div>
            <div class="w-full mb-6 flex gap-5" v-if="service.type == 'field_array' && selectedServices.includes(returnKey(service))">
                <div class="max-w-[100px] text-left" v-for="field in service.fields">
                    <InputText type="number" :name="`services.${returnKey(service)}.${field.key}`" class="w-full" :label="field.label" suffix="stk." />
                </div>
            </div>
            <div class="w-full mb-6 " v-if="service.type == 'object_array' && selectedServices.includes(returnKey(service))">
                <ObjectComponent :service="service"></ObjectComponent>
            </div>
            <div v-if="service.link">
                <p class=" text-tertiary text-body-lg-heavy mb-3 text-left">{{ t('useful_links') }}</p>
                <a :href="JSON.parse(service.link)" target="_blank" rel="noopener noreferrer">
                    <Button type="button" class="w-full max-w-max flex mb-2" variant="primary-link" textLeft>
                        {{ t('read_more_about', {entity: service.label.toLocaleLowerCase()}) }}
                        <Icon class="mt-0.5" src="arrow_up_right" size="medium"></Icon>
                    </Button>
                </a>
            </div>
            <!--<Divider></Divider>
            <Button type="button" class="w-full flex mt-2" variant="primary-link" textLeft>
                Disse typer tilbydes der ikke indbæring af
                <Icon class="mt-0.5 ml-auto" src="arrow_up_right" size="medium"></Icon>
            </Button>-->
        </div>
    </div>
</template>


<script setup lang="ts">
    import SideBar from './extraServiceComponents/sideBar.vue';
    import SelectComponent from './extraServiceComponents/selectComponent.vue';
    import ObjectComponent from './extraServiceComponents/objectComponent.vue';
    import DotDeliveryComponent from './extraServiceComponents/dotDeliveryComponent.vue';
    import MultiSelectComponent from './extraServiceComponents/multiSelectComponent.vue';
    const { t } = useI18n();
    const yup = useYup();

    const emit = defineEmits(['addExtraService', 'dateUpdate', 'updateSelectValue', 'updateValidation']);

    const selectedService = ref(null);

    const props = defineProps<{
        services: any;
        values: any;
    }>();

    const ref_services = ref(props.services);

    const { translateFieldsToValidation } = useValidation();

    watch(() => props.services, (val) => {
        ref_services.value = val;
    });

    const returnKey = (service: Object) => {
        return service.carrier_key == 'service_codes' ? service.fields.key : service.carrier_key
    }

    const selectedServices = computed(() => {
        return Object.keys(props.values.services).filter(key =>
        {
            return props.values.services[key]?.isSet
        })
    });

    const visibleService = computed(() =>  {
        return selectedService.value ? selectedService.value : Object.values(ref_services)[3][0];
    });

    const addValidationRules = async (val, service) => {
        if(service.carrier_key == 'service_codes') {
            if (val) {
                emit('updateValidation', {fields: {
                [service.fields.key]: yup.object({
                    isSet: yup.boolean(),
                }) }, step: 3});
            } else {
                emit('updateValidation', {fields: {
                [service.fields.key]: yup.object({
                }) }, step: 3});
            }
        } else {
            let fields = await translateFieldsToValidation(service, val);
            emit('updateValidation', {fields: fields, step: 3});
        }
    }

    const selectService = (val) => {
        selectedService.value = val;
    }

</script>