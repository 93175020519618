<template>
    <div class="w-full flex">
        <div class="h-11 w-11 justify-center items-center flex rounded-[10px] mr-3"
            :class="{
                'bg-[#7AB55C]' : shop.platform == 'shopify',
                'bg-[#92588B]' : shop.platform == 'woocommerce',
                'bg-[#E56624]' : shop.platform == 'magento-1' || shop.platform == 'magento-2',
                'bg-[#3E9FFF]' : shop.platform == 'shopware',
                'bg-foreground-brand' : shop.platform == 'custom'
                }">
            <Icon v-if="shop.platform == 'shopify'"size="large" src="custom/shopIcons/Shopify" class=" text-white" />
            <Icon v-else-if="shop.platform == 'shopware'" size="large" src="custom/shopIcons/Shopware" class=" text-white" />
            <Icon v-else-if="shop.platform == 'magento-1' || shop.platform == 'magento-2'" size="large" src="custom/shopIcons/Magento" class=" text-white" />
            <Icon v-else-if="shop.platform == 'woocommerce'" size="large" src="custom/shopIcons/Woo" class=" text-white" />
            <Icon v-else-if="shop.platform == 'custom'" size="large" src="custom/shopIcons/Custom" class=" text-white" />
        </div>
        <div class="mr-4">
            <p class="text-secondary text-body-lg-heavy">{{ shop.name }}</p>
            <p class="text-quarterary text-body-default capitalize">{{ shop.platform }}</p>
        </div>
        <DropdownSelect @select="val => selectCheckout(val)" class="ml-auto mr-2" v-model="selectedCheckout" :triggerText="t('add_checkout')" :options="checkouts"  />
        <Button class="mr-2" variant="tertiary" size="default" @click="toggleDropdown" ref="trigger" :active="showDropdown">
            <Icon src="bold/dots_three_vertical" class="aspect-square" />
        </Button>
        <Dropdown v-model:open="showDropdown" ref="dropdown" :toggleElement="trigger" :calculatePosition="calculatePosition">
            <div class="flex flex-col space-y-1 dropdown__content p-1.5">
                <DropdownItem @click="() => openEditShop()">
                    <div class="flex items-center gap-2 w-full">
                        <div class="text-nowrap overflow-hidden text-ellipsis">{{ t('edit') }}</div>
                    </div>
                </DropdownItem>
                <DropdownItem variant="destructive" @click="() => deleteShop()">
                    <div class="flex items-center gap-2 w-full">
                        <div class="text-nowrap overflow-hidden text-ellipsis">{{ t('delete') }}</div>
                    </div>
                </DropdownItem>
            </div>
        </Dropdown>
        <Button @click="toggleShop()" class="mr-2" variant="tertiary" :active="isOpen" size="default"><Icon class="transform transition duration-300" :class="{'-rotate-180' : isOpen }" size="medium" src="caret_down" /></Button>
    </div>
    <div class="overflow-hidden grid transition-all duration-300" :class="isOpen ? 'grid-rows-1fr' : 'grid-rows-0'">
        <div class="w-full bg-surface-lvl-01 overflow-hidden rounded-xl flex gap-1.5 mt-2">
            <div class="min-w-[120px] py-3 pl-3">
                <p class=" text-quarterary text-body-sm mb-1.5">{{ t('website') }}</p>
                <p class=" text-quarterary text-body-sm mb-1.5">{{ t('checkout') }}</p>
                <p class=" text-quarterary text-body-sm mb-1.5">{{ t('token') }}</p>
                <p class=" text-quarterary text-body-sm mb-1.5">{{ t('activated') }}</p>
                <p v-if="shop.activation_expires_at && !isExpired()" class=" text-quarterary text-body-sm">{{ t('activation_expires_at') }}</p>
                <p v-if="shop.activation_expires_at && isExpired()" class=" text-quarterary text-body-sm">{{ t('activation_expired') }}</p>
            </div>
            <div class="py-3 pr-3">
                <p class=" text-quarterary text-body-sm mb-1.5">{{ shop.shop_url ?? '-' }}</p>
                <p class=" text-quarterary text-body-sm mb-1.5">{{ shop?.checkout?.name ?? '-' }}</p>
                <p class=" text-quarterary text-body-sm mb-1.5">{{ shop.shop_token ?? '-' }}</p>
                <p class=" text-quarterary text-body-sm mb-1.5">{{ shop.activated ? t('yes') : t('no') }}</p>
                <p v-if="shop.activation_expires_at && !isExpired()" class=" text-quarterary text-body-sm">{{ formatDate(DateTime.fromSQL(shop.activation_expires_at)) }}</p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import { DateTime } from 'luxon';

export interface Webshop {
        activated: Boolean;
        activation_code: string;
        activation_expires_at: Date;
        checkout: Checkout;
        checkout_id: number;
        created_at: Date;
        customer_id: number;
        id: number;
        image_url: string;
        name: string;
        pingback_url: string;
        platform: string;
        shop_token: string;
        shop_url: string;
        updated_at: Date;
        version: string;
    }
    export interface Checkout {
        created_at: Date;
        customer_id: number;
        description: string;
        id: number;
        label: string;
        name: string;
        updated_at: Date;
    }

const { t } = useI18n();

const emit = defineEmits(["update", "openedit"]);

const isOpen = ref<boolean>(false);

const props = defineProps<{
    shop: Webshop;
    checkouts: { label: string; value: string; icon: string }[];
}>();

const selectedCheckout = computed(() => {
    return props.shop.checkout_id;
});

const openEditShop = () => {
    emit('openedit', props.shop);
};

const isExpired = () => {
    if (!props.shop.activation_expires_at) {
        return false;
    } else {
        return DateTime.fromSQL(props.shop.activation_expires_at) < DateTime.now();
    }
};

const selectCheckout = (val: number) => {
    let shop = { ...props.shop };
    shop.checkout_id = val;
    homeFetch(`workspace/webshops/${props.shop.id}`, {
        method: "PUT",
        body: {
            ...shop
        }
    }).then((r) => {
        emit('update');
    });
};


const deleteShop = () => {
  $confirm({
    message: t("webshop_delete_confirm", { shop: props.shop.name }),
    onConfirm: () => {
      homeFetch(`workspace/webshops/${props.shop.id}`, { method: "DELETE" })
        .then((response) => {
            emit('update');
        })
    },
    destructive: true,
  });
};

const toggleShop = () => {
    isOpen.value = !isOpen.value;
};

const { dropdown, calculatePosition, trigger, showDropdown, toggleDropdown } = useDropdown();
</script>