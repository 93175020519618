<template>
  <div class="flex gap-2 items-center">
    <div class="text-body-sm text-quarterary">{{ t("preview_checkout_in") }}</div>
    <DropdownSelect trigger-type="button" trigger-button-variant="tertiary" size="small" :options="markets" v-model="previewCountry" :readonly="markets.length <= 1"></DropdownSelect>
  </div>
  <div class="flex flex-col gap-2.5">
    <div v-if="!activeShippingMethodsForCountry.length">
      <div class="text-body-default-heavy text-tertiary">{{ t("no_shipping_methods_for_country") }}</div>
    </div>
    <template v-for="(sm, idx) in activeShippingMethodsForCountry" :key="sm.cps">
      <div class="flex w-full gap-3 items-center">
        <div class="flex gap-3 w-full min-w-0 items-center">
          <Avatar
            size="xs"
            :entity="{
              name: sm.name,
              image: getCarrierLogoUrl(sm.carrier),
            }"
          />
          <div class="flex flex-col w-full min-w-0">
            <div class="text-body-lg-heavy whitespace-nowrap text-ellipsis overflow-hidden flex gap-3 items-center">
              <div>{{ sm.name }}</div>
              <div v-if="sm.label">
                <BadgeChip type="bold"> {{ sm.label }} </BadgeChip>
              </div>
            </div>
            <div class="text-body-sm text-tertiary">{{ sm.description }}</div>
          </div>
        </div>
        <div class="flex gap-3 flex-nowrap text-nowrap">
          <div class="text-body-default-heavy">{{ formatPrice(sm.rules[0].price || 0, 'force', '')}}</div>
        </div>
      </div>

      <Divider :spacing="false" v-if="idx < activeShippingMethodsForCountry.length - 1" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { countryFilter } from "./SimpleRuleBuilder.vue";

const props = withDefaults(
  defineProps<{
    markets: any[];
    shippingMethods: any[];
  }>(),
  {
    markets: () => [],
    shippingMethods: () => [],
  }
);

const { t } = useI18n();

const previewCountry = ref(props.markets[0].value);

const activeShippingMethodsForCountry = computed(() => {
  return props.shippingMethods.filter((sm: any) => sm.active && countryFilter(sm, previewCountry.value)).sort((a, b) => a.rules[0].price - b.rules[0].price);
});

const setPreviewCountry = (country: string) => {
  previewCountry.value = country;
};

defineExpose({
  setPreviewCountry,
});
</script>
