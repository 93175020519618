export default () => {
  const { t } = useI18n();
  const icon = 'user_circle_plus';
  const color = 'lemon';
  const title = t('boarding.setup_invoicing_responsible.title');
  const description = t('boarding.setup_invoicing_responsible.description');
  const key = 'invoicing_responsible';

  return {
    icon,
    color,
    title,
    description,
    key,
  };
}