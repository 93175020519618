<template>
  <div class="flex items-stretch group">
    <Avatar
      v-for="(entity, idx) in visibleEntities"
      :index="idx"
      class="border-2  border-surface-lvl-00 rounded-lg [&_.shadow-avatar-border]:shadow-none group-hover:border-inherit"
      :entity="entity"
      v-bind="props"
      :class="{
        '-ml-3': idx !== 0,
      }"
    />
    <div class="h-full" v-if="numberOfHiddenEntities">
      <Tooltip class="first-child:h-full first-child:flex h-full">
        <div
          class="h-full flex items-center justify-center text-tertiary text-body-default-heavy w-fit bg-01 border-2 group-hover:border-inherit border-surface-lvl-00 rounded-lg -ml-3 px-0.5"
        >
          +{{ numberOfHiddenEntities }} <Icon src="bold/CaretDown" size="small" />
        </div>

        <template #body>
          <div class="flex flex-col gap-1 py-1 text-surface-lvl-00 w-48 text-body-sm">
            {{hiddenEntities.map((entity) => entity.name).join(", ")}}
          </div>
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
export interface AvatarGroupProps {
  size?: "3xs" | "2xs" | "xs" | "sm" | "md" | "lg" | "xl";
  entities?: {
    name?: string;
    email?: string;
    image?: string | null | File;
  }[];
  useLetters?: boolean;
  color?: "tangerine" | "raspberry" | "lemon" | "grape" | "kiwi";
  randomColor?: boolean;
  maxLetters?: 1 | 2;
  maxAvatars?: number;
}

const props = withDefaults(defineProps<AvatarGroupProps>(), {
  maxAvatars: 4,
});

const visibleEntities = computed(() => props.entities?.slice(0, props.maxAvatars));
const hiddenEntities = computed(() => props.entities?.slice(props.maxAvatars));
const numberOfHiddenEntities = computed(() => hiddenEntities.value?.length);
</script>
