<template>
  <div class="flex flex-col gap-6 @container" ref="flowWrapper">
    <!--<Callout type="aware" icon="filled/WarningDiamond" class="">
            {{ t('settings.return_portal.not_yet_implemented') }}
    </Callout>-->

    <div class="flex gap-4 justify-between">
      <div class="flex-col w-full">
        <div class="text-body-lg-heavy">
          {{ t("settings.return_portal.label.title") }}
        </div>
        <div class="text-body-default text-quarterary">
          {{ t("settings.return_portal.label.description") }}
        </div>
      </div>
      <Button class="mt-auto" variant="primary" @click="createOpen = true">{{ t("create_return_portal") }}</Button>
    </div>
    <Divider />

    <EmptyView v-show="hasNoReturnPortals" :title="t('settings.return_portal.empty.title')" :description="t('settings.return_portal.empty.description')">
      <template #illustration>
        <img src="~/assets/illustrations/emptyViews/ec-safe-browser-shopping.svg" alt="No return portals illustration" />
      </template>
    </EmptyView>

    <div v-if="!hasNoReturnPortals" class="flex flex-col gap-6">
      <template :key="portal.uid" v-for="(portal, idx) in portals">
        <ListItem :portal="portal" :link="getLinkToReturnPortal(portal)" @update="fetchReturnPortals()" @editPortal="(val) => openEdit(val)" :checkouts="checkouts"/>
        <Divider v-if="idx !== portals.length - 1" />
      </template>
    </div>
    <SetupModal
      @update="fetchReturnPortals()"
      :portal="selectedPortal"
      :information="information"
      :open="createOpen"
      @close="[(createOpen = false), (selectedPortal = null)]"
    />
  </div>
</template>

<script setup lang="ts">
import ListItem from "../components/ReturnPortal/ListItem.vue";
import type { ISettingsFlow } from "../types";
import SetupModal from "../components/ReturnPortal/SetupModal.vue";
import type { IReturnPortal } from "~/types/returns";

const { t } = useI18n();
const loading = ref<boolean>(false);

const props = withDefaults(
  defineProps<{
    currentHeader?: string | null;
  }>(),
  {
    currentHeader: null,
  }
);

const createOpen = ref(false);
const checkouts = ref<{ label: string; value: string; icon: string }[]>([]);

const selectedPortal = ref<Object | null>(null);

const information = ref<OrganisationInformation>({} as OrganisationInformation);

const getLinkToReturnPortal = (portal?: IReturnPortal) => {
  const { getAccount, getAuthCookie, getAuthCookieExpiresIn } = useAuth();
  const account = getAccount();
  const authToken = getAuthCookie()?.value;
  const expiresIn = getAuthCookieExpiresIn();

  const id = portal?.uid || "new";

  return `https://${account?.subdomain}.homerunner.com/login/o?token=${authToken}&expires_in=${expiresIn}&nextUrl=/returns/${id}`;
};

const portals = ref<IReturnPortal[]>([]);

const openEdit = (portal: Object) => {
  selectedPortal.value = portal;
  createOpen.value = true;
};

const hasNoReturnPortals = computed(() => portals.value.length === 0 && !loading.value);

const fetchReturnPortals = () => {
  setLoading(true);
  return homeFetch("workspace/return-portals")
    .then((response) => {
      if (response?.data) {
        portals.value = response.data as IReturnPortal[];
      }
    })
    .finally(() => {
      setLoading(false);
    });
};

onMounted(() => {
  fetchReturnPortals().then(() => {
    goToCurrentHeader();
    fetchInformation();
  });
  getCheckouts();
});

const fetchInformation = async () => {
  let info = await getOrganisationInfo();
  information.value = info;
};

const { checkoutsForSelect } = useCheckouts();

const getCheckouts = async () => {
  const fetchedCheckouts = await checkoutsForSelect();
  checkouts.value = fetchedCheckouts.checkouts.map((checkout: IShopCheckout) => {
    return {
      label: checkout.name,
      value: checkout.id,
      icon: "bold/CreditCard",
    };
  });
};
const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

const emits = defineEmits(["update:loading"]);
const setLoading = (l: boolean) => {
  loading.value = l;
  emits("update:loading", l);
};
</script>

<script lang="ts">
export const useReturnPortalSettings = (): ISettingsFlow => {
  const { t } = useI18n();
  const icon = "regular/ArrowCounterClockwise";
  const title = t("settings.return_portal.title");
  const pageTitle = t("settings.return_portal.page_title");
  const key = "returnPortals";
  const condition = () => !useAuth().isDFM.value;
  const group = "setup";

  return {
    icon,
    pageTitle,
    title,
    key,
    condition,
    group
  };
};
</script>
