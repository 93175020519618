import EventBus from "@/plugins/eventbus";
import { inject, ref } from "vue";
import type { IFormWizardService } from "./types";

export const open = ref(false);

/**
 * A unique identifier to access the provided/injected method
 */
export const FormWizardSymbol = Symbol();

/**
 * Composable which allows accessing the FormWizard service in Composition API
 * @returns FormWizard
 */
export function useFormWizard() {
  const FormWizard = inject(FormWizardSymbol);
  if (!FormWizard) {
    throw new Error("No Form provided!");
  }

  return FormWizard;
}

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const FormWizardService = {
  install: (app) => {
    const FormWizardService: IFormWizardService = {
      toggle: () => {
        EventBus.$emit("FORMWIZARD_TOGGLE");
      },
      open: () => {
        EventBus.$emit("FORMWIZARD_OPEN");
      },
      close: () => {
        EventBus.$emit("FORMWIZARD_CLOSE");
      },
    };
    app.config.globalProperties.$FormWizard = FormWizardService;
    if (typeof window !== "undefined") window.$formwizard = FormWizardService;
    app.provide(FormWizardSymbol, FormWizardService);
  },
};