<template>
  <div class="flex flex-col gap-1 relative w-full" :class="[{ error: !!error }]" @click="() => emit('click')">
    <p v-if="label" class="text-secondary text-body-sm-heavy w-full text-center">{{ label }}</p>
    <div
      class="input-wrapper rounded-full text-title-screen relative px-5 h-[82px] flex gap-2 overflow-hidden items-center justify-center cursor-text"
      @click.self="focusInput"
    >
      <div
        contenteditable
        ref="input"
        @blur="handleBlur"
        @keypress="handleChange"
        @keydown.delete="handleChange"
        @keydown.enter.prevent
        class="min-w-5 whitespace-nowrap overflow-hidden"
      >
        {{ inputValue }}
      </div>
      <div class="flex-shrink-0" @click.self="onSuffixClick">
        {{ suffix }}
      </div>
    </div>
    <div v-if="message" class="text-body-default-heavy text-quarterary text-center justify-center">{{ message }}</div>

    <Message v-if="error" variant="invalid">{{ error }}</Message>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

defineOptions({
  name: "ValueXL",
});

const modelValue = defineModel();
const { locale } = useI18n();

const input = ref<HTMLElement | null>(null);

const props = withDefaults(
  defineProps<{
    error?: string | null;
    type?: "text" | "number";
    name?: string;
    suffix?: string;
    label?: string;
    message?: string;
  }>(),
  {
    error: null,
    type: "text",
    name: "",
    suffix: "",
    label: "",
  }
);

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  meta,
  setValue,
} = useField(() => props.name, undefined, {
  syncVModel: props.name ? false : true,
  controlled: !!props.name,
});

const handleMetaCtrl = ($evt) => {
  console.log($evt);
};

const handleChange = ($evt) => {
  const { target, key, ctrlKey, metaKey } = $evt;

  const isDecimalSeparator = key === "." || key === ",";
  if (isDecimalSeparator) {
    if (target.textContent.includes(".") || target.textContent.includes(",")) {
      $evt.preventDefault();
      return;
    }

    if (target.textContent.length === 0) {
      $evt.preventDefault();
      document.execCommand("insertText", false, "0");
    }

    if (key == ",") {
      $evt.preventDefault();
      //insert dot instead of comma
      document.execCommand("insertText", false, ".");
    }
  }

  const isDeleteOrBackspace = key === "Backspace" || key === "Delete";
  const isNumeric = !isNaN(Number($evt.key));
  if (!isNumeric && !isDecimalSeparator && !isDeleteOrBackspace && !(ctrlKey || metaKey)) {
    $evt.preventDefault();
  } else {
    setTimeout(() => {
      const cursorPosition = getCurrentCursorPosition();

      setValue(target.textContent);
      nextTick(() => {
        setCursorPosition(cursorPosition);
      });
    });
  }
};

const emit = defineEmits(["click", "click:suffix"]);

const focusInput = ($evt) => {
  //check if click events is right or left from the input
  if ($evt.clientX > input.value?.getBoundingClientRect().right) {
    focusRightSide();
  } else {
    input.value?.focus();
  }
};

const focusRightSide = () => {
  const range = document.createRange();
  const sel = window.getSelection();
  range.selectNodeContents(input.value as Node);
  range.collapse(false);
  sel?.removeAllRanges();
  sel?.addRange(range);
};

const onSuffixClick = ($evt) => {
  emit("click:suffix");
  focusInput($evt);
};

const getCurrentCursorPosition = () => {
  const sel = window.getSelection();
  if (sel?.rangeCount) {
    const range = sel.getRangeAt(0);
    return range.startOffset;
  }
  return 0;
};

const setCursorPosition = (position: number) => {
  const range = document.createRange();
  const sel = window.getSelection();
  range.setStart(input.value?.firstChild as Node, position);
  range.collapse(true);
  sel?.removeAllRanges();
  sel?.addRange(range);
};

defineExpose({
  input,
});
</script>

<style scoped>
.input-wrapper,
.input-wrapper div:first-child {
  @apply bg-00 text-tertiary placeholder:text-quarterary hover:placeholder:text-tertiary read-only:text-disabled box-border;
}
.error .input-wrapper,
.input-wrapper div:first-child {
  /** remove focus outline using tailwind */
  @apply focus:outline-none;
}

.input-wrapper:focus-within {
  /** Add focus outline using tailwind */
  @apply ring-2 ring-core-100 ring-offset-1;
}

.error .input-wrapper div:first-child {
  @apply border-none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
