<template>
  <div class="flex flex-col gap-6 @container" ref="flowWrapper"> 
    <div class="flex flex-col gap-2 @sm:flex-row @sm:gap-5 justify-between items-end">
      <div class="settings-flow-header hidden">
        {{ t("settings.users.invite_with_mail") }}
      </div>
      <InputText
        size="large"
        v-model="invitation.email"
        :label="t('settings.users.invite_with_mail')"
        :placeholder="t('settings.users.invite_placeholder')"
        class="w-full"
      >
        <template #button>
          <DropdownSelect :options="roles" v-model="invitation.role">{{ t("settings.users.send_invitation") }}</DropdownSelect>
        </template>
      </InputText>
      <Button class="w-full @sm:w-auto" size="large" :disabled="!isEmailValid" :loading="creating" @click="createUser">{{
        t("settings.users.send_invitation")
      }}</Button>
    </div>

    <div>
      <TabBar class="!h-11 mb-3 bg-surface-lvl-00 z-50">
        <Tab active>
          {{ t("settings.users.members") }}
          <div class="border border-01 text-secondary rounded-full px-2 text-button-sm">{{ usersCount }}</div>
        </Tab>
        <div class="flex justify-end items-end w-full mb-1.5">
          <InputText size="small" :placeholder="t('settings.users.search')" v-model="search">
            <template #prefix>
              <Icon src="MagnifyingGlass" size="medium" class="text-quarterary" />
            </template>
          </InputText>
        </div>
      </TabBar>

      <div>
        <UserRow v-for="user in filteredUsers" :user="user" :roles="roles" @fetch="fetchUsers" />
      </div>
    </div>

  </div>
  <NotificationWrapper />

</template>

<script setup lang="ts">
import type { ISettingsFlow } from "../types";
import UserRow from "../components/UserRow.vue";
import NotificationWrapper from "../components/NotificationWrapper.vue";
const props = withDefaults(
  defineProps<{
    currentHeader?: string | null;
  }>(),
  {
    currentHeader: null,
  }
);
const { t } = useI18n();
const users = ref<Record<string, any>>([]);
const creating = ref(false);

const roles = [
  { value: "1", label: t("settings.users.roles.owner") },
  { value: "3", label: t("settings.users.roles.support") },
];
const invitation = ref<{
  email: string;
  role: string;
}>({
  email: "",
  role: "1",
});

const search = ref("");

const filteredUsers = computed(() => {
  return users.value.filter((u) => {
    return u.email.toLowerCase().includes(search.value.toLowerCase()) || u.name.toLowerCase().includes(search.value.toLowerCase());
  });
});

const usersCount = computed(() => users.value?.length || 0);

const fetchUsers = () => {
  setLoading(true);
  return homeFetch("workspace/users")
    .then((response) => {
      if (response?.data) {
        users.value = response.data?.users || [];
      }
    })
    .finally(() => {
      setLoading(false);
    });
};

const createUser = () => {
  $confirm({
    message: t("settings.users.confirm_invitation", {
      user: invitation.value.email,
      role: roles.find((r) => r.value == invitation.value.role)?.label,
    }),
    onConfirm: () => {
      creating.value = true;

      homeFetch(`workspace/users`, {
        method: "POST",
        body: {
          emails: [invitation.value.email],
          roles: [invitation.value.role],
        },
      })
        .then(() => {
          $toast.add({
            title: t("settings.users.invitation.success", {
              user: invitation.value.email,
              role: roles.find((r) => r.value == invitation.value.role)?.label,
            }),
            icon: "users",
            target: "#settings-modal .notification-wrapper",
          });
          invitation.value.email = "";
          invitation.value.role = "1";
          fetchUsers();
        })
        .finally(() => {
          creating.value = false;
        });
    },
  });
};

onMounted(() => {
  fetchUsers().then(() => {
    goToCurrentHeader();
  });
});

const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

const emits = defineEmits(["update:loading"]);
const setLoading = (l: boolean) => emits("update:loading", l);

const isEmailValid = computed(() => {
  if (!invitation.value.email) return false;
  return !!invitation.value.email.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/);
});
</script>

<script lang="ts">
export const useUserSettings = (): ISettingsFlow => {
  const { t } = useI18n();
  const icon = "regular/users";
  const title = t("settings.users.title");
  const pageTitle = t("settings.users.page_title");
  const key = "users";
  const description = t("settings.users.description");
  const headers = [t("settings.users.invite_with_mail")];

  return {
    icon,
    pageTitle,
    title,
    key,
    description,
    headers,
  };
};
</script>
