<template>
  <canvas ref="canvas" class="w-full h-full rounded-inherit"></canvas>
</template>

<script setup lang="ts">
import { Layout, Fit, Alignment, Rive } from '@rive-app/canvas';

const canvas = ref<HTMLElement>();

const props = withDefaults(
  defineProps<{
    src: string
  }>(),
  {
  }
);


// ---------------------------------
// The layout of the graphic will adhere to
const layout = new Layout({
  fit: Fit.Contain, // Change to: rive.Fit.Contain, or Cover
  alignment: Alignment.Center,
});

onMounted(() => {
  nextTick(() => {
    const riveInstance = new Rive({
    canvas: canvas.value,
    autoplay: true,
    layout,
    onLoad: () => {
      // Prevent a blurry canvas by using the device pixel ratio
      riveInstance.resizeDrawingSurfaceToCanvas();
    },
    src: props.src,
  })
  })
})




</script>