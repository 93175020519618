<template>
  <div class="w-full">
    <p class="mb-3" v-if="label">{{ label }}</p>
    <div class="flex flex-col gap-3">
      <div
        v-for="(option, index) in values"
        class="flex text-left cursor-pointer transition duration-300 list-select-option"
        :class="{
          'shadow-inset-01+01': isSelected(option) && !simple,
          'shadow-inset-00+01': !isSelected(option) && !simple,
          'rounded-xl px-4 py-3 gap-4': !simple,
          'gap-2.5': simple,
        }"
        @click="() => setValue(option)"
      >
        <TickerRadio
          v-if="ticker && !multi"
          class="cursor-pointer pointer-events-none mt-px"
          :class="{
            'my-auto': radioPosition == 'center',
            'mb-auto': radioPosition == 'top',
            'mt-auto': radioPosition == 'bottom',
          }"
          :selected="isSelected(option)"
        ></TickerRadio>

        <TickerCheckbox
          v-if="ticker && multi"
          class="cursor-pointer pointer-events-none mt-px"
          :class="{
            'my-auto': radioPosition == 'center',
            'mb-auto': radioPosition == 'top',
            'mt-auto': radioPosition == 'bottom',
          }"
          :model-value="isSelected(option)"
        ></TickerCheckbox>

        <slot name="avatar" :value="option"></slot>
        <slot name="title" :value="option"></slot>
        <slot name="value" :value="option"></slot>
      </div>
      <Divider class="!my-3" v-if="simple && index !== values.length - 1"></Divider>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();
const emit = defineEmits(["update:modelValue", "fullOption"]);
const props = withDefaults(
  defineProps<{
    values: any[] | Record<string, any>;
    name?: string;
    valueKey?: string;
    radioPosition?: string;
    simple?: boolean;
    label?: string;
    ticker?: boolean;
    multi?: boolean;
  }>(),
  {
    values: () => [],
    name: "",
    valueKey: "id",
    radioPosition: "center",
    simple: false,
    label: "",
    ticker: true,
    multi: false,
  }
);

const selectedValue = defineModel();

const setValue = (option: object) => {
  if (!props.multi) {
    if (value.value === option[props.valueKey]) {
      emit("fullOption", option);
      emit("update:modelValue", null);
      setFormValue(null);
    } else {
      setFormValue(option[props.valueKey]);
      emit("fullOption", option);
      emit("update:modelValue", option[props.valueKey]);
    }
  } else {
    const selectedValues = value.value || [];
    const index = selectedValues.indexOf(option[props.valueKey]);
    if (index === -1) {
      selectedValues.push(option[props.valueKey]);
    } else {
      selectedValues.splice(index, 1);
    }
    setFormValue(selectedValues);
    emit("update:modelValue", selectedValues);
  }
};

const isSelected = (option: object) => {
  if (!props.multi) {
    return value.value === option[props.valueKey];
  }

  const selectedValues = value.value || [];

  return selectedValues?.includes(option[props.valueKey]);
};

const {
  value,
  errorMessage,
  handleBlur,
  meta,
  setValue: setFormValue,
} = useField(() => props.name, undefined, {
  syncVModel: props.name ? false : true,
  controlled: !!props.name,
});
</script>
