import qs from "qs";
const query = ref<Record<string, any>>({});

const queryString = computed(() => {
  return qs.stringify(query.value, {
    encodeValuesOnly: true, // prettify URL
  });
});

export default () => {
  const getQueryFromRoute = () => {
    return qs.parse(qs.stringify(useRoute().query, { encodeValuesOnly: true }));
  };

  const setQueryFromRoute = () => {
    setQuery(getQueryFromRoute(), false);
  };

  const setQuery = (q: Record<string, any>, navigate: boolean) => {
    //remove empty values from query
    Object.keys(q).forEach((key) => {
      if (q[key] === "" || q[key] === null || q[key] === undefined) {
        delete q[key];
      }
    });

    setBrowserHistory(q);
  };

  const resetQuery = () => {
    setQueryFromRoute();
  };

  const setBrowserHistory = (q: Record<string, any>) => {
    useRouter()
      .replace(useRoute().path + "?" + qs.stringify(q, { encodeValuesOnly: true }))
      .then(() => {
        query.value = q;
      });
  };

  const setQueryParam = (val: any, key?: string | null) => {
    let q: Record<string, any> = {};
    if (key) {
      q = {
        ...query.value,
        [key]: val,
      };
    } else {
      q = { ...query.value, ...val };
    }

    if (key && ["filters", "sort", "limit", "search"].includes(key)) {
      //remove page from query if filters, sort or limit are changed
      delete q.page;
    }

    setBrowserHistory(q);

  };

  const getQueryParam = (key: string) => {
    return getQueryFromRoute()[key];
  };


  return {
    setQueryParam,
    resetQuery,
    setQuery,
    getQueryFromRoute,
    getQueryParam,
  };
};
