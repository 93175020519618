<template>
  <Button
    :variant="buttonVariant"
    :size="buttonSize"
    icon="arrow-left"
    ref="trigger"
    @click="toggleDropdown"
    type="button"
  >
    <template #prefix> <Icon src="plus" /> </template>
    {{ buttonText || t("add_entity", { entity: t("filter.label").toLowerCase() }) }}
  </Button>

  <Dropdown
    :toggle-element="trigger.el"
    ref="dropdown"
    v-model:open="showDropdown"
    v-if="trigger"
    :calculatePosition="calculatePosition"
    :overlay="false"
    minWidth="399px"
  >
    <DropdownItemSearch
      v-model="search"
      ref="searchElm"
      :placeholder="t('filter.search_filters')"
      @keydown.down.prevent="() => setFocusOnItem(-1, 'down')"
      @keydown.up.prevent="() => setFocusOnItem(-1, 'up')"
    />
    <Divider :spacing="false"/>
    <div class="max-h-64 overflow-y-auto p-1.5" ref="optionsWrapper">
      <DropdownItem
        v-for="(option, index) in filteredOptions"
        :key="`${search}_${option.value}`"
        @click="() => selectOption(option.value)"
        @keydown.enter.prevent="() => selectOption(option.value)"
        @keydown.down.prevent="() => setFocusOnItem(index, 'down')"
        @keydown.up.prevent="() => setFocusOnItem(index, 'up')"
      >
        {{ option.label }}
      </DropdownItem>
      <DropdownItem v-if="filteredOptions.length === 0" disabled> {{ t("filter.no_filters_available") }} </DropdownItem>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from "vue";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    options: { label: string; value: string }[];
    buttonVariant?: ButtonVariant;
    buttonSize?: string
    buttonText?: string;
  }>(),
  {
    options: () => [],
    context: "content",
    buttonVariant: "primary",
    buttonSize: "default"
  }
);
const modelValue = ref("");
const emit = defineEmits(["select", "update:modelValue"]);
const options = computed(() => props.options);

const { dropdown, trigger, showDropdown, toggleDropdown, setStyleProperty } = useDropdown();

const calculatePosition = (cb?: null | Function) => {
  //calculate dropdown position based on tr position
  const triggerRect = trigger.value?.el ? trigger.value?.el.getBoundingClientRect() : trigger.value?.getBoundingClientRect();
  triggerRect.width = triggerRect.width || 0;
  if (triggerRect) {
    //get dropdown height
    const dropdownHeight = dropdown.value?.height || 0;

    //get dropdown width
    const dropdownWidth = dropdown.value?.width || 0;

    let top = triggerRect.top;
    let left = triggerRect.left - (dropdownWidth - triggerRect.width) / 2;

    //if top is below the bottom of the table, move it up
    if (top + 300 > window.innerHeight) top = top - dropdownHeight;

    //if left is outside the window, move it to the left with 10px to spare
    if (left + dropdownWidth > window.innerWidth) left = window.innerWidth - dropdownWidth - 20;
    setStyleProperty("top", `${top}px`);
    setStyleProperty("left", `${left}px`);
  }

  if (cb && typeof cb === "function") cb();
};

const { searchElm, optionsWrapper, search, filteredOptions, setFocusOnItem, selectOption } = useSelect({
  dropdown,
  options,
  modelValue,
  emit,
  open: showDropdown,
  toggleDropdown,
});
</script>
