<template>
    <ModalPresentationBase v-model:open="open" :center="true" anchor="top" ref="modal" v-if="currentBoardingGuide && open">
        <div class="flex gap-2 h-[450px]">
            <div class="w-screen max-w-[750px] bg-white rounded-xl relative">
                <div class="absolute top-5 left-5">
                    <Button :href="currentBoardingGuide.link" target="_blank" size="small" variant="default">
                        <div>{{ t("read_in_helpcenter") }}</div>
                        <Icon src="ArrowUpRight" skeleton="false" size="medium"></Icon>
                    </Button>
                </div>
                <RivePlayer :src="getRivUrl(currentBoardingGuide.fileName)"/>
            </div>
            <div class="w-screen max-w-[200px] flex flex-col justify-between bg-white px-5 pb-5 pt-6 rounded-xl">
                <div class="flex flex-col gap-6">
                    <div class="text-title-body">{{currentBoardingGuide.title}}</div>
                    <div  class="text-body-default text-tertiary">{{ currentBoardingGuide.description }}</div>
                </div>
                <Button @click="closeModal" variant="default">
                    <div>{{ t("close_modal") }}</div>
                </Button>
            </div>
        </div>
    </ModalPresentationBase>
</template>

<script setup lang="ts">
const { t, locale } = useI18n();
const open = ref(false);

const currentLocale = locale.value.split('-')[0];

const getRivUrl = (name: string) => {
    let currentLocale = locale.value.split('-')[0];
    if (currentLocale != 'da') 
        currentLocale = 'en';

    return "https://assets.homerunner.com/platform/boarding/" + currentLocale + "/" + name + ".riv";
}

const boardingGuides: Record<string, any> = {
    'shipments': {
        title: t('introductions.shipments.title'),
        description: t('introductions.shipments.description'),
        fileName: "shipments",
        link: "https://help.homerunner.com/"
    },
    'returns': {
        title: t('introductions.returns.title'),
        description: t('introductions.returns.description'),
        fileName: "returns",
        link: "https://help.homerunner.com/"
    },
    'tickets': {
        title: t('introductions.tickets.title'),
        description: t('introductions.tickets.description'),
        fileName: "tickets",
        link: "https://help.homerunner.com/"
    },
    'sortings': {
        title: t('introductions.sortings.title'),
        description: t('introductions.sortings.description'),
        fileName: "sortings",
        link: "https://help.homerunner.com/"
    },
    'shipments-id': {
        title: t('introductions.shipment.title'),
        description: t('introductions.shipment.description'),
        fileName: "shipments_detail_page",
        link: "https://help.homerunner.com/"
    },

};
const route = useRoute()

const currentBoardingGuide = computed(() => {
    const routeKey = route.name as string;
    if (!routeKey) {
        return null;
    }

    const seenGuides = JSON.parse(localStorage.getItem('seenGuides') || '[]');

    if (seenGuides.includes(routeKey)) {
        return null;
    }

    if (boardingGuides[routeKey]) {
        return boardingGuides[routeKey];
    }

    return null;
});

watch(currentBoardingGuide, (cbg) => {
    if(cbg)
        open.value = true;
}, {
    immediate: true
})

const closeModal = () => {
    const routeKey = route.name as string;
    if (routeKey) {
        let seenGuides = JSON.parse(localStorage.getItem('seenGuides') || '[]');

        if (!seenGuides.includes(routeKey)) {
            seenGuides.push(routeKey);
        }

        localStorage.setItem('seenGuides', JSON.stringify(seenGuides));
    }
    open.value = false;
}

</script>

<style></style>