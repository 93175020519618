import { DateTime } from "luxon";

export const checkIsRangeOperator = (operator: string) => {
  return operator === "$between" || operator === "$notBetween";
};

export const getFilterType = (filterKeys: Record<string, any>, key: string) => {
  return filterKeys.find((item) => item.value === key)?.type;
};

export const dateToString = (date: Date) => {
  return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
};

export const isValidDateString = (dateString: string) => {
  return DateTime.fromISO(dateString).isValid;
};

export const handleDateFilter = (filter: Record<string, any>) => {
  // Iterate through each condition in the $or array
  filter.forEach((item) => {
    // Extract the operator key from the current item object (e.g., "$gte", "$lte")
    const operator = Object.keys(item)[0];
    // Check if the operator indicates a range query (e.g., between two dates)
    if (checkIsRangeOperator(operator)) {
      // Convert the 'from' and 'to' values to Date objects for range operations
      item[operator].from = item[operator].from ? dateToString(new Date(item[operator].from)) : dateToString(new Date());
      //if 'to' is empty, set it to tomorrow
      item[operator].to = item[operator].to ? dateToString(new Date(item[operator].to)) : dateToString(new Date(new Date().setDate(new Date().getDate() + 1)));
    } else if (operator === "$in" || operator === "$notIn") {
      // For '$in' or '$notIn' operators, convert each value in the array to a Date object
      item[operator] = item[operator].map((i: any) => dateToString(new Date(i)));
    } else if (operator !== "$null" && operator !== "$notNull") {
      if (!isValidDateString(item[operator])) {
        const dateString = dateToString(new Date(item[operator]));

        if (dateString !== "Invalid Date" && dateString !== "Invalid DateTime") {
          item[operator] = dateString;
        }
      }
    }
  });

  return filter;
};

/**
 * Removes empty filters from the given filters object.
 * @param filters - The filters object to remove empty filters from.
 * @returns The filters object with empty filters removed.
 */
export const removeEmptyFilters = (filters: Record<string, any>) => {
  // Use filter method to keep only non-empty conditions
  return filters.filter((item) => {
    // Extract the operator key from the current item object (e.g., "$eq", "$ne")
    const operator = Object.keys(item)[0];

    if (operator === "$in" || operator === "$notIn") {
      // Check that the array for '$in' or '$notIn' operators is not empty
      return Object.values(item)[0]?.length > 0;
    } else if (checkIsRangeOperator(operator)) {
      // For range operators, check that 'from' and 'to' values are not empty
      return Object.values(item)[0].from !== "" && Object.values(item)[0].to !== "";
    } else if (operator === "$null" || operator === "$notNull") {
      // Null checks always return true as they don't require a value
      return true;
    }

    // For all other cases, ensure the value is truthy (not null, undefined, etc.)
    return !!Object.values(item)[0];
  });
};

// Define a function to sanitize filter parameters intended for an API call
export const sanitiseFiltersForApi = (
  acc: Record<string, any>, // Accumulator object for sanitized filters
  [key, value]: [string, any], // Key-value pair from the original filters object
  filterKeys: Record<string, any> // Object containing information about filter keys
) => {
  // Determine the type of filter (e.g., "text", "date") based on filter keys metadata
  const filterType = getFilterType(filterKeys, key);
  // If the filter has an $or array, process it further
  if (value.$or) {
    // Remove empty filters from the $or array using previously defined function
    let $or = removeEmptyFilters(value.$or);

    // If the filter corresponds to a date, handle it with a specific date filter handler
    if (filterType === "date") $or = handleDateFilter($or);

    // After sanitization, if the $or array is not empty, add it to the accumulator object
    if ($or.length > 0) {
      acc[key] = { $or };
    }
  }

  // Return the accumulator, progressively building the sanitized filters object
  return acc;
};
