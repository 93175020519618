<template>
  <div
    class="rounded-xl w-full px-2 py-1.5 flex gap-2 items-center"
    :class="{
      'bg-00': props.type === 'subtle',
      'bg-white': props.type === 'ghost',
      'bg-brand-subtlest': props.type === 'highlight',
      'bg-destructive-light': props.type === 'error',
      'bg-utility-lemon': props.type === 'aware',
    }"
  >
    <Icon
      :src="icon"
      :filled="iconFilled"
      size="large"
      class="self-start"
      :class="{
        'text-foreground-tertiary': props.type === 'subtle',
        'text-foreground-quarterary': props.type === 'ghost',
        'text-foreground-brand': props.type === 'highlight',
        'text-destructive': props.type === 'error',
        'text-utility-lemon-darkest': props.type === 'aware',
      }"
    >
    </Icon>
    <p
      class="text-body-sm"
      :class="{
        'text-tertiary': props.type === 'subtle',
        'text-quarterary': props.type === 'ghost',
        'text-brand-dark': props.type === 'highlight',
        'text-destructive': props.type === 'error',
        'text-utility-lemon-darkest': props.type === 'aware',
      }"
    >
      <slot> {{ text }} </slot>
    </p>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();
const props = withDefaults(
  defineProps<{
    text?: string;
    type?: "subtle" | "ghost" | "highlight" | "error" | "aware";
    icon?: string;
    iconFilled?: boolean;
  }>(),
  {
    text: "",
    type: "subtle",
    icon: "",
    iconFilled: true,
  }
);
</script>
