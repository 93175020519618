<template>
  <div class="flex whitespace-nowrap font-normal text-sm items-center gap-2.5 scroll-m-2.5 select-none">
    <div class="flex gap-2.5 w-full">
      <div class="max-w-[157.2px] w-full">
        <DropdownSelect
          :options="filterKeys"
          :modelValue="filterKey"
          @select="updateFilterKey"
          class="w-full"
          filterable
          :empty-text="t('filter.no_filters_available')"
        />
      </div>

      <div class="min-w-[157.2px] w-full">
        <DropdownSelect :options="operatorSelectOption" v-model="filterOperator" class="w-full" filterable :empty-text="t('filter.no_operators_matching')" />
      </div>
    </div>
    <div
      class="flex gap-2.5 items-center"
      :class="{
        'w-full': hasInputs,
      }"
    >
      <div class="w-full gap-2.5 flex items-center" v-if="hasInputs" ref="userInputWrapper">
        <template v-if="isRangeOperator">
          <Datepicker v-if="inputType === 'date'" v-model="filterValue" class="w-full filter-value" :range="true" />
          <div v-else-if="inputType === 'time'" class="flex items-center">
            <InputTime
              :model-value="filterValue[0]"
              @update:modelValue="(val) => (filterValue = [val, filterValue[1]])"
              :max="filterValue[1]"
              :placeholder="t('value')"
              class="w-full filter-value"
              :minutesInterval="1"
            />
            <span class="text-gray-400">-</span>
            <InputTime
              :model-value="filterValue[1]"
              @update:modelValue="(val) => (filterValue = [filterValue[0], val])"
              :min="filterValue[0]"
              :placeholder="t('value')"
              class="w-full filter-value"
              :minutesInterval="1"
            />
          </div>
          <template v-else>
            <InputNumber
              :model-value="filterValue[0]"
              @update:modelValue="(val) => (filterValue = [val, filterValue[1]])"
              :max="filterValue[1]"
              :placeholder="t('value')"
              class="w-full filter-value"
              :step="0.01"
            />
            <span class="text-gray-400">-</span>
            <InputNumber
              :model-value="filterValue[1]"
              @update:modelValue="(val) => (filterValue = [filterValue[0], val])"
              :min="filterValue[0]"
              :placeholder="t('value')"
              class="w-full filter-value"
              :step="0.01"

            />
          </template>
        </template>
        <template v-else>
          <DropdownSelect
            class="w-full filter-value"
            v-if="isSelect"
            :options="options"
            v-model="filterValue"
            :placeholder="t('value')"
            :multiple="operatorAcceptsList"
            filterable
          ></DropdownSelect>
          <template v-else>
            <Datepicker v-if="inputType === 'date'" v-model="filterValue" class="w-full filter-value" />
            <InputTime v-else-if="inputType === 'time'" v-model="filterValue" class="filter-value" :minutesInterval="1" />
            <InputText v-else v-model="filterValue" :multi="operatorAcceptsList" :placeholder="t('value')" class="w-full filter-value" />
          </template>
        </template>
      </div>
      <div class="flex">
        <Button variant="tertiary" size="small" @click="emits('remove')" type="button"><Icon src="close" /></Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

defineOptions({
  name: "RuleFilterEntry",
})

const { t } = useI18n();

const userInputWrapper = ref<HTMLElement | null>(null);

const props = withDefaults(
  defineProps<{
    modelValue: Record<string, any>;
    filterKey: string;
  }>(),
  {
    modelValue: () => ({}),
  }
);

const description = inject<IRuleFilterDescription>("description");
const filterKeys = computed(() => {
  return Object.entries(description.value || {})
    .map(([key, value]) => {
      return {
        ...value,
        label: value.label || key,
        value: key,
      };
    })
    .filter((val) => val);
});

const filterOperators = computed(() => {
  return _.get(description.value, `${props.filterKey}.operators`, []);
});

const operatorSelectOption = computed<{ label: string; value: string }[]>(() => {
  const fo = unref(filterOperators);

  return fo.map((o) => {
    return {
      label: t(`filter.operators.${o}`),
      value: o,
    };
  });
});

const filterOperator = computed({
  get: () => {
    return props.modelValue.operator || "eq";
  },
  set: (value) => {
    let mv = _.cloneDeep(props.modelValue) as Record<string, any>;

    //if new operator is range operator, make sure filterValue is an object
    if (checkIsRangeOperator(value)) {
      if (isRangeOperator.value) {
        //just swap the operator
        mv = {
          ...mv,
          operator: value,
        };
      } else {
        if (inputType.value === "time") {
          mv = {
            ...mv,
            operator: value,
            value: [formatDate(getDateRounded5Minute(), "HH:mm"), formatDate(getDateRounded5Minute(), "HH:mm")],
          };
        } else
          mv = {
            ...mv,
            operator: value,
            value: [null, null],
          };
      }
    } else {
      if (!checkIfHasInputs(value)) {
        mv = {
          ...mv,
          operator: value,
          value: 1,
        };
      } else if (checkIfOperatorAcceptsList(value) && !Array.isArray(mv.value)) {
        mv = {
          ...mv,
          operator: value,
          value: [mv.value],
        };
      } else if (Array.isArray(mv.value)) {
        mv = {
          ...mv,
          operator: value,
          value: mv.value?.length > 0 ? mv.value[0] : null,
        };
      } else
        mv = {
          ...mv,
          operator: value,
        };
    }

    emits("update:modelValue", mv);
  },
});

const filterValue = computed({
  get: () => {
    let val = props.modelValue?.value;

    if (inputType.value === "date") {
      if (isRangeOperator.value)
        val = {
          from: _.get(val, "0") ? new Date(val[0]) : new Date(),
          to: _.get(val, "1") ? new Date(val[1]) : new Date(),
        };
      else if (inputType.value === "time") {
        if (isRangeOperator.value) val = Object.values(val).map((v) => (v ? getDateRounded5Minute(v) : null));
        //@ts-ignore
        else val = val ? getDateRounded5Minute(val) : getDateRounded5Minute();
      } else val = val ? getDateRounded5Minute(val) : getDateRounded5Minute();
    }

    return val;
  },
  set: (val: string | any[]) => {
    if (inputType.value === "date") {
      if (isRangeOperator.value) val = Object.values(val).map((v) => (v ? formatDate(v, "y-MM-dd") : null));
      //@ts-ignore
      else val = val ? formatDate(val, "y-MM-dd") : null;
    } else if (inputType.value === "time") {
      if (isRangeOperator.value) val = Object.values(val).map((v) => (v ? formatDate(v, "HH:mm") : null));
      //@ts-ignore
      else val = val ? formatDate(val, "HH:mm") : null;
    }
    const mv = _.cloneDeep(props.modelValue) as Record<string, any>;
    emits("update:modelValue", { ...mv, value: val });
  },
});

watch(
  () => filterOperator.value,
  () => {
    if (userInputWrapper.value) {
      //if filter has inputs, click on the first button
      if (isSelect.value) {
        userInputWrapper.value.querySelector("button")?.click();
      }
      //if filter is date, click on the input to open the datepicker
      else if (inputType.value === "date") {
        userInputWrapper.value.querySelector("input:not([type='hidden'])")?.click();
      }
      // else, focus on the input
      else {
        userInputWrapper.value.querySelector("input")?.focus();
      }
    }
  }
);

const isRangeOperator = computed(() => {
  return checkIsRangeOperator(filterOperator.value);
});

const checkIsRangeOperator = (operator) => {
  return operator === "between" || operator === "notBetween";
};

const checkIfHasInputs = (operator) => {
  return operator !== "null" && operator !== "notNull";
};

const hasInputs = computed(() => {
  return checkIfHasInputs(filterOperator.value);
});

const getOptions = (filterKey) => {
  return filterKeys.value.find((f) => f.value === filterKey)?.options;
};

const options = computed(() => {
  return Object.entries(getOptions(props.filterKey)).map(([key, value]) => {
    return {
      label: value,
      value: key,
      image: props.filterKey.includes("_country") ? `/images/flags/${key.toLowerCase()}.svg` : null,
    };
  });
});

const checkIsSelect = (filterKey: string) => {
  return getInputType(filterKey) === "select";
};

const isSelect = computed(() => {
  return checkIsSelect(props.filterKey);
});

const operatorAcceptsList = computed(() => {
  return checkIfOperatorAcceptsList(filterOperator.value);
});

const checkIfOperatorAcceptsList = (operator: string) => {
  return ["in", "notIn"].includes(operator);
};

const getInputType = (filterKey: string) => {
  return filterKeys.value.find((f) => f.value === filterKey)?.type;
};

const inputType = computed(() => {
  return getInputType(props.filterKey);
});

const updateFilterKey = (value) => {
  const filterkey = filterKeys.value.find((f) => f.value === value);
  //check if new filterkey has same operators as the old one or not and set the new value accordingly
  const operator = filterkey?.operators?.includes(filterOperator.value) ? filterOperator.value : filterkey?.operators[0];
  let newEmptyValue = null;
  if (checkIfOperatorAcceptsList(operator)) {
    newEmptyValue = [];
  } else if (checkIsRangeOperator(operator)) {
    if (filterkey?.type === "date") newEmptyValue = [formatDate(new Date(), "y-MM-dd"), formatDate(new Date(), "y-MM-dd")];
    else if (filterkey?.type === "time") newEmptyValue = [formatDate(getDateRounded5Minute(), "HH:mm"), formatDate(getDateRounded5Minute(), "HH:mm")];
    else newEmptyValue = [null, null];
  } else if (filterkey?.type === "date") {
    newEmptyValue = formatDate(new Date(), "y-MM-dd");
  } else if (filterkey?.type === "time") {
    newEmptyValue = formatDate(getDateRounded5Minute(), "HH:mm");
  }

  let mv = _.cloneDeep(props.modelValue) as Record<string, any>;
  //if new filterKey has options or not same type, set filterValue to null
  if (checkIsSelect(value) || inputType.value !== filterkey?.type) {
    mv = {
      type: filterkey?.type,
      key: value,
      value: newEmptyValue,
      operator,
    };
  }

  emits("update:filterKey", value, mv);
};

const emits = defineEmits(["remove", "update:filterKey", "update:modelValue"]);
</script>
<style>
.filter-group-label {
  @apply text-quarterary text-[13px] tracking-[0.02rem];
}

.filter-or--border {
  @apply relative h-full self-stretch after:absolute after:left-1/2 after:w-px after:bg-01 after:h-full flex items-center justify-center;
}

.filter-or--border > .filter-group-label {
  @apply relative z-[5] px-2.5 py-1.5 bg-surface-lvl-00;
}
</style>
