<template>
<div class="px-5 py-2.5 flex items-center gap-3 h-9 box-content">
  <div class="text-body-default">
    {{ t('override_shipping_method_description') }}
  </div>
  <Toggle :model-value="rule.description !== null" @update:model-value="toggle"/>
  <InputText v-model="rule.description" v-if="rule.description !== null" class="flex-grow"/>
</div>

</template>

<script setup lang="ts">

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    rule: IRule;
  }>(),
  {
  }
);

let originalDescription: string | null = '';

onMounted(() => {
  originalDescription = props.rule.description;
});

onUnmounted(() => {
  if (props.rule.description === "") {
    props.rule.description = originalDescription;
  }
});

const toggle = (val: boolean) => {
  if (val) {
    props.rule.description = originalDescription || ""; 
  } else {
    props.rule.description = null;
  }
}

</script>
