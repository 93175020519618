<template>
  <div>
    <p v-if="label" class="text-secondary text-body-sm-heavy mb-1">{{ label }}</p>
    <div class="flex shadow-lvl-01 rounded-xl items-stretch cursor-pointer">
      <div
        v-for="(button, index) in buttons"
        class="flex-1 gap-2 px-5 flex items-center justify-center h-11 border border-01 group transition-all duration-300"
        :class="{
          'rounded-l-xl': index === 0,
          '!border-l-0': index !== 0,
          'rounded-r-xl': index === buttons.length - 1,
          'h-11 px-5': size === 'large',
          'h-9 px-4': size === 'default',
          '!bg-00': isSelectedButton(button),
        }"
        @click="selectedValue = button.value"
      >
        <Transition name="checkmark-show">
          <div v-show="isSelectedButton(button)" class="overflow-hidden items-center flex">
            <Icon src="check" size="medium" class="text-inherit" />
          </div>
        </Transition>
        <p class="text-secondary text-body-default-heavy group-hover:text-primary transition-all duration-300">{{ button.text }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
export interface IButton {
  iconOnly?: string;
  text?: string;
  prefix?: string;
  suffix?: string;
  variant?: string;
  value?: string;
}

const props = defineProps<{
  buttons: any;
  size?: "default" | "small" | "large";
  name: string;
  label?: string;
}>();

const isSelectedButton = (button: { value: any }): boolean => {
  return button.value === selectedValue.value;
};

const { value: selectedValue } = useField(() => props.name, undefined, {
  syncVModel: true,
});
</script>
