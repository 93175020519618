export default async () => {
  const { t } = useI18n();
  const {data, error, pending, refresh} = await useSsrApi("contacts", {
    key: "select:contacts",
    lazy: true,
    silent: true,
    transform: ({ data }) => {
      if (!data) return;
      return {
        contacts: data.map((contact: Contact) => ({
          label: buildLabel(contact),
          value: contact.id,
          data: contact,
        })),
        fetchedAt: new Date(),
      };
    },
    default: () => [],
  });

  const contacts = computed(() => data?.value?.contacts || []);

  const deleteContact = async (id: number) => {
    $confirm({
      message: t("contact_delete_confirm"),
      onConfirm: () => {
        homeFetch(`contacts/${id}`, {
          method: "Delete",
        })
        .then(() => {
        })
        .finally(() => {
          refresh();
        });
      },
      destructive: true,
    });
  };

  const buildLabel = (contact: Contact) => {
    if(!contact) return '';

    if(contact.company_name) return `${contact.company_name}, ${contact.name}, ${contact.street1}, ${contact.zip_code} ${contact.city}, ${getCountryName(contact.country_code)}`;

    return `${contact.name}, ${contact.street1}, ${contact.zip_code} ${contact.city}, ${getCountryName(contact.country_code)}`;
  };

  return {
    data: contacts,
    error,
    pending,
    refresh,
    deleteContact,
  }
}
