<template>
  <div class="flex flex-col gap-6 @container" ref="flowWrapper">
    <div class="flex gap-4 justify-between flex-col w-full">
      <div class="text-body-lg-heavy settings-flow-header">
        {{ t("settings.organisation.workspace.name.title") }}
      </div>
      <div class="flex flex-wrap gap-3 w-full">
        <InputText class="flex-1" v-model="information.name"> </InputText>
        <InputSubdomain class="flex-1" v-model="information.subdomain" :placeholder="information.name" />
      </div>
    </div>
    <Divider />
    <div class="flex gap-3 justify-between flex-col w-full">
      <div class="flex-col gap-0.5">
        <div class="text-body-lg-heavy settings-flow-header">
          {{ t("settings.organisation.workspace.address.title") }}
        </div>
        <div class="text-body-default text-quarterary">
          {{ t("settings.organisation.workspace.address.description") }}
        </div>
      </div>
      <div class="grid grid-cols-2 @lg:grid-cols-4 gap-3 auto-cols-fr" v-if="shippingIndex !== -1">
        <InputText :label="t('organisation_name')" class="col-span-2 w-full" v-model="information.contact_info[shippingIndex].name" />
        <InputText :label="t('street_name_and_number')" class="col-span-2 w-full" v-model="information.contact_info[shippingIndex].street1" />
        <div class="grid grid-cols-2 @lg:grid-cols-4 gap-3 col-span-2 @lg:col-span-4">
          <InputText :label="t('zip_code')" class="w-full col-span-2 @md:col-span-1" v-model="information.contact_info[shippingIndex].zip_code" />
          <InputText :label="t('city')" class="w-full col-span-2 @md:col-span-1" v-model="information.contact_info[shippingIndex].city" />
          <DropdownSelectCountry :label="t('country')" class="col-span-2" v-model="information.contact_info[shippingIndex].country_iso" triggerType="input" />
        </div>
        <InputText :label="t('phone_number')" class="col-span-2" v-model="information.contact_info[shippingIndex].phone" />
        <InputText :label="t('email')" class="col-span-2" v-model="information.contact_info[shippingIndex].email" />

        <div class="col-span-2 @lg:col-span-4">
          <TickerCheckbox :label="t('settings.organisation.workspace.address.billing_and_shipping_same')" v-model="billingAndShippingSame" />
        </div>
      </div>
    </div>
    <div class="flex gap-3 justify-between flex-col w-full" v-if="!billingAndShippingSame && billingIndex !== -1">
      <div class="flex-col gap-0.5">
        <div class="text-body-lg-heavy">
          {{ t("settings.organisation.workspace.address.invoice.title") }}
        </div>
      </div>
      <div class="grid grid-cols-2 @lg:grid-cols-4 gap-3 auto-cols-fr">
        <InputText :label="t('organisation_name')" class="col-span-2 w-full" v-model="information.contact_info[billingIndex].name" />
        <InputText :label="t('street_name_and_number')" class="col-span-2 w-full" v-model="information.contact_info[billingIndex].street1" />
        <div class="grid grid-cols-2 @lg:grid-cols-4 gap-3 col-span-2 @lg:col-span-4">
          <InputText :label="t('zip_code')" class="w-full col-span-2 @md:col-span-1" v-model="information.contact_info[billingIndex].zip_code" />
          <InputText :label="t('city')" class="w-full col-span-2 @md:col-span-1" v-model="information.contact_info[billingIndex].city" />
          <DropdownSelectCountry :label="t('country')" class="col-span-2" v-model="information.contact_info[billingIndex].country_iso" />
        </div>
        <InputText :label="t('phone_number')" class="col-span-2" v-model="information.contact_info[billingIndex].phone" />
        <InputText :label="t('email')" class="col-span-2" v-model="information.contact_info[billingIndex].email" />
      </div>
    </div>

  </div>
  <NotificationWrapper />

</template>

<script setup lang="ts">
import _ from "lodash";
import type { ISettingsFlow } from "../types";
import NotificationWrapper from "../components/NotificationWrapper.vue";

export interface OrganisationInformation {
  id: number;
  name: string;
  vat: string;
  payment_method: string;
  business: boolean;
  test_mode: number;
  level: string;
  created_at: Date;
  updated_at: Date;
  core_customer_id: number;
  account_id: null;
  deleted_at: null;
  disabled_at: null;
  subdomain: string;
  contact_info: ContactInfo[];
}

export interface ContactInfo {
  id: number;
  title: null;
  name: string;
  phone_prefix: null;
  phone: string;
  email: string;
  street1: string;
  street2: null;
  zip_code: string;
  city: string;
  type: "BILLING" | "SHIPPING";
  country_id: number;
  country_iso: string;
  entity_type: string;
  entity_id: number;
  latitude: number;
  longitude: number;
  created_at: Date;
  updated_at: Date;
}
const props = withDefaults(
  defineProps<{
    currentHeader?: string | null;
  }>(),
  {
    currentHeader: null,
  }
);
const { t } = useI18n();
const information = ref<OrganisationInformation>({} as OrganisationInformation);
const billingAndShippingSame = ref();
const billingIndex = ref(-1);
const shippingIndex = ref(-1);
let initialInformation: OrganisationInformation = {} as OrganisationInformation;
let initialSame: boolean;

const fetchInformation = async () => {
  setLoading(true);
  let info = await getOrganisationInfo();
  information.value = info;
  information.value = info;
  initialInformation = _.cloneDeep(info);
  getIndices();
  checkIfBillingAndShippingSame(true);
  setLoading(false);
};

const updateInformation = () => {
  $confirm({
    message: t("confirm_save_changes"),
    confirmText: t("save_changes"),
    cancelText: t("cancel"),
    onConfirm: () => {
      setLoading(true);
      homeFetch("workspace/organisation", {
        method: "PUT",
        body: JSON.stringify(information.value),
      })
        .then(fetchInformation)
        .finally(() => {
          setLoading(false);
        });
    },
  });
};

const getIndices = () => {
  if (information.value?.contact_info) {
    billingIndex.value = information.value.contact_info.findIndex((c) => c.type === "BILLING");
    shippingIndex.value = information.value.contact_info.findIndex((c) => c.type === "SHIPPING");
  }
};

const checkIfBillingAndShippingSame = (setInitial: boolean) => {
  if (information.value?.contact_info) {
    const billing = information.value.contact_info.find((c) => c.type === "BILLING");
    const shipping = information.value.contact_info.find((c) => c.type === "SHIPPING");
    if (billing && shipping) {
      //check all fields except id, created_at, updated_at, latitude, longitude, title and type

      billingAndShippingSame.value = Object.keys(billing).every((key) => {
        if (
          key === "id" ||
          key === "created_at" ||
          key === "updated_at" ||
          key === "latitude" ||
          key === "longitude" ||
          key === "title" ||
          key === "type" ||
          key === "country"
        ) {
          return true;
        }
        return billing[key] === shipping[key];
      });

      if (setInitial) initialSame = billingAndShippingSame.value;
    }
  }
};

watch(billingAndShippingSame, (val, oldVal) => {
  if (!information.value.contact_info) return;
  // Check if oldVal is undefined
  if (oldVal === undefined) return;

  // Check if billing and shipping are the same
  if (billingAndShippingSame.value) {
    // Check if billingIndex is -1
    if (billingIndex.value === -1) {
      // Push a new contact info object with the same values as the shipping contact info, but with type "BILLING"
      information.value.contact_info.push({ ...information.value.contact_info[shippingIndex.value], type: "BILLING" });
      return;
    }
    // Check if the shipping contact info has changed
    if (!_.isEqual(information.value.contact_info[shippingIndex.value], initialInformation.contact_info[shippingIndex.value])) {
      // Replace the billing contact info with the initial shipping contact info, but with type "BILLING"
      information.value.contact_info[billingIndex.value] = { ...initialInformation.contact_info[shippingIndex.value], type: "BILLING" };
      return;
    } else {
      // Check if the initial billing and shipping are the same
      if (initialSame) {
        // Reset the billing contact info to the initial values
        information.value.contact_info[billingIndex.value] = { ...initialInformation.contact_info[billingIndex.value] };
      } else {
        // Replace the billing contact info with the shipping contact info, but with type "BILLING"
        information.value.contact_info[billingIndex.value] = { ...information.value.contact_info[shippingIndex.value], type: "BILLING" };
      }
    }
  }
});

const hasChanges = computed(() => {
  if (!information.value.id) return false;
  return !_.isEqual(information.value, initialInformation);
});

watch(
  () => information.value,
  (val, oldVal) => {
    if (!oldVal.id) return;
    if (!hasChanges.value) {
      $toast.remove("organisation_settings");
      return;
    }
    $toast.add({
      duration: 0,
      id: "organisation_settings",
      group: "organisation",
      title: t("unsaved_changes"),
      target: "#settings-modal .notification-wrapper",
      icon: null,
      actions: [
        {
          text: t("undo"),
          handler: () => {
            resetOrganisationInformation();
          },
        },
        {
          text: t("save_changes"),
          handler: () => {
            updateInformation();
          },
        },
      ],
    });
  },
  {
    deep: true,
  }
);

const resetOrganisationInformation = () => {
  information.value = _.cloneDeep(initialInformation);
  billingAndShippingSame.value = initialSame;
};

onMounted(() => {
  fetchInformation().then(() => {
    goToCurrentHeader();
  });
});

onDeactivated(() => {
  resetOrganisationInformation();
});

const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

const emits = defineEmits(["update:loading"]);
const setLoading = (l: boolean) => emits("update:loading", l);
</script>

<script lang="ts">
export const useOrganisationSettings = (): ISettingsFlow => {
  const { t } = useI18n();
  const icon = "regular/nut";
  const title = t("settings.organisation.title");
  const pageTitle = t("settings.organisation.page_title");
  const key = "organisation";
  const headers = [t("settings.organisation.workspace.name.title"), t("settings.organisation.workspace.address.title")];

  return {
    icon,
    pageTitle,
    title,
    key,
    headers
  };
};
</script>
