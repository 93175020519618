<template>
    <div @click="() => parentClicked = !parentClicked" class="w-full h-12 bg-00 hover:bg-01 transition-all duration-300 py-3 px-4 rounded-xl flex items-center cursor-pointer">
        <p class="text-secondary text-body-default-heavy">{{ text }}</p>
        <Toggle @click.stop="" :parentClicked="parentClicked" :name="name" class="ml-auto" @update:model-value="val => {inputValue = val, emitUpdate(val)}" />
    </div>
</template>

<script setup lang="ts">

const emit = defineEmits(['update:modelValue']);

const props = withDefaults(
    defineProps<{
        text?: string;
        name?: string;
    }>(),
    {
        text: '',
        name: '',
    }
);

const parentClicked = ref(false);

const emitUpdate = (val) => {
    emit('update:modelValue', val);
}

</script>