<template>
  <div class="flex items-center mb-1" :id="id">
    <Avatar class="mr-4 cursor-pointer" size="sm" :entity="wrappedValue" @click="removeImage" :random-color="type == 'user'" :useLetters="type == 'user'" />
    <Button size="small" variant="secondary" type="button" @click="input?.click()">
      {{ type == 'user' ? t("upload_picture") : t('upload_logo') }}
    </Button>

    <div v-html="description" class="ml-3 text-body-sm text-tertiary text-wrap"></div>
    <input :id="inputId" type="file" accept="image/jpeg, image/svg+xml, image/png" style="display: none" ref="input" @change="onChange" />
  </div>
  <Message v-if="error" variant="invalid">{{ error }}</Message>

</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    name?: string;
    error?: string;
    required?: boolean;
    placeholder?: string;
    description?: string;
    id?: string;
    type?: "user" | "organisation";
  }>(),
  {
    disabled: false,
    name: "",
    description: "",
    placeholder: "",
    id: "input",
    type: "user",
  }
);

const inputId = `input-${useId()}`;

const input = ref<HTMLElement | null>(null);
const stringRepresentation = ref("");
const { t } = useI18n();

const wrappedValue = computed(() => {  
  return { image: stringRepresentation.value, name: props.placeholder };
});

const {
  value: inputValue,
  setValue,
  errorMessage,
} = useField(() => props.name, undefined, {
  syncVModel: true,
});


const error = computed(() => {
  return props.error || errorMessage.value;
});

const onChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (typeof result === "string") {
        stringRepresentation.value = result;
        setValue(file, true);
      }
    };
    reader.readAsDataURL(file);
  }
};

const removeImage = () => {
  inputValue.value = "";
  if (input.value) {
    input.value.value = null;
  }
  input.value?.focus();
};
</script>
