<template>
  <DropdownSelect v-bind="$attrs" v-model="inputValue" :options="countries" :triggerText="t('select_country')" :error="error" filterable />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    name?: string;
    error?: string;
    selectDefault?: boolean;
    withAll?: boolean;
  }>(),
  {
    name: "",
    selectDefault: false,
  }
);

const modelValue = defineModel({ default: "" });

onMounted(() => {
  if (props.selectDefault) {
    inputValue.value = "DK";
  }
});


const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(() => props.name, undefined, {
  syncVModel: props.name ? false: true,
  controlled: !!props.name,
});

const error = computed(() => {
  return props.error || errorMessage.value;
});

const input = ref<HTMLElement | null>(null);

const el = computed(() => {
  return input.value?.input;
});

const emit = defineEmits(["click"]);

const countries = ref<DropdownOption[]>([])

const getCountries = () => {
  return homeFetch("available-countries")
    .then((res) => {
      if (!res.data) {
        return;
      }
      countries.value = formatCountriesList(res.data);

    })
    .finally(() => {
      if (props.withAll) {
        countries.value.unshift({
          label: t("all"),
          value: "",
        });
      }

      if(!countries.value.find(p => p.value === inputValue.value)) {
        inputValue.value = "";
      }
    });
};

onMounted(() => {
  getCountries().then(() => {
    if (props.selectDefault) {
      inputValue.value = countries.value[0].value;
    }
  });
});

defineExpose({
  el,
});
</script>
