import CellDefault from "./Row/Cell/Default.vue";
import CellLead from "./Row/Cell/Lead.vue";
import CellChip from "./Row/Cell/Chip.vue";
import CellActions from "./Row/Cell/Actions/index.vue";
import CellFileType from "./Row/Cell/Filetype.vue";
import CellTracking from "./Row/Cell/Tracking.vue";
import type { ITableData, ITableHeader } from "./types";
import _ from "lodash";

export const isRowNew = (item: ITableData, newIndicator?: string|Function) => {
  if (!newIndicator) return false;
  //if props.newIndicator is a string, return the value of the key
  if (typeof newIndicator === "string") {
    return _.get(item, newIndicator);
  }

  //if props.newIndicator is a function, return the value of the function
  return newIndicator(item);
};


export const cellType = (header: ITableHeader, lead?: boolean) => {
  if (header.type) return header.type;
  else return lead ? "lead" : "default";
};

export const cellComponent = (header: ITableHeader, lead?: boolean) => {
  switch (cellType(header, lead)) {
    case "chip":
      return CellChip;
    case "tracking":
      return CellTracking;
    case "lead":
      return CellLead;
    case "actions":
      return CellActions;
    case "file_type":
      return CellFileType;
    default:
      return CellDefault;
  }
};

export const cellValue = (row: any, header: ITableHeader, lead?: boolean) => {
  if (header.valueFunc) return header.valueFunc(row);
  else if (header.mappings) return header.mappings[row[header.key]];
  else {
    const rawValue = getNestedValue(row, header.key);

    switch (header.type) {
      case "date":
      case "datetime":
      case "datetime_relative":
        return formatDate(rawValue);
      case "price":
        return formatPrice(rawValue, true, getCurrency(row, header));
      case "weight":
        return formatWeight(rawValue);
      default:
        return rawValue;
    }
  }
}

export const cellSubValue = (row: any, header: ITableHeader, lead?: boolean) => {
  const type = cellType(header, lead);

  if ((type !== "lead" && type !== "default") || !header.subValueKey) {
    return null;
  }
  if (header.subValueFunc) return header.subValueFunc(row);
  else if (header.subValueMappings) return header.subValueMappings[row[header.key]];
  else {
    const rawValue = getNestedValue(row, header.subValueKey);
    switch (header.subValueType) {
      case "date":
      case "datetime":
      case "datetime_relative":
        return formatDate(rawValue);
      case "price":
        return formatPrice(rawValue, true, getCurrency(row, header));
      case "weight":
        return formatWeight(rawValue);
      default:
        return rawValue;
    }
  }
};

export const isDestructive = (header: ITableHeader, row: any, lead?: boolean) => {
  if(!lead) return false;
  //check if header.destructive is boolean or function
  if (typeof header.destructive === "function") return header.destructive(row);
  else return header.destructive;
};


const getCurrency = (item: any, header: ITableHeader) => {
  if (header.currency) {
    //if currency is a function, call it with item as argument
    if (typeof header.currency === "function") return header.currency(item);
    else return header.currency;
  }
  return item.currency;
}