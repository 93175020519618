<template>
  <div class="flex gap-4 px-5 py-2.5 items-center text-body-default">
    {{ t('price_should_be') }}
    <InputNumber class="w-[75px]" v-model="price" :step="0.01"/>
    &
    {{ t('priority_should_be').toLowerCase() }}
    <InputNumber class="w-[100px]" prefix="#" v-model="priority"/>
  </div>
</template>

<script setup lang="ts">

const {t} = useI18n();

const props = withDefaults(
  defineProps<{
    rule: IRule;
  }>(),
  {
  }
);

const r = computed({
  get: () => props.rule,
  set: (val: IRule) => emits('update:rule', val)
});


const price = computed({
  get: () => r.value.price,
  set: (val: number) => r.value = {...r.value, price: val}
});

const priority = computed({
  get: () => r.value.priority,
  set: (val: number) => r.value = {...r.value, priority: val}
});


const emits = defineEmits(['update:rule']);

</script>
