

<template>
  <CellDefault class="!py-px [&_.cell-content]:w-[28px]" >
    <Icon size="2xl" :src="icon" />
  </CellDefault>
</template>

<script setup lang="ts">
import CellDefault from "./Default.vue";

const props = defineProps<{
  value: string;

}>();

const icon = computed(() => {
  return `file_${props.value}`;
});
</script>
