<template>
  <Input
    v-bind="$attrs"
    tag="input"
    :model-value="inputValue"
    @update:model-value="(val) => setValue(val, false)"
    @blur="evt => handleBlur(evt, true)"
    @click="() => emit('click')"
    :error="error"
    :size="size"
    ref="input"
    :name="name"
    :darkBg="darkBg"
    :modifiers="allModifiers"
    :disabled="disabled"
  >
    <template v-for="(slot, index) of slotNames" :key="index" #[slot]>
      <slot :name="slot" />
    </template>
  </Input>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ref } from "vue";

const slots = useSlots();
const slotNames = Object.keys(slots) as "default"[];

const modelValue = defineModel();

const props = withDefaults(
  defineProps<{
    size?: "default" | "small" | "large";
    disabled?: boolean;
    selected?: boolean;
    active?: boolean;
    name?: string;
    error?: string;
    darkBg?: boolean;
    multi?: boolean;
    modifiers?: string[];
  }>(),
  {
    size: "default",
    disabled: false,
    selected: false,
    active: false,
    name: "",
    darkBg: false,
    multi: false,
    modifiers: () => [],
  }
);

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  setValue,
  meta,
} = useField(() => props.name, undefined, {
  syncVModel: props.name ? false : true,
  validateOnMount: false,
  controlled: !!props.name,
});

const error = computed(() => {
  return props.error || errorMessage.value;
});

const input = ref<HTMLElement | null>(null);

const el = computed(() => {
  return input.value?.input;
});
const wrapper = computed(() => {
  return input.value?.wrapper;
});

const allModifiers = computed(() => {
  let m = [...props.modifiers];
  if(props.multi) m.push("multi");

  return m;
});

const emit = defineEmits(["click"]);

defineExpose({
  el,
  wrapper
});
</script>
