<template>
  <div>
    <div class="w-full grid grid-cols-2 px-6 gap-6">
      <div class="w-full h-[60vh] overflow-y-auto">
        <div class="w-full flex">
          <div class="w-full group">
            <div class="flex gap-3 mb-3">
              <p class="text-secondary text-body-default-heavy">{{ t("sender") }}</p>
              <Button @click="emit('changeStep', 0)" type="button" variant="primary-link" class="max-h-max opacity-0 group-hover:opacity-100">{{
                t("edit")
              }}</Button>
            </div>
            <div>
              <p class="text-tertiary text-body-default mb-1">{{ values.sender.business ? values.sender.company_name : values.sender.name }}</p>
              <p class="text-tertiary text-body-default" v-if="values.sender.business">{{ values.sender.name }}</p>
              <p class="text-tertiary text-body-default">{{ values.sender.street1 }}</p>
              <p class="text-tertiary text-body-default mb-1">{{ values.sender.street2 }}</p>
              <p class="text-tertiary text-body-default">{{ values.sender.zip_code }} {{ values.sender.city }}</p>
              <p class="text-tertiary text-body-default">{{ getCountryName(values.sender.country) }}</p>
            </div>
          </div>
          <Divider class="mx-6" rotation="vertical" />
          <div class="group w-full pb-3">
            <div class="flex gap-3 mb-3">
              <p class="text-secondary text-body-default-heavy">{{ t("receiver") }}</p>
              <Button @click="emit('changeStep', 0)" type="button" variant="primary-link" class="max-h-max opacity-0 group-hover:opacity-100">{{
                t("edit")
              }}</Button>
            </div>
            <p class="text-tertiary text-body-default mb-1">{{ values.receiver.business ? values.receiver.company_name : values.receiver.name }}</p>
            <p class="text-tertiary text-body-default" v-if="values.receiver.business">{{ values.receiver.name }}</p>
            <p class="text-tertiary text-body-default">{{ values.receiver.street1 }}</p>
            <p class="text-tertiary text-body-default">{{ values.receiver.street2 }}</p>
            <p class="text-tertiary text-body-default">{{ values.receiver.zip_code }} {{ values.receiver.city }}</p>
            <p class="text-tertiary text-body-default mb-1">{{ getCountryName(values.receiver.country) }}</p>
            <p class="text-tertiary text-body-default">{{ values.receiver.email }}</p>
            <p class="text-tertiary text-body-default">{{ values.receiver.phone }}</p>
          </div>
        </div>
        <Divider class="col-span-2 mb-3 !mt-0" />
        <div class="w-full group">
          <div class="flex gap-3 mb-3">
            <p class="text-secondary text-body-default-heavy">{{ t("size_and_weight") }}</p>
            <Button @click="emit('changeStep', 1)" type="button" variant="primary-link" class="max-h-max opacity-0 group-hover:opacity-100">{{
              t("edit")
            }}</Button>
          </div>
          <div class="flex gap-1 items-center">
            <p class="text-tertiary text-body-default-heavy">L:{{ values.length }} <span class="text-quarterary">cm</span></p>
            <Icon class="text-quarterary" size="small" src="close" />
            <p class="text-tertiary text-body-default-heavy">B:{{ values.width }} <span class="text-quarterary">cm</span></p>
            <Icon class="text-quarterary" size="small" src="close" />
            <p class="text-tertiary text-body-default-heavy">H:{{ values.height }} <span class="text-quarterary">cm</span></p>
            <Divider rotation="vertical" class="mx-3 h-5" />
            <p class="text-tertiary text-body-default-heavy">{{ values.weight }} <span class="text-quarterary">g</span></p>
          </div>
        </div>
        <Divider v-if="values.set_order_lines" class="col-span-2 my-3" />
        <div v-if="values.set_order_lines" class="w-full">
          <div>
            <p class="text-secondary text-body-default-heavy mb-3">{{ t("order_lines") }}</p>
          </div>
          <div>
            <div class="flex gap-1.5 flex-wrap mb-1.5" v-for="order_line in values.set_order_lines">
              <p class="text-tertiary text-body-default w-[30px]">{{ order_line.qty }}x</p>
              <div>
                <p class="text-tertiary text-body-default">{{order_line?.item_number}} {{ order_line?.customs[0]?.description }} {{ t('weighing').toLocaleLowerCase() }} {{ parseInt(order_line?.customs[0]?.weight) * order_line?.qty }}g {{ t('costing').toLocaleLowerCase() }} {{ parseInt(order_line?.customs[0]?.total_price).toFixed(2) }} {{ t('from').toLocaleLowerCase() }} {{ getCountryName(order_line?.customs[0]?.origin_country) }} </p>
                <BadgeChip v-if="order_line?.dangerous_goods_set" type="bold">{{ t("dangerous_goods") }}</BadgeChip>
              </div>
            </div>
          </div>
          <Divider class="col-span-2 my-3" />
        </div>
      </div>
      <div class="w-full h-[60vh] rounded-xl flex flex-col overflow-hidden">
        <div class="w-full bg-00 flex-grow min-h-0 rounded-xl flex flex-col mb-2">
          <div class="overflow-auto py-3 px-3">
            <div class="group">
              <div class="flex gap-3 mb-3 items-center">
                <p class="text-secondary text-body-default-heavy">{{ t("product", 99) }}</p>
                <p v-if="values.carrier_product_service.split('_')[0] == 'dfm'" class="text-quarterary text-body-sm leading-none">{{ t("with_ev_and_oil") }}</p>
                <Button @click="emit('changeStep', 2)" type="button" variant="primary-link" class="max-h-max opacity-0 group-hover:opacity-100">{{
                  t("edit")
                }}</Button>
              </div>
              <div class="flex items-center">
                <Avatar
                  :entity="{ image: `https://assets.homerunner.com/carrier_icons/${values.carrier_product_service.split('_')[0]}.png` }"
                  size="xs"
                ></Avatar>
                <p class="text-secondary text-body-default-heavy ml-3">{{ values.carrier_product_title }}</p>
                <p class="ml-auto text-tertiary text-body-default-heavy">{{ formatPrice(calculatedPrice ?? values.carrier_product_price, true) }}</p>
              </div>
            </div>
            <div v-if="!isDFM || (isDFM && values.carrier_product_service.split('_')[0] !== 'dfm')">
              <Divider class="!my-3" />
              <div class="group">
                <div class="flex gap-3 mb-3">
                  <p class="text-secondary text-body-default-heavy">Homerunner {{ t("insurance") }}</p>
                  <Button @click="emit('changeStep', 6)" type="button" variant="primary-link" class="opacity-0 group-hover:opacity-100">{{ t("edit") }}</Button>
                </div>
                <div class="flex ml-3">
                  <p v-if="values.insurance_coverage" class="text-secondary text-body-default-heavy">{{ t("up_to") }} {{ values.insurance_coverage.toString().includes('.') ? values.insurance_coverage.toFixed(2) : values.insurance_coverage}}</p>
                  <p v-else class="text-secondary text-body-default-heavy">{{ t("up_to") }} {{ freeCoverage.toString().includes('.') ? freeCoverage.toFixed(2) + ' DKK' : freeCoverage + ' DKK' }}</p>
                  <p class="ml-auto text-tertiary text-body-default-heavy">{{ formatPrice(values.insurance_price, true) || t('free') }}</p>
                </div>
              </div>
            </div>
            <div v-if="extraServices">
              <Divider class="!my-3" />
              <div class="group">
                <div class="flex gap-3 mb-3">
                  <p class="text-secondary text-body-default-heavy">{{ t("extra_services") }}</p>
                  <Button @click="emit('changeStep', 3)" type="button" variant="primary-link" class="opacity-0 group-hover:opacity-100">{{ t("edit") }}</Button>
                  <p v-if="calculatedFees" class="ml-auto text-tertiary text-body-default">{{ formatPrice(calculatedFees, true) }}</p>
                </div>
                <div class="mb-3 ml-3" v-for="service in filteredExtraServices">
                  <div class="flex gap-6 mb-1.5">
                    <p class="text-body-default-heavy text-secondary">{{ service.title }}</p>
                    <p class="text-body-default text-secondary ml-auto">{{ service.price }}</p>
                  </div>
                  <div v-for="value in service.values">
                    <div v-if="value.line" class="flex gap-3 mb-1.5">
                      <p v-for="fieldValue in value.values" class="text-body-sm text-tertiary">{{ fieldValue }}</p>
                    </div>
                    <div v-if="!value.line && value.value" class="flex gap-3 mb-1">
                      <p class="text-body-sm text-tertiary">{{ value.label }}:</p>
                      <p class="text-body-sm text-tertiary">{{ value.value }}</p>
                    </div>
                    <p v-else class="text-body-sm text-tertiary">{{ value.label }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-auto">
            <Divider class="my-0" />
            <div class="flex p-3">
              <p v-if="isDFM && values.carrier_product_service.split('_')[0] == 'dfm'" class="text-secondary text-title-body">{{ t("provisional_price") }}</p>
              <p v-else class="text-secondary text-title-body">{{ t("total") }}</p>
              <p class="ml-auto text-secondary text-title-body">
                {{ (calculatedTotalPrice ?? parseFloat(values.carrier_product_price) + getInsurancePrice).toFixed(2) }} DKK
              </p>
            </div>
          </div>
        </div>
        <div class="w-full bg-00 rounded-xl py-2 px-3 flex gap-4 mb-2 h-max">
          <p class="text-secondary text-body-sm-heavy min-w-max mt-2.5">{{ t("reference_id") }}</p>
          <InputText id="reference" :darkBg="true" name="reference" class="w-full max-w-[255px] ml-auto" :placeholder="t('reference_ex')"></InputText>
        </div>
        <div v-if="values.carrier_product_service.split('_')[0] == 'dfm'" class="w-full bg-00 rounded-xl py-2 px-3 flex gap-4 mb-2 h-max">
          <p class="text-secondary text-body-sm-heavy min-w-max mt-2.5">{{ t("delivery_instructions") }}</p>
          <InputText id="comment" :darkBg="true" name="comment" class="w-full max-w-[255px] ml-auto" placeholder=""></InputText>
        </div>
        <div class="w-full bg-00 rounded-xl py-3 px-3 flex gap-2.5 h-max">
          <TickerCheckbox :darkBg="true" id="accept_terms" name="accept_terms" />
          <p v-if="isDFM" class="text-body-default text-secondary">
            {{ t("i_accept") }}
            <span class="text-brand cursor-pointer">
              <a href="https://www.fragt.dk/media/2033/forretningsbetingelser_transport_2021.pdf" target="_blank" rel="noopener noreferrer">{{
                t("the_terms").toLocaleLowerCase()
              }}</a></span
            >
          </p>
          <p v-else class="text-body-default text-secondary">
            {{ t("i_accept") }}
            <span class="text-brand cursor-pointer">
              <a href="https://www.homerunner.com/handelsbetingelser/" target="_blank" rel="noopener noreferrer">{{
                t("the_terms").toLocaleLowerCase()
              }}</a></span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();
const emit = defineEmits(["changeStep", "updatePrice"]);
import { DateTime } from "luxon";
import { loading } from "../../service";
const props = defineProps({
  values: {
    type: Object,
  },
  services: {
    type: Object,
  },
});

const extraServices = computed(() => {
  return Object.keys(props.values.services).length !== 0;
});

const isDFM = useAuth().isDFM.value;

const { loading, fetchInsurances } = useInsurance()

const freeCoverage = ref(0);

const calculatedTotalPrice = ref(null);

const calculatedFees = ref(null);

const calculatedPrice = ref(null);

onMounted(() => {
  if (!props.values.insurance) {
    fetchInsurances(props.values).then((res) => {
      freeCoverage.value = res[0].coverage;
    });
  }
  if (isDFM && props.values.carrier_product_service.includes("dfm_")) {
    getPrice();
  }
  if (isDFM.value && props.values.carrier_product_service.split('_')[0] !== 'dfm') {
        $loader.toggle()
        homeFetch("customers/active-transfer").then((res) => {
            $loader.toggle()
            if (res.data) {
              return;
            } else {
            EventBus.$emit("NEWSHIPMENT_NO_TRANSFER");
            }
        });
    }
});

const formatExtraservices = (values: object) => {
  let extraservices = {};
  extraservices.service_codes = [];
  Object.entries(values).forEach(([key, value]) => {
    if (!value.isSet) {
      return;
    }
    if (/^([A-Z0-9]{3})$/.test(key)) {
      extraservices.service_codes.push(key);
      return;
    }
    if (value.array) {
      extraservices[key] = value.array;
      return;
    } else {
      extraservices[key] = value;
      return;
    }
  });
  return extraservices;
};

const getPrice = async () => {
  loading.value = true;
  let services = await formatExtraservices(props.values.services);
  let cps = props.values.carrier_product_service.split("_");
  const [carrier, carrier_product, ...carrier_service] = cps;
  let data = {
    shipment: {
      sender: { ...props.values.sender, country: props.values.sender.country.toUpperCase(), type: props.values.sender.business ? "business" : "private" },
      receiver: {
        ...props.values.receiver,
        country: props.values.receiver.country.toUpperCase(),
        type: props.values.receiver.business ? "business" : "private",
      },
      height: props.values.height,
      length: props.values.length,
      width: props.values.width,
      weight: props.values.weight,
      carrier,
      carrier_product,
      carrier_service: carrier_service.join("_"),
      servicepoint_id: props.values?.droppoint,
      services: services,
    },
  };
  const price = await homeFetch("shipments/calculate-price", {
    method: "POST",
    body: data,
  })
    .then((response) => {
      loading.value = false;
      return response.data;
    })
    .catch((error) => {
      loading.value = false;
    });
  calculatedPrice.value = price.price + price.price_surcharge + price.price_capacity_surcharge;
  calculatedFees.value = price.fees;
  calculatedTotalPrice.value = price.total_price;
  emit("updatePrice", price);
  loading.value = false;
};

const filteredExtraServices = computed(() => {
  let services = [];
  let serviceArray = Object.keys(props.values.services);
  serviceArray.forEach((element) => {
    let service = props.services.find((service) => {
      return service.carrier_key === element || service.fields.key === element;
    });
    let isSet = props.values.services[element].isSet;
    let values = null;
    if (isSet) {
      if (Array.isArray(service.fields)) {
        values = [...service.fields];
      } else {
        values = [];
      }
      if (service.type == "field_array" || service.type == "select" || service.type == "object_array" || service.type == "multi_select") {
        if (service.type == "field_array") {
          values.forEach((value) => {
            value.value = props.values.services[element][value.key];
          });
        }
        if (service.type == "object_array") {
          let newValues = [];
          props.values.services[element].forEach((object) => {
            let newObject = { line: true, values: {} };
            service.fields.forEach((field) => {
              if (field.type == "select") {
                newObject.values[field.key] = field.options.find((option) => option.key == object[field.key])?.label;
              } else {
                newObject.values[field.key] = object[field.key];
              }
            });
            newValues.push(newObject);
          });
          values = newValues;
        }
        if (service.type == "select") {
          let newValues = [];
          values.forEach((value) => {
            if (value.options) {
              newValues.push({ label: value.options.find((option) => option.key == props.values.services[element][value.key])?.label, value: "" });
            } else {
              newValues.push({ label: value.label, value: props.values.services[element][value.key] });
            }
          });
          values = newValues;
        }
        if (service.type == "multi_select") {
          let newValues = [];
          props.values.services[element].array.forEach((value) => {
            newValues.push({ label: value.toUpperCase(), value: "" });
          });
          values = newValues;
        }
      }
      if (service.type == "dot_delivery") {
        let formValue = props.values.services[element];
        if (formValue.type == "DOT1") {
          values = [{ label: service.fields[0].label, value: "DOT1" }];
        } else {
          values = [
            { label: service.fields[0].label, value: formValue.type },
            { label: service.fields[1].label, value: DateTime.fromJSDate(formValue.interval_start).toFormat("HH:mm") },
          ];
        }
      }
      services.push({ title: service.label, values: values, price: service.price });
    }
  });
  return services;
});

const getInsurancePrice = computed(() => {
  return props.values.insurance ? parseFloat(props.values.insurance_price) : 0;
});
</script>
