<template>
 <div tabindex="0" class="text-sm h-8 flex items-center gap-1.5 justify-start px-3 leading-[30px] text-quarterary dropdown__item">
  <Icon src="magnifying_glass" size="medium"/>
  <input type="text" v-model="search" class="h-5 w-full focus-visible:border-0 focus-visible:outline-none" ref="input" :placeholder="placeholder"/>
</div>
</template>


<script setup lang="ts">
import { ref } from 'vue';

const input = ref<HTMLInputElement | null>(null);
const search = defineModel({ default: "" });

const props = withDefaults(
  defineProps<{
    placeholder?: string;
  }>(),
  {
    placeholder: "",
  }
);

defineExpose({
  focus() {
    input.value?.focus();
  },
});

</script>