<template>
    <Modal v-if="open" :package_number="package_number" :select-all="selectAll" :selected="selected" :filters="filters"></Modal>
</template>

<script setup lang="ts">

import Modal from "./modal.vue";
import EventBus from "@/plugins/eventbus";
import { open } from "../service";

const package_number = ref('');
const selectAll = ref(false);
const selected = ref([]);
const filters = ref({});

onMounted(() => {
    EventBus.$on("PRINTING_TOGGLE", () => {
        open.value = !open.value;
    });
    EventBus.$on("PRINTING_OPEN", () => {
        open.value = true;
    });
    EventBus.$on("PRINTING_CLOSE", () => {
        open.value = false;
        package_number.value = '';
        selectAll.value = false;
        selected.value = [];
        filters.value = {};
    });
    EventBus.$on("PRINTING_ADD_LABEL", (package_number_s: any, selectAll_s: boolean, selected_s: string[] | number[], filters_s: any) => {
        package_number.value = package_number_s;
        selectAll.value = selectAll_s;
        selected.value = selected_s;
        filters.value = filters_s;
    });
});

onUnmounted(() => {
  EventBus.$off("PRINTING_TOGGLE");
  EventBus.$off("PRINTING_OPEN");
  EventBus.$off("PRINTING_CLOSE");
  EventBus.$off("PRINTING_ADD_LABEL");
});

</script>