<template>
  <div
    class="rounded-xl transition-colors border"
    :class="{
      'bg-surface-lvl-00 border-01': expanded,
      'bg-surface-lvl-01 border-transparent hover:border-01': !expanded,
    }"
  >
    <RuleItem :rule="rule" :expanded="expanded" @toggle="expanded = !expanded" @remove="() => emits('delete')" />
    <template v-if="expanded">
      <Divider :spacing="false"></Divider>
      <RuleHighlight :rule="rule" />
      <Divider :spacing="false"></Divider>
      <div class="px-5 py-3 flex flex-col">
        <RuleFilterGroup
          v-for="([filterKey, filter], idx) in Object.entries(rule.filters)"
          :modelValue="filter"
          :filterKey="filterKey"
          :index="idx"
          :first="idx == 0"
          :last="idx == Object.keys(rule.filters).length - 1"
          @remove="(mv) => removeFilter(filterKey, mv)"
          @update:filterKey="(newFk, entryIdx, mv) => updateFilterKey(filterKey, entryIdx, newFk, mv)"
          @update:modelValue="(value) => updateFilters(filterKey, value)"
        />
        <div class="mt-4 w-full flex flex-col items-stretch">
          <FilterAddDropdown :options="filterKeys" @select="val => addNewFilter(val)" button-variant="tertiary" :buttonText="t('add_parameter')"/>
        </div>
      </div>
      <Divider :spacing="false"></Divider>

      <RuleResult :rule="rule" @update:rule="(val) => emits('update:rule', val)" />
    </template>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";

defineOptions({
  name: "Rule",
});

const props = withDefaults(
  defineProps<{
    rule: IRule;
    group: number;
  }>(),
  {}
);
const description = inject("description");

const expanded = ref(false);
const { t } = useI18n();

const filterKeys = computed(() => {
  return Object.entries(description.value).map(([key, value]) => {
    return {
      value: key,
      label: value.label,
    };
  });
});

const removeFilter = (key: string, mv: any) => {
  const rule = _.cloneDeep(props.rule);

  if (!mv?.length) {
    delete rule.filters[key];
  } else rule.filters[key] = mv;

  emits("update:rule", rule);
};

const updateFilterKey = (oldFk: string, entryIdx: number, newFek: string, mv: any) => {
  const rule = _.cloneDeep(props.rule);

  if (!rule.filters[newFek]) {
    rule.filters[newFek] = [];
  }

  rule.filters[newFek].push(mv);

  rule.filters[oldFk].splice(entryIdx, 1);

  if (!rule.filters[oldFk].length) {
    delete rule.filters[oldFk];
  }

  emits("update:rule", rule);
};

const updateFilters = (filterKey: string, payload: any) => {
  const rule = _.cloneDeep(props.rule);
  if (!rule.filters || Array.isArray(rule.filters)) {
    rule.filters = {};
  }
  rule.filters[filterKey] = payload;
  emits("update:rule", rule);
};

const addNewFilter = (filterKey: string) => {
  const type = description.value[filterKey].type;
  const operator = description.value[filterKey].operators[0];

  let newValue = null;
  if (type === "date") {
    newValue = formatDate(new Date(), "y-MM-dd");
  } else if (type === "time") {
    newValue = formatDate(new Date(), "HH:mm");
  }

  const newFilter = {
    inverse: false,
    key: filterKey,
    operator,
    type,
    value: newValue,
  };

  const rule = _.cloneDeep(props.rule);
  if (!rule.filters || Array.isArray(rule.filters)) {
    rule.filters = {};
  }

  if (!rule.filters[filterKey]) {
    rule.filters[filterKey] = [];
  }

  rule.filters[filterKey].push(newFilter);

  emits("update:rule", rule);
};

const open = () => {
  expanded.value = true;
};

const emits = defineEmits(["update:rule", "delete"]);
defineExpose({
  open,
});
</script>
