<template>
    <div class="h-[60vh] w-full">
        <div class=" mx-6 flex flex-col gap-0.5 max-h-[550px] overflow-y-auto">
            <div v-for="order_line, index in _order_lines_copy" class="p-3 flex gap-3 items-center cursor-pointer rounded-xl hover:bg-00 transition-all duration-300" :class="set_order_lines.some(item => item.item_number == order_line.item_number ) ? 'shadow-inset-01+01':''" @click="selectOrderLine(order_line)">
                <TickerCheckbox :activeValue="set_order_lines.some(item => item.item_number == order_line.item_number )" />
                <div v-if="!order_line.image_url" class=" h-16 w-16 bg-no-repeat rounded-2xl bg-01 flex items-center justify-center">
                    <Icon size="xl" src="camera_slash" class=" text-disabled" />
                </div>
                <div v-if="order_line.image_url" class=" h-16 w-16 bg-no-repeat rounded-2xl" :style="`background-image: url('${order_line.image_url}'); background-position: center; background-size: contain;`">
                </div>
                <div>
                    <p>{{ returnProductTitle(order_line) }}</p>
                    <p>{{ order_line.item_number }}</p>
                </div>
                <DropdownSelect @click.prevent.stop triggerType="button" class="ml-auto" :options="returnQtyOptions(order_line)" preSelected @update:modelValue="val => setQty(order_line, val)"></DropdownSelect>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    const { t } = useI18n();
    const props = withDefaults(
        defineProps<{
            values: any;
        }>(),
        {
            values: {},
        }
    );
    const _order_lines_copy = ref<any[]>([]);
    onMounted(() => {
        let orderlines = [...props.values.order_lines];
        let orderlinesMerged = [];
        orderlines.forEach(line => {
            const existing = orderlinesMerged.find(item => item.item_number === line.item_number);
            if (existing) {
                existing.qty += line.qty;
            } else {
                orderlinesMerged.push({ ...line });
            }
        });
        _order_lines_copy.value = orderlinesMerged;
        if (!set_order_lines.value) {
            set_order_lines.value = [];
        }
    });
    const returnProductTitle = (order_line: any) => {
        if (order_line.title) {
            return order_line.title;
        }
        if (order_line.customs.description) {
            return order_line.customs.description;
        }
        if (order_line.customs[0]) {
            return order_line.customs[0].description;
        }
    };
    const returnQtyOptions = (order_line: any) => {
        const options = [];
        for (let i = 1; i <= order_line.qty; i++) {
            options.push({ label: i.toString(), value: i });
        }
        options.reverse();
        return options;
    };
    const selectOrderLine = (order_line: any) => {
        const index = set_order_lines.value.findIndex((line) => line.item_number === order_line.item_number);
        if (index > -1) {
            set_order_lines.value.splice(index, 1);
        } else {
            set_order_lines.value.push({...order_line});
        }
    };
    const setQty = (order_line: any, qty: number) => {
        const index = set_order_lines.value.findIndex((line) => line.item_number === order_line.item_number);
        if (index > -1) {
            set_order_lines.value[index].qty = qty;
        } else {
            return;
        }
    };
    const { value: set_order_lines } = useField(() => "set_order_lines", undefined, {
        syncVModel: true,
    });
</script>