<template>
  <div class="aspect-square hr-icon">
    <div class="flex items-center justify-center">
      <component
        v-if="!isCustomIcon && icon"
        :is="icon"
        :class="{
          'text-xs': size === 'small',
          'text-base': size === 'medium',
          'text-xl': size === 'large',
          'text-2xl': size === 'xl',
          'text-[28px]': size === '2xl',
          'text-3xl': size === '3xl',
          'text-[32px]': size === '4xl',
          'text-5xl': size === '5xl',
          'text-6xl': size === '6xl',
        }"
        :weight="weight"
      ></component>
      <Suspense v-else-if="icon">
        <nuxt-icon
          :name="icon.toString()"
          :filled="filled"
          :class="{
            'text-xs': size === 'small',
            'text-base': size === 'medium',
            'text-xl': size === 'large',
            'text-2xl': size === 'xl',
            'text-[28px]': size === '2xl',
            'text-3xl': size === '3xl',
            'text-[32px]': size === '4xl',
            'text-5xl': size === '5xl',
            'text-6xl': size === '6xl',
          }"
        />
        <template #fallback>
          <div class="rounded-full border-2 border-foreground-secondary h-full w-full"></div>
        </template>
      </Suspense>
      <div v-else>
        <div class="rounded-full border-2 border-foreground-secondary h-full w-full"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type Weight = "bold" | "duotone" | "fill" | "light" | "regular";
export type IconSource = `${Weight}/${string}`;
export type CustomIconSource = `custom/${IconSource}`;

import {
  PhFunnelSimple,
  PhArrowsLeftRight,
  PhTruck,
  PhSquareSplitHorizontal,
  PhArrowsDownUp,
  PhQuestion,
  PhHouse,
  PhPackage,
  PhClipboard,
  PhArrowUpRight,
  PhWarehouse,
  PhChartDonut,
  PhEye,
  PhCirclesThreePlus,
  PhCubeTransparent,
  PhNewspaper,
  PhArrowRight,
  PhPrinter,
  PhStamp,
  PhFileArrowDown,
  PhProhibit,
  PhXCircle,
  PhFlag,
  PhTagSimple,
  PhPhone,
  PhCalendarBlank,
  PhArrowCounterClockwise,
  PhClockCounterClockwise,
  PhEnvelope,
  PhDotsThreeVertical,
  PhBinoculars,
  PhPlus,
  PhArrowLeft,
  PhCopy,
  PhMagnifyingGlass,
  PhCheck,
  PhDotsNine,
  PhCaretDown,
  PhCameraSlash,
  PhInfo,
  PhX as PhClose,
  PhCloudX,
  PhFileText,
  PhExport,
  PhWarningDiamond,
  PhWarningCircle,
  PhWarningDiamond as PhError,
  PhImages,
  PhPencilSimpleLine,
  PhAt as PhAtSign,
  PhPaperclip,
  PhSmiley,
  PhClock,
  PhPaperPlaneRight,
  PhArrowCircleUp,
  PhNut,
  PhReceipt,
  PhClipboardText,
  PhTray,
  PhPlug,
  PhCheckSquareOffset,
  PhLockSimple,
  PhUsers,
  PhGlobe,
  PhShoppingBagOpen,
  PhCreditCard,
  PhPalette,
  PhCaretUp,
  PhFilePdf,
  PhFileXls,
  PhCaretRight,
  PhLink,
  PhFile,
  PhFileCsv,
  PhFlowArrow,
  PhLifebuoy,
  PhPlay,
  PhGlobeSimple,
  PhSignIn,
  PhPencilSimple,
  PhTrashSimple,
  PhTrash,
  PhUserCircle,
  PhArrowsCounterClockwise,
  PhNotification,
  PhLightbulb,
  PhArrowUp,
  PhArrowDown,
  PhArrowElbowDownLeft,
  PhArrowUDownLeft,
  PhSidebar,
  PhGear
} from "@phosphor-icons/vue/compact"

const icons: Record<string, Component> = {
  PhFunnelSimple,
  PhArrowsLeftRight,
  PhTruck,
  PhXCircle,
  PhCameraSlash,
  PhSquareSplitHorizontal,
  PhArrowsDownUp,
  PhQuestion,
  PhHouse,
  PhStamp,
  PhEye,
  PhCalendarBlank,
  PhPackage,
  PhClipboard,
  PhWarehouse,
  PhArrowUpRight,
  PhChartDonut,
  PhCirclesThreePlus,
  PhNewspaper,
  PhArrowRight,
  PhPrinter,
  PhFileArrowDown,
  PhCubeTransparent,
  PhFlag,
  PhProhibit,
  PhTagSimple,
  PhWarningCircle,
  PhPhone,
  PhArrowCounterClockwise,
  PhClockCounterClockwise,
  PhEnvelope,
  PhDotsThreeVertical,
  PhBinoculars,
  PhPlus,
  PhArrowLeft,
  PhCopy,
  PhMagnifyingGlass,
  PhCheck,
  PhDotsNine,
  PhCaretDown,
  PhInfo,
  PhClose,
  PhCloudX,
  PhFileText,
  PhExport,
  PhWarningDiamond,
  PhImages,
  PhError,
  PhPencilSimpleLine,
  PhAtSign,
  PhPaperclip,
  PhSmiley,
  PhClock,
  PhPaperPlaneRight,
  PhArrowCircleUp,
  PhNut,
  PhReceipt,
  PhClipboardText,
  PhTray,
  PhPlug,
  PhCheckSquareOffset,
  PhLockSimple,
  PhUsers,
  PhGlobe,
  PhShoppingBagOpen,
  PhCreditCard,
  PhPalette,
  PhCaretUp,
  PhFilePdf,
  PhFileXls,
  PhCaretRight,
  PhLink,
  PhFile,
  PhFileCsv,
  PhFlowArrow,
  PhLifebuoy,
  PhPlay,
  PhGlobeSimple,
  PhSignIn,
  PhPencilSimple,
  PhTrashSimple,
  PhTrash,
  PhUserCircle,
  PhArrowsCounterClockwise,
  PhFileXml: PhFile,
  PhNotification,
  PhLightbulb,
  PhArrowUp,
  PhArrowDown,
  PhArrowElbowDownLeft,
  PhArrowUDownLeft,
  PhSidebar,
  PhGear
};

defineOptions({
  name: "Icon",
});

const props = withDefaults(
  defineProps<{
    size?: "small" | "medium" | "large" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl";
    src?: IconSource | CustomIconSource | string | "undefined" | null;
    filled?: boolean;
  }>(),
  {
    size: "medium",
    src: "",
    filled: false,
  }
);

const icon = computed(() => {
  if(!props.src) return null;
  if (props.src === "undefined") return "Question";
  if (props.src.startsWith("custom/")) return customIconPath.value;

  let name = props.src;

  if (name.includes("/")) {
    name = name.split("/")[1];
  }
  if (name.includes(".svg")) {
    name = name.split(".svg")[0];
  }
  name = name
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");

    //check if the icon exists in the icons object
  if (!icons[`Ph${name}`]) {
    console.log(`Icon Ph${name} does not exist, please make sure the icon is imported in the Icon component.`);
    return name;
  }
  return icons[`Ph${name}`];
});

const isCustomIcon = computed(() => {
  if(!props.src) return false;
  return props.src.startsWith("custom/") || typeof icon.value === "string" || icon.value instanceof String;
});

const customIconPath = computed(() => {
  if(!props.src) return false;

  return props.src.replace("custom/", "");
});

const weight = computed<Weight>(() => {
  if(!props.src) return 'fill';
  if (props.filled) return "fill";

  const style = props.src.split("/")[0];
  switch (style) {
    case "bold":
      return "bold";
    case "duotone":
      return "duotone";
    case "fill":
      return "fill";
    case "light":
      return "light";
    case "regular":
      return "regular";
    default:
      return "bold";
  }
});
</script>

<style>
.nuxt-icon svg {
  @apply mb-0;
}
</style>
