<template>
    <div ref="autoCompleteComponent" class="relative">
        <InputText autocomplete="one-time-code" @focus="$event.target.select()" :name="name" size="large" ref="trigger" @click="open = !open" v-model="inputValue" :label="label" :placeholder="placeholder">
            <template #prefix>
                <Icon src="bold/magnifying_glass" class="text-quarterary" size="medium" />
            </template>
        </InputText>
        <div v-if="open" class="w-full text-left border-2 rounded-xl z-50 max-h-[323px] overflow-auto border-00 p-1.5 bg-surface-lvl-00 absolute top-[72px] left-0">
            <div v-for="option in filteredOptions" class="group flex gap-1 items-center rounded-lg hover:bg-01 transition-all duration-300 px-3 py-1.5">
                <Entry @click="setOption(option)" :entry="option" :search="inputValue"></Entry>
                <Button type="button" class="ml-auto opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto" variant="tertiary" size="small" iconOnly @click="emitDelete(option)">
                    <Icon src="close" />
                </Button>
            </div>
            <Overlay v-if="loading" class="h-16 min-h-16"></Overlay>
            <div v-if="filteredOptions.length === 0 && !loading" class="px-3 py-1.5 flex rounded-lg text-body-default text-secondary">
                {{ emptyText }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    const { t } = useI18n();

    import outsideClick from '~/composables/useOutsideClick'
    import Entry from './Entry.vue'

    const open = ref<boolean>(false);

    const autoCompleteComponent = ref()

    const emit = defineEmits(['update:modelValue', 'delete']);

    const props = withDefaults(
        defineProps<{
            options: any[];
            placeholder: string;
            label: string;
            name: string;
            loading: boolean;
            emptyText: string;
            name: string;
        }>(),
        {
            options: [],
            placeholder: "",
            label: "",
            name: null,
            loading: false,
            emptyText: "",
            name: null,
        }
    );

    const setOption = (option: object) => {
        inputValue.value = option.label
        emit("update:modelValue", option.value);
        open.value = false
    }

    const emitDelete = (option: object) => {
        emit("delete", option.value);
    };

    outsideClick(autoCompleteComponent, () => {
        open.value = false
    })

    const filteredOptions = computed(() => {
        let entries = props.options.filter((option) => {
            if (option.label && inputValue.value) {
                if (option.label.toLowerCase().includes(inputValue.value.toLowerCase())) {
                    return true;
                }
                else {
                    return false;
                }
            } else {
                return true;
            }
        });
    return entries;
    });

    const { value: inputValue } = useField(() => props.name, undefined, {
        syncVModel: props.name ? false : true,
        controlled: !!props.name,
    });
</script>