<template>
  <div class="min-w-[219px] bg-surface-lvl-01 p-1">
    <div class="flex gap-2.5 py-1.5 px-2 self-stretch">
      <LayoutNavigationUserAvatar />
      <div class="text-sm font-medium text-primary mr-auto flex flex-col overflow-hidden">
        <span>{{ user.name }}</span>
        <span class="text-xs text-tertiary text-nowrap text-ellipsis">{{ user.email }}</span>
      </div>
    </div>
    <div class="my-1.5">
      <InputText v-model="search" :placeholder="t('settings.search')" dark-bg>
        <template #prefix>
          <Icon src="bold/magnifying_glass" class="text-quarterary" size="medium" />
        </template>
      </InputText>
    </div>
    <div class="flex flex-col gap-0.5">
      <Entry
        v-for="(flow, idx) in filteredFlows"
        :key="flow.key"
        :entry="flow"
        :active="isCurrentFlow(flow.key)"
        @click="selectFlow(flow)"
        :search="search"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISettingsFlow } from "../../types";
import Entry from "./Entry.vue";
import _ from "lodash";

const props = withDefaults(
  defineProps<{
    flows: ISettingsFlow[];
    currentFlow: ISettingsFlow | null;
  }>(),
  {
    flows: () => [],
    currentFlow: null,
  }
);
const { t } = useI18n();
const { getUser } = useAuth();
const user = getUser()!;

const search = ref<string>("");
const isCurrentFlow = (key: string) => {
  return props.currentFlow?.key === key;
};


const filteredFlows = computed(() => {
  //filter flows based on search, looking through title and description
  let entries = props.flows.map((flow) => {
    let entries = [];
    if (flow.headers && search.value) {
      //check if any of the flow's headers match the search and push header to entries
      entries.push(
        ...flow.headers
          .filter((header) => {
            return header.toLowerCase().includes(search.value.toLowerCase());
          })
          .map((header) => {
            return {
              title: header,
              key: header,
              parent: flow.key,
            };
          })
      );
    }

    if (
      flow.title.toLowerCase().includes(search.value.toLowerCase()) ||
      (flow.description && flow.description.toLowerCase().includes(search.value.toLowerCase())) ||
      entries.length
    ) {
      //prepend the flow itself to the entries
      entries.unshift(flow);
    }

    return entries;
  });

  //flatten the array of arrays
  entries = _.flatten(entries)
    //remove empty arrays and undefined values
    .filter((entry) => entry);

  return entries;
});

const emits = defineEmits(["entry:click"]);

const selectFlow = (flow) => {
  search.value = "";

  if(flow.parent) {
    //if the selected entry is a header, emit the parent flow
    return emits("entry:click", flow.parent, flow.key);
  }

  return emits("entry:click", flow.key);

};
</script>
