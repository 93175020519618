<template>
  <CellDefault class="sticky right-0 group-[.pinned]/table:shadow-md pr-5 py-2 z-0" ref="cell">
    <div class="flex actions-cell justify-end">
      <Tooltip v-if="hasActions" v-for="action in visibleActions" :text="action.label">
        <Button
          variant="tertiary"
          size="small"
          @click.stop="() => (action.handler ? action.handler(item) : null)"
          :href="action.link ? resolveActionLink(action, item) : ''"
          :target="action.target || '_self'"
          iconOnly
        >
          <Icon :src="getIconForAction(action, true)" :filled="action.iconFilled" class="aspect-square" />
        </Button>
      </Tooltip>
      <template v-if="shouldShowMoreActions" :actions="actions">
        <Button variant="tertiary" size="small" class="dropdown-toggle !rounded-lg" @click.stop="toggleDropdown" ref="trigger" :active="showDropdown" iconOnly>
          <Icon src="bold/dots_three_vertical" class="aspect-square" />
        </Button>

        <Dropdown v-model:open="showDropdown" ref="dropdown" :maxWidth="`${width}px`" :toggleElement="trigger" :calculatePosition="calculatePosition">
          <div class="flex flex-col space-y-1 dropdown__content p-1.5">
            <template v-for="action in actions">
              <Divider v-if="action == null" />
              <DropdownItem
                v-else
                :key="action.key"
                @click.stop="() => (action.handler ? action.handler(item) : null)"
                :href="action.link ? resolveActionLink(action, item) : ''"
                :variant="getActionTextVariant(action)"
                :target="action.target || '_self'"
              >
                <div class="flex items-center gap-2 w-full">
                  <Icon :src="getIconForAction(action)" v-if="getIconForAction(action)" :filled="action.iconFilled"/>

                  <div class="text-nowrap overflow-hidden text-ellipsis">{{ action.label }}</div>
                </div>
              </DropdownItem>
            </template>
          </div>
        </Dropdown>
      </template>
    </div>
  </CellDefault>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import type { ITableData, ITableHeader, ITableRowAction } from "../../../types";
import { cleanActionsList } from "~/utils/actions";
import CellDefault from "../Default.vue";

defineOptions({
  name: "TableRowCellActions",
});
const props = withDefaults(
  defineProps<{
    header: ITableHeader;
    item: ITableData;
  }>(),
  {}
);

const maxVisibleActions = 2;
const cell = ref<HTMLElement>();
const width = 185;

const actions = computed(() => {
  return cleanActionsList(props.header.data?.actions, props.item);
});

const filteredNonNullActions = computed<ITableRowAction[]>(() => {
  return actions.value.filter((action) => action) as ITableRowAction[];
});

const hasActions = computed(() => {
  return filteredNonNullActions.value.length > 0;
});

const visibleActions = computed(() => {
  //return first 2 actions
  return filteredNonNullActions.value.slice(0, maxVisibleActions);
});

const shouldShowMoreActions = computed(() => {
  return filteredNonNullActions.value.length > maxVisibleActions;
});

const { dropdown, calculatePosition, trigger, showDropdown, toggleDropdown } = useDropdown({
  repositionFromBottom: false,
});
</script>
