
export default (companyName: string) : string => {
  // Convert to lowercase
  companyName = companyName.toLowerCase();

  //replace æ, ø, å with ae, oe, aa
  companyName = companyName.replace(/æ/g, "ae");
  companyName = companyName.replace(/ø/g, "oe");
  companyName = companyName.replace(/å/g, "aa");

  //remove companytype
  companyName = companyTypeScrubber(companyName);

  // Replace all spaces with hyphens
  companyName = companyName.replace(/\s+/g, '-');

  // This line of code replaces any character that is not a lowercase letter, number, or hyphen with an empty string
  companyName = companyName.replace(/[^a-z0-9-]/ig, "");

  // Blacklist subdomains that are used for other purposes
  companyName = subdomainBlacklister(companyName);

  // Convert to lowercase
  companyName = companyName.toLowerCase();

  return companyName;
}