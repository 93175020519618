<template>
  <div class="w-full flex flex-col gap-3">
    <div class="flex gap-3">
      <div class="flex flex-col gap-1 flex-1">
        <div class="text-title-subsection settings-flow-header">
          {{ checkout.name }}
        </div>
        <div class="text-body-sm text-quarterary">
          {{ formatDate(checkout.created_at, DateTime.DATE_FULL) }}
        </div>
      </div>
      <div class="flex gap-2">
        <Button variant="tertiary" @click="openEditRulesShop" v-if="hasCarriers">{{ t("edit_ruleset") }}</Button>
        <Button variant="tertiary" @click="openEditShop">{{ t("edit_setup") }}</Button>
        <DropdownActions triggerIconPrefix="bold/DotsThreeVertical" triggerText="" :actions="actions" buttonSize="default" />
      </div>
    </div>
    <div class="grid grid-cols-3 gap-3">
      <div class="h-24 flex flex-col justify-between border border-00 rounded-xl p-3">
        <div class="text-body-sm text-quarterary">
          {{ t("activated_on_shop") }}
        </div>

        <DropdownSelect size="small">
          <template #triggerText>
            {{ shopTriggerText }}
          </template>
          <template #options>
            <DropdownItem v-for="shop in checkout.webshops" :key="shop.id" :title="shop.name" :subtext="shop.shop_url" selected />
            <Divider />

            <DropdownItem @click="toggleAddShop" :title="t('change_setup')" />
          </template>
        </DropdownSelect>
      </div>

      <div class="h-24 flex flex-col justify-between border border-00 rounded-xl p-3">
        <div class="text-body-sm text-quarterary">
          {{ t("activated_carriers") }}
        </div>

        <AvatarGroup :entities="mappedCarriers" size="2xs" />
      </div>
      <div class="h-24 flex flex-col justify-between border border-00 rounded-xl p-3">
        <div class="text-body-sm text-quarterary">
          {{ t("rule_set", 99) }}
        </div>

       <div class="text-body-lg-heavy">
        {{ checkout.rules_count || 0 }}
       </div>
      </div>
    </div>

    <Modal
      ref="renameDropdown"
      v-model:open="showRenameDropdown"
      :calculate-position="calculateRenamePosition"
      :overlay="false"
      max-width="360px"
      min-width="320px"
    >
      <ModalHeader>
        {{ t("rename_checkout") }}
      </ModalHeader>
      <div class="p-3 space-y-5">
        <InputText v-model="name" :label="t('name_checkout')" :placeholder="t('new_name')" />
      </div>
      <ModalFooter class="">
        <Button @click="clearRename">{{ t("cancel") }}</Button>
        <Button :disabled="!name" :loading="actionLoading" @click="rename" variant="primary">{{ t("save_checkout") }}</Button>
      </ModalFooter>
    </Modal>

    <AddWebshopModal v-if="webshopModalOpen" @close="toggleAddShop" :checkout="checkout"/>
  </div>
</template>

<script setup lang="ts">
import { DateTime } from "luxon";
import type { ICheckoutIndex } from "../../types";
import AddWebshopModal from "./AddWebshopModal.vue";

const { t } = useI18n();

const isOpen = ref<boolean>(false);
const webshopModalOpen = ref<boolean>(false);

const props = defineProps<{
  checkout: ICheckoutIndex;
}>();

const openEditShop = () => {
  emits("edit"); 
};

const openEditRulesShop = () => {
  emits("edit:rules"); 
};

const emits = defineEmits(["delete", "edit", "edit:rules", "duplicate"]);

const name = ref<string>(props.checkout.name);
const actionLoading = ref<boolean>(false);
const clearRename = () => {
  name.value = props.checkout.name;
  toggleRenameDropdown();
};

const rename = () => {
  const body = {
    name: name.value,
  };
  actionLoading.value = true;

  return homeFetch(`workspace/checkouts/${props.checkout.id}`, { method: "PUT", body })
    .then((response) => {
      if (response?.data) {
        props.checkout.name = name.value;
      }
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const deleteCheckout = () => {
  $confirm({
    message: t("checkout_delete_confirm", { checkout: props.checkout.name }),
    onConfirm: () => {
      homeFetch(`workspace/checkouts/${props.checkout.id}`, { method: "DELETE" })
        .then((response) => {
          emits("delete");
        })
        .finally(() => {
          actionLoading.value = false;
        });
    },
    destructive: true,
  });
};

const actions = computed(() => {
  return [
    {
      label: t("rename"),
      value: "rename",
      handler: toggleRenameDropdown,
    },
    {
      label: t("duplicate"),
      value: "duplicate",
      handler: () => {
        emits("duplicate");
      },
    },
    null,
    {
      label: t("delete"),
      value: "delete",
      handler: deleteCheckout,
      destructive: true,
    },
  ];
});

const hasCarriers = computed(() => {
  return !!Object.keys(props.checkout.carriers).length;
});

const mappedCarriers = computed(() => {
  return Object.entries(props.checkout.carriers).map(([slug, name]) => {
    return {
      name: name,
      image: getCarrierLogoUrl(slug),
    };
  });
});

const shopTriggerText = computed(() => {
  if (!props.checkout.webshops?.length) {
    return t("none_selected");
  }
  let text = props.checkout.webshops[0].name;
  if (props.checkout.webshops.length === 1) {
    return text;
  }

  text += " +" + (props.checkout.webshops.length - 1);
  return text;
});

const {
  dropdown: renameDropdown,
  showDropdown: showRenameDropdown,
  toggleDropdown: toggleRenameDropdown,
  calculatePosition: calculateRenamePosition,
} = useDropdown();

const toggleAddShop = () => {
  webshopModalOpen.value = !webshopModalOpen.value;
};

</script>
