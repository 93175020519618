<template>
    <div class="h-[60vh] overflow-y-auto w-full max-w-[780px] mx-auto">
        <p class=" text-secondary text-title-section mb-6 text-center w-full">{{ t('describe_the_contents') }}</p>
        <div class="w-full mb-3">
            <div class="w-full flex mb-3" v-for="(order_line, index) in order_lines" :key="index">
                <div class="w-full border border-00 rounded-xl p-2">
                    <div class="w-full bg-01 rounded-lg px-0.5 flex mb-2 items-center">
                        <p class="px-3 text-secondary text-body-sm-heavy leading-none">{{ index + 1 }}</p>
                        <Divider class="!bg-02 h-5" rotation="vertical"></Divider>
                        <p class="text-tertiary text-body-sm leading-none flex gap-[3px]">
                            <span v-if="order_line.qty !== ''">{{ order_line.qty }}x</span>
                            <span>{{ order_line.item_number }}</span>
                            <span>{{ order_line.customs[0].description }}</span>
                            <span v-if="order_line.customs[0].weight !== ''">på {{ order_line.customs[0].weight }} {{returnSelecteditem(order_line.customs[0].unit, 'unit')}},</span>
                            <span v-if="order_line.unit_price !== ''">til {{ order_line.unit_price }} {{ returnSelecteditem(order_line.customs[0].currency_code, 'currency') }}</span>
                            <span v-if="order_line.customs[0].origin_country"> fra {{ returnSelecteditem(order_line.customs[0].origin_country, 'country') }}</span>
                        </p>
                        <Button type="button" class="ml-auto" :class=" index !== 0 ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'" variant="tertiary" size="small" @click="order_lines.splice(index, 1)">{{ t('delete') }}</Button>
                    </div>
                    <div class="w-full p-2 flex flex-col">
                        <div class="w-full flex gap-3 mb-3">
                            <InputText class="w-1/12" type="number" v-model='order_line.qty' :label="t('qty')" />
                            <InputText class="w-3/12" type="text" v-model='order_line.item_number' :label="t('item_number')" />
                            <InputText class="w-8/12" type="text" v-model='order_line.customs[0].description' :placeholder="t('eg_computer_screens')" :label="t('describe_content')" />
                        </div>
                        <div class="w-full grid grid-cols-12 gap-3 mb-3">
                            <InputText class="col-span-2" type="number" v-model='order_line.customs[0].weight' :label="`${t('weight')} ${t('pr_item')}`" />
                            <DropdownSelect class="mt-[22px] col-span-2" v-model='order_line.customs[0].unit' :options="units" />
                            <InputText @update:model-value="val => setTotalPrice(index, val, 'price')" class="col-span-2" type="number" v-model='order_line.unit_price' :label="t('unit_price')" />
                            <DropdownSelect class="mt-[22px] col-span-2" v-model='order_line.customs[0].currency_code' :options="currencies" />
                            <DropdownSelectCountry class="col-span-4" :label="t('origin_country')" v-model='order_line.customs[0].origin_country' />
                        </div>
                        <div class="w-full grid grid-cols-12 gap-3 mb-3">
                            <InputText class="col-span-6" type="text" v-model='order_line.customs[0].sender_tariff' :label="t('sender_tariff')" />
                            <InputText class="col-span-6" type="text" v-model='order_line.customs[0].receiver_tariff' :label="t('receiver_tariff')" />
                        </div>
                        <!--
                        <div class="flex gap-2.5">
                            <TickerCheckbox v-model="order_line.dangerous_goods_set" />
                            <p class=" text-body-default text-secondary">{{ t('this_order_line_is_dangrous') }}</p>
                        </div>
                        <div class="overflow-hidden grid transition-all duration-300" :class="order_line.dangerous_goods_set ? 'grid-rows-1fr pt-4 mt-3' : 'grid-rows-0'">
                            <div class="overflow-hidden rounded-xl transition-all duration-300 border-2 border-destructive px-4 -mt-1" :class="order_line.dangerous_goods_set ? 'opacity-100 py-4' : 'opacity-0'">
                                <p class="text-secondary text-title-body">{{ t('dangerous_goods') }}</p>
                                <div class="w-full flex gap-3">
                                    <InputText type="text" v-model='order_line.dangerous_goods.class_code' :placeholder="t('set_code')" :label="t('class_code')" />
                                    <InputText class=" min-w-max" type="text" v-model='order_line.dangerous_goods.un' :placeholder="t('set_number')" :label="t('un_number')" />
                                    <DropdownSelect v-model='order_line.dangerous_goods.package_group' :label="t('package_group')" :triggerText="t('select_package_group')" :options="package_groups" />
                                    <div class="w-full">
                                        <InputText type="text" v-model='order_line.dangerous_goods.package_type' :placeholder="t('set_packaging')" :label="t('packaging')" />
                                        <p class=" text-quarterary text-body-sm">{{ t('packaging_obs') }}</p>
                                    </div>
                                </div>
                                <InputText class="w-full mb-1" type="text" v-model='order_line.dangerous_goods.description' :placeholder="t('set_technical_description')"  :label="t('teknical_description_dg')" />
                                <p class=" w-full text-quarterary text-body-sm">{{ t('technical_desc_obs') }}</p>
                            </div>
                        </div>
                        -->
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full flex justify-center pb-2">
            <Button type="button" class="w-full max-w-max" @click="addOrderLine">{{ t('add_order_line') }}</Button>
        </div>
    </div>
</template>

<script setup lang="ts">

const { t } = useI18n();

const units = [{label: 'Kg', value: 'kg'}, {label: 'L', value: 'ltr'}]; 

const currencies = [{label: 'DKK', value: 'DKK'}, {label: 'SEK', value: 'SEK'}];

const package_groups = [{label: 'I', value: 'I'}, {label: 'II', value: 'II'}, {label: 'III', value: 'III'}];

const countries = getCountrySelectOptions();

onMounted(() => {
    if (!order_lines.value || order_lines.value.length === 0) {
        order_lines.value = [];
        addOrderLine();
    }
});

const returnSelecteditem = (key: string, type: string) => {
    switch (type) {
        case 'unit':
            return units.find((u) => u.value === key)?.label;
        case 'currency':
            return currencies.find((c) => c.value === key)?.label;
        case 'country':
            return countries.find((c) => c.value === key)?.label;
    }
};

const { value: order_lines } = useField(() => 'order_lines', undefined, {
        syncVModel: true,
    });

const setTotalPrice = (index: number, val: number, unit: string) => {
    if (order_lines.value[index].unit_price && order_lines.value[index].qty) {
        switch (unit) {
            case 'qty':
                order_lines.value[index].customs[0].total_price = (order_lines.value[index].unit_price * val);
                break;
            case 'price':
                order_lines.value[index].customs[0].total_price = (val * order_lines.value[index].qty);
                break;
        }
    }
    return
};

const addOrderLine = () => {
    order_lines.value.push({
        qty: '',
        item_number: '',
        unit_price: '',
        customs: [
            {
                description: '',
                total_price: '',
                currency_code: 'DKK',
                sender_tariff: '',
                origin_country: undefined,
                receiver_tariff: '',
                weight: '',
                unit: 'kg',
            }
        ],
        dangerous_goods_set: false,
        LQ: false,
        dangerous_goods: {
            un: '',
            description: '',
            package_type: '',
            package_group: undefined,
            class_code: '',
        }
    });
};

</script>