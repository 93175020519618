<template>
    <ModalLegalBase v-model:open="open" :center="true" anchor="top" ref="modal">
        <div class="p-1 flex flex-col gap-2 w-[90vw] max-w-[1152px] bg-00 rounded-lg relative">
            <div class="h-[70dvh] overflow-auto">
                <embed v-if="pendingDocument?.files" :src="pendingDocument?.urls[getLocale()]" type="application/pdf"
                    width="100%" class="h-[inherit] rounded-lg" />
            </div>
            <div class="flex justify-between items-center gap-6 p-2">
                <div class="flex flex-col">
                    <TickerCheckbox :label="t('i_hereby_accept_document', {document: pendingDocument?.readable_type})" v-model="consentChecked" darkBg
                        :error="error" />
                </div>
                <Button @click="updateConsent" :disabled="!consentChecked" variant="primary">
                    <div>{{ t('save_and_close') }}</div>
                </Button>
            </div>
            <Overlay v-if="loading" class="aboslute top-0 left-0 h-full w-full" />
        </div>
    </ModalLegalBase>
</template>

<script setup lang="ts">

const props = defineProps<{
    pendingDocument: any;
    loading: boolean;
}>();

const emit = defineEmits(['updateAccept']);

const { locale, t } = useI18n();
const open = ref(true);
const error = ref(false);

const consentChecked = ref(false);

const getLocale = () => {
    if (locale.value === 'da') {
        return 'da';
    }
    else {
        return 'en';
    }
}

const updateConsent = () => {
    if (!consentChecked.value) {
        error.value = true;
    } else {
        emit('updateAccept', props.pendingDocument);
        consentChecked.value = false;
    }
}
</script>