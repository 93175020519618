<template>
    <div>
        <ListSelector v-model="type" radioPosition="top" :values="service.fields[0].options" valueKey="key">
            <template #title="{ value }">
                <div class="text-left">
                    <p class=" text-body-lg-heavy text-secondary">{{ value.label }}</p>
                    <p v-if="value.key == 'DOT1'" class=" text-tertiary text-body-default">{{ t('dot_option_desc.1') }}</p>
                    <p v-if="value.key == 'DOT2'" class=" text-tertiary text-body-default">{{ t('dot_option_desc.2') }}</p>
                    <p v-if="value.key == 'DOT3'" class=" text-tertiary text-body-default">{{ t('dot_option_desc.3') }}</p>
                </div>
            </template>
        </ListSelector>
        <div v-if="type == 'DOT2' || type == 'DOT3'" class="w-full text-left mt-6 flex gap-4 items-center">
            <InputTime
                value-as-date
                class="w-full filter-value max-w-max"
                :label="t('define_time')"
                v-model="time"
                :minMax="type == 'DOT2' ? '10:00-14:00' : '6:15-16:45'"
            />
            <p v-if="time && type == 'DOT2' || time && type == 'DOT3'" class=" text-quarterary text-body-default mt-6"> {{ t('the_shipment_will_be_delivered_between', {time_from: time_from, time_to: time_to}) }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">

    import { DateTime } from 'luxon';

    const { t } = useI18n();
    const props = defineProps<{
        service: any;
    }>();
    const emit = defineEmits(['dateUpdate']);

    const { value: type } = useField(() => `services.${props.service.carrier_key}.type`, undefined, {
        syncVModel: false,
        controlled: true,
    });

    const { value: time } = useField(() => `services.${props.service.carrier_key}.interval_start`, undefined, {
        syncVModel: false,
        controlled: true,
    });

    const time_from = computed(() => {
        if (time.value) {
            if (type.value == 'DOT2') {
                let newTime = DateTime.fromJSDate(time.value);
                return newTime.minus({ minutes: 60 }).toFormat('HH:mm');
            }
            if (type.value == 'DOT3') {
                let newTime = DateTime.fromJSDate(time.value);
                return newTime.minus({ minutes: 15 }).toFormat('HH:mm');
            }
        }
        return '';
    });

    const time_to = computed(() => {
        if (time.value) {
            if (type.value == 'DOT2') {
                let newTime = DateTime.fromJSDate(time.value);
                return newTime.plus({ minutes: 60 }).toFormat('HH:mm');
            }
            if (type.value == 'DOT3') {
                let newTime = DateTime.fromJSDate(time.value);
                return newTime.plus({ minutes: 15 }).toFormat('HH:mm');
            }
        }
        return '';
    });

</script>