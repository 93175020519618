<template>
  <div class="relative text-left flex flex-col gap-1">
    <label v-if="label" class="text-body-sm-heavy">{{ label }}</label>

    <DropdownTriggerButton v-if="triggerType == 'button'" @click="toggleDropdown" ref="trigger" class="w-full"
      :size="size" :title="prettyModelValue" :disabled="readonly" :variant="triggerButtonVariant">
      <div class="flex">
        <NuxtImg class="mr-2" v-if="selectedOption && selectedOption.image" :src="selectedOption.image" width="20"
          height="20" />
          <Avatar class="mr-2" v-else-if="selectedOption && selectedOption.avatar" :entity="selectedOption.avatar" size="3xs" />

          <slot name="triggerText">
          {{ prettyModelValue || triggerText || t("select_element") }}
        </slot>
      </div>
      <template #prefix>
        <Icon class="text-inherit" v-if="selectedOption && selectedOption.icon" :src="selectedOption.icon" size="medium" />
      </template>
    </DropdownTriggerButton>
    <DropdownTriggerInput v-else-if="triggerType == 'input'" @click="toggleDropdown"
      @keyup.tab.exact.stop.prevent="toggleAndSetFocus()" ref="trigger" class="w-full" :size="size"
      :title="prettyModelValue" :disabled="readonly"
      :modelValue="prettyModelValue || triggerText || t('select_element')">
      <template #prefix>
        <NuxtImg class="mr-2" v-if="selectedOption && selectedOption.image" :src="selectedOption.image" width="20"
          height="20" />
          <Avatar class="mr-2" v-else-if="selectedOption && selectedOption.avatar" :entity="selectedOption.avatar" size="3xs" />

      </template>
    </DropdownTriggerInput>

    <Dropdown anchor="top" :toggle-element="trigger.el" ref="dropdown" v-model:open="showDropdown" v-if="trigger?.el"
      :calculatePosition="calculatePosition" :minWidth="triggerWidth">
      <template v-if="filterable">
        <DropdownItemSearch v-model="search" ref="searchElm" @keydown.down.prevent="() => setFocusOnItem(-1, 'down')"
          @keydown.up.prevent="() => setFocusOnItem(-1, 'up')" />
        <Divider :spacing="false" />
      </template>
      <div class="max-h-64 overflow-y-auto w-full p-1.5 overflow-x-hidden" :class="{
        'pt-1.5': filterable,
      }" :style="`min-width:${triggerWidth}`" ref="optionsWrapper">
        <slot name="options">
          <template v-for="(option, index) in filteredOptions"
            :key="`${search}_${option ? option.value : 'seperator'}`">
            <DropdownItem v-if="option" @click="() => selectOption(option.value)"
              @keydown.enter.prevent="() => selectOption(option.value)"
              @keydown.down.prevent="() => setFocusOnItem(index, 'down')"
              @keydown.up.prevent="() => setFocusOnItem(index, 'up')" :close-on-click="!multiple"
              class="text-ellipsis overflow-hidden text-nowrap" :aria-label="option.label"
              :selected="isSelected(option)">
              <div class="flex gap-1 items-center">
                <NuxtImg v-if="option.image" :src="option.image" loading="lazy" width="20" height="20" />
                <Avatar v-else-if="option.avatar" :entity="option.avatar" size="3xs" />
                <Icon class="" v-if="option.icon" :src="option.icon" size="medium" />
                <div class="overflow-hidden text-ellipsis">{{ option.label }}</div>
              </div>
            </DropdownItem>

            <Divider v-else />
          </template>

          <DropdownItem v-if="filteredOptions.length === 0" disabled> {{ emptyText || t("no_options_available") }}
          </DropdownItem>
        </slot>
      </div>
    </Dropdown>
    <Message v-if="error" variant="invalid">{{ error }}</Message>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

defineOptions({
  name: "DropdownSelect",
});

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    options?: { label: string; value: string; image?: string | null; icon?: string | null }[];
    filterable?: boolean;
    triggerText?: string;
    emptyText?: string;
    size?: "default" | "small" | "medium" | "large";
    name?: string;
    label?: string;
    readonly?: boolean;
    triggerType?: "button" | "input";
    triggerButtonVariant?: ButtonVariant
    preSelected?: boolean;
    multiple?: boolean;
    error?: any;
  }>(),
  {
    options: () => [],
    filterable: false,
    size: "default",
    name: "",
    label: "",
    readonly: false,
    triggerType: "button",
    triggerButtonVariant: "default",
    preSelected: false,
    multiple: false,
    error: null,
  }
);

onMounted(() => {
  if (props.preSelected && props.options.length > 0) {
    selectOption(props.options[0].value);
    toggleDropdown();
  }
});

const options = computed(() => props.options);

const modelValue = defineModel({ default: "" });

const emit = defineEmits(["select"]);

const isSelected = (option) => {
  if (props.multiple) {
    return modelValue.value.includes(option.value);
  }
  return modelValue.value === option.value;
};

const selectedOption = computed(() => props.options.find(isSelected));

const { dropdown, trigger, showDropdown, toggleDropdown, calculatePosition, triggerWidth } = useDropdown();

const { searchElm, optionsWrapper, search, filteredOptions, setFocusOnItem, selectOption, prettyModelValue } = useSelect({
  dropdown,
  options,
  modelValue,
  emit,
  open: showDropdown,
  toggleDropdown,
  multiple: props.multiple,
});

const { value: selectedValue } = useField(() => props.name, undefined, {
  syncVModel: props.name ? false : true,
  controlled: !!props.name,
});

watchEffect(() => {
  if (props.name) {
    selectedValue.value = modelValue.value;
  }
});

const toggleAndSetFocus = () => {
  toggleDropdown();
  setTimeout(() => {
    setFocusOnItem(-1, "down");
  }, 300);
};
</script>
