<template>
  <div class="text-center h-[60vh] overflow-y-auto max-w-[864px] py-7 mx-auto">
    <Slider preSelect :deselectItem="deselectItem" :items="sizes" @update:modelValue="(val) => setSize(val)">
      <template #content="{ item }">
        <div class="relative">
          <div class="absolute -top-1 right-0" v-if="item.type== 'custom'">
            <DropdownActions
              trigger-icon-prefix="bold/DotsThreeVertical"
              :actions="[
                {
                  label: t('delete'),
                  destructive: true,
                  handler: () => deleteDimension(item),
                },
              ]"
            />
          </div>
          <Icon src="light/cube_transparent" size="4xl" class="text-foreground-tertiary max-w-[32px] mx-auto mb-2"></Icon>
          <p class="text-body-default-heavy text-secondary">{{ item.name }}</p>
          <p class="text-body-default text-tertiary">{{ item.length }} x {{ item.width }} x {{ item.height }}</p>
        </div>
      </template>
    </Slider>
    <div class="max-w-[780px] mx-auto">
      <div class="w-full flex gap-4 mt-5 mb-3">
        <InputValueXL
          @update:modelValue="deselectItem = !deselectItem"
          v-model="length"
          type="number"
          suffix="cm"
          required
          min="1"
          :label="t('length')"
        ></InputValueXL>
        <Icon src="close" size="large" class="text-foreground-quarterary mt-14"></Icon>
        <InputValueXL
          @update:modelValue="deselectItem = !deselectItem"
          v-model="width"
          type="number"
          suffix="cm"
          required
          min="1"
          :label="t('width')"
        ></InputValueXL>
        <Icon src="close" size="large" class="text-foreground-quarterary mt-14"></Icon>
        <InputValueXL
          @update:modelValue="deselectItem = !deselectItem"
          v-model="height"
          type="number"
          suffix="cm"
          required
          min="1"
          :label="t('height')"
        ></InputValueXL>
      </div>
      <Button
        type="button"
        :disabled="!length || !height || !width"
        variant="secondary-link"
        class="w-full max-w-max mx-auto px-1 mb-5"
        @click="saveSizeOpen = true"
        >{{ t("save_as_standard") }}</Button
      >
      <Divider class="my-5"></Divider>
      <InputValueXL :message="formattedWeight" v-model="weight" suffix="g" required min="1" :label="t('weight')" />
      <div class="border border-00 rounded-lg max-w-[546px] mx-auto px-5 py-4 mt-5 flex gap-10">
        <img class="" src="~/assets/illustrations/modals/boxsizes.svg" alt="Boomerang" />
        <div class="my-auto">
          <p class="text-secondary text-body-default-heavy text-left">{{ t("how_to_measure") }}</p>
          <p class="text-tertiary text-body-default text-left">{{ t("how_to_measure_desc") }}</p>
        </div>
      </div>
    </div>
  </div>
  <Overlay v-if="!sizes" class="inset-x-0 sticky min-h-[300px] w-full"></Overlay>
  <Modal v-model:open="saveSizeOpen" maxWidth="342px" minWidth="342px" class="shadow-lvl-05" ref="modal">
    <div class="flex flex-col gap-4 p-4 pb-3">
      <div class="flex flex-col gap-4">
        <InputText v-model="customSizeName" :label="t('name_new_size')" :placeholder="t('size_name')" />
        <p class="text-quarterary text-title-body flex gap-2 mx-auto items-center">
          L:{{ length }}cm
          <Icon src="close" size="small"></Icon>
          B:{{ width }}cm
          <Icon src="close" size="small"></Icon>
          H:{{ height }}cm
        </p>
      </div>
    </div>
    <ModalFooter class="border-t-0">
      <div class="flex gap-3 w-full">
        <Button @click="saveSizeOpen = false" class="ml-auto">{{ t("cancel") }}</Button>
        <Button @click="saveSize" variant="primary">{{ t("save") }} {{ t("view").toLowerCase() }}</Button>
      </div>
    </ModalFooter>
  </Modal>
</template>

<script setup lang="ts">
interface Size {
  name: string;
  height: number;
  length: number;
  width: number;
}

const { t, locale } = useI18n();

const customSizeName = ref("");

const saveSizeOpen = ref(false);

const deselectItem = ref(false);

const props = defineProps<{
  sizes: any;
}>();

const sizes = ref(props.sizes);

const setSize = (val: Size) => {
  selectDimension(val);
};

const saveSize = () => {
  const size = {
    height: height.value,
    length: length.value,
    width: width.value,
    name: customSizeName.value,
  };
  homeFetch("dimensions", {
    method: "POST",
    body: size,
  }).then((response) => {
    saveSizeOpen.value = false;
  });
};

function selectDimension(size: Size) {
  length.value = size?.length;
  height.value = size?.height;
  width.value = size?.width || 10;
}

const deleteDimension = (size: Size) => {
  homeFetch(`dimensions/${size.id}`, {
    method: "DELETE",
  }).then((response) => {
    sizes.value = sizes.value.filter((s: Size) => s.id !== size.id);

    if(selectDimension.value?.id === size.id) {
      selectDimension(null);
    }
  });
};

const { value: height } = useField(() => "height", undefined, {
  syncVModel: true,
});

const { value: width } = useField(() => "width", undefined, {
  syncVModel: true,
});

const { value: length } = useField(() => "length", undefined, {
  syncVModel: true,
});

const { value: weight } = useField(() => "weight", undefined, {
  syncVModel: true,
});

const formattedWeight = computed(() => {
  return `${((weight.value || 0) / 1000).toFixed(2)} kg`;
});
</script>
