<template>
  <Input
    v-bind="$attrs"
    tag="textarea"
    :model-value="inputValue"
    @update:model-value="(val) => setValue(val, false)"
    @blur="(evt) => handleBlur(evt, true)"
    :error="error"
    size="xl"
    ref="input"
    @click="() => emit('click')"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ref } from "vue";

const modelValue = defineModel();

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    name?: string;
    error?: string;
  }>(),
  {
    disabled: false,
    name: "",
  }
);

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  setValue,
  meta,
} = useField(() => props.name, undefined, {
  syncVModel: props.name ? false : true,
  controlled: !!props.name,
});

const error = computed(() => {
  return props.error || errorMessage.value;
});

const input = ref<HTMLElement | null>(null);

const el = computed(() => {
  return input.value?.input;
});

const emit = defineEmits(["click"]);

defineExpose({
  el,
});
</script>
