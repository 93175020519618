<template>
    <Modal v-model:open="open" maxWidth="630px" :center="true" anchor="top" ref="modal">
        <div class="w-screen max-w-[614px]">
            <div class="px-0.5 py-1.5 flex items-center">
                <p class=" ml-2.5 text-secondary text-body-lg-heavy">{{ t('invite_users') }}</p>
                <Button class="ml-auto" @click="open = false" variant="tertiary">
                    <Icon src="close" size="medium" class=" text-foreground-quarterary" />
                </Button>
            </div>
            <div class="px-3.5 py-4">
                <div class="settings-flow-header hidden">
                    {{ t("settings.users.invite_with_mail") }}
                </div>
                <div class="flex gap-3 items-end">
                    <InputText
                        size="large"
                        v-model="invitation.email"
                        :label="t('settings.users.invite_with_mail')"
                        :placeholder="t('settings.users.invite_placeholder')"
                        class="w-full"
                    >
                        <template #suffix>
                        <DropdownSelect :options="roles" v-model="invitation.role">{{ t("settings.users.send_invitation") }}</DropdownSelect>
                        </template>
                    </InputText>
                    <Button class="" size="large" :disabled="!isEmailValid" :loading="creating" @click="createUser">{{
                        t("settings.users.send_invitation")
                    }}</Button>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script setup lang="ts">
const { t } = useI18n();

import { open } from "../service";
import EventBus from "@/plugins/eventbus";

const creating = ref(false);

const roles = [
  { value: "1", label: t("settings.users.roles.owner") },
  { value: "3", label: t("settings.users.roles.support") },
];
const invitation = ref<{
  email: string;
  role: string;
}>({
  email: "",
  role: "1",
});

const isEmailValid = computed(() => {
  if (!invitation.value.email) return false;
  return !!invitation.value.email.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/);
});

const createUser = () => {
  $confirm({
    message: t("settings.users.confirm_invitation", {
      user: invitation.value.email,
      role: roles.find((r) => r.value == invitation.value.role)?.label,
    }),
    onConfirm: () => {
      creating.value = true;

      homeFetch(`workspace/users`, {
        method: "POST",
        body: {
          emails: [invitation.value.email],
          roles: [invitation.value.role],
        },
      })
        .then(() => {       
          $toast.add({
            title: t("settings.users.invitation.success", {
              user: invitation.value.email,
              role: roles.find((r) => r.value == invitation.value.role)?.label,
            }),
            icon: "users",
          });
          invitation.value.email = "";
          invitation.value.role = "1";
        })
        .finally(() => {
          creating.value = false;
        });
    },
  });
};

onMounted(() => {
    EventBus.$on("INVITEUSERS_TOGGLE", () => {
        open.value = !open.value;
    });
    EventBus.$on("INVITEUSERS_OPEN", () => {
        open.value = true;
    });
    EventBus.$on("INVITEUSERS_CLOSE", () => {
        open.value = false;
    });
});

onUnmounted(() => {
    EventBus.$off("INVITEUSERS_TOGGLE");
    EventBus.$off("INVITEUSERS_OPEN");
    EventBus.$off("INVITEUSERS_CLOSE");
});

</script>

<style>
.input_title {
    @apply text-left text-body-sm-heavy mb-1 mt-4
}
</style>