export default async () => {

    const { data, error, pending, refresh } = await useSsrApi("countries", {
        key: "select:countries",
        lazy: true,
        transform: ({ data }) => {
            if (!data) return;
            return {
                prefixes: data.map((country) => ({
                    label: '+' + country.phone_prefix,
                    value: '+' + country.phone_prefix,
                    iso: country.iso,
                })),
                fetchedAt: new Date(),
            };
        },
        //getCachedData: (key: string) => getCachedData(key, 60 * 10),
        default: () => [],
    });

    return {
        data,
        error,
        pending,
        refresh,
    }

}