export default () => {
    const { t } = useI18n();

    const checkoutsForSelect = async () => {
        let checkouts = [];
        await homeFetch(`workspace/checkouts/select`)
        .then((r) => {
            checkouts = r.data;
        }).catch((r) => {
        });
        return {
            checkouts: checkouts,
        }
    }
    
    return {
        checkoutsForSelect,
    };
};