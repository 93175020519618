<template>
  <div class="text-center px-6 pb-5 h-[60vh] overflow-y-auto max-w-[540px] mx-auto">
    <div class="mb-5 flex items-center gap-2 px-4 h-9 border border-01 rounded-full">
      <p class=" text-body-default-heavy text-quarterary">{{ t(reverseMode) }}</p>
      <p class=" text-body-default-heavy text-secondary truncate">{{ values[reverseMode]?.name }}<span v-if="values[reverseMode]?.attention">, att. {{ values[reverseMode]?.attention }}</span>, {{ values[reverseMode]?.street1 }}, {{ values[reverseMode]?.zip_code }}, {{ values[reverseMode]?.city }}, {{ getCountryName(values[reverseMode]?.country) }}</p>
      <Button type="button" variant="primary-link" @click="addressModalOpen = true">{{ t('change') }}</Button>
    </div>
    <AutoComplete
      name="saved_receiver_input"
      :loading="loadingContacts"
      :emptyText="t('no_contacts_found')"
      v-model="selectedContact"
      @delete="val => deleteContact(val)"
      :label="t('search_saved_contacts')"
      :placeholder="t('search_saved_contacts_placeholder')"
      :options="contacts"
      class="w-full"
    />
    <div class="py-7">
      <Divider :text="t('enter_address_manually')"></Divider>
    </div>
    <SettingsToggle id="business" :name="`${mode}.business`" :text="t(`${mode}_is_business`)" />
    <div class="grid grid-cols-6 gap-4 mt-4">
      <InputText :class="isBusiness ? 'col-span-3' : 'col-span-6'" id="name" :name="`${mode}.name`" :label="isBusiness ? t('company_name') : t('name')" :placeholder="t('name')"></InputText>
      <InputText v-if="isBusiness" :class="isBusiness ? 'col-span-3' : 'col-span-6'" id="attention" :name="`${mode}.attention`" :label="t('attention_name')" :placeholder="t('attention_name')"></InputText>
      <InputText class="col-span-3" id="street1" :name="`${mode}.street1`" :label="t('street1')" :placeholder="t('address')"></InputText>
      <InputText class="col-span-3" id="street2" :name="`${mode}.street2`" :label="t('street2_optional')" :placeholder="t('address')"></InputText>
      <div class="col-span-6 flex justify-between gap-3">
        <InputText
          @update:modelValue="autocity"
          id="zip_code"
          class="max-w-[120px] w-full"
          :name="`${mode}.zip_code`"
          :label="t('zip_code')"
          :placeholder="t('zip_code')"
        ></InputText>
        <InputText class="w-full" id="city" :name="`${mode}.city`" :label="t('city')" :placeholder="t('city')"></InputText>
        <DropdownSelectCountriesWithPrices @update:model-value="val => setCountry(val)" class="w-full" :id="'country'" :label="t('country')" :name="`${mode}.country`" />
      </div>
      <InputText class="col-span-3" id="email" :name="`${mode}.email`" :label="t('email')" :placeholder="t('email')"></InputText>
      <InputPhone class="col-span-3" id="phone" :setPrefix="setPrefix" :prefixName="`${mode}.phone_prefix`" :name="`${mode}.phone`" :label="t('phone')" :placeholder="t('phone')"></InputPhone>
    </div>
    <div class="flex gap-2.5 mt-5">
      <TickerCheckbox id="save_receiver" :name="`save_${mode}`" />
      <p class="text-body-default text-secondary">{{ t("save_receiver") }}</p>
    </div>
    <AddressModal v-if="addressModalOpen" :open="addressModalOpen" :mode="mode" :values="values" @close="addressModalOpen = false" @updateAddress="val => updateAddress(val)"></AddressModal>
  </div>
</template>

<script setup lang="ts">
import AddressModal from './addressModal.vue';
const { t } = useI18n();
const selectedContact = ref(null);

const emit = defineEmits(["emitContact", "updateCity", "updateAddress"]);

const debounceTimeout = ref(null);

const addressModalOpen = ref(false);

const setPrefix = ref(null);

const props = withDefaults(
  defineProps<{
    values: any;
    mode: "receiver" | "sender";
  }>(),
  {
    values: [],
  }
);

const isBusiness = computed(() => {
  if (props.mode === "receiver") {
    return props.values.receiver?.business;
  } else {
    return props.values.sender?.business;
  }
});

const reverseMode = computed(() => {
  if (props.mode === "receiver") {
    return "sender";
  } else {
    return "receiver";
  }
});

const debounce = () => {
  if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
  debounceTimeout.value = setTimeout(() => {
    autocity();
  }, 300); // delay for half second
};

watch(
  () => props?.values[props.mode]?.zip_code,
  (value) => {
    if (value) {
      debounce();
    }
  }
);

watch(
  () => props?.values[props.mode]?.country,
  (value) => {
    if (value) {
      setPrefix.value = value;
    }
  }
);

const autocity = () => {
  if (!props.values[props.mode]) return;

  homeFetch(`/autocity/?zip_code=${props.values[props.mode]?.zip_code}&country=${props.values[props.mode]?.country}`).then((response) => {
    if (response.data.autocity.length > 0) {
      emit("updateCity", response.data.autocity[0].city);
    }
  });
};

const updateAddress = (val:object) => {
  addressModalOpen.value = false;
  emit("updateAddress", {data: {...val, company_name: val.name}});
};

watch(
  () => selectedContact.value,
  (value) => {
    if (value) {
      const contact = contacts.value.find((contact) => contact.value === value);
      if (contact) {
        emit("emitContact", contact);
      }
    }
  }
);

const { data: contacts, pending: loadingContacts, deleteContact } = await useContacts();
</script>
