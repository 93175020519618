<template>
  <component
    ref="el"
    :is="componentType"
    v-bind="$attrs"
    :disabled="disabled"
    :to="props.to || props.href || ''"
    :href="componentType == 'a' ? props.href : undefined"
    :external="(!props.to && props.href && props.href.startsWith('http'))"
    :target="props.target"
    class="relative flex box-border transition-all duration-300 [&>*]:pointer-events-none [&>*]:touch-none gap-2 items-center text-ellipsis"
    :class="{
      'items-center justify-center': !textLeft,
      'text-body-default-heavy': !isLink,
      'h-9 rounded-xl': props.size === 'default' && !isLink,
      'h-7 rounded-lg': props.size === 'small' && !isLink,
      'h-11 rounded-xl': props.size === 'large' && !isLink,

      'py-2 px-4': props.size === 'default' && !isLink && !iconOnly,
      'py-1 px-3': props.size === 'small' && !isLink && !iconOnly,
      'py-3 px-5': props.size === 'large' && !isLink && !iconOnly,

      'w-9 min-w-9': props.size === 'default' && !isLink && iconOnly,
      'w-7 min-w-7': props.size === 'small' && !isLink && iconOnly,
      'w-11 min-w-11': props.size === 'large' && !isLink && iconOnly,

      'text-body-default-heavy rounded-xl': props.size === 'default' && isLink,
      'text-body-sm-heavy rounded-xl': props.size === 'small' && isLink,
      'text-body-lg-heavy rounded-xl': props.size === 'large' && isLink,

      'text-secondary bg-surface-lvl-00 border border-01 hover:border-02 active:bg-00 shadow-lvl-01 active:shadow-none ': props.variant === 'default',
      '!bg-00 !shadow-none': props.variant === 'default' && active,

      'text-inversed bg-inversed-00 active:bg-inversed-02 hover:bg-inversed-01 focus:bg-inversed-01': props.variant === 'primary',
      'bg-inversed-02': props.variant === 'primary' && active,

      'text-primary bg-01 hover:bg-02 focus:text-secondary': props.variant === 'secondary',
      'bg-02': props.variant === 'secondary' && active,

      'text-secondary hover:text-primary hover:bg-01 active:bg-02': props.variant === 'tertiary',
      'bg-02 text-primary': props.variant === 'tertiary' && active,

      'text-secondary-inversed hover:text-primary-inversed hover:bg-01-inversed active:bg-02-inversed': props.variant === 'tertiary-inversed',
      'bg-02-inversed': props.variant === 'tertiary-inversed' && active,

      'text-white bg-positive hover:bg-positive-dark active:bg-positive-dark ': props.variant === 'positive',
      'bg-positive-dark ': props.variant === 'positive' && active,

      'text-destructive bg-destructive-01 hover:bg-destructive-02 active:bg-destructive active:text-white':
        props.variant === 'destructive',
      'bg-destructive text-white': props.variant === 'destructive' && active,

      'text-warning bg-warning-01 hover:bg-warning-02 active:bg-warning':
        props.variant === 'warning',
      'bg-warning': props.variant === 'warning' && active,

      'text-destructive hover:bg-white active:bg-white': props.variant === 'attention',
      'bg-white': props.variant === 'attention' && active,

      'text-brand hover:text-brand-dark active:bg-01 ': props.variant === 'primary-link',
      'bg-01': props.variant === 'primary-link' && active,

      'text-secondary hover:text-primary active:text-primary active:bg-01': props.variant === 'secondary-link',
      'text-primary active': props.variant === 'secondary-link' && active,

      'text-destructive hover:text-destructive-dark active:text-destructive-dark active:bg-01': props.variant === 'destructive-link',
      'text-destructive-dark active': props.variant === 'destructive-link' && active,

      '!text-disabled !border-none !shadow-none': props.disabled,
      '!bg-disabled': props.disabled && props.variant !== 'secondary-link' && props.variant !== 'tertiary',
      '!text-disabled': props.disabled && isTextButton,
      'aspect-square': hasIcon,
      'flex-shrink-0': !shrink,
      'pointer-events-none touch-none': loading,
    }"
  >
    <slot name="prefix"></slot>
    <slot></slot>
    <slot name="suffix"></slot>
    <Transition name="checkmark-show">
    <div v-show="active && activeCheckmark" class="overflow-hidden items-center flex ">
      <Icon src="check" size="medium" class="text-inherit" />
    </div>
  </Transition>
    <div
      v-if="loading"
      class="w-full flex items-center justify-center absolute bg-inherit rounded"
      :class="{
        'h-9 rounded-xl': props.size === 'default',
        'h-7 rounded-lg': props.size === 'small',
        'h-11 rounded-xl': props.size === 'large',
      }"
    >
      <div class="animate-ping border-00 border rounded-full aspect-square h-2/5"></div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { NuxtLink } from "#components";
const props = withDefaults(
  defineProps<{
    size?: "default" | "small" | "large";
    variant?: ButtonVariant;
    disabled?: boolean;
    active?: boolean;
    loading?: boolean;
    href?: string;
    to?: string | { name: string; params?: Record<string, string | number> };
    iconOnly?: boolean;
    textLeft?: boolean;
    target?: string;
    activeCheckmark?: boolean;
    shrink?: boolean;
  }>(),
  {
    size: "default",
    variant: "default",
    disabled: false,
    active: false,
    loading: false,
    iconOnly: false,
    textLeft: false,
    target: "_self",
    activeCheckmark: false,
    shrink: false,
  }
);

const el = ref<HTMLElement>();

const isTextButton = computed(() => isLink.value || props.variant === "tertiary");
const isLink = computed(() => props.variant.endsWith("link"));

const slots = defineSlots();

//computed value to check if default slot is element with class 'nuxt-icon'
const hasIcon = computed(() => {
  let defaultslot = slots.default?.()[0];

  if (!defaultslot) return false;

  return defaultslot?.type.name === "Icon";
});

const componentType = computed(() => {
  if(props.to) {
    return NuxtLink;
  }


  if (!props.href) {
    return "button";
  } else {
    //check if href is full url or relative
    if (props.href.startsWith("http")) {
      return "a";
    } else {
      return NuxtLink;
    }
  }
});

defineExpose({
  el,
});
</script>

<style lang="css">
.checkmark-show-enter-to,
.checkmark-show-leave-from {
  transition: all 0.3s;
  @apply w-4 opacity-100 ml-0;
}

.checkmark-show-enter-from,
.checkmark-show-leave-to {
  transition: all 0.3s;

  @apply w-0 opacity-0 -ml-2;
}
</style>