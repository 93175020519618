<template>
    <div class="flex flex-col gap-6 @container" ref="flowWrapper">
        <div class="flex gap-4 justify-between">
            <div class="flex-col">
                <div class="text-body-lg-heavy settings-flow-header">
                {{ t("settings.webshop.label.title") }}
                </div>
                <div class="text-body-default text-quarterary">
                {{ t("settings.webshop.label.description") }}
                </div>
            </div>
            <Button class="mt-auto" variant="primary" @click="modalOpen = true">{{ t('create_webshop') }}</Button>
        </div>
        <Divider />
        <div v-show="hasNoShops" class="w-full py-6 max-w-[481px] mx-auto">
            <EmptyView :title="''"
                :description="''">
                <template #description>
                    <div>
                        <p class="text-primary text-title-subsection mb-2">{{ t('no_webshops') }}</p>
                        <p class="text-body-default text-tertiary">{{ t('create_webshop_desc') }}</p>
                    </div>
                </template>
                <template #illustration>
                    <img :src="illustration" alt="No transfers illustration" />
                </template>
            </EmptyView>
        </div>
        <div v-if="!hasNoShops">
            <div v-for="shop in webshops" class="w-full mb-3">
                <ShopItem :shop="shop" :checkouts="checkouts" @update="fetchShops()" @openedit="val => openEdit(val)" />
            </div>
        </div>
    </div>
    <CreateModal v-if="modalOpen" :open="modalOpen" @close="[modalOpen = false, fetchShops(), setShop = null]" :setShop="setShop" />
</template>

<script setup lang="ts">


    import illustration from "~/assets/illustrations/emptyViews/ec-sell-online.svg";
    import type { ISettingsFlow, IShopCheckout, IWebshop } from "../types";
    import ShopItem from "../components/webshop/ShopItem.vue";
    import CreateModal from "../components/webshop/CreateModal.vue";

    const props = withDefaults(
        defineProps<{
            currentHeader?: string | null;
        }>(),
        {
            currentHeader: null,
        }
    );

    const { t } = useI18n();

    const setShop = ref<IWebshop | null>(null);

    const emits = defineEmits(["update:loading"]);

    const loading = ref<boolean>(false);

    const webshops = ref<IWebshop[]>([]);

    const checkouts = ref<{ label: string; value: string; icon: string }[]>([]);

    const modalOpen = ref<boolean>(false);

    const hasNoShops = computed(() => webshops.value.length === 0 && !loading.value);

    const fetchShops = () => {
        setLoading(true);
        return homeFetch("workspace/webshops")
            .then((response) => {
            if (response?.data) {
                webshops.value = response.data as IWebshop[];
            }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    onMounted(() => {
        fetchShops().then(() => {
            goToCurrentHeader();
        });
        getCheckouts()
    });

    const openEdit = (shop: IWebshop) => {
        setShop.value = shop;
        modalOpen.value = true;
    };

    const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

    const { checkoutsForSelect } = useCheckouts();

    const setLoading = (l: boolean) => {
        loading.value = l;
        emits("update:loading", l);
    };

    const getCheckouts = async () => {
        const fetchedCheckouts = await checkoutsForSelect();
        checkouts.value = fetchedCheckouts.checkouts.map((checkout: IShopCheckout) => {
            return {
                label: checkout.name,
                value: checkout.id,
                icon: 'bold/CreditCard'
            }
        })
    };
</script>

<script lang="ts">
    export const useWebshopSettings = (): ISettingsFlow => {
        const { t } = useI18n();
        const icon = "regular/ShoppingBagOpen";
        const title = t("settings.webshop.title");
        const pageTitle = t("settings.webshop.page_title");
        const key = "webshops";
        const condition = () => !useAuth().isDFM.value;
        const group = "setup";

        return {
            icon,
            pageTitle,
            title,
            key,
            condition,
            group
        };
    };
</script>