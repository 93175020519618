import { IAccount } from "~/types/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { isLoggedIn, updateCapabilities } = useAuth();
  if (isLoggedIn()) {
    if (to.name == "auth-logout") {
      return;
    }


    if (to.name == "auth-login") {
        if(to.query.token)
          return true;
      return navigateTo({ name: "index" });
    }
    return;
  } else {
    const exludedRoutes = ["auth-login", "auth-register", "auth-register-workspace", "auth-register-user", "auth-reset-process", "auth-reset-request"];

    if (!exludedRoutes.includes(to.name)) {
      let redirect = to.fullPath;
      if (redirect == "/") redirect = "";
      if(redirect.includes("login")) redirect = "";
      let query = {};
      if (to.name !== "auth-logout") query = { redirect };

      query = { ...query, ...to.query };

      return navigateTo({ name: "auth-login", query });
    }

    return;
  }
});
