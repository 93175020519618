<template>
  <Modal :clickToClose="false" :open="true" maxWidth="342px" minWidth="342px" :contentPadding="false" :center="true" anchor="top" ref="modal">
    <ModalHeader class="border-b-0">
      {{ t("connect_webshop") }}
    </ModalHeader>
    <div class="text-body-default text-tertiary flex flex-col gap-4" v-if="hasWebshops">
      <i18n-t keypath="activate_checkout_on_webshop" tag="p" class="px-4">
        <template v-slot:checkout>
          <span class="text-body-default-heavy text-secondary">{{ checkout.name }}</span>
        </template>
      </i18n-t>

      <div class="max-h-80 overflow-auto px-4">
        <ListSelector v-model="selectedWebshops" :values="webshops" valueKey="id" :ticker="false" multi>
          <template #title="{ value }">
            <div class="flex gap-4 text-secondary">
              <div class="mt-0.5">
                <Icon src="bold/ShoppingBagOpen" size="large" />
              </div>
              <div class="flex flex-col">
                <div class="text-body-lg-heavy">{{ value.name }}</div>
                <div class="text-body-default text-tertiary">{{ value.shop_url || t("not_specified") }}</div>
              </div>
            </div>
          </template>
        </ListSelector>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center p-5 relative h-24 mx-4" v-else-if="loading">
      <Overlay>
        <div class="text-body-default-heavy text-tertiary mt-2">{{ t("loading_webshops") }}</div>
      </Overlay>
    </div>
    <div class="flex flex-col items-center justify-center p-4" v-else>
      <img class="mb-4" src="~/assets/illustrations/emptyViews/ec-no-result.svg" alt="No webshops illustration" />
      <p class="text-body-default text-tertiary text-center">{{ t("checkout_no_webshops") }}</p>
    </div>
    <ModalFooter class="flex-col items-stretch border-t-0 gap-2">
      <template v-if="hasWebshops">
        <Button :disabled="!hasChanged" :loading="actionLoading" @click="attachWebshops" variant="primary">{{
          t("connect_n_webshop", { n: selectedWebshops.length }, selectedWebshops.length)
        }}</Button>

        <Button @click="close">{{ t("cancel") }}</Button>
      </template>
      <template class="flex-col items-stretch border-t-0 gap-2" v-else-if="loading">
        <Button @click="close" variant="primary">{{ t("close") }}</Button>
      </template>

      <template v-else>
        <Button @click="close" variant="primary">{{ t("close") }}</Button>
        <Button @click="goToWebshops">{{ t("go_to_webshops") }} <template #suffix><Icon src="bold/ArrowRight"></Icon></template>
        </Button>
      </template>
    </ModalFooter>
  </Modal>
</template>

<script setup lang="ts">
import _ from "lodash";
import { type IWebshop, type ICheckoutIndex } from "../../types";

const { t } = useI18n();

const emit = defineEmits(["close"]);
const actionLoading = ref(false);
const loading = ref(false);
const hasWebshops = computed(() => webshops.value.length > 0);
const props = defineProps<{
  checkout: ICheckoutIndex;
}>();

const initialSelectedWebshops = props.checkout.webshops.map((shop) => shop.id);

onMounted(() => {
  getWebshops();
  selectedWebshops.value = props.checkout.webshops.map((shop) => shop.id);
});

const hasChanged = computed(() => {
  return !_.isEqual(selectedWebshops.value, initialSelectedWebshops);
});

const webshops = ref<IWebshop[]>([]);
const selectedWebshops = ref<number[]>([]);

const getWebshops = () => {
  loading.value = true;
  return homeFetch("workspace/webshops")
    .then((response) => {
      if (response?.data) {
        webshops.value = response.data;
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const attachWebshops = () => {
  const body = {
    webshop_ids: selectedWebshops.value,
  };
  actionLoading.value = true;

  return homeFetch(`workspace/checkouts/${props.checkout.id}/attach-webshops`, { method: "POST", body })
    .then((response) => {
      if (response?.data) {
        props.checkout.webshops = response.data.shops;
        close();
      }
    })
    .finally(() => {
      actionLoading.value = false;
    });
};

const goToWebshops = () => {
  emit("close");
  window.$workspace_settings.start("webshops");
};

const close = () => {
  emit("close");
};
</script>
