<template>
  <div class="group relative">
    <VTooltip :placement="placement" class="h-[inherit]">
      <div class="peer h-[inherit]">
        <slot>
          <icon class="text-quarterary cursor-pointer" src="bold/info" size="medium"></icon>
        </slot>
      </div>

      <template #popper>
        <div
          :class="{
            'bg-inversed-00 rounded-[4px] px-1.5 py-0.5': props.type === 'default',
            'bg-surface-lvl-00 rounded-xl shadow-lvl-01 px-4 py-3': props.type === 'secondary',
          }">
          <p class="text-body-sm tracking-[0.26px] max-w-[200px]" :class="{
            'text-white': props.type === 'default',
            'text-primary': props.type === 'secondary',
          }">

            <slot name="header">
              {{ text }}
            </slot>
          </p>
          <slot name="body"></slot>
        </div>
      </template>
    </VTooltip>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    text?: string;
    type?: "default" | "secondary";
    placement?: 'auto' | 'auto-start' | 'auto-end' | 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end' ;
  }>(),
  {
    text: "",
    type: "default",
    placement: 'bottom',

  }
);

</script>
