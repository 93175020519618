<template>
  <component
    :is="context === 'header' ? 'th' : 'td'"
    class="border-b border-inherit bg-inherit text-inherit group-hover:bg-inherit  group-[:last-of-type]/row:border-0"
    :class="{
      'h-9 pl-5 pr-3 py-2 label': context === 'header',
      'pl-5 pr-3 py-3 h-12': context === 'row',
      'cursor-pointer': show
    }"
    @click.stop="handleClick"
  >
    <Checkbox v-if="show" :model-value="modelValue" :indeterminate="indeterminate" :readonly="readonly"/>
  </component>
</template>

<script setup lang="ts">
import Checkbox from '../Ticker/Checkbox.vue';

const props = withDefaults(
  defineProps<{
    context: "header" | "row";
    indeterminate?: boolean;
    show?: boolean;
    readonly?: boolean;
  }>(),
  {
    show: true
  }
);

const modelValue = defineModel();

const handleClick = () => {
  if(!props.show)
    return;
  modelValue.value = !modelValue.value;
};
</script>
