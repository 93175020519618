<template>
  <div class="grid grid-cols-2 min-h-0 w-full h-full">
    <div class="flex flex-col items-center h-full">
      <div class="m-auto flex flex-col gap-5 w-full pl-2 pr-6">
        <TabLine :options="tabOptions" class="max-w-[203px] mx-auto" @update:modelValue="val => selectedView = val" />
        <p class="w-full text-center text-secondary text-title-section m-auto">{{ t("portal_communication") }}</p>
        <InputText v-if="selectedView == 'form'" name="title" :label="t('portal_title')" />
        <ClientOnly>
          <Editor v-if="selectedView == 'form'" name="description" :label="t('portal_description')"></Editor>
        </ClientOnly>
        <InputText v-if="selectedView == 'orderGate'" name="settings.order_gate_title" :label="t('portal_title')" />
        <InputText v-if="selectedView == 'orderGate'" name="settings.order_gate_description" :label="t('portal_description')" />
      </div>
    </div>
    <div class="h-full w-full bg-00 rounded-l-lg relative">
      <div class="h-full w-4 bg-00 absolute top-0 -right-4"></div>
      <p class="text-quarterary text-body-sm-heavy p-6 pb-3">{{ t("preview") }}</p>
      <div class="w-full border-2 border-00 rounded-xl absolute top-[55px] left-6">
        <div class="py-4 px-6">
          <p class="text-primary text-body-sm-heavy">{{ values.settings.alternative_address.attention || setAddress?.name }}</p>
          <div class="flex gap-6 mt-1">
            <div>
              <p class="text-primary text-body-sm">{{ values.settings.alternative_address.street || setAddress?.street1 }}</p>
              <p class="text-primary text-body-sm">
                {{ values.settings.alternative_address.zip_code || setAddress?.zip_code }} {{ values.settings.alternative_address.city || setAddress?.city }}
              </p>
              <p class="text-primary text-body-sm">
                {{ getCountryName(values.settings.alternative_address.country) || getCountryName(setAddress?.country_iso) }}
              </p>
            </div>
            <div class="flex flex-col">
              <p class="text-primary text-body-sm mt-auto">{{ values.settings.alternative_address.telephone || setAddress?.phone }}</p>
              <p class="text-primary text-body-sm">{{ values.settings.alternative_address.notification_email || setAddress?.email }}</p>
            </div>
          </div>
        </div>
        <div class="h-full w-full bg-surface-lvl-00 rounded-b-xl p-6 max-h-[500px] overflow-auto">
          <div v-if="selectedView == 'orderGate'">
            <p class="text-primary text-title-section mb-2">{{ values.settings.order_gate_title || t('order_gate_title') }}</p>
            <p class="text-quarterary text-body-l mb-6">{{ values.settings.order_gate_description || t('order_gate_description') }}</p>
            <InputText class="mb-3" placeholder="Ordrenummer" />
            <InputText placeholder="Email" />
          </div>
          <div v-if="selectedView == 'form'">
            <p class="text-primary text-title-section mb-2">{{ values.title }}</p>
            <div class="text-quarterary text-body-lg mb-6 lookDescription" v-html="values.description"></div>
            <p class="text-primary text-title-section mb-6">{{ t("fill_form") }}</p>
            <InputText label="label" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();
const props = defineProps<{
  schema: any;
  values: any;
  information: any;
}>();

const setAddress = ref(null);

const selectedView = ref("form");

const tabOptions = [
  { label: t("form"), value: "form" },
  { label: t("order_gate"), value: "orderGate" },
];

onMounted(() => {
  for (const address of props.information.contact_info) {
    if (address.type === "SHIPPING") {
      setAddress.value = address;
    }
  }
});
</script>

<style lang="scss">
.lookDescription {
  ol {
    @apply list-decimal list-inside pl-3;
  }
  ul {
    @apply list-disc list-inside pl-3;
  }
}
</style>
