const types = ["A/S", "ApS", "IVS", "S.M.B.A", "A.M.B.A", "F.M.B.A", "K/S", "P/S", "I/S", "G/S", "S/I", "SOV"];


export default (companyName: string) : string => {
  types.forEach(type => {
    const variations = createVariations(type);

    variations.forEach(variation => {
        const escapedVariation = variation.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

        const regex = new RegExp(`\\b${escapedVariation}\\b`, 'i');
        companyName = companyName.replace(regex, '');
    });
  });

  return companyName.trim();
}

const createVariations = (type: string): any[] => {
  return [type, ...createDotted(type), ...createUndotted(type), ...createUnslashed(type)];
};

const createDotted = (type: string): string[] => {
  return Array.from(new Set([type.replace("/", ".")]));
};

const createUndotted = (type: string): string[] => {
  return Array.from(new Set([type.replace(".", "")]));
};

const createUnslashed = (type: string): string[] => {
  return Array.from(new Set([type.replace("/", "")]));
};
