<template>
    <div class="p-6 h-[60vh] overflow-y-auto">
        <p v-if="insuranceData" class=" text-secondary text-title-section mb-3">{{ t('be_safe') }}</p>
        <div v-if="insuranceData" class="flex">
            <p class=" text-tertiary text-body-default">{{ t('insurance_text_1', { value: returnStandartCoverage() }) }}
            </p>
            <a href="https://www.homerunner.com/handelsbetingelser/" target="_blank" rel="noopener noreferrer"
                class=" text-tertiary text-body-default underline ml-1">{{ t('as_our_terms') }}</a>
        </div>
        <p v-if="insuranceData" class=" text-tertiary text-body-default mb-5">{{ t('insurance_text_2') }}</p>
        <Overlay v-if="loading" class="w-full h-[60vh]"></Overlay>
        <div class="grid grid-cols-4 gap-4 mb-5">
            <div class=" shadow-inset-00+01 flex flex-col p-3 rounded-lg h-full w-auto min-h-[400px] cursor-pointer transition-all duration-300 hover:shadow-inset-01+01"
                :class="selectedInsurance === insurance.id ? 'shadow-inset-01+01' : ''"
                v-for="insurance in insuranceData" @click="() => selectInsurance(insurance)">
                <div class="w-full h-28 relative">
                    <img v-if="findColor(insurance.id) == 'grape'"
                        src="~/assets/illustrations/backgrounds/grape_grid.svg" alt="" />
                    <img v-if="findColor(insurance.id) == 'lemon'"
                        src="~/assets/illustrations/backgrounds/lemon_grid.svg" alt="" />
                    <img v-if="findColor(insurance.id) == 'raspberry'"
                        src="~/assets/illustrations/backgrounds/raspberry_grid.svg" alt="" />
                    <img v-if="findColor(insurance.id) == 'tangerine'"
                        src="~/assets/illustrations/backgrounds/tangerine_grid.svg" alt="" />
                    <div class="absolute top-0 left-0">
                        <Avatar :entity="{ name: insurance.title }" :color="findColor(insurance.id)" size="sm" />
                    </div>
                </div>
                <p class="text-body-lg text-secondary">{{ t('up_to') }}</p>
                <p class="text-title-subsection mb-5 text-primary">{{ formatPrice(insurance.coverage, true) }}</p>
                <p class="text-tertiary text-body-lg">{{ returnInsuranceText(insurance.id) }}</p>
                <div class=" w-full mt-auto flex">
                    <p class="text-quarterary text-body-default">{{ t('price') }}
                        <br>
                        <span class=" text-secondary text-body-lg-heavy">{{ returnPrice(insurance) }}</span>
                    </p>
                    <Badge v-if="!insurance.id" class="ml-auto h-7 flex" type="bold">
                        <Icon src="check" class="mr-1 mt-0.5" />
                        {{ t('included') }}
                    </Badge>
                </div>
            </div>
        </div>
        <p v-if="insuranceData" class="text-tertiary text-body-sm">{{ t('insurance_disclaimer') }}</p>
    </div>
</template>

<script setup lang="ts">

import EventBus from "@/plugins/eventbus";

const { t } = useI18n();

const { isAccountBusiness, isDFM } = useAuth();

const props = defineProps<{
    values: any;
}>();

const { loading, fetchInsurances } = useInsurance()

const insuranceData = ref<any>(null);

fetchInsurances(props.values).then((data) => {
    insuranceData.value = data;
});

const {
    value: selectedInsurance,
} = useField(() => 'insurance', undefined, {
    syncVModel: true,
});

const {
    value: selectedInsuranceCoverage,
} = useField(() => 'insurance_coverage', undefined, {
    syncVModel: true,
});

const {
    value: selectedInsurancePrice,
} = useField(() => 'insurance_price', undefined, {
    syncVModel: true,
});

const selectInsurance = (insurance: any) => {
    selectedInsurance.value = insurance.id;
    selectedInsuranceCoverage.value = formatPrice(insurance.coverage, false);
    selectedInsurancePrice.value = isAccountBusiness() ? insurance.price_excl_tax : insurance.price_incl_tax;
};

const returnStandartCoverage = () => {
    if (insuranceData.value) {
        return formatPrice(insuranceData.value[0].coverage, true);
    }
    return formatPrice(0, true);
};

const returnPrice = (insurance: any) => {
    if (insurance.price_incl_tax == 0) {
        return t('free');
    }
    return isAccountBusiness() ? formatPrice(insurance.price_excl_tax, true) : formatPrice(insurance.price_incl_tax, true);
};

const returnInsuranceText = (id: number) => {
    switch (id) {
        case 1:
            return t('free_insurance');
        case 2:
            return t('insurance_text_with_value', { value: t('moderate_value').toLowerCase() });
        case 3:
            return t('insurance_text_with_value', { value: t('high_value').toLocaleLowerCase() });
        case 4:
            return t('insurance_text_with_value', { value: t('very_high_value').toLowerCase() });
        default:
            return t('free_insurance');
    }
};

const findColor = (id: number) => {
    switch (id) {
        case 1:
            return 'lemon';
        case 2:
            return 'tangerine';
        case 3:
            return 'raspberry';
        case 4:
            return 'grape';
        default:
            return 'lemon';
    }
};
</script>