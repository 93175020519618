import { default as indexlXWKW23Hj9Meta } from "/app/pages/auth/login/index.vue?macro=true";
import { default as logoutCHyXH6UWwkMeta } from "/app/pages/auth/logout.vue?macro=true";
import { default as indexFfkaDmrbi7Meta } from "/app/pages/auth/register/index.vue?macro=true";
import { default as BaseVcPtdrNxk4Meta } from "/app/pages/auth/register/partials/Mockups/Base.vue?macro=true";
import { default as CreateOrganisationv4H0dnoB4nMeta } from "/app/pages/auth/register/partials/Mockups/CreateOrganisation.vue?macro=true";
import { default as EnterPersonalInformationKwkUlBeqC1Meta } from "/app/pages/auth/register/partials/Mockups/EnterPersonalInformation.vue?macro=true";
import { default as InviteTeamMemberslj2z5WF0WlMeta } from "/app/pages/auth/register/partials/Mockups/InviteTeamMembers.vue?macro=true";
import { default as OrganisationInformationJRhwTiMLB8Meta } from "/app/pages/auth/register/partials/Mockups/OrganisationInformation.vue?macro=true";
import { default as NotificationStepOO3Mx0HUXBMeta } from "/app/pages/auth/register/partials/NotificationStep.vue?macro=true";
import { default as useCreateOrganisation5g9MhNSUQZMeta } from "/app/pages/auth/register/schemas/useCreateOrganisation.ts?macro=true";
import { default as useCreatePassword4F2WytoYglMeta } from "/app/pages/auth/register/schemas/useCreatePassword.ts?macro=true";
import { default as useEnableNotificationscVDntcRZEsMeta } from "/app/pages/auth/register/schemas/useEnableNotifications.ts?macro=true";
import { default as useEnterEmailGfmOaPjEyyMeta } from "/app/pages/auth/register/schemas/useEnterEmail.ts?macro=true";
import { default as useEnterPersonalInformationu3VHk8AKspMeta } from "/app/pages/auth/register/schemas/useEnterPersonalInformation.ts?macro=true";
import { default as useEnterPersonalInformationWithTOCjb8O8UDCzZMeta } from "/app/pages/auth/register/schemas/useEnterPersonalInformationWithTOC.ts?macro=true";
import { default as useInviteTeamMembersznJDsXcZkFMeta } from "/app/pages/auth/register/schemas/useInviteTeamMembers.ts?macro=true";
import { default as useOrganisationInformationkU1TUyrk6tMeta } from "/app/pages/auth/register/schemas/useOrganisationInformation.ts?macro=true";
import { default as useRequestPasswordResetiWdjYjvgBFMeta } from "/app/pages/auth/register/schemas/useRequestPasswordReset.ts?macro=true";
import { default as useResetPassword91N1jz8QJEMeta } from "/app/pages/auth/register/schemas/useResetPassword.ts?macro=true";
import { default as useVerifyIdentityRpxSLJKgPaMeta } from "/app/pages/auth/register/schemas/useVerifyIdentity.ts?macro=true";
import { default as indexqBiyqmBWQYMeta } from "/app/pages/auth/register/user/index.vue?macro=true";
import { default as indexcr5UFr0TWSMeta } from "/app/pages/auth/register/workspace/index.vue?macro=true";
import { default as index8gApeRg22xMeta } from "/app/pages/auth/reset/process/index.vue?macro=true";
import { default as indexRDDDbax4LVMeta } from "/app/pages/auth/reset/request/index.vue?macro=true";
import { default as forwarderwY6AMMcVvfMeta } from "/app/pages/auth/website/forwarder.vue?macro=true";
import { default as test6sGCljJlTKMeta } from "/app/pages/checkouts/test.vue?macro=true";
import { default as indexpEqeHBee6QMeta } from "/app/pages/documents/index.vue?macro=true";
import { default as EmptyListSJd0GUz51vMeta } from "/app/pages/documents/partials/EmptyList.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexjq7Ho9BMUYMeta } from "/app/pages/invoices/index.vue?macro=true";
import { default as EmptyListFsYrSBayGGMeta } from "/app/pages/invoices/partials/EmptyList.vue?macro=true";
import { default as indexNwMsGxInsLMeta } from "/app/pages/prices/index.vue?macro=true";
import { default as ordersuccessYia42uQzQTMeta } from "/app/pages/redirects/ordersuccess.vue?macro=true";
import { default as indexeDTdgZ4uqEMeta } from "/app/pages/returns/index.vue?macro=true";
import { default as EmptyList70tzqUHDm4Meta } from "/app/pages/returns/partials/EmptyList.vue?macro=true";
import { default as EmptyTableE8OExY7IbXMeta } from "/app/pages/returns/partials/EmptyTable.vue?macro=true";
import { default as index2HHjv8eMlIMeta } from "/app/pages/shipments/[id]/index.vue?macro=true";
import { default as labelJ6aSgbuK4bMeta } from "/app/pages/shipments/[id]/label.vue?macro=true";
import { default as index1s6FyJnw0EMeta } from "/app/pages/shipments/import/index.vue?macro=true";
import { default as listeWoekqTTv5Meta } from "/app/pages/shipments/import/list.vue?macro=true";
import { default as BalanceTooLowModalContentctspJaaRG5Meta } from "/app/pages/shipments/import/partials/BalanceTooLowModalContent.vue?macro=true";
import { default as CompleteModalContentu1R2aUyd4JMeta } from "/app/pages/shipments/import/partials/CompleteModalContent.vue?macro=true";
import { default as EditModalContentZGuCWClUujMeta } from "/app/pages/shipments/import/partials/EditModalContent.vue?macro=true";
import { default as TemplateModalContentnG2hpaTCTjMeta } from "/app/pages/shipments/import/partials/TemplateModalContent.vue?macro=true";
import { default as TopbarKqndjwvdP9Meta } from "/app/pages/shipments/import/partials/Topbar.vue?macro=true";
import { default as indexUQkB9U3X0WMeta } from "/app/pages/shipments/index.vue?macro=true";
import { default as EmptyListOLwja9b0H1Meta } from "/app/pages/shipments/partials/EmptyList.vue?macro=true";
import { default as EmptyListNoShopoZJ1R2SsYiMeta } from "/app/pages/shipments/partials/EmptyListNoShop.vue?macro=true";
import { default as EmptyListWithFilters4LjQsw6G9lMeta } from "/app/pages/shipments/partials/EmptyListWithFilters.vue?macro=true";
import { default as SelectionGzCZ2fWo7OMeta } from "/app/pages/shipments/partials/MultiActions/Selection.vue?macro=true";
import { default as OrderDetailsp5Q0b4TbN7Meta } from "/app/pages/shipments/partials/OrderDetails.vue?macro=true";
import { default as PackageContent131KJjUClFMeta } from "/app/pages/shipments/partials/PackageContent.vue?macro=true";
import { default as WmsDetailsXjgdKeZvgzMeta } from "/app/pages/shipments/partials/WmsDetails.vue?macro=true";
import { default as index8vlRZcXPmuMeta } from "/app/pages/sortings/index.vue?macro=true";
import { default as EmptyListtiLtm6JCBDMeta } from "/app/pages/sortings/partials/EmptyList.vue?macro=true";
import { default as GroupEn4DfCIJhfMeta } from "/app/pages/sortings/partials/Group.vue?macro=true";
import { default as GroupActionstvAtzkLFVFMeta } from "/app/pages/sortings/partials/GroupActions.vue?macro=true";
import { default as NewTransfer60TFpyZ1wqMeta } from "/app/pages/sortings/partials/NewTransfer.vue?macro=true";
import { default as ProhibitItemOurEuzSGVjMeta } from "/app/pages/sortings/partials/ProhibitItem.vue?macro=true";
import { default as TopBoxItemfqLVSfbS4tMeta } from "/app/pages/sortings/partials/TopBoxItem.vue?macro=true";
import { default as indexWUwfrPymtWMeta } from "/app/pages/superuser/index.vue?macro=true";
import { default as indexjd0p1QE5TBMeta } from "/app/pages/tickets/[id]/index.vue?macro=true";
import { default as indexSYGgMZWiZOMeta } from "/app/pages/tickets/index.vue?macro=true";
import { default as EmptyListuogcf5UP9vMeta } from "/app/pages/tickets/partials/EmptyList.vue?macro=true";
import { default as TicketAttributeDhGTrghviGMeta } from "/app/pages/tickets/partials/TicketAttribute.vue?macro=true";
import { default as TicketDetailsBb4AqsaSpsMeta } from "/app/pages/tickets/partials/TicketDetails.vue?macro=true";
export default [
  {
    name: indexlXWKW23Hj9Meta?.name ?? "auth-login",
    path: indexlXWKW23Hj9Meta?.path ?? "/auth/login",
    meta: indexlXWKW23Hj9Meta || {},
    alias: indexlXWKW23Hj9Meta?.alias || [],
    redirect: indexlXWKW23Hj9Meta?.redirect,
    component: () => import("/app/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: logoutCHyXH6UWwkMeta?.name ?? "auth-logout",
    path: logoutCHyXH6UWwkMeta?.path ?? "/auth/logout",
    meta: logoutCHyXH6UWwkMeta || {},
    alias: logoutCHyXH6UWwkMeta?.alias || [],
    redirect: logoutCHyXH6UWwkMeta?.redirect,
    component: () => import("/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: indexFfkaDmrbi7Meta?.name ?? "auth-register",
    path: indexFfkaDmrbi7Meta?.path ?? "/auth/register",
    meta: indexFfkaDmrbi7Meta || {},
    alias: indexFfkaDmrbi7Meta?.alias || [],
    redirect: indexFfkaDmrbi7Meta?.redirect,
    component: () => import("/app/pages/auth/register/index.vue").then(m => m.default || m)
  },
  {
    name: BaseVcPtdrNxk4Meta?.name ?? "auth-register-partials-Mockups-Base",
    path: BaseVcPtdrNxk4Meta?.path ?? "/auth/register/partials/Mockups/Base",
    meta: BaseVcPtdrNxk4Meta || {},
    alias: BaseVcPtdrNxk4Meta?.alias || [],
    redirect: BaseVcPtdrNxk4Meta?.redirect,
    component: () => import("/app/pages/auth/register/partials/Mockups/Base.vue").then(m => m.default || m)
  },
  {
    name: CreateOrganisationv4H0dnoB4nMeta?.name ?? "auth-register-partials-Mockups-CreateOrganisation",
    path: CreateOrganisationv4H0dnoB4nMeta?.path ?? "/auth/register/partials/Mockups/CreateOrganisation",
    meta: CreateOrganisationv4H0dnoB4nMeta || {},
    alias: CreateOrganisationv4H0dnoB4nMeta?.alias || [],
    redirect: CreateOrganisationv4H0dnoB4nMeta?.redirect,
    component: () => import("/app/pages/auth/register/partials/Mockups/CreateOrganisation.vue").then(m => m.default || m)
  },
  {
    name: EnterPersonalInformationKwkUlBeqC1Meta?.name ?? "auth-register-partials-Mockups-EnterPersonalInformation",
    path: EnterPersonalInformationKwkUlBeqC1Meta?.path ?? "/auth/register/partials/Mockups/EnterPersonalInformation",
    meta: EnterPersonalInformationKwkUlBeqC1Meta || {},
    alias: EnterPersonalInformationKwkUlBeqC1Meta?.alias || [],
    redirect: EnterPersonalInformationKwkUlBeqC1Meta?.redirect,
    component: () => import("/app/pages/auth/register/partials/Mockups/EnterPersonalInformation.vue").then(m => m.default || m)
  },
  {
    name: InviteTeamMemberslj2z5WF0WlMeta?.name ?? "auth-register-partials-Mockups-InviteTeamMembers",
    path: InviteTeamMemberslj2z5WF0WlMeta?.path ?? "/auth/register/partials/Mockups/InviteTeamMembers",
    meta: InviteTeamMemberslj2z5WF0WlMeta || {},
    alias: InviteTeamMemberslj2z5WF0WlMeta?.alias || [],
    redirect: InviteTeamMemberslj2z5WF0WlMeta?.redirect,
    component: () => import("/app/pages/auth/register/partials/Mockups/InviteTeamMembers.vue").then(m => m.default || m)
  },
  {
    name: OrganisationInformationJRhwTiMLB8Meta?.name ?? "auth-register-partials-Mockups-OrganisationInformation",
    path: OrganisationInformationJRhwTiMLB8Meta?.path ?? "/auth/register/partials/Mockups/OrganisationInformation",
    meta: OrganisationInformationJRhwTiMLB8Meta || {},
    alias: OrganisationInformationJRhwTiMLB8Meta?.alias || [],
    redirect: OrganisationInformationJRhwTiMLB8Meta?.redirect,
    component: () => import("/app/pages/auth/register/partials/Mockups/OrganisationInformation.vue").then(m => m.default || m)
  },
  {
    name: NotificationStepOO3Mx0HUXBMeta?.name ?? "auth-register-partials-NotificationStep",
    path: NotificationStepOO3Mx0HUXBMeta?.path ?? "/auth/register/partials/NotificationStep",
    meta: NotificationStepOO3Mx0HUXBMeta || {},
    alias: NotificationStepOO3Mx0HUXBMeta?.alias || [],
    redirect: NotificationStepOO3Mx0HUXBMeta?.redirect,
    component: () => import("/app/pages/auth/register/partials/NotificationStep.vue").then(m => m.default || m)
  },
  {
    name: useCreateOrganisation5g9MhNSUQZMeta?.name ?? "auth-register-schemas-useCreateOrganisation",
    path: useCreateOrganisation5g9MhNSUQZMeta?.path ?? "/auth/register/schemas/useCreateOrganisation",
    meta: useCreateOrganisation5g9MhNSUQZMeta || {},
    alias: useCreateOrganisation5g9MhNSUQZMeta?.alias || [],
    redirect: useCreateOrganisation5g9MhNSUQZMeta?.redirect,
    component: () => import("/app/pages/auth/register/schemas/useCreateOrganisation.ts").then(m => m.default || m)
  },
  {
    name: useCreatePassword4F2WytoYglMeta?.name ?? "auth-register-schemas-useCreatePassword",
    path: useCreatePassword4F2WytoYglMeta?.path ?? "/auth/register/schemas/useCreatePassword",
    meta: useCreatePassword4F2WytoYglMeta || {},
    alias: useCreatePassword4F2WytoYglMeta?.alias || [],
    redirect: useCreatePassword4F2WytoYglMeta?.redirect,
    component: () => import("/app/pages/auth/register/schemas/useCreatePassword.ts").then(m => m.default || m)
  },
  {
    name: useEnableNotificationscVDntcRZEsMeta?.name ?? "auth-register-schemas-useEnableNotifications",
    path: useEnableNotificationscVDntcRZEsMeta?.path ?? "/auth/register/schemas/useEnableNotifications",
    meta: useEnableNotificationscVDntcRZEsMeta || {},
    alias: useEnableNotificationscVDntcRZEsMeta?.alias || [],
    redirect: useEnableNotificationscVDntcRZEsMeta?.redirect,
    component: () => import("/app/pages/auth/register/schemas/useEnableNotifications.ts").then(m => m.default || m)
  },
  {
    name: useEnterEmailGfmOaPjEyyMeta?.name ?? "auth-register-schemas-useEnterEmail",
    path: useEnterEmailGfmOaPjEyyMeta?.path ?? "/auth/register/schemas/useEnterEmail",
    meta: useEnterEmailGfmOaPjEyyMeta || {},
    alias: useEnterEmailGfmOaPjEyyMeta?.alias || [],
    redirect: useEnterEmailGfmOaPjEyyMeta?.redirect,
    component: () => import("/app/pages/auth/register/schemas/useEnterEmail.ts").then(m => m.default || m)
  },
  {
    name: useEnterPersonalInformationu3VHk8AKspMeta?.name ?? "auth-register-schemas-useEnterPersonalInformation",
    path: useEnterPersonalInformationu3VHk8AKspMeta?.path ?? "/auth/register/schemas/useEnterPersonalInformation",
    meta: useEnterPersonalInformationu3VHk8AKspMeta || {},
    alias: useEnterPersonalInformationu3VHk8AKspMeta?.alias || [],
    redirect: useEnterPersonalInformationu3VHk8AKspMeta?.redirect,
    component: () => import("/app/pages/auth/register/schemas/useEnterPersonalInformation.ts").then(m => m.default || m)
  },
  {
    name: useEnterPersonalInformationWithTOCjb8O8UDCzZMeta?.name ?? "auth-register-schemas-useEnterPersonalInformationWithTOC",
    path: useEnterPersonalInformationWithTOCjb8O8UDCzZMeta?.path ?? "/auth/register/schemas/useEnterPersonalInformationWithTOC",
    meta: useEnterPersonalInformationWithTOCjb8O8UDCzZMeta || {},
    alias: useEnterPersonalInformationWithTOCjb8O8UDCzZMeta?.alias || [],
    redirect: useEnterPersonalInformationWithTOCjb8O8UDCzZMeta?.redirect,
    component: () => import("/app/pages/auth/register/schemas/useEnterPersonalInformationWithTOC.ts").then(m => m.default || m)
  },
  {
    name: useInviteTeamMembersznJDsXcZkFMeta?.name ?? "auth-register-schemas-useInviteTeamMembers",
    path: useInviteTeamMembersznJDsXcZkFMeta?.path ?? "/auth/register/schemas/useInviteTeamMembers",
    meta: useInviteTeamMembersznJDsXcZkFMeta || {},
    alias: useInviteTeamMembersznJDsXcZkFMeta?.alias || [],
    redirect: useInviteTeamMembersznJDsXcZkFMeta?.redirect,
    component: () => import("/app/pages/auth/register/schemas/useInviteTeamMembers.ts").then(m => m.default || m)
  },
  {
    name: useOrganisationInformationkU1TUyrk6tMeta?.name ?? "auth-register-schemas-useOrganisationInformation",
    path: useOrganisationInformationkU1TUyrk6tMeta?.path ?? "/auth/register/schemas/useOrganisationInformation",
    meta: useOrganisationInformationkU1TUyrk6tMeta || {},
    alias: useOrganisationInformationkU1TUyrk6tMeta?.alias || [],
    redirect: useOrganisationInformationkU1TUyrk6tMeta?.redirect,
    component: () => import("/app/pages/auth/register/schemas/useOrganisationInformation.ts").then(m => m.default || m)
  },
  {
    name: useRequestPasswordResetiWdjYjvgBFMeta?.name ?? "auth-register-schemas-useRequestPasswordReset",
    path: useRequestPasswordResetiWdjYjvgBFMeta?.path ?? "/auth/register/schemas/useRequestPasswordReset",
    meta: useRequestPasswordResetiWdjYjvgBFMeta || {},
    alias: useRequestPasswordResetiWdjYjvgBFMeta?.alias || [],
    redirect: useRequestPasswordResetiWdjYjvgBFMeta?.redirect,
    component: () => import("/app/pages/auth/register/schemas/useRequestPasswordReset.ts").then(m => m.default || m)
  },
  {
    name: useResetPassword91N1jz8QJEMeta?.name ?? "auth-register-schemas-useResetPassword",
    path: useResetPassword91N1jz8QJEMeta?.path ?? "/auth/register/schemas/useResetPassword",
    meta: useResetPassword91N1jz8QJEMeta || {},
    alias: useResetPassword91N1jz8QJEMeta?.alias || [],
    redirect: useResetPassword91N1jz8QJEMeta?.redirect,
    component: () => import("/app/pages/auth/register/schemas/useResetPassword.ts").then(m => m.default || m)
  },
  {
    name: useVerifyIdentityRpxSLJKgPaMeta?.name ?? "auth-register-schemas-useVerifyIdentity",
    path: useVerifyIdentityRpxSLJKgPaMeta?.path ?? "/auth/register/schemas/useVerifyIdentity",
    meta: useVerifyIdentityRpxSLJKgPaMeta || {},
    alias: useVerifyIdentityRpxSLJKgPaMeta?.alias || [],
    redirect: useVerifyIdentityRpxSLJKgPaMeta?.redirect,
    component: () => import("/app/pages/auth/register/schemas/useVerifyIdentity.ts").then(m => m.default || m)
  },
  {
    name: indexqBiyqmBWQYMeta?.name ?? "auth-register-user",
    path: indexqBiyqmBWQYMeta?.path ?? "/auth/register/user",
    meta: indexqBiyqmBWQYMeta || {},
    alias: indexqBiyqmBWQYMeta?.alias || [],
    redirect: indexqBiyqmBWQYMeta?.redirect,
    component: () => import("/app/pages/auth/register/user/index.vue").then(m => m.default || m)
  },
  {
    name: indexcr5UFr0TWSMeta?.name ?? "auth-register-workspace",
    path: indexcr5UFr0TWSMeta?.path ?? "/auth/register/workspace",
    meta: indexcr5UFr0TWSMeta || {},
    alias: indexcr5UFr0TWSMeta?.alias || [],
    redirect: indexcr5UFr0TWSMeta?.redirect,
    component: () => import("/app/pages/auth/register/workspace/index.vue").then(m => m.default || m)
  },
  {
    name: index8gApeRg22xMeta?.name ?? "auth-reset-process",
    path: index8gApeRg22xMeta?.path ?? "/auth/reset/process",
    meta: index8gApeRg22xMeta || {},
    alias: index8gApeRg22xMeta?.alias || [],
    redirect: index8gApeRg22xMeta?.redirect,
    component: () => import("/app/pages/auth/reset/process/index.vue").then(m => m.default || m)
  },
  {
    name: indexRDDDbax4LVMeta?.name ?? "auth-reset-request",
    path: indexRDDDbax4LVMeta?.path ?? "/auth/reset/request",
    meta: indexRDDDbax4LVMeta || {},
    alias: indexRDDDbax4LVMeta?.alias || [],
    redirect: indexRDDDbax4LVMeta?.redirect,
    component: () => import("/app/pages/auth/reset/request/index.vue").then(m => m.default || m)
  },
  {
    name: forwarderwY6AMMcVvfMeta?.name ?? "auth-website-forwarder",
    path: forwarderwY6AMMcVvfMeta?.path ?? "/auth/website/forwarder",
    meta: forwarderwY6AMMcVvfMeta || {},
    alias: forwarderwY6AMMcVvfMeta?.alias || [],
    redirect: forwarderwY6AMMcVvfMeta?.redirect,
    component: () => import("/app/pages/auth/website/forwarder.vue").then(m => m.default || m)
  },
  {
    name: test6sGCljJlTKMeta?.name ?? "checkouts-test",
    path: test6sGCljJlTKMeta?.path ?? "/checkouts/test",
    meta: test6sGCljJlTKMeta || {},
    alias: test6sGCljJlTKMeta?.alias || [],
    redirect: test6sGCljJlTKMeta?.redirect,
    component: () => import("/app/pages/checkouts/test.vue").then(m => m.default || m)
  },
  {
    name: indexpEqeHBee6QMeta?.name ?? "documents",
    path: indexpEqeHBee6QMeta?.path ?? "/documents",
    meta: indexpEqeHBee6QMeta || {},
    alias: indexpEqeHBee6QMeta?.alias || [],
    redirect: indexpEqeHBee6QMeta?.redirect,
    component: () => import("/app/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: EmptyListSJd0GUz51vMeta?.name ?? "documents-partials-EmptyList",
    path: EmptyListSJd0GUz51vMeta?.path ?? "/documents/partials/EmptyList",
    meta: EmptyListSJd0GUz51vMeta || {},
    alias: EmptyListSJd0GUz51vMeta?.alias || [],
    redirect: EmptyListSJd0GUz51vMeta?.redirect,
    component: () => import("/app/pages/documents/partials/EmptyList.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjq7Ho9BMUYMeta?.name ?? "invoices",
    path: indexjq7Ho9BMUYMeta?.path ?? "/invoices",
    meta: indexjq7Ho9BMUYMeta || {},
    alias: indexjq7Ho9BMUYMeta?.alias || [],
    redirect: indexjq7Ho9BMUYMeta?.redirect,
    component: () => import("/app/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: EmptyListFsYrSBayGGMeta?.name ?? "invoices-partials-EmptyList",
    path: EmptyListFsYrSBayGGMeta?.path ?? "/invoices/partials/EmptyList",
    meta: EmptyListFsYrSBayGGMeta || {},
    alias: EmptyListFsYrSBayGGMeta?.alias || [],
    redirect: EmptyListFsYrSBayGGMeta?.redirect,
    component: () => import("/app/pages/invoices/partials/EmptyList.vue").then(m => m.default || m)
  },
  {
    name: indexNwMsGxInsLMeta?.name ?? "prices",
    path: indexNwMsGxInsLMeta?.path ?? "/prices",
    meta: indexNwMsGxInsLMeta || {},
    alias: indexNwMsGxInsLMeta?.alias || [],
    redirect: indexNwMsGxInsLMeta?.redirect,
    component: () => import("/app/pages/prices/index.vue").then(m => m.default || m)
  },
  {
    name: ordersuccessYia42uQzQTMeta?.name ?? "redirects-ordersuccess",
    path: ordersuccessYia42uQzQTMeta?.path ?? "/redirects/ordersuccess",
    meta: ordersuccessYia42uQzQTMeta || {},
    alias: ordersuccessYia42uQzQTMeta?.alias || [],
    redirect: ordersuccessYia42uQzQTMeta?.redirect,
    component: () => import("/app/pages/redirects/ordersuccess.vue").then(m => m.default || m)
  },
  {
    name: indexeDTdgZ4uqEMeta?.name ?? "returns",
    path: indexeDTdgZ4uqEMeta?.path ?? "/returns",
    meta: indexeDTdgZ4uqEMeta || {},
    alias: indexeDTdgZ4uqEMeta?.alias || [],
    redirect: indexeDTdgZ4uqEMeta?.redirect,
    component: () => import("/app/pages/returns/index.vue").then(m => m.default || m)
  },
  {
    name: EmptyList70tzqUHDm4Meta?.name ?? "returns-partials-EmptyList",
    path: EmptyList70tzqUHDm4Meta?.path ?? "/returns/partials/EmptyList",
    meta: EmptyList70tzqUHDm4Meta || {},
    alias: EmptyList70tzqUHDm4Meta?.alias || [],
    redirect: EmptyList70tzqUHDm4Meta?.redirect,
    component: () => import("/app/pages/returns/partials/EmptyList.vue").then(m => m.default || m)
  },
  {
    name: EmptyTableE8OExY7IbXMeta?.name ?? "returns-partials-EmptyTable",
    path: EmptyTableE8OExY7IbXMeta?.path ?? "/returns/partials/EmptyTable",
    meta: EmptyTableE8OExY7IbXMeta || {},
    alias: EmptyTableE8OExY7IbXMeta?.alias || [],
    redirect: EmptyTableE8OExY7IbXMeta?.redirect,
    component: () => import("/app/pages/returns/partials/EmptyTable.vue").then(m => m.default || m)
  },
  {
    name: index2HHjv8eMlIMeta?.name ?? "shipments-id",
    path: index2HHjv8eMlIMeta?.path ?? "/shipments/:id()",
    meta: index2HHjv8eMlIMeta || {},
    alias: index2HHjv8eMlIMeta?.alias || [],
    redirect: index2HHjv8eMlIMeta?.redirect,
    component: () => import("/app/pages/shipments/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: labelJ6aSgbuK4bMeta?.name ?? "shipments-id-label",
    path: labelJ6aSgbuK4bMeta?.path ?? "/shipments/:id()/label",
    meta: labelJ6aSgbuK4bMeta || {},
    alias: labelJ6aSgbuK4bMeta?.alias || [],
    redirect: labelJ6aSgbuK4bMeta?.redirect,
    component: () => import("/app/pages/shipments/[id]/label.vue").then(m => m.default || m)
  },
  {
    name: index1s6FyJnw0EMeta?.name ?? "shipments-import",
    path: index1s6FyJnw0EMeta?.path ?? "/shipments/import",
    meta: index1s6FyJnw0EMeta || {},
    alias: index1s6FyJnw0EMeta?.alias || [],
    redirect: index1s6FyJnw0EMeta?.redirect,
    component: () => import("/app/pages/shipments/import/index.vue").then(m => m.default || m)
  },
  {
    name: listeWoekqTTv5Meta?.name ?? "shipments-import-list",
    path: listeWoekqTTv5Meta?.path ?? "/shipments/import/list",
    meta: listeWoekqTTv5Meta || {},
    alias: listeWoekqTTv5Meta?.alias || [],
    redirect: listeWoekqTTv5Meta?.redirect,
    component: () => import("/app/pages/shipments/import/list.vue").then(m => m.default || m)
  },
  {
    name: BalanceTooLowModalContentctspJaaRG5Meta?.name ?? "shipments-import-partials-BalanceTooLowModalContent",
    path: BalanceTooLowModalContentctspJaaRG5Meta?.path ?? "/shipments/import/partials/BalanceTooLowModalContent",
    meta: BalanceTooLowModalContentctspJaaRG5Meta || {},
    alias: BalanceTooLowModalContentctspJaaRG5Meta?.alias || [],
    redirect: BalanceTooLowModalContentctspJaaRG5Meta?.redirect,
    component: () => import("/app/pages/shipments/import/partials/BalanceTooLowModalContent.vue").then(m => m.default || m)
  },
  {
    name: CompleteModalContentu1R2aUyd4JMeta?.name ?? "shipments-import-partials-CompleteModalContent",
    path: CompleteModalContentu1R2aUyd4JMeta?.path ?? "/shipments/import/partials/CompleteModalContent",
    meta: CompleteModalContentu1R2aUyd4JMeta || {},
    alias: CompleteModalContentu1R2aUyd4JMeta?.alias || [],
    redirect: CompleteModalContentu1R2aUyd4JMeta?.redirect,
    component: () => import("/app/pages/shipments/import/partials/CompleteModalContent.vue").then(m => m.default || m)
  },
  {
    name: EditModalContentZGuCWClUujMeta?.name ?? "shipments-import-partials-EditModalContent",
    path: EditModalContentZGuCWClUujMeta?.path ?? "/shipments/import/partials/EditModalContent",
    meta: EditModalContentZGuCWClUujMeta || {},
    alias: EditModalContentZGuCWClUujMeta?.alias || [],
    redirect: EditModalContentZGuCWClUujMeta?.redirect,
    component: () => import("/app/pages/shipments/import/partials/EditModalContent.vue").then(m => m.default || m)
  },
  {
    name: TemplateModalContentnG2hpaTCTjMeta?.name ?? "shipments-import-partials-TemplateModalContent",
    path: TemplateModalContentnG2hpaTCTjMeta?.path ?? "/shipments/import/partials/TemplateModalContent",
    meta: TemplateModalContentnG2hpaTCTjMeta || {},
    alias: TemplateModalContentnG2hpaTCTjMeta?.alias || [],
    redirect: TemplateModalContentnG2hpaTCTjMeta?.redirect,
    component: () => import("/app/pages/shipments/import/partials/TemplateModalContent.vue").then(m => m.default || m)
  },
  {
    name: TopbarKqndjwvdP9Meta?.name ?? "shipments-import-partials-Topbar",
    path: TopbarKqndjwvdP9Meta?.path ?? "/shipments/import/partials/Topbar",
    meta: TopbarKqndjwvdP9Meta || {},
    alias: TopbarKqndjwvdP9Meta?.alias || [],
    redirect: TopbarKqndjwvdP9Meta?.redirect,
    component: () => import("/app/pages/shipments/import/partials/Topbar.vue").then(m => m.default || m)
  },
  {
    name: indexUQkB9U3X0WMeta?.name ?? "shipments",
    path: indexUQkB9U3X0WMeta?.path ?? "/shipments",
    meta: indexUQkB9U3X0WMeta || {},
    alias: indexUQkB9U3X0WMeta?.alias || [],
    redirect: indexUQkB9U3X0WMeta?.redirect,
    component: () => import("/app/pages/shipments/index.vue").then(m => m.default || m)
  },
  {
    name: EmptyListOLwja9b0H1Meta?.name ?? "shipments-partials-EmptyList",
    path: EmptyListOLwja9b0H1Meta?.path ?? "/shipments/partials/EmptyList",
    meta: EmptyListOLwja9b0H1Meta || {},
    alias: EmptyListOLwja9b0H1Meta?.alias || [],
    redirect: EmptyListOLwja9b0H1Meta?.redirect,
    component: () => import("/app/pages/shipments/partials/EmptyList.vue").then(m => m.default || m)
  },
  {
    name: EmptyListNoShopoZJ1R2SsYiMeta?.name ?? "shipments-partials-EmptyListNoShop",
    path: EmptyListNoShopoZJ1R2SsYiMeta?.path ?? "/shipments/partials/EmptyListNoShop",
    meta: EmptyListNoShopoZJ1R2SsYiMeta || {},
    alias: EmptyListNoShopoZJ1R2SsYiMeta?.alias || [],
    redirect: EmptyListNoShopoZJ1R2SsYiMeta?.redirect,
    component: () => import("/app/pages/shipments/partials/EmptyListNoShop.vue").then(m => m.default || m)
  },
  {
    name: EmptyListWithFilters4LjQsw6G9lMeta?.name ?? "shipments-partials-EmptyListWithFilters",
    path: EmptyListWithFilters4LjQsw6G9lMeta?.path ?? "/shipments/partials/EmptyListWithFilters",
    meta: EmptyListWithFilters4LjQsw6G9lMeta || {},
    alias: EmptyListWithFilters4LjQsw6G9lMeta?.alias || [],
    redirect: EmptyListWithFilters4LjQsw6G9lMeta?.redirect,
    component: () => import("/app/pages/shipments/partials/EmptyListWithFilters.vue").then(m => m.default || m)
  },
  {
    name: SelectionGzCZ2fWo7OMeta?.name ?? "shipments-partials-MultiActions-Selection",
    path: SelectionGzCZ2fWo7OMeta?.path ?? "/shipments/partials/MultiActions/Selection",
    meta: SelectionGzCZ2fWo7OMeta || {},
    alias: SelectionGzCZ2fWo7OMeta?.alias || [],
    redirect: SelectionGzCZ2fWo7OMeta?.redirect,
    component: () => import("/app/pages/shipments/partials/MultiActions/Selection.vue").then(m => m.default || m)
  },
  {
    name: OrderDetailsp5Q0b4TbN7Meta?.name ?? "shipments-partials-OrderDetails",
    path: OrderDetailsp5Q0b4TbN7Meta?.path ?? "/shipments/partials/OrderDetails",
    meta: OrderDetailsp5Q0b4TbN7Meta || {},
    alias: OrderDetailsp5Q0b4TbN7Meta?.alias || [],
    redirect: OrderDetailsp5Q0b4TbN7Meta?.redirect,
    component: () => import("/app/pages/shipments/partials/OrderDetails.vue").then(m => m.default || m)
  },
  {
    name: PackageContent131KJjUClFMeta?.name ?? "shipments-partials-PackageContent",
    path: PackageContent131KJjUClFMeta?.path ?? "/shipments/partials/PackageContent",
    meta: PackageContent131KJjUClFMeta || {},
    alias: PackageContent131KJjUClFMeta?.alias || [],
    redirect: PackageContent131KJjUClFMeta?.redirect,
    component: () => import("/app/pages/shipments/partials/PackageContent.vue").then(m => m.default || m)
  },
  {
    name: WmsDetailsXjgdKeZvgzMeta?.name ?? "shipments-partials-WmsDetails",
    path: WmsDetailsXjgdKeZvgzMeta?.path ?? "/shipments/partials/WmsDetails",
    meta: WmsDetailsXjgdKeZvgzMeta || {},
    alias: WmsDetailsXjgdKeZvgzMeta?.alias || [],
    redirect: WmsDetailsXjgdKeZvgzMeta?.redirect,
    component: () => import("/app/pages/shipments/partials/WmsDetails.vue").then(m => m.default || m)
  },
  {
    name: index8vlRZcXPmuMeta?.name ?? "sortings",
    path: index8vlRZcXPmuMeta?.path ?? "/sortings",
    meta: index8vlRZcXPmuMeta || {},
    alias: index8vlRZcXPmuMeta?.alias || [],
    redirect: index8vlRZcXPmuMeta?.redirect,
    component: () => import("/app/pages/sortings/index.vue").then(m => m.default || m)
  },
  {
    name: EmptyListtiLtm6JCBDMeta?.name ?? "sortings-partials-EmptyList",
    path: EmptyListtiLtm6JCBDMeta?.path ?? "/sortings/partials/EmptyList",
    meta: EmptyListtiLtm6JCBDMeta || {},
    alias: EmptyListtiLtm6JCBDMeta?.alias || [],
    redirect: EmptyListtiLtm6JCBDMeta?.redirect,
    component: () => import("/app/pages/sortings/partials/EmptyList.vue").then(m => m.default || m)
  },
  {
    name: GroupEn4DfCIJhfMeta?.name ?? "sortings-partials-Group",
    path: GroupEn4DfCIJhfMeta?.path ?? "/sortings/partials/Group",
    meta: GroupEn4DfCIJhfMeta || {},
    alias: GroupEn4DfCIJhfMeta?.alias || [],
    redirect: GroupEn4DfCIJhfMeta?.redirect,
    component: () => import("/app/pages/sortings/partials/Group.vue").then(m => m.default || m)
  },
  {
    name: GroupActionstvAtzkLFVFMeta?.name ?? "sortings-partials-GroupActions",
    path: GroupActionstvAtzkLFVFMeta?.path ?? "/sortings/partials/GroupActions",
    meta: GroupActionstvAtzkLFVFMeta || {},
    alias: GroupActionstvAtzkLFVFMeta?.alias || [],
    redirect: GroupActionstvAtzkLFVFMeta?.redirect,
    component: () => import("/app/pages/sortings/partials/GroupActions.vue").then(m => m.default || m)
  },
  {
    name: NewTransfer60TFpyZ1wqMeta?.name ?? "sortings-partials-NewTransfer",
    path: NewTransfer60TFpyZ1wqMeta?.path ?? "/sortings/partials/NewTransfer",
    meta: NewTransfer60TFpyZ1wqMeta || {},
    alias: NewTransfer60TFpyZ1wqMeta?.alias || [],
    redirect: NewTransfer60TFpyZ1wqMeta?.redirect,
    component: () => import("/app/pages/sortings/partials/NewTransfer.vue").then(m => m.default || m)
  },
  {
    name: ProhibitItemOurEuzSGVjMeta?.name ?? "sortings-partials-ProhibitItem",
    path: ProhibitItemOurEuzSGVjMeta?.path ?? "/sortings/partials/ProhibitItem",
    meta: ProhibitItemOurEuzSGVjMeta || {},
    alias: ProhibitItemOurEuzSGVjMeta?.alias || [],
    redirect: ProhibitItemOurEuzSGVjMeta?.redirect,
    component: () => import("/app/pages/sortings/partials/ProhibitItem.vue").then(m => m.default || m)
  },
  {
    name: TopBoxItemfqLVSfbS4tMeta?.name ?? "sortings-partials-TopBoxItem",
    path: TopBoxItemfqLVSfbS4tMeta?.path ?? "/sortings/partials/TopBoxItem",
    meta: TopBoxItemfqLVSfbS4tMeta || {},
    alias: TopBoxItemfqLVSfbS4tMeta?.alias || [],
    redirect: TopBoxItemfqLVSfbS4tMeta?.redirect,
    component: () => import("/app/pages/sortings/partials/TopBoxItem.vue").then(m => m.default || m)
  },
  {
    name: indexWUwfrPymtWMeta?.name ?? "superuser",
    path: indexWUwfrPymtWMeta?.path ?? "/superuser",
    meta: indexWUwfrPymtWMeta || {},
    alias: indexWUwfrPymtWMeta?.alias || [],
    redirect: indexWUwfrPymtWMeta?.redirect,
    component: () => import("/app/pages/superuser/index.vue").then(m => m.default || m)
  },
  {
    name: indexjd0p1QE5TBMeta?.name ?? "tickets-id",
    path: indexjd0p1QE5TBMeta?.path ?? "/tickets/:id()",
    meta: indexjd0p1QE5TBMeta || {},
    alias: indexjd0p1QE5TBMeta?.alias || [],
    redirect: indexjd0p1QE5TBMeta?.redirect,
    component: () => import("/app/pages/tickets/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSYGgMZWiZOMeta?.name ?? "tickets",
    path: indexSYGgMZWiZOMeta?.path ?? "/tickets",
    meta: indexSYGgMZWiZOMeta || {},
    alias: indexSYGgMZWiZOMeta?.alias || [],
    redirect: indexSYGgMZWiZOMeta?.redirect,
    component: () => import("/app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: EmptyListuogcf5UP9vMeta?.name ?? "tickets-partials-EmptyList",
    path: EmptyListuogcf5UP9vMeta?.path ?? "/tickets/partials/EmptyList",
    meta: EmptyListuogcf5UP9vMeta || {},
    alias: EmptyListuogcf5UP9vMeta?.alias || [],
    redirect: EmptyListuogcf5UP9vMeta?.redirect,
    component: () => import("/app/pages/tickets/partials/EmptyList.vue").then(m => m.default || m)
  },
  {
    name: TicketAttributeDhGTrghviGMeta?.name ?? "tickets-partials-TicketAttribute",
    path: TicketAttributeDhGTrghviGMeta?.path ?? "/tickets/partials/TicketAttribute",
    meta: TicketAttributeDhGTrghviGMeta || {},
    alias: TicketAttributeDhGTrghviGMeta?.alias || [],
    redirect: TicketAttributeDhGTrghviGMeta?.redirect,
    component: () => import("/app/pages/tickets/partials/TicketAttribute.vue").then(m => m.default || m)
  },
  {
    name: TicketDetailsBb4AqsaSpsMeta?.name ?? "tickets-partials-TicketDetails",
    path: TicketDetailsBb4AqsaSpsMeta?.path ?? "/tickets/partials/TicketDetails",
    meta: TicketDetailsBb4AqsaSpsMeta || {},
    alias: TicketDetailsBb4AqsaSpsMeta?.alias || [],
    redirect: TicketDetailsBb4AqsaSpsMeta?.redirect,
    component: () => import("/app/pages/tickets/partials/TicketDetails.vue").then(m => m.default || m)
  }
]