<template>
  <div class="!relative">
    <Input
      autocomplete="new-password"
      type="password"
      v-bind="$attrs"
      tag="input"
      v-model.noWhitespace="inputValue"
      @blur="onBlur"
      @update:model-value="val => setValue(val, false)" 
      @focus="onFocus"
      :error="!hasFocus ? error : ''"
      :size="size"
      ref="input"
      @click="() => emit('click')"
    />
    <div
      class="absolute px-4 py-3 rounded-xl bg-surface-lvl-00 w-full shadow-[0px_4px_8px_0px_rgba(38,_40,_44,_0.1)] border-2 border-00 text-body-sm mt-1 z-10"
      v-if="hasFocus"
    >
      <div class="mb-3">
        <div class="text-primary font-medium mb-1">{{ t("passwords_must_contain") }}</div>
        <ul class="text-secondary font-normal space-y-1 list-none pl-4">
          <li
            class="password-requirment"
            :class="{
              fulfilled: fulFills('length'),
            }"
          >
            {{t('minimum_n_characters', { n: min })}}
          </li>
          <li
            class="password-requirment"
            :class="{
              fulfilled: fulFills('case'),
            }"
          >
            {{ t("uppercase_and_lowercase") }}
          </li>
        </ul>
      </div>
      <div>
        <div>
          <div class="mb-2">{{ t("strength") }}</div>
          <PasswordStrength :password="(inputValue as string)" />
          <div class="mt-2 text-quarterary">
            {{ t("avoid_passwords") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ref } from "vue";

const modelValue = defineModel();


const props = withDefaults(
  defineProps<{
    size?: "default" | "small" | "large";
    disabled?: boolean;
    selected?: boolean;
    active?: boolean;
    name?: string;
    error?: string;
    min?: number;
  }>(),
  {
    size: "default",
    disabled: false,
    selected: false,
    active: false,
    name: "",
    min: 8,
  }
);

const { t } = useI18n();

const hasFocus = ref(false);

const onFocus = (event: Event) => {
  hasFocus.value = true;
};

const onBlur = (event: Event) => {
   hasFocus.value = false;
  handleBlur(event, true);
};

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  setValue,
  meta,
} = useField(() => props.name, undefined, {
    syncVModel: props.name ? false: true,
  controlled: !!props.name,
});

const fulFills = (rule) => {
  if (!inputValue.value || inputValue.value.length === 0) return false;

  switch (rule) {
    case "length":
      return inputValue.value.length >= props.min;
    case "case":
      return /[a-z]/.test(inputValue.value) && /[A-Z]/.test(inputValue.value);
    default:
      return false;
  }
};

const error = computed(() => {
  return props.error || errorMessage.value;
});

const input = ref<HTMLElement | null>(null);

const el = computed(() => {
  return input.value?.input;
});


const emit = defineEmits(["click"]);

const isFullfilled = computed(() => {
  return fulFills("length") && fulFills("case");
});

defineExpose({
  el,
  isFullfilled
});
</script>

<style scoped>
.password-requirment {
  @apply flex gap-2 items-center marker:text-xs marker:aspect-square before:w-4 before:h-4 before:bg-no-repeat before:bg-[url(assets/icons/dot.svg)]  before:bg-center before:bg-cover before:content-[''] before:inline-block -ml-5;
}

.password-requirment.fulfilled {
  @apply text-positive before:bg-positive-list before:bg-blend-multiply ;
}
</style>
