<template>
  <Tooltip :bottom="bottom" v-if="event" :text="event.title">
    <div class="h-7 rounded-r-[3px] max-w-full px-2 py-1 w-fit" 
     :class="{
        'text-brand': !isSpecialEvent,
        'text-positive bg-positive-01': event.event_code === 'DELIVERED',
        'text-warning bg-warning-01': event.event_code === 'DEVIATION',
        'text-destructive bg-destructive-01': event.event_code === 'EXCEPTION',
      }"
    :style="{
      background: background,
    }">
      <p class="text-body-default-heavy w-full text-ellipsis overflow-hidden text-nowrap truncate flex gap-1.5">
        <span v-if="timestamp"> 
          {{ eventTime }}
        </span>
        <span v-if="timestamp"> 
          •
        </span>
        <span class="truncate">{{ titleWithOutHtml }}</span>
      </p>
    </div>
    <template #header>
      <p class="text-wrap">
        {{ `${eventDateTime} • ${titleWithOutHtml}` }}
      </p>
    </template>
  </Tooltip>

  <div v-else class="h-7 rounded-r-[3px] max-w-full px-2 py-1" :style="{
    background: background,
    filter: 'grayscale(1)',
  }">
    <p class="text-[13px] font-medium text-disabled w-full text-ellipsis overflow-hidden text-nowrap truncate">
      {{ t("not_available") }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { DateTime } from "luxon";
const props = withDefaults(
  defineProps<{
    event?: {
      created_at: string;
      title: string;
      event_code: string;
      event_at_utc?: string;
      event_at?: string;
    };
    timestamp?: boolean;
    bottom?: boolean;
  }>(),
  {
    timestamp: false,
    bottom: false,
  }
);

const { locale, t } = useI18n();

const specialEvents = ["DELIVERED", "DEVIATION", "EXCEPTION"];

const isSpecialEvent = computed(() => {
  if (!props.event) return false;

  return specialEvents.includes(props.event.event_code);
});

const background = computed(() => {
  if (!props.event || !isSpecialEvent.value) return "linear-gradient(90deg, rgba(205, 221, 246, 0.12) 40%, rgba(4, 86, 209, 0.12) 100%)";
});

const eventTime = computed(() => {
  if (!props.event) return;

  return DateTime.fromISO(props.event.event_at_utc || props.event.event_at).toFormat("T");
});

const eventDateTime = computed(() => {
  if (!props.event) return;

  return DateTime.fromISO(props.event.event_at_utc || props.event.event_at).setLocale(locale.value).toFormat("d. LLL y, HH:mm");
});

const titleWithOutHtml = computed(() => {
  if (!props.event) return;

  return removeHtmlTags(props.event.title)
});
</script>
