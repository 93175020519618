export default defineNuxtRouteMiddleware(async (to, from) => {
  const { getAccount, setAccountIdCookie, hasSubdomains, checkIfSubdomainExists, getAccountFromSubdomain, updateCapabilities, isAccountBusiness, getAuthCookie, getAuthCookieExpiresIn } = useAuth();
  // skip middleware on layout auth
  if (to.meta.layout == "auth") {
    return;
  }

  // skip middleware on server
  if (process.server) return;

  if(!isAccountBusiness()) {
    const token = getAuthCookie().value;
    const expires_in = getAuthCookieExpiresIn();
   return navigateTo(`https://account.coolrunner.dk/login/o?token=${token}&expires_in=${expires_in*1000}`, { external: true });
  }

  const hostname = useRequestURL().hostname;
  //get domain + tld from hostname
  const domain = hostname.split(".").slice(1).join(".");

  if (isDomainDev()) {
    return;
  }

  const account = getAccount()!;
  const currentSubdomain = hostname.split(".")[0];

  if (hasSubdomains()) {
    if (checkIfSubdomainExists(currentSubdomain)) {
      if (currentSubdomain != account.subdomain) {
        //chekc if current account id cookie is the same as the account id of the subdomain
        if (getAccountFromSubdomain(currentSubdomain)!.account.id != account.account.id) {
          setAccountIdCookie(getAccountFromSubdomain(currentSubdomain)!.account.id);
          return;
        }
        return navigateTo(`https://${account.subdomain}.${domain}`, { external: true });
      }
      return;
    } else {
      if (process.client)
        //redirect to account subdomain
        return navigateTo(`https://${account.subdomain}.${domain}`, { external: true });
    }
  }
});
