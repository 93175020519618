import md5lib from "md5";

export const md5 = (message: string): string => md5lib(message);

export const formatWeight = (weight: number) => {
  const { $i18n } = useNuxtApp();

  // if over 1000g, convert to kg
  if (weight >= 10000) {
    const formattedWeight = (weight / 1000).toLocaleString($i18n.locale, { maximumFractionDigits: 2 });
    return `${formattedWeight}kg`;
  }
  return `${weight}g`;
};

export const formatPrice = (price: number | string | null | undefined, decimals: boolean | "auto", currency: string = "DKK") => {
  const { $i18n } = useNuxtApp();

  if (price === "" || price === null || price === undefined) return "";

  //if price is string convert to number
  if (typeof price === "string") {
    //split string by space and take first part
    const splits = price.split(" ");
    
    price = parseFloat(splits[0]);
    currency = splits[1] || currency;
  }

  if (decimals) {
    if (decimals === "auto") {
      return `${price.toLocaleString($i18n.locale, { maximumFractionDigits: 2 })} ${currency}`;
    }
    return `${price.toLocaleString($i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currency}`;
  }
  return `${price} ${currency}`;
};

export const isString = (value: any) => typeof value === "string" || value instanceof String;

export const removeHtmlTags = (str: string) => str.replace(/<[^>]*>?/gm, "");

export const stringToIntegerValue = (value?: string | null) => {
  if (!value) {
    return 0;
  }
  return value.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
};

export const getFileNameFromFileUrl = (url: string) => {
  if (!url) return "";
  //handle potential query string in url
  url = url.split("?")[0];

  return url.split("/").pop();
};
