import { defu } from "defu";
import type { UseFetchOptions } from "#app";

export function useSsrApi<T>(
  url: string | (() => string),
  options: UseFetchOptions<T> = {},
  silent: boolean = false
) {
  const { $i18n } = useNuxtApp();
  const t = $i18n.t;

  const defaultOptions = {
    deep: false,
    timeout: 60000,
    onResponseError({ request, response, options }) {
      const { _data: error } = response;
      if (
        error.statusCode == 401 &&
        error.url != "/api/auth/logout" &&
        error.url != "/api/auth/login"
      ) {
        navigateTo({ name: "auth-logout" });
        if ($toast)
          $toast.add({
            group: "session_expired",
            title: t("session_expired"),
            text: t("session_expired_login_again"),
          });
        else console.error(t("session_expired"));
      } else if (!silent)
        if ($toast)
          $toast.add({
            type: "error",
            group: error.url,
            title: `${t("error")} ${error.statusCode}`,
            text: t("error_occurred_contact_support"),
          });
        else console.error(error);

      return error;
    },
  };
  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaultOptions);
  return useFetch(getStringifiedUrl(url), params);
}

export const homeFetchRaw = (
  url: string,
  options: Record<string, any> = {}
) => {
  return homeFetch(url, options, true);
};

export const homeFetch = async (
  url: string,
  options: Record<string, any> = {},
  raw?: boolean
) => {
  const { $i18n } = useNuxtApp();
  const t = $i18n.t;

  const defaultOptions = {
    timeout: 60000,
  };
  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaultOptions);

  const method = raw ? $fetch.raw : $fetch;

  return await method(getStringifiedUrl(url), params).catch(
    (error) => {
      const silent = params.silent || false;
      if (
        error.statusCode == 401 &&
        error.url != "/api/auth/logout" &&
        error.url != "/api/auth/login"
      ) {
        navigateTo({ name: "auth-logout" });
        if ($toast && !silent)
          $toast.add({
            group: "session_expired",
            title: t("session_expired"),
            text: t("session_expired_login_again"),
          });
        else console.error(t("session_expired"));
      } else if ($toast && !silent) {
        $toast.add({
          type: "error",
          group: error.url,
          title: `${t("error")} ${error.statusCode}`,
          text: getErrorMessage(error),
        });
      } else console.error(error);

      throw error;
    }
  );
};

const getStringifiedUrl = (url: string | (() => string)) => {
  let stringifiedUrl = typeof url === "function" ? url() : url;

  //if url is not prepended with /api/ then prepend it and not if it is a full url
  if (stringifiedUrl.indexOf("http") !== 0)
    stringifiedUrl = "/api/" + stringifiedUrl;
  return stringifiedUrl;
};

const getErrorMessage = (error: any) => {
  const { $i18n } = useNuxtApp();
  const t = $i18n.t;

  let message;
  const responseData = error.data;
  if (responseData?.data?.data) {
    message =
      responseData.data.data.status_message ||
      responseData.data.data.message;
  } else if (responseData?.data) {
    message =
      responseData.data.status_message || responseData.data.message;
  } else {
    message = responseData.message;
  }

  if (!message) message = t("error_occurred_contact_support");

  return message;
};
