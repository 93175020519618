 <template>
  <Modal ref="modal" :maxWidth="maxWidth" minWidth="fit-content" :calculatePosition="calculatePosition" :overlay="overlay" :contentPadding="false">
    <slot></slot>
  </Modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { computed } from "vue";

defineOptions({
  name: "Dropdown",
});

const props = withDefaults(
  defineProps<{
    anchor?: "top-left" | "top-right" | "bottom-left" | "bottom-right" | "top" | "bottom";
    maxWidth?: string;
    calculatePosition?: () => void;
    overlay?: boolean;
  }>(),
  {
    anchor: "top-left",
    calculatePosition: () => {},
    overlay: false,
  }
);


const modal = ref<HTMLElement | null>(null);

const height = computed(() => {
  if (!modal.value) return 0;
  return modal.value?.height;
});

const width = computed(() => {
  if (!modal.value) return 0;
  return modal.value?.width;
});

const openingDuration = computed(() => {
  if (!modal.value) return 0;
  return modal.value?.openingDuration;
});


const setStyleProperty = (property: string, value: string) => {
  modal.value?.setStyleProperty(property, value);
};

const updateHeight = () => {
  modal.value?.updateHeight();
};

defineExpose({
  dropdown: modal,
  height,
  width,
  setStyleProperty,
  updateHeight,
  openingDuration,
});
</script>
