<template>
  <div class="flex flex-col gap-1">
    <label v-if="label" class="text-body-sm-heavy text-left text-secondary">{{ label }}</label>

    <div class="w-full flex gap-3">
      <div class="">
        <DropdownSelect filterable v-model="countrycode" :name="prefixName" @update:model-value="updatePhone" :options="phonePrefixes.prefixes" class="w-full max-w-[100px]" />
      </div>
      <div class="">
        <InputText :name="name" v-model="number" @update:model-value="updatePhone" :placeholder="t('phone')"></InputText>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const emit = defineEmits(["update:modelValue"]);

const props = withDefaults(
  defineProps<{
    name?: string;
    prefixName?: string;
    label?: string;
    values?: any;
    setPrefix?: any;
  }>(),
  {}
);

onMounted(() => {
  if (props.values) {
    number.value = props.values.phone_number;
  }
});

watch(() => props.setPrefix, (val) => {
  if (val) {
    const foundPrefix = phonePrefixes.value.prefixes.find(prefix => prefix.iso === val);
    if (foundPrefix) {
      countrycode.value = foundPrefix.value;
    }
  }
});

const { data: phonePrefixes } = await useCountries();

const countrycode = ref("+45");

const number = ref("");

watchEffect(() => {});

const updatePhone = () => {
  emit("update:modelValue", {prefix: countrycode.value, phone_number: number.value});
};
</script>
