<template>
  <div
    class="h-11 px-4 py-2 gap-2 flex items-center transition-colors rounded-xl cursor-pointer bg-surface-lvl-00"
    @click="() => emit('toggle')"

  >
    <Button variant="tertiary" type="button" icon-only size="small" :active="expanded">
      <Icon
        src="bold/CaretDown"
        class="transform transition-all duration-300"
        :class="{
          '-rotate-90': !expanded,
        }"
      />
    </Button>
    <div class="flex gap-2 items-center flex-grow">
      <Badge type="subtle">
        <Icon src="FlowArrow" size="small" />
        {{ filtersCount }}
      </Badge>
      <Divider :spacing="false" rotation="vertical" class="self-stretch"></Divider>

      <Badge type="grape" v-for="value in values">
        {{ getPrettyValue(value) }}
      </Badge>

      <div class="text-quarterary text-body-default-heavy">
        {{ price }}
      </div>
    </div>

    <DropdownActions trigger-icon-prefix="bold/DotsThreeVertical" :actions="[
      { 
        label: t('delete'), 
        destructive: true,
        handler: () => emit('remove')
      }
    ]" />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    rule: IRule;
    expanded: boolean;
  }>(),
  {}
);

const description = inject("description");
const { t } = useI18n();
const emit = defineEmits(["toggle", "remove"]);

const filters = computed(() => Object.entries(props.rule.filters));
const filtersCount = computed(() => filters.value?.length);

const values = computed(() => {
  const values = Object.entries(props.rule.filters)
    .map(([key, filter]) => {
      return {
        key,
        value: filter[0].value,
        operator: filter[0].operator,
      };
    })
    .flat()
    .filter((filter) => filter.value && filter.value?.length);

  //sort values by key so that receiver_country is always first, and then everything else
  return values.sort((a, b) => {
    if (a.key === "receiver_country") {
      return -1;
    }
    if (b.key === "receiver_country") {
      return 1;
    }
    return 0;
  });
});

const getPrettyValue = (filter: IRuleFilter) => {
  const options = description.value[filter.key].options || [];

  const getOption = (value: string) => {
    return options[value] || value;
  };

  if (filter.operator === "between" || filter.operator === "notBetween") {
    return `${getOption(filter.value[0])} - ${getOption(filter.value[1])}`;
  }

  if (filter.operator === "in" || filter.operator === "notIn") {
    if (filter.value.length === 0) return null;
    //return the first value and the count of the rest
    let text = `${getOption(filter.value[0])}`;
    if (filter.value.length > 1) return `${text} +${filter.value.length - 1}`;

    return text;
  }

  return getOption(filter.value);
};

const price = computed(() => {
  return formatPrice(props.rule.price || 0, "force", "");
});
</script>
