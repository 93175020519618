<template>
    <form v-if="validationSchema" @submit.prevent="onSubmit">
        <slot name="hidden"></slot>
        <div class="w-full py-4 px-4 flex">
            <Button type="button" iconOnly class="mr-2" variant="tertiary" @click="exit">
                <Icon src="close" size="medium" class="cursor-pointer text-secondary" />
            </Button>
            <p class=" text-quarterary text-title-body mt-1.5">{{ name }}</p>
            <Divider class="mx-2" rotation="vertical"></Divider>
            <p class=" text-secondary  text-title-body mt-1.5">{{ stepNames[currentStepIdx] }}</p>
        </div>
        <slot>
        </slot>
        <div class="p-4 flex">
            <Button v-if="hasPrevious" type="button" class="w-full max-w-max" @click="goToPrev" variant="tertiary" size="default">{{ t("previous") }}</Button>
            <Button type="button" class="w-full ml-auto max-w-max mr-3" variant="default" size="default" @click="skip" v-if="hasSkip">{{ t("skip_this_step") }}</Button>
            <Button type="submit" class="w-full max-w-max" :class="{'ml-auto' : !hasSkip}" :disabled="!canSubmit || hasSkip" :variant="isLastStep && lastStepPositive ? 'positive' : 'primary'" size="default" :loading="loading" v-if="hasContinueButton">
                {{ continueButtonText }}
            </Button>
        </div>
    </form>
</template>

<script setup lang="ts">

const { t } = useI18n();

const props = withDefaults(
    defineProps<{
        validationSchema?: any[];
        loading?: boolean;
        submitButtonText?: string;
        lastStepPositive?: boolean;
        showProgress?: boolean;
        splitFooter?: boolean;
        customFooter?: boolean;
        name?: string;
        stepNames?: string[];
    }>(),
    {
        loading: false,
        submitButtonText: "",
        lastStepPositive: false,
        showProgress: false,
        splitFooter: false,
        name: "",
        customFooter: false,
        stepNames: [],

    }
);

const emit = defineEmits(["submit", "exit", "skip"]);
const {
    currentStepIdx,
    isLastStep,
    hasPrevious,
    hasSkip,
    stepKey,
    hasContinueButton,
    continueButtonText,
    schemaFields,
    goToPrev,
    form,
    canSubmit,
    skip,
    exit,
    onSubmit,
    goToLastStep,
    goToStepByKeyName,
    resetForm,
    setErrors
} = useCreateShipment({
    submitButtonText: props.submitButtonText,
    validationSchema: props.validationSchema,
    emit,
});

const { values, setValues, setFieldValue } = form;

defineExpose({
    values,
    currentStepIdx,
    isLastStep,
    hasPrevious,
    canSubmit,
    stepKey,
    setValues,
    setFieldValue,
    goToLastStep,
    goToStepByKeyName,
    resetForm,
    setErrors
});
</script>