<template>
    <div class="min-w-[250px] flex flex-col gap-2">
        <div class="w-full h-11" v-for="service, key in props.services">
            <Button type="button" class="w-full !h-11" variant="tertiary" textLeft :active="selectedService == key" @click="selectSetvice(service, key)">
                <p>{{ service.label }}</p>
                <Icon class="ml-auto text-tertiary transition-opacity duration-300" :class="isSelected(service) ? 'opacity-100' : 'opacity-0'" src="check" size="medium"></Icon>
            </Button>
        </div>
    </div>
</template>

<script setup lang="ts">

    const props = defineProps<{
        services: any;
        selectedServices: Array<string>;
    }>();

    const emit = defineEmits(['valueChange']);

    const setService = ref(null);

    const selectedService = computed(() => {
        return setService.value ? setService.value : Object.keys(props.services)[0]
    });

    const isSelected = (service) => {
        return props.selectedServices.includes(returnKey(service));
    }

    const returnKey = (service: Object) => {
        return service.carrier_key == 'service_codes' ? service.fields.key : service.carrier_key
    }

    const selectSetvice = (service, key) => {
        setService.value = key;
        emit('valueChange', service);
    }

</script>