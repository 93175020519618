<template>
    <Modal v-model:open="open" maxWidth="660px" :center="true" anchor="top" ref="modal">
        <FormWizard :validation-schema="validationSchema" @submit="onSubmit" :submitButtonText="t('send_label_to_customer')" ref="wizard" splitFooter @exit="open = false" class="max-w-[660px] w-screen">
            <FormStep>
                <div class="p-3.5">
                    <h2 class="text-body-lg-heavy mb-4 text-secondary">{{ t("newreturn.title") }}</h2>
                    <p class=" text-body-default text-secondary w-full mb-5">{{ t("newreturn.description") }}</p>
                    <div v-if="shipment?.order_lines && shipment?.order_lines.length !== 0" class="mb-5">
                        <p class=" text-body-sm-heavy text-secondary mb-2">{{ t('select') }} {{ t('product', 99).toLowerCase() }}</p>
                        <ClientOnly>
                            <Table
                                :headers="headers"
                                :items="shipment.order_lines"
                                checkbox
                                :selected="selected"
                                :selectAll="selectAll"
                                @select:all="toggleAllOrderLinesSelection"
                                @update:selected="toggleOrderLineSelection"
                                row-identifier="item_number"
                            />
                        </ClientOnly>
                    </div>
                    <p class=" text-body-sm-heavy text-secondary mb-2">{{ t('select') }} {{ t('carrier').toLowerCase() }}</p>
                    <Overlay v-if="loading && products.length == 0" class="inset-x-0 sticky min-h-[300px] w-full"></Overlay>
                    <ListSelector name="carrier" :values="products" valueKey="carrier_product_service">
                      <template #avatar="{ value }">
                        <Avatar :entity="{ image: `https://assets.homerunner.com/carrier_icons/${value.carrier}.png` }" size="xs" class="transition duration-300 mr-2"></Avatar>
                      </template>
                      <template #title="{ value }">
                        <p class=" text-body-lg-heavy text-secondary mt-2">{{ value.title }}</p>
                      </template>
                      <template #value="{ value }">
                        <p class="ml-auto text-body-default text-tertiary mt-2.5">{{ value.sales_price_excl_vat }} {{ value.currency }}</p>
                      </template>
                    </ListSelector>
                </div>
            </FormStep>
        </FormWizard>
        <Overlay v-if="loading && products.length !== 0" class="inset-x-0 min-h-[300px] h-full w-full absolute top-0 left-0"></Overlay>
    </Modal>
</template>

<script setup lang="ts">
const { t } = useI18n();
const wizard = ref(null);
const yup = useYup();

import { open } from "../service";
import EventBus from "@/plugins/eventbus";

const shipment = ref(null);

const products = ref([]);

const loading = ref(false);

const selected_order_lines = ref([]);

const getProducts = () => {
    const queryString = `sender_country=${shipment.value.receiver.country}&sender_street1=${shipment.value.receiver.street1}&sender_zip_code=${shipment.value.receiver.zip_code}&sender_city=${shipment.value.receiver.city}&receiver_street1=${shipment.value.sender.street1}&receiver_country=${shipment.value.receiver.country}&receiver_zip_code=${shipment.value.receiver.zip_code}&receiver_city=${shipment.value.receiver.city}&weight=${shipment.value.weight}&height=${shipment.value.height}&width=${shipment.value.width}&length=${shipment.value.length}&q=return`
    const {data} = useSsrApi(`products?${queryString}`, {
        key: "select:products",
        transform: ({ data }) => {
            if (!data) return;
            products.value = data
            loading.value = false;
            return {
                data: data,
            };
        },
        default: () => [],
    });
    return data
}

const { selectAll, selected, toggleRowSelection, toggleAllRowsSelection} = useTableSelection({ identifier: "id", rows: shipment.order_lines });

const headers = [
  {
    key: "item_number",
    label: t('item_number'),
  },
  {
    key: "item_description",
    label: t('item_description'),
    valueFunc: ({ customs }: { customs: Array<[]> }) => {customs.description ?? customs[0].description},
  },

  {
    key: "item_origin",
    label: t('origin_country'),
    valueFunc: ({ customs }: { customs: Array<[]>  }) => getCountryName(customs.origin_country ?? customs[0].origin_country),
  },

  {
    key: "item_price",
    label: t('price'),
    valueFunc: ({ customs }: { customs: Array<[]>  }) => `${customs.total_price ?? customs[0].total_price} ${customs.currency_code ?? customs[0].currency_code}`,
  }
];

const validationSchema = [
    yup.object({
        items: yup.array(),
        carrier: yup.string().required(),
    }),
];

const toggleOrderLineSelection = (row) => {
  if (selected_order_lines.value.includes(row)) {
    let rowIndex = selected_order_lines.value.findIndex((element) => element.item_number == row.item_number)
    selected_order_lines.value.splice(rowIndex, 1);
  } else {
    selected_order_lines.value.push(row);
  }
};

const toggleAllOrderLinesSelection = () => {
  if (selected_order_lines.value.length !== 0) {
    selected_order_lines.value = [];
    toggleAllRowsSelection();
  } else {
    selected_order_lines.value = shipment.value.order_lines;
    toggleAllRowsSelection();
  }
};

watch(() => shipment.value, (val) => {
  if (val) {
    loading.value = true;
    getProducts();
  }
}, { immediate: true });

function onSubmit(formData) {
  let cpsSplit = formData.carrier.split("_");
  let data = {
    carrier: cpsSplit[0],
    product: cpsSplit[1],
    service: cpsSplit[2],
    order_lines: selected_order_lines.value,
  }
  $fetch(`api/returns/${shipment.value.id}`, {
    method: "POST",
    body: data,
  }).then((response) => {
    open.value = false;
    $toast.add({
        group: "shipment_created",
        title: t("shipment_created"),
      });
  }).catch((error) => {
  });
}

onMounted(() => {
  EventBus.$on("NEWRETURN_TOGGLE", () => {
    open.value = !open.value;
  });
  EventBus.$on("NEWRETURN_ADD", (val) => {
    shipment.value = val;
  });
  EventBus.$on("NEWRETURN_OPEN", () => {
    open.value = true;
  });
  EventBus.$on("NEWRETURN_CLOSE", () => {
    open.value = false;
  });
});

onUnmounted(() => {
  EventBus.$off("NEWRETURN_TOGGLE");
  EventBus.$off("NEWRETURN_OPEN");
  EventBus.$off("NEWRETURN_CLOSE");
});

</script>

<style>
.input_title {
    @apply text-left text-body-sm-heavy mb-1 mt-4
}
</style>