<template>
  <Avatar random-color  :max-letters="1" :entity="{
    name: account.account.name,
    image: account.account.image,
  }" size="2xs" />
   </template>

<script setup lang="ts">
import type { IAccount } from "~/types/auth";
const { getAccount } = useAuth();

const account = getAccount()!;

</script>
