<template>
    <Transition name="fade">
    <div v-if="open" @click="emit('close')" class="absolute top-0 left-0 z-50 bg-loader-overlay w-full h-dvh p-1 flex overflow-hidden ">
        <div v-on:click.stop ref="modalContent" class="flex slide-in flex-col w-full relative max-w-[450px] ml-auto bg-surface-lvl-00 rounded-xl border-2 border-00 overflow-hidden">
            <div class=" p-5 pb-3 flex">
                <p class=" text-title-subsection text-secondary">{{ title }}</p>
                <Button type="button" class="ml-auto" iconOnly variant="tertiary" @click="emit('close')">
                    <Icon src="close" size="medium" class="cursor-pointer" />
                </Button>
            </div>
            <slot />
            <div v-if="showFooter" class="w-full surface-lvl-00">
                <div class=" bg-surface-lvl-00 w-full p-5 pt-3">
                <Button :disabled="!submitReady" type="button" class="w-full" variant="primary" @click="emit('submit')">{{ buttonText }}</Button>
                </div>
            </div>
        </div>
    </div>
    </Transition>
</template>

<script setup lang="ts">

    const t = useI18n();

    const props = defineProps({
        open: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        showFooter: {
            type: Boolean,
            default: true
        },
        buttonText: {
            type: String,
            default: ''
        },
        submitReady: {
            type: Boolean,
            default: false
        }
    });
    const emit = defineEmits(["close", "submit"]);
</script>