<template>
    <DropdownSelect v-bind="$attrs" v-model="inputValue" :options="countries" :triggerText="t('select_currency')"
        :error="error" filterable />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

const { t } = useI18n();

const props = withDefaults(
    defineProps<{
        name?: string;
        error?: string;
        selectDefault?: boolean;
    }>(),
    {
        name: "",
        selectDefault: false,
    }
);

const modelValue = defineModel({ default: "" });

onMounted(() => {
    if (props.selectDefault) {
        inputValue.value = "DKK";
    }
});


const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
});

const error = computed(() => {
    return props.error || errorMessage.value;
});

const input = ref<HTMLElement | null>(null);

const el = computed(() => {
    return input.value?.input;
});

const emit = defineEmits(["click"]);

const countries = [
    {
        label: "DKK", value: "DKK", image: null
    },
    {
        label: "EUR", value: "EUR", image: null
    },
    {
        label: "GBP", value: "GBP", image: null
    },
    {
        label: "NOK", value: "NOK", image: null
    },
    {
        label: "SEK", value: "SEK", image: null
    },
    {
        label: "USD", value: "USD", image: null
    }
]

defineExpose({
    el,
});
</script>