import type { IShipment, Receiver, Servicepoint } from "~/types/shipment";

export const servicePointAddressString = (servicePoint: Servicepoint | null) => {
  if (!servicePoint) return "";

  const { name: servicePointName, street: servicePointStreet, zip_code: servicePointZip, city: servicePointCity } = servicePoint;
  return `${servicePointName} - ${servicePointStreet}, ${servicePointZip} ${servicePointCity}`;
};

export const receiverAddressString = (receiver: Receiver | null) => {
  if (!receiver) return "";

  const { street1, zip_code, city } = receiver;
  return `${street1}, ${zip_code} ${city}`;
};

export const isDroppoint = (cps: string) => {
  return cps.includes("droppoint");
};

export const getCarrierLogoUrl = (carrier: string): string => {
  return `https://assets.homerunner.com/carrier_icons/${carrier}.png`;
};

export const buildCps = (shipment: IShipment) => {
  const { carrier_product, carrier, carrier_service } = shipment;
  return `${carrier}_${carrier_product}_${carrier_service}`;
};

export const isReturn = (shipment: IShipment) => {
  return shipment.carrier_product_service?.includes("return");
};

export const hasTickets = (shipment: IShipment) => {
  return shipment.has_ticket || shipment.tickets?.length > 0 || shipment.ticket_ids?.length > 0;
};

export const getFirstTicketId = (shipment: IShipment) => {
  if (shipment.tickets?.length > 0) return shipment.tickets[0].id;
  if (shipment.ticket_ids?.length > 0) return shipment.ticket_ids[0];

  return null;
};
