<template>
  <Modal :clickToClose="false" v-model:open="open" maxWidth="1020px" :contentPadding="false" :center="true" anchor="top" ref="modal">
    <div class="relative w-full">
      <FormShipment
        v-show="!shipmentCreated"
        :validation-schema="validationSchema"
        lastStepPositive
        :continueButtonText="t('continue')"
        :submitButtonText="t('create_shipment')"
        @submit="(val) => submitForm(val)"
        ref="wizard"
        :stepNames="stepNames"
        @exit="close()"
        :name="mode == 'return' ? t('new_return_shipment') : t('new_shipment')"
        class="max-w-[1020px] w-[90vw]"
        headerType="create"
      >
        <FormStep>
          <ReceiverSender
            :mode="mode == 'return' ? 'sender' : 'receiver'"
            :values="values"
            @updateCity="(val) => setReceiverSenderCity(val)"
            @emitContact="(val) => setReceiverSender(val)"
            @updateAddress="(val) => setReceiverSender(val, true)"
          ></ReceiverSender>
        </FormStep>
        <FormStep>
          <div class="w-full overflow-hidden">
            <Dimensions :sizes="data.sizes"></Dimensions>
          </div>
        </FormStep>
        <FormStep>
          <Carrier
            :values="values"
            @skipInsurance="(val) => skipInsurance(val)"
            @goback="changeStep(1)"
            @addExtraServices="(val) => addExtraServices(val)"
          ></Carrier>
        </FormStep>
        <FormStep>
          <ReturnProducts :values="values"></ReturnProducts>
        </FormStep>
        <FormStep>
          <ExtraServices
            @updateValidation="(val) => updateValidation(val)"
            :services="services"
            :values="values"
            @dateUpdate="(val) => setNewServiceDate(val)"
            @updateSelectValue="(val) => setNewSelectValue(val)"
          ></ExtraServices>
        </FormStep>
        <FormStep>
          <OrderLines></OrderLines>
        </FormStep>

        <FormStep>
          <Insurance :values="values"></Insurance>
        </FormStep>
        <FormStep>
          <Confirm @changeStep="(val) => changeStep(val)" :values="values" :services="services" @updatePrice="(val) => (billingInfo = val)"></Confirm>
        </FormStep>
      </FormShipment>
      <Overlay v-if="loading" class="inset-x-0 h-full w-full absolute top-0 left-0"></Overlay>
      <div class="w-[1020px] h-[70vh] flex relative" v-if="shipmentCreated">
        <div class="absolute top-4 left-4 w-0 max-w-0 overflow-visible">
          <Button type="button" iconOnly class="" variant="tertiary" @click="close()">
            <Icon src="close" size="medium" class="cursor-pointer text-secondary" />
          </Button>
        </div>
        <div class="my-auto mx-auto max-w-[540px] transform -translate-x-1">
          <div class="relative overflow-hidden rounded-full mx-auto h-[100px] w-[200px] bg-utility-kiwi mb-4">
            <svg width="200" height="101" viewBox="0 0 200 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M-136.967 38.1815C-149.752 45.5628 -154.132 61.9106 -146.751 74.6953C-139.37 87.4801 -123.022 91.8605 -110.237 84.4792L32.553 2.03922C45.3378 -5.34206 49.7182 -21.6899 42.3369 -34.4746C34.9556 -47.2594 18.6078 -51.6398 5.82302 -44.2585L-136.967 38.1815Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
              <path
                d="M-162.116 132.028C-174.9 139.409 -179.281 155.757 -171.9 168.542C-164.518 181.326 -148.17 185.707 -135.386 178.325L7.40457 95.8854C20.1893 88.5041 24.5697 72.1563 17.1884 59.3715C9.80715 46.5868 -6.54066 42.2064 -19.3254 49.5877L-162.116 132.028Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
              <path
                d="M30.3861 51.7514C37.7674 64.5362 54.1152 68.9166 66.8999 61.5353C79.6847 54.154 84.0651 37.8062 76.6838 25.0214C69.3025 12.2367 52.9547 7.85629 40.1699 15.2376C27.3852 22.6189 23.0048 38.9667 30.3861 51.7514Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
              <path
                d="M99.6656 -19.1125C86.8808 -11.7312 82.5004 4.61665 89.8817 17.4014C97.263 30.1862 113.611 34.5666 126.396 27.1853L269.186 -55.2547C281.971 -62.636 286.351 -78.9838 278.97 -91.7686C271.588 -104.553 255.241 -108.934 242.456 -101.552L99.6656 -19.1125Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
              <path
                d="M62.6226 81.6034C49.8378 88.9846 45.4575 105.332 52.8387 118.117C60.22 130.902 76.5678 135.282 89.3526 127.901L232.143 45.4611C244.928 38.0798 249.308 21.732 241.927 8.94723C234.545 -3.83752 218.198 -8.21791 205.413 -0.836628L62.6226 81.6034Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
              <path
                d="M168.369 99.8797C155.584 107.261 151.204 123.609 158.585 136.394C165.966 149.178 182.314 153.559 195.099 146.177L266.494 104.957C279.279 97.5762 283.659 81.2284 276.278 68.4436C268.896 55.6588 252.549 51.2785 239.764 58.6597L168.369 99.8797Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
            </svg>
            <Icon class="absolute top-[35.5px] left-[84px] text-utility-kiwi-darkest" src="check" size="4xl"></Icon>
          </div>
          <p class="w-full text-center text-title-section text-secondary mb-2">{{ t("shipment_is_created") }}</p>
          <div class="flex gap-2 items-center justify-center mb-2">
            <p class="text-quarterary text-body-default">{{ t("package_number") }}</p>
            <Button variant="tertiary" type="button" size="small" @click="copyToClipboard(packageNumber || '')">
              {{ packageNumber }}
              <Icon src="copy" size="medium" class="cursor-pointer"></Icon>
            </Button>
          </div>
          <p class="text-body-lg text-tertiary mb-4">{{ t("shipment_is_created_desc") }}</p>
          <div class="flex w-full border border-00 rounded-xl px-4 py-0.5 gap-2">
            <Icon class="text-secondary mt-[5px]" src="file_text" size="medium"></Icon>
            <p class="text-secondary text-body-default-heavy mt-1">Label.pdf</p>
            <div class="ml-auto">
              <a :href="returnLink()" target="_blank" rel="noopener noreferrer">
                <Button type="button" variant="tertiary" size="small" class="!inline-block">{{ t("download") }}</Button>
              </a>
              <Button @click="openPrinterModal" type="button" variant="tertiary" size="small" class="!inline-block">{{ t("print_entity") }}</Button>
            </div>
          </div>
          <Button @click="close()" type="button" variant="default" class="w-full mt-4">{{ t("close") }}</Button>
          <Button @click="navigateToShipments()" type="button" variant="secondary" class="w-full mt-2">{{
            t("go_to_entity", { entity: t("shipment").toLowerCase() })
          }}</Button>
          <Button @click="createNewShipment()" type="button" variant="tertiary" class="w-full mt-2">{{ t("create_another_shipment") }}</Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { open, wizard, loading, mode, return_shipment, getPhonePrefixFromObject } from "../service";
const { t } = useI18n();
const yup = useYup();
const router = useRouter();
import { DateTime } from "luxon";
import EventBus from "@/plugins/eventbus";

import ReceiverSender from "./steps/receiver_sender.vue";
import Dimensions from "./steps/dimensions.vue";
import Carrier from "./steps/carrier.vue";
import Insurance from "./steps/insurance.vue";
import Confirm from "./steps/confirm.vue";
import ExtraServices from "./steps/extraServices.vue";
import OrderLines from "./steps/orderLines.vue";
import ReturnProducts from "./steps/returnProducts.vue";

const stepNames = computed(() => {
  let names = [];

  if (mode.value === "return") {
    names.push(t("set_sender"));
  } else {
    names.push(t("set_receiver"));
  }

  return [
    ...names,
    t("specify_dimensions"),
    t("select_carrier_product"),
    t("select_products"),
    t("select_extra_services"),
    t("describe_content"),
    t("add_insurance"),
    t("summary"),
  ];
});

const values = computed(() => wizard.value?.values);

const isLastStep = computed(() => wizard.value?.isLastStep);

const orderId = ref(null);

const billingInfo = ref(null);

const packageNumber = ref(null);

const packageId = ref(null);

const services = ref([]);

const shipmentCreated = ref(false);

const close = () => {
  return_shipment.value = null;
  open.value = false;
};

const { data } = await useSsrApi("dimensions", {
  key: "select:dimensions",
  lazy: true,
  transform: ({ data }) => {
    if (!data) return;
    return {
      sizes: data,
      fetchedAt: new Date(),
    };
  },
  getCachedData: (key: string) => getCachedData(key, 60 * 10),
  default: () => [],
});

const changeStep = (val) => {
  wizard.value.currentStepIdx = val;
};

const editDuplicate = () => {
  changeStep(0);
  validationSchema.value[validationSchema.value.length - 1].spec.meta.onPrev = null;
};

const createNewShipment = () => {
  shipmentCreated.value = false;
};

const skipInsurance = (val: boolean) => {
  const insuranceStepIdx = validationSchema.value.findIndex((step) => step.spec.meta.key === "insurance");

  if (val) {
    validationSchema.value[insuranceStepIdx].spec.meta.autoskip = true;
  } else {
    validationSchema.value[insuranceStepIdx].spec.meta.autoskip = true;
  }
};

const validationSchema = ref<any[]>([
  yup
    .object({
      height: yup.number().required(),
      length: yup.number().required(),
      width: yup.number(),
      weight: yup.string().required(),
    })
    .meta({ continueButtonText: t("continue") }),
  yup
    .object({
      carrier_product_service: yup.string().required(),
      cpsUid: yup.string().required(),
      carrier_product_title: yup.string().required(),
      carrier_product_price: yup.string().required(),
      droppoint: yup.string().when("carrier_product_service", {
        is: (val) => val && val.includes("droppoint"),
        then: (schema) => schema.required(),
      }),
    })
    .meta({ continueButtonText: t("continue"), key: "carrier_product_service" }),
  yup
    .object({
      set_order_lines: yup.array(),
    })
    .meta({ continueButtonText: t("continue"), autoskip: true, key: "return_products" }),
  yup
    .object({
      services: yup.object({}),
    })
    .meta({ continueButtonText: t("continue"), skip: true, autoskip: false, key: "services" }),
  yup
    .object({
      order_lines: yup.array(),
      /*
      order_lines: yup.array().of(yup.object({
        qty: yup.number().required(),
        item_number: yup.string().required(),
        dangerous_goods_set: yup.boolean(),
        customs: yup.array().of(yup.object({
          description: yup.string(),
          total_price: yup.number(),
          currency_code: yup.string(),
          sender_tariff: yup.string(),
          origin_country: yup.string(),
          receiver_tariff: yup.string(),
          weight: yup.number(),
          unit: yup.string(),
        })).min(1),
      })).min(1).required(),*/
    })
    .meta({ continueButtonText: t("continue"), autoskip: true }),
  yup
    .object({
      insurance: yup.string(),
      insurance_coverage: yup.string(),
      insurance_price: yup.string(),
    })
    .meta({ continueButtonText: t("continue"), autoskip: true, key: "insurance" }),
  yup
    .object({
      accept_terms: yup.boolean().required().isTrue(),
      reference: yup.string().nullable(),
    })
    .meta({ onPrev: mode.value === "duplicate" ? editDuplicate : null }),
]);

const { injectValidationFields } = useValidation();

const updateValidation = (val: object) => {
  const servicesStepIdx = validationSchema.value.findIndex((step) => step.spec.meta.key === "services");
  injectValidationFields(val.fields, validationSchema.value, servicesStepIdx, "services");

  const hasNestedObjects = () => {
    const serviceFields = validationSchema.value[servicesStepIdx].fields.services.fields;
    for (const key in serviceFields) {
      if (typeof serviceFields[key] === "object" && Object.keys(serviceFields[key].fields).length > 0) {
        return true;
      }
    }
    return false;
  };

  if (hasNestedObjects()) {
    validationSchema.value[servicesStepIdx].spec.meta.skip = false;
  } else {
    validationSchema.value[servicesStepIdx].spec.meta.skip = true;
  }
};

const formatExtraservices = (values: object) => {
  let extraservices = {};
  extraservices.service_codes = [];
  Object.entries(values).forEach(([key, value]) => {
    if (!value.isSet) {
      return;
    }
    if (/^([A-Z0-9]{3})$/.test(key)) {
      extraservices.service_codes.push(key);
      return;
    }
    if (value.array) {
      extraservices[key] = value.array;
      return;
    } else {
      extraservices[key] = value;
      return;
    }
  });
  return extraservices;
};

onBeforeMount(() => {
  validationSchema.value[3].spec.meta.autoskip = true;
  if (mode.value === "return") {
    validationSchema.value.unshift(
      yup
        .object({
          save_sender: yup.boolean().nullable(),
          sender: yup.object().shape({
            name: yup.string().required().label(t("name")),
            email: yup.string().email().required().label(t("email")),
            phone: yup.string().required().label(t("phone")),
            phone_prefix: yup.number().required().label(t("phone_prefix")).default(45),
            street1: yup.string().required().label(t("street1")),
            street2: yup.string().nullable().label(t("street2")),
            zip_code: yup.string().required().label(t("zip_code")),
            city: yup.string().required().label(t("city")),
            country: yup.string().required().label(t("country")).default("DK"),
            business: yup.boolean().required().default(useAuth().isDFM.value),
            attention: yup.string().nullable().label(t("attention_name")),
          }),
        })
        .meta({ continueButtonText: t("continue") })
    );
  } else {
    validationSchema.value.unshift(
      yup
        .object({
          save_receiver: yup.boolean().nullable(),
          receiver: yup.object().shape({
            name: yup.string().required().label(t("name")),
            email: yup.string().email().required().label(t("email")),
            phone: yup.string().required().label(t("phone")),
            phone_prefix: yup.number().required().label(t("phone_prefix")).default(45),
            street1: yup.string().required().label(t("street1")),
            street2: yup.string().nullable().label(t("street2")),
            zip_code: yup.string().required().label(t("zip_code")),
            city: yup.string().required().label(t("city")),
            country: yup.string().required().label(t("country")).default("DK"),
            business: yup.boolean().required().default(useAuth().isDFM.value),
            company_name: yup.string().nullable().label(t("company_name")),
          }),
        })
        .meta({ continueButtonText: t("continue") })
    );
  }
  if (return_shipment.value?.order_lines && return_shipment.value?.order_lines.length > 0) {
    wizard.value?.setValues({
      order_lines: return_shipment.value.order_lines,
    });
    validationSchema.value[3].spec.meta.autoskip = false;
  } else {
    validationSchema.value[3].spec.meta.autoskip = true;
  }
});

const submitForm = async (formData) => {
  let pickup_datetime = null;
  if (formData.pickup_date) {
    let pickup_time = new Date(formData.pickup_time);
    let pickup_date = new Date(new Date(formData.pickup_date).setHours(pickup_time.getHours(), pickup_time.getMinutes()));
    pickup_datetime = DateTime.fromJSDate(pickup_date).toFormat("yyyy-MM-dd HH:mm:ss");
  }
  let services = await formatExtraservices(formData.services);
  loading.value = true;
  let cps = formData.carrier_product_service.split("_");
  const [carrier, carrier_product, ...carrier_service] = cps;
  let reference = formData.reference;

  if (mode.value == "return") {
    if (reference && reference.length > 0) {
      reference += " - ";
    }
    reference += "Return";
  }

  let data = {
    shipment: {
      sender: {
        ...formData.sender,
        country: formData.sender.country.toUpperCase(),
        type: formData.sender.business ? "business" : "private",
        name: formData.sender.name,
        attention: formData.sender.attention,
      },
      receiver: {
        ...formData.receiver,
        country: formData.receiver.country.toUpperCase(),
        type: formData.receiver.business ? "business" : "private",
        name: formData.receiver.name,
        attention: formData.receiver.attention,
      },
      height: formData.height,
      length: formData.length,
      width: formData.width,
      weight: formData.weight,
      carrier,
      carrier_product,
      carrier_service: carrier_service.join("_"),
      servicepoint_id: formData.droppoint,
      insurance: formData.insurance,
      reference: reference,
      order_lines: formData.set_order_lines || formData.order_lines || [],
      meta: {
        billing_info: billingInfo.value,
      },
      services: services,
      pickup_date: pickup_datetime,
      comment: formData.comment,
    },
    final_url: window.location.origin + "/redirects/ordersuccess",
  };
  $fetch("/api/shipments", {
    method: "POST",
    body: data,
  })
    .then((response) => {
      orderId.value = response.data?.order?.id ?? null;
      if (formData.save_receiver) {
        saveReceiver(formData.receiver);
      } else if (formData.save_sender) {
        saveReceiver(formData.sender);
      }
      if (response.data.processed) {
        loading.value = false;
        shipmentCreated.value = true;
        packageNumber.value = response.data.shipment.package_number;
        packageId.value = response.data.shipment.id;
        EventBus.$emit("NEWSHIPMENT_CREATED");
        wizard.value.currentStepIdx = 0;
        return_shipment.value = null;

        $toast.add({
          group: "shipment_created",
          title: t("shipment_created_with_id", { id: response.data.shipment.package_number }),
        });
      } else {
        handlePayment(response.data);
      }
    })
    .catch((error) => {
      $toast.add({
        group: "shipment_created",
        title: t("error"),
        text: error.data.data.data.message,
      });
      loading.value = false;
    });
};

const saveReceiver = (receiver) => {
  $fetch("/api/contacts", {
    method: "POST",
    body: {
      company_name: receiver.business ? receiver.name : null,
      name: receiver.business ? receiver.attention : receiver.name,
      email: receiver.email,
      phone: receiver.phone,
      phone_prefix: receiver.phone_prefix,
      street1: receiver.street1,
      street2: receiver.street2,
      zip_code: receiver.zip_code,
      city: receiver.city,
      country: receiver.country,
    },
  });
};

const setNewSelectValue = (object: object) => {
  wizard.value?.setValues({
    services: {
      [object.service.carrier_key]: {
        [object.field.key]: object.val,
      },
    },
  });
};
const setNewServiceDate = (object: object) => {
  wizard.value?.setValues({
    services: {
      [object.service.carrier_key]: {
        [object.extraField.key]: DateTime.fromJSDate(object.val).toFormat("yyyy-MM-dd T"),
      },
    },
  });
};

const addExtraServices = (extraServices: any) => {
  wizard.value?.setFieldValue("services", {});
  if (extraServices.length !== 0) {
    services.value = extraServices;
    validationSchema.value[4].spec.meta.autoskip = false;
  } else {
    validationSchema.value[4].spec.meta.autoskip = true;
  }
};

const handlePayment = (data: object) => {
  if (data.type !== "invoice" && data.type !== "balance") {
    let payment_window = window.open(data.payment_url);
    window.addEventListener("message", eventHandler);
  } else {
    processOrder(orderId.value);
  }
};

const eventHandler = (event) => {
  if (event.data === "payment:success") {
    processOrder(orderId.value);
    window.removeEventListener("message", eventHandler);
  }
};

const processOrder = (orderId) => {
  $fetch(`/api/shipments/${orderId}/process`, {
    method: "POST",
  })
    .then((response) => {
      loading.value = false;
      shipmentCreated.value = true;
      packageNumber.value = response.data.shipment.package_number;
      packageId.value = response.data.shipment.id;
      EventBus.$emit("NEWSHIPMENT_CREATED");
      wizard.value.currentStepIdx = 0;
      return_shipment.value = null;

      $toast.add({
        group: "shipment_created",
        title: t("shipment_created_with_id", { id: response.data.shipment.package_number }),
      });
    })
    .catch((error) => {
      $toast.add({
        group: "shipment_created",
        title: t("error"),
        text: error.data.data.data.message,
      });
      loading.value = false;
    });
};

function openPrinterModal() {
  $printing.add(packageNumber.value, null, null, null);
  $printing.open();
}

function setReceiverSenderCity(city: string) {
  wizard.value?.setFieldValue(`${[mode.value == "return" ? "sender" : "receiver"]}.city`, city);
}

function setReceiverSender(contact: object, reverse = false) {
  let key = mode.value == "return" ? "sender" : "receiver";
  if (reverse) {
    key = mode.value == "return" ? "receiver" : "sender";
  }
  wizard.value?.setValues({
    [key]: {
      name: contact.data.company_name ? contact.data.company_name : contact.data.name,
      attention: contact.data.company_name ? contact.data.name : null,
      email: contact.data.email,
      phone_prefix: contact.data.phone_prefix,
      phone: contact.data.phone,
      street1: contact.data.street1,
      street2: contact.data.street2,
      zip_code: contact.data.zip_code,
      city: contact.data.city,
      country: contact.data.country_code,
      business: contact.data.company_name ? true : false,
    },
  });
}
const returnLink = () => {
  return useRouter().resolve({ name: "shipments-id-label", params: { id: packageNumber.value } })?.href;
};
const navigateToShipments = () => {
  const link = useRouter().resolve({ name: "shipments-id", params: { id: packageId.value } })?.href;
  window.open(link, "_blank");
};
</script>

<style>
.input_title {
  @apply text-left text-body-sm-heavy mb-1 mt-4;
}
</style>
