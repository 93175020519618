import type {
  IAccount,
  IAuthResponse,
  ICapabilitiesResponse,
  ICredentials,
  IUser,
} from "@/types/auth";

export default () => {
  /**
   * Logs a user into the CoolRunner API using the provided credentials.
   * @param {ICredentials} credentials - An object containing a 'username' and 'password' property, which will be sent to the API for authentication.
   */
  const login = async (
    credentials: ICredentials,
    silent: boolean
  ): Promise<any> => {
    // A POST request is sent to the CoolRunner API's '/api/auth/login' endpoint with the provided credentials.
    // The response received from the server contains an access token and its expiration time.
    // This data is then used to set a new cookie called 'hr_token'.
    return $fetch("/api/auth/login", {
      method: "POST",
      body: credentials,
    }).then((response: IAuthResponse) => {
      setAuthCookie(response.access_token, response.expires_in);
    });
  };

  /**
   * Logs the user out by sending a POST request to the server and removing the 'hr_token' cookie.
   * @returns {Promise<any>} A Promise that resolves when the user is successfully logged out, or rejects with an error if there was a problem logging out.
   */
  const logout = (redirect = true): Promise<any> => {
    const { $i18n } = useNuxtApp();

    if (process.client) $loader.open($i18n.t("logging_out"));

    // Send a POST request to the server to log the user out
    return $fetch("/api/auth/logout")
      .catch((error) => {
        console.error("Error logging out", error);
      })
      .finally(() => {
        clearCookies();

        if (!redirect) return;

        if (process.client) $loader.close();

        // If the user is not already on the login page, redirect them to the login page
        useRouter().replace({ name: "auth-login" })
      });
  };

  const resetPassword = async (
    token: string,
    body: {
      password: string;
      password_confirmation: string;
      name?: string;
      toc?: boolean;
    }
  ): Promise<any> => {
    const isCreate = !!body.name;
    let url = `auth/reset-password/${token}/`;
    if (isCreate) {
      url += "new-user";
    }

    return homeFetch(url, {
      method: "POST",
      body: body,
    });
  };

  const requestResetPassword = async (
    email: string
  ): Promise<any> => {
    //const get current url without patg
    const form_url = window.location.toString().replace(
      window.location.pathname,
      useRouter().resolve({
        name: "auth-reset-process",
        query: {
          token: ":token",
        },
      }).href
    );

    const redirect_url = window.location.toString().replace(
      window.location.pathname,
      useRouter().resolve({
        name: "auth-login",
      }).href
    );

    return homeFetch("auth/reset-password/", {
      method: "POST",
      body: {
        email,
        form_url,
        title: "password",
        type: "password",
        origin: "homerunnner_platform",
        redirect_url,
      },
    });
  };

  /**
   * Checks if a user is currently logged in by checking if the 'hr_token' cookie exists.
   * @returns {boolean} A boolean value that indicates whether or not the user is logged in.
   */
  const isLoggedIn = (): boolean => {
    // Check if the 'hr_token' cookie exists
    // If it does, return true (user is logged in) - otherwise, return false (user is not logged in)
    return !!getAuthCookie().value;
  };

  /**
   * Logs the user out if they are logged in, then logs them in as a superuser by setting the 'hr_token' cookie.
   * @param {string} token - The authentication token to set as the cookie value.
   * @param {number} expiration - The number of seconds until the cookie expires.
   */
  const superuserLogin = async ({
    token,
    expiration,
  }: {
    token: string;
    expiration: number;
  }): Promise<any> => {
    // Check if the user is logged in
    if (isLoggedIn()) {
      // If the user is logged in, log them out
      await logout(true);
    }

    // Set the 'hr_token' cookie to the provided token and expiration values
    setAuthCookie(token, expiration);
  };

  /**
   * Returns a Promise that resolves to an array of IAccount objects representing the user's capabilities.
   * @returns {Promise<IAccount[]>} A Promise that resolves to an array of IAccount objects representing the user's capabilities.
   */
  const getCapabilities = async (): Promise<IAccount[]> => {
    // Send a GET request to the server to get the user's capabilities
    return $fetch("/api/auth/capabilities").then(
      (response: ICapabilitiesResponse) => {
        // Extract the capabilities data from the response and return it
        return response.data;
      }
    );
  };

  const getLegalDocuments = async (): Promise<any> => {
    return $fetch("/api/customers/legal-documents").then(
      (response) => {
        return response.data;
      }
    );
  };

  const getServices = async (): Promise<string[]> => {
    return homeFetch("get-services").then((response) => {
      return response.data;
    });
  };

  const setCapabilities = (
    accounts: IAccount[],
    redirect: boolean = true
  ): void => {
    const router = useRouter();
    const route = useRoute();
    const { $i18n } = useNuxtApp();
    const { t } = $i18n;
    setAccountsInLocalStorage(accounts);
    //Set account
    setAccount(accounts[0]);
    setTimeout(() => {
      getServices().then((services) => {
        setServicesInLocalStorage(services);
        //if redirect param, go to redirect param
        if (route.query.redirect) {
          router.push(route.query.redirect as string);
          return;
        }

        if (redirect) {
          $toast.add({
            title: t("redirected_to_account"),
            icon: "check",
            duration: 2000,
          });
          setTimeout(() => {
            router.push({ name: "shipments" });
          }, 1500);
        }
        return;
      });
    }, 200);
  };

  const checkCurrentAccountCapability = (
    capability: string
  ): boolean => {
    const account = getAccount();
    if (!account) {
      return false;
    }

    if (account.capabilities.includes("owner")) return true;

    return account.capabilities.includes(capability);
  };

  /**
   * Returns the value of the 'hr_token' cookie, which contains the user's access token.
   * @returns {string | null} The value of the 'hr_token' cookie, or null if cookie is not found.
   */
  const getAuthCookie = (): Ref<string | null> => {
    return useCookie("hr_token");
  };

  const getAuthCookieExpiresIn = (): number | null => {
    const expires = useCookie("hr_token_expire").value;
    if (!expires) {
      return null;
    }

    const expirationDate = new Date(expires).getTime();

    //get milliseconds until expiration
    return expirationDate - Date.now();
  };

  /**
   * Sets the 'hr_token' cookie, which contains the user's access token.
   * @param {string} token The access token to set in the cookie.
   * @param {number} expiration The expiration time of the cookie, in miliseconds.
   * @returns {void}
   */
  const setAuthCookie = (token: string, expiration: number): void => {
    // Set the 'hr_token' cookie with the given token and expiration
    setCookie({
      name: "hr_token",
      value: token,
      expiration: expiration,
    });
    setCookie({
      name: "hr_token_expire",
      value: new Date(Date.now() + expiration).toUTCString(),
      expiration: expiration,
    });

    //Set LEGACY cookie
    setCookie({
      name: "cr_access_token",
      value: JSON.stringify({
        token,
        expires_in: expiration,
        expires: Date.now() + expiration,
      }),
      expiration: expiration,
    });
  };

  /**
   * @param {string} accounts - The accounts value to be set in local storage.
   * @returns {void} This function does not return anything
   */
  const setAccountsInLocalStorage = (
    accounts?: IAccount[] | null | undefined
  ): void => {
    setCookie({
      name: "accounts",
      value: JSON.stringify(accounts || []),
      expiration: 60 * 60 * 24 * 1 * 1000,
    });
  };

  const setServicesInLocalStorage = (
    services?: string[] | null | undefined
  ): void => {
    setCookie({
      name: "services",
      value: JSON.stringify(services || []),
      expiration: 60 * 60 * 24 * 1 * 1000,
    });
  };

  /**
   * Gets the accounts object from browser storage.
   *
   * @returns {IAccount[] | null} Returns an object of type IAccount if it exists or null if it doesn't.
   */
  const getAccounts = (): IAccount[] => {
    const accounts = useCookie("accounts").value;
    if (!accounts) {
      return [];
    }
    return accounts as IAccount[];
  };

  /**
   * Returns the account that matches the current account ID.
   *
   * @return IAccount|null The account that matches the current account ID, or null if no match was found.
   */
  const getAccount = (): IAccount | null => {
    // Get the current account ID
    const accountId = getAccountId();

    // If an account ID was found, try to find a matching account in the list of accounts
    if (accountId) {
      const accounts = getAccounts();
      if (!accounts) {
        return null;
      }

      return (
        (accounts as IAccount[]).find(
          (account) => account.account.id === accountId
        ) || null
      );
    }

    // If no account was found or no account ID was present, return null
    return null;
  };

  const setAccount = (account: IAccount, reload?: boolean): void => {
    setAccountIdCookie(account.account.id);

    if (reload && process.client) {
      $loader.toggle();
      window.location.reload();
    }
  };

  const updateAccount = (account: IAccount): void => {
    const accounts = getAccounts();
    if (!accounts) {
      return;
    }

    const index = accounts.findIndex(
      (acc) => acc.account.id === account.account.id
    );
    accounts[index] = account;
    setAccountsInLocalStorage(accounts);
  };

  const getUser = (): IUser | null => {
    const account = getAccount();
    if (!account) {
      return null;
    }

    return { ...account.user };
  };

  const updateUser = (user: IUser): void => {
    const account = getAccount();
    if (!account) {
      return;
    }

    account.user = user;
    updateAccount(account);
  };

  /**
   * Sets a cookie named 'hr_account_id' with the given account ID value converted to string and an expiration of 24 hours.
   *
   * @param accountId - Account ID number to be set as cookie value.
   */
  const setAccountIdCookie = (accountId: number): void => {
    setCookie({
      name: "hr_account_id",
      value: accountId.toString(), //Conversion of 'accountId' to string for setting cookie value.
      expiration: 60 * 60 * 24 * 1000,
    });
  };

  /**
   * Returns the account ID as a number or null, obtained from 'hr_account_id' cookie.
   *
   * @returns {number | null} Returns a number if 'hr_account_id' cookie exists, otherwise returns null.
   */
  const getAccountId = (): number | null => {
    // Get the account ID from the 'hr_account_id' cookie.
    const accountId = useCookie("hr_account_id");

    // If 'hr_account_id' cookie exists, then parse it to a number and return it.
    if (accountId.value) {
      return parseInt(accountId.value);
    }

    // If 'hr_account_id' cookie does not exist, then return null.
    return null;
  };

  /**
   * Removes four cookies related to the user's account.
   *
   * @returns {void}
   */
  const clearCookies = (): void => {
    // Remove the 'hr_token' cookie using a helper function.
    removeCookie("hr_token");
    // Remove the 'hr_account_id' cookie using a helper function.
    removeCookie("hr_account_id");
    // Remove the 'accounts' cookie using a helper function.
    removeCookie("accounts");
    // Remove the 'hr_token_expire' cookie using a helper function.
    removeCookie("hr_token_expire");
    // Remove the 'cr_access_token' cookie using a helper function.
    removeCookie("cr_access_token");
    // Remove the 'services' cookie using a helper function.
    removeCookie("services");
    // Remove the 'external_access' cookie using a helper function.
    removeCookie("external_access");
  };

  const checkIsActive = (account: IAccount): boolean => {
    return account.account.id === getAccountId();
  };

  const hasSubdomains = (): boolean => {
    const accounts = getAccounts();
    if (!accounts) {
      return false;
    }

    return accounts.some((account) => account.subdomain);
  };

  const getSubdomains = (): string[] => {
    const accounts = getAccounts();
    if (!accounts) {
      return [];
    }

    return accounts.map((account) => account.subdomain || "");
  };

  const checkIfSubdomainExists = (subDomain: string): boolean => {
    const subDomains = getSubdomains();
    if (!subDomains) {
      return false;
    }

    return subDomains.includes(subDomain);
  };

  const getAccountFromSubdomain = (
    subDomain: string
  ): IAccount | null => {
    const accounts = getAccounts();
    if (!accounts) {
      return null;
    }

    return (
      accounts.find((account) => account.subdomain === subDomain) ||
      null
    );
  };

  const isAccountBusiness = (): boolean => {
    const account = getAccount();
    if (!account) {
      return false;
    }

    return !!account.account.business;
  };

  const isUserLoggedIn = computed(() => {
    return isLoggedIn();
  });

  const accountLevel = computed(() => {
    const account = getAccount();
    if (!account) {
      return "";
    }

    return account.account.level;
  });

  const updateCapabilities = async (): Promise<void> => {
    return getCapabilities().then(setAccountsInLocalStorage);
  };

  const accountOptions = computed(() => {
    const account = getAccount();

    if (!account) {
      return {};
    }

    return account.account.options;
  });

  const hasMultipleWorkspaces = computed(() => {
    const accounts = getAccounts();
    if (!accounts) {
      return false;
    }

    return accounts.length > 1;
  });

  const isDFM = computed(() => {
    const account = getAccount();
    if (!account) {
      return false;
    }
    return accountLevel.value === "DFM";
  });

  const accountName = computed(() => {
    const account = getAccount();
    if (!account) {
      return "";
    }

    return account.account.name;
  });

  const accountId = computed(() => {
    const account = getAccount();
    if (!account) {
      return "";
    }

    return account.account.id;
  });

  const services = computed(() => {
    const services = useCookie("services").value;

    if (!services) {
      return [];
    }

    return services as Record<string, any>;
  });

  const hasService = (service: string): boolean => {
    if (service === "transfer") service = "transfer_enabled";

    return !!services.value[service];
  };

  const user = computed<IUser | {}>(() => {
    const account = getAccount();
    if (!account) {
      return {};
    }

    return account.user;
  });

  const isExternalAccess = computed(() => {
    return !!useCookie("external_access").value;
  });

  return {
    login,
    logout,
    isLoggedIn,
    isUserLoggedIn,
    superuserLogin,
    getCapabilities,
    getLegalDocuments,
    setCapabilities,
    getAuthCookie,
    setAccountsInLocalStorage,
    getAccounts,
    getAccount,
    setAccount,
    getUser,
    setAccountIdCookie,
    getAccountId,
    clearCookies,
    checkIsActive,
    getSubdomains,
    hasSubdomains,
    checkIfSubdomainExists,
    getAccountFromSubdomain,
    isAccountBusiness,
    setAuthCookie,
    accountLevel,
    updateCapabilities,
    accountOptions,
    hasMultipleWorkspaces,
    getAuthCookieExpiresIn,
    isDFM,
    resetPassword,
    requestResetPassword,
    accountName,
    accountId,
    hasService,
    user,
    updateUser,
    isExternalAccess,
    services,
  };
};
