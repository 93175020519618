import { theme } from "#tailwind-config";

export const getNestedValue = (obj: any, path: string, defaultValue: any = null) => {
  const pathArray = path.split(".");
  const value = pathArray.reduce((o, key) => (o && o[key] !== "undefined" ? o[key] : defaultValue), obj);
  return value;
};

export const copyToClipboard = (text: string, toast?: string) => {
  const { $i18n } = useNuxtApp();
  const t = $i18n.t;
  return navigator.clipboard.writeText(text).then(() => {
    $toast.add({
      group: "clipboard_success",
      title: toast || t("value_copied_to_clipboard"),
      duration: 750,
    });
  });
};

export const getTailwindConfigValue = (path: string) => {
  return getNestedValue(theme, path);
};

export const isDomainDev = () : boolean => {
  const hostname = useRequestURL().hostname;
  const domain = hostname?.split(".").slice(1).join(".");

  return !hostname || hostname == "localhost" || hostname == "127.0.0.1" || domain == "cloudfront.net" || domain == "devtunnels.ms";
};

export const getOrganisationInfo = () => {
  return homeFetch("workspace/organisation")
  .then((response) => {
    if (response?.data) {
      return { ...response.data } as OrganisationInformation;
    }
  })
  .finally(() => {
  });
};

export function assertNever(x: never): any {
  const _exhaustiveCheck: never = x;
  return x;
}


export function getOS() : 'Android' | 'iOS' | 'Windows' | 'Mac' | 'Unknown' {
  const { userAgent } = window.navigator;
  if (/Android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iOS";
  }
  if (/Windows/i.test(userAgent)) {
    return "Windows";
  }
  if (/Mac/i.test(userAgent)) {
    return "Mac";
  }

  return "Unknown";
}
