<template>
    <div>
        <div class="text-left flex flex-col gap-3" v-for="field in service.fields">
            <p class=" text-secondary text-body-sm-heavy">{{ field.label }}</p>
            <div v-for="option, index in field.options">
                <div class="flex gap-2.5 cursor-pointer" @click="toggleOption(option.key)"  >
                    <TickerCheckbox :activeValue="isSelected(option.key)" />
                    <p class=" text-body-default text-secondary">{{ option.label }}</p>
                </div>
                <Divider v-if="index !== field.options.length - 1" class="mt-3" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    const props = defineProps<{
        service: any;
    }>();

    onMounted(() => {
        if (!array.value) {
            array.value = [];
        }
    });
    const isSelected = (key: string) => {
        if (!array.value) {
            return false;
        }
        if (array.value.length === 0) {
            return false;
        }
        return array.value.includes(key);
    }
    const toggleOption = (key: string) => {
        if (array.value.includes(key)) {
            array.value = array.value.filter((item: string) => item !== key);
        } else {
            array.value.push(key);
        }
    }
    const { value: array } = useField(() => `services.${props.service.carrier_key}.array`, undefined, {
        syncVModel: false,
        controlled: true,
    });
</script>