<template>
  <div popover ref="popover" class="w-full h-full bg-transparent">
    <Overlay :text="loadingText" v-if="loading" />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from "vue";
import { loading, loadingText } from "../service";
import EventBus from "@/plugins/eventbus";

const popover = ref(null);

const emit = defineEmits(["loading", "loaded"]);

onMounted(() => {
  EventBus.$on("LOADER_TOGGLE", (text) => {
    if (!loading.value) {
      emit("loading", text);
      popover.value?.showPopover();
    } else {
      emit("loaded", text);
      popover.value?.hidePopover();
    }
    loadingText.value = text;
    loading.value = !loading.value;
  });
  EventBus.$on("LOADER_OPEN", (text) => {
    emit("loading", text);
    popover.value?.showPopover();
    loadingText.value = text;
    loading.value = true;
  });
  EventBus.$on("LOADER_CLOSE", (text) => {
    emit("loaded", text);
    popover.value?.hidePopover();
    loadingText.value = text;
    loading.value = false;
  });
});

onUnmounted(() => {
  EventBus.$off("LOADER_TOGGLE");
});
</script>
