<template>
  <Modal v-model:open="open" maxWidth="1020px" class="shadow-lvl-05" ref="modal" id="settings-modal" :contentPadding="false">
    <div class="flex w-[1020px] max-w-full h-[756px] max-h-full">
      <SettingsSidebar :flows="flows" :currentFlow="currentFlow" :currentHeader="currentHeader" @entry:click="setCurrentFlowKey"> </SettingsSidebar>
      <div class="flex w-full relative">
        <div class="w-full box-content flex flex-col" ref="flowContainer">
          <div class="overflow-auto py-6">
            <div class="mb-6 px-10 gap-[7.5px] flex flex-col">
              <div class="text-title-body">
                {{ currentPageTitle }}
              </div>
              <div class="text-quarterary text-body-lg" v-if="currentPageDescription">
                {{ currentPageDescription }}
              </div>
            </div>
            <div class="px-10 pb-16">
              <ClientOnly>
                <KeepAlive>
                  <component :is="settingsComponent" @update:loading="setLoading" :currentHeader="currentHeader" />
                </KeepAlive>
              </ClientOnly>
            </div>
          </div>
        </div>
        <Overlay v-if="loading" />
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from "vue";
import { open, currentFlowKey, currentHeader } from "../service";
import EventBus from "@/plugins/eventbus";
import useHasScrollbar from "~/composables/useHasScrollbar";
import SettingsSidebar from "./Sidebar/index.vue";
import type { ISettingsFlow } from "../types";
import OrganisationSettings, { useOrganisationSettings } from "../flows/OrganisationSettings.vue";
import PrinterSettings, { usePrinterSettings } from "../flows/PrinterSettings.vue";
import PaymentSettings, { usePaymentSettings } from "../flows/PaymentSettings.vue";
import IntegrationSettings, { useIntegrationSettings } from "../flows/IntegrationSettings.vue";
import UserSettings, { useUserSettings } from "../flows/UserSettings.vue";
import PlanSettings, { usePlanSettings } from "../flows/PlanSettings.vue";
import ReturnPortalSettings, { useReturnPortalSettings } from "../flows/ReturnPortalSettings.vue";
import WebshopSettings, { useWebshopSettings } from "../flows/WebshopSettings.vue";
import CheckoutSettings, { useCheckoutSettings } from "../flows/CheckoutSettings.vue";
import NoSuchSetting from "../flows/NoSuchSetting.vue";

const { t } = useI18n();

const modal = ref<HTMLDivElement | null | undefined>();

const emit = defineEmits(["loading", "loaded"]);
const loading = ref<boolean>(false);
const flowContainer = ref<HTMLElement | null | undefined>();

const setLoading = (l: boolean) => (loading.value = l);
let allFlows = [
  useOrganisationSettings(),
  usePlanSettings(),
  useUserSettings(),
  usePrinterSettings(),
  useIntegrationSettings(),
  useWebshopSettings(),
  useCheckoutSettings(),
  useReturnPortalSettings(),
  usePaymentSettings(),
];

const flows = computed<ISettingsFlow[]>(() => {
  return allFlows.filter((flow) => {
    if (flow.condition) {
      return flow.condition();
    }
    return true;
  });
});

watch(open, (o) => {
  if (o) {
    setCurrentFlowKey(flows.value[0].key);
  }
});

watch(
  () => flowContainer.value,
  () => {
    if (flowContainer.value) {
      useHasScrollbar(flowContainer.value);
    }
  }
);

const settingsComponent = computed(() => {
  switch (currentFlow.value?.key) {
    case "organisation":
      return OrganisationSettings;
    case "payment":
      return PaymentSettings;
    case "printers":
      return PrinterSettings;
    case "integration":
      return IntegrationSettings;
    case "users":
      return UserSettings;
    case "plan":
      return PlanSettings;
    case "returnPortals":
      return ReturnPortalSettings;
    case "webshops":
      return WebshopSettings;
    case "checkouts":
      return CheckoutSettings;
    default:
      return NoSuchSetting;
  }
});

const addFlow = (boarding: ISettingsFlow) => {
  flows.value.push(boarding);
};

const currentFlow = computed(() => {
  if (!currentFlowKey.value) return null;
  return flows.value.find((b) => b.key === currentFlowKey.value);
});

const startSettings = (key: string) => {
  open.value = true;
  nextTick(() => {
    setCurrentFlowKey(key);
  });
};

const removeFlow = (key: string) => {
  const index = flows.value.findIndex((setup) => setup.key === key);
  if (index !== -1) {
    flows.value.splice(index, 1);
  }
};

const setCurrentFlowKey = (key: string, header?: string) => {
  currentFlowKey.value = key;
  currentHeader.value = null;
  nextTick(() => {
    if (header) currentHeader.value = header;
  });
};

onMounted(() => {
  EventBus.$on("SETTINGS_TOGGLE", () => {
    open.value = !open.value;
  });
  EventBus.$on("SETTINGS_OPEN", () => {
    open.value = true;
  });
  EventBus.$on("SETTINGS_CLOSE", () => {
    open.value = false;
  });
  EventBus.$on("SETTINGS_ADD", addFlow);
  EventBus.$on("SETTINGS_REMOVE", removeFlow);
  EventBus.$on("SETTINGS_START", startSettings);
});

onUnmounted(() => {
  EventBus.$off("SETTINGS_TOGGLE");
  EventBus.$off("SETTINGS_OPEN");
  EventBus.$off("SETTINGS_CLOSE");
  EventBus.$off("SETTINGS_ADD");
  EventBus.$off("SETTINGS_REMOVE");
  EventBus.$off("SETTINGS_START");
});

const currentPageTitle = computed(() => {
  if (!currentFlow.value) return t("settings.no_such_setting");

  if (currentFlow.value?.pageTitle) return currentFlow.value.pageTitle;
  else return currentFlow.value.title;
});

const currentPageDescription = computed(() => {
  if (!currentFlow.value) return "";

  return currentFlow.value.description;
});
</script>
, currentHeader, currentHeader
