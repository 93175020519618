<template>
  <NuxtLayout name="empty">
    <section class="flex items-center h-screen p-16 bg-00 relative justify-center">
      <div class="container flex flex-col items-center">
        <div class="flex flex-col gap-4 max-w-lg text-center">
          <div class="text-title-screen">
            {{ errorTitle }}
          </div>
          <p class="text-title-body text-tertiary">{{ errorDescription }}</p>

          <img :src="errorIllustration" alt="Error illustration" class="max-h-[250px] my-10" />

          <Button variant="secondary" class="w-fit" @click="handleError">
            <Icon size="large" src="arrow_left" class="mr-2" />
            {{ t("go_back") }}
          </Button>
        </div>
      </div>
      <Badge type="subtle" class="absolute left-[30px] bottom-[30px]">
        {{ statusCode }}
      </Badge>
    </section>
  </NuxtLayout>
</template>

<script setup lang="ts">
import error400Illustration from "~/assets/illustrations/errors/nc-octopus.svg";
import error401Illustration from "~/assets/illustrations/errors/oc-login.svg";
import error403Illustration from "~/assets/illustrations/errors/oc-login.svg";
import error404Illustration from "~/assets/illustrations/errors/nc-improve-search-experience.svg";
import error405Illustration from "~/assets/illustrations/errors/nc-work-efficiently.svg";
import error500Illustration from "~/assets/illustrations/errors/nc-turning-off-lamp.svg";
import error502Illustration from "~/assets/illustrations/errors/nc-octopus.svg";
const lastRoute = ref(null);
onBeforeMount(() => {
  lastRoute.value = useLastRoute().value;
});

const { t } = useI18n();
const error = useError();
const statusCode = computed(() => error.value?.statusCode);
const { isLoggedIn } = useAuth();

const handleError = () => {
  if (!lastRoute.value?.name) {
    if (isLoggedIn()) {
      return clearError({ redirect: "/" });
    }
    return clearError({ redirect: "auth/login" });
  } else clearError().then(() => useRouter().back());
}

const errorTitle = computed(() => {
  switch (statusCode.value) {
    case 400:
      return t("errors.400.title");
    case 401:
      return t("errors.401.title");
    case 403:
      return t("errors.403.title");
    case 404:
      return t("errors.404.title");
    case 405:
      return t("errors.405.title");
    case 500:
      return t("errors.500.title");
    case 502:
      return t("errors.502.title");
    default:
      return error.value?.message;
  }
});

const errorDescription = computed(() => {
  switch (statusCode.value) {
    case 400:
      return t("errors.400.description");
    case 401:
      return t("errors.401.description");
    case 403:
      return t("errors.403.description");
    case 404:
      return t("errors.404.description");
    case 405:
      return t("errors.405.description");
    case 500:
      return t("errors.500.description");
    case 502:
      return t("errors.502.description");
    default:
      return "";
  }
});

const errorIllustration = computed(() => {
  switch (statusCode.value) {
    case 400:
      return error400Illustration;
    case 401:
      return error401Illustration;
    case 403:
      return error403Illustration;
    case 404:
      return error404Illustration;
    case 405:
      return error405Illustration;
    case 500:
      return error500Illustration;
    case 502:
      return error502Illustration;
    default:
      return error400Illustration;
  }
});
</script>
