<template>
    <div class="w-full">
        <InputText :error="attribute.error" :model-value="attribute.value" @update:modelValue="(val) => emit('emitValue', val)" v-if="type == 'text'" class="w-full"/>
        <InputNumber :error="attribute.error" :model-value="attribute.value" @update:modelValue="(val) => emit('emitValue', val)" v-if="type == 'number'" class="w-full"/>
        <InputParagraph :error="attribute.error" :model-value="attribute.value" @update:modelValue="(val) => emit('emitValue', val)" v-if="type == 'textarea'" ></InputParagraph>
        <FileUpload :error="attribute.error" :model-value="attribute.value" @update:modelValue="(val) => emit('emitValue', val)" :index="index" v-if="type == 'file' || type == 'dor' || type == 'file_with_pdf'"  :shipmentId="shipmentId" s3 :message="t('pdf_size')"></FileUpload>
        <FileUpload :error="attribute.error" :model-value="attribute.value" @update:modelValue="(val) => emit('emitValue', val)" :index="index" v-if="type == 'file[]'" imagePreview  multiple :shipmentId="shipmentId" s3></FileUpload>
        <DropdownSelect :error="attribute.error" v-model="attribute.value" @update:modelValue="(val) => emit('emitValue', val)" v-if="type == 'select'" :options="attribute.options" class="w-full" :triggerText="t('select')" triggerType="button"/>
        <TickerCheckbox v-if="type == 'boolean'" class="mt-1" v-model="attribute.value">{{ t('yes') }}</TickerCheckbox>
    </div>
</template>

<script setup lang="ts">

const { t } = useI18n();

const emit = defineEmits(["emitValue"]);

const props = defineProps<{
    type: string;
    name?: string;
    shipmentId: number;
    index: number;
    attribute: any;
}>();
</script>