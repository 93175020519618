<template>
    <div class="w-full flex flex-col gap-3">
        <div class="w-full flex gap-3" v-for="object, index in objects">
            <div class="text-left" v-for="field in service.fields">
                <InputText v-if="field.type == 'text'" type="text" v-model="objects[index][field.key]" class="w-full" :label="field.label" />
                <InputText v-if="field.type == 'number'" type="number" v-model="objects[index][field.key]" class="w-full" :label="field.label" />
                <DropdownSelect v-if="field.type == 'select'" v-model="objects[index][field.key]" :options="field.options" :label="field.label" />
            </div>
            <Button @click="removeField(index)" class=" mt-[22.5px]" type="button" v-if="index !== 0" variant="tertiary" size="default" iconOnly><Icon src="close" size="medium"></Icon></Button>
        </div>
        <Button type="button" class="mr-auto" variant="primary-link" size="default" @click="addField()">+ {{ t('add_field') }}</Button>
    </div>
</template>

<script setup lang="ts">
    import { defineProps, defineEmits, ref, onMounted } from 'vue';
    const { t } = useI18n();
    const props = defineProps<{
        service: any;
    }>();

    onMounted(() => {
        if (!objects.value) {
            objects.value = [];
            let fieldArray = {};
            props.service.fields.forEach((field: any) => {
                fieldArray[field.key] = '';
            });
            objects.value.push(fieldArray);
        }
    });

    const addField = () => {
        let fieldArray = {};
        props.service.fields.forEach((field: any) => {
            fieldArray[field.key] = '';
        });
        objects.value.push(fieldArray);
    }

    const removeField = (index: number) => {
        objects.value.splice(index, 1);
    }

    const { value: objects } = useField(() => `services.${props.service.carrier_key}`, undefined, {
    syncVModel: false,
    controlled: true,
    });

</script>