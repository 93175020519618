<template>
    <div class="relative">
        <label class="flex cursor-pointer gap-2.5" :for="id">
            <input
            :id="id"
            :disabled="readonly"
            type="checkbox"
            :indeterminate="indeterminate"
            class="peer relative h-5 w-5 aspect-square cursor-pointer appearance-none rounded-full border border-01 bg-00 transition-all duration-200 focus-visible:outline-none focus-visible:before:border-2 focus-visible:before:border-focus !checked:border-inversed-00 checked:bg-inversed-00 checked:hover:bg-inversed-01 checked:hover:border-inversed-01 checked:disabled:bg-disabled !indeterminate:border-inversed-00 indeterminate:bg-inversed-00 indeterminate:hover:bg-inversed-01 indeterminate:hover:border-inversed-01 indeterminate:disabled:bg-disabled disabled:border-disabled disabled:cursor-default hover:border-02"
            v-model="inputValue"
            />
            <div class="rounded-full h-2 w-2 absolute top-1.5 left-1.5 transition duration-200" :class="{'bg-white' : inputValue}">
            </div>
            <slot></slot>
        </label>
    </div>
</template>

<script setup lang="ts">
const id = `checkbox-${useId()}`;

const props = defineProps({
    readonly: {
        type: Boolean,
        default: false,
    },
    indeterminate: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        default: "",
    },
    error: {
        type: String,
        default: "",
    },
    selected: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue']);
const modelValue = defineModel();

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(() => props.name, undefined, {
    type: "checkbox",
    checkedValue: true,
    uncheckedValue: false,
        syncVModel: props.name ? false: true,
    controlled: !!props.name,
});

const error = computed(() => {
    return props.error || errorMessage.value;
});

watch(
    () => props.selected,
    (value) => {
        inputValue.value = value;
    },
    { immediate: true }
);
</script>