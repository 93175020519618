<template>
  <Avatar :entity="user" random-color :index="0"/>
   </template>

<script setup lang="ts">



const { getUser } = useAuth();

const user = getUser();
</script>
