<template>
  <div
    class="relative rounded-lg px-2.5 py-1 mx-4 flex flex-row gap-1.5 text-sm items-center  text-foreground-white min-h-9 shadow-toast z-50 text-nowrap"
    :class="{
      'bg-black': !props.message.type || props.message.type === 'info' || props.message.type === 'success' || props.message.type === 'warning',
      'bg-destructive': props.message.type === 'error',
    }"
    @click="dismissClick"
  >
    <Icon :src="icon || 'Bold/Check'" size="medium" :filled="filledIcon"/>
    <div class="font-medium" v-if="message.title">{{ message.title }}</div>
    <div class="font-normal text-foreground-white/80" v-if="message.text">{{ message.text }}</div>

    <Button v-for="(action, index) in message.actions" :key="index" :icon="action.icon" @click.prevent.stop="action.handler" variant="tertiary-inversed" size="small">
      {{ action.text }}
    </Button>

    <div
      v-if="message.count && message.count > 1"
      class="transition-[width,height] -top-2.5 -right-2.5 flex items-center justify-center text-xs absolute rounded-full bg-destructive text-white"
      :class="{
        'h-5 w-5 ': message.count < 10,
        'h-6 w-6': message.count >= 10,
      }"
    >
      {{ count }}
    </div>
  </div>
</template>
<script lang="ts">
export interface IToast {
  title?: string;
  text?: string;
  type?: ToastMessageType;
  icon?: string | null | undefined;
  actions?: {
    text: string;
    icon?: string;
    handler: () => void;
  }[];
  group?: string;
  count?: number;
  duration?: number;
  dismissible?: boolean;
  id?: string | number;
  target?: string | HTMLDivElement | null;
  zIndex?: number;
}
</script>

<script setup lang="ts">
import type { ToastMessageType } from '../../types';

const props = withDefaults(defineProps<{ message: IToast }>(), {
  message: () => ({
    color: "green",
    title: "",
    text: "",
    icon: "",
    actions: () => [],
    group: "",
    count: 0,
    duration: null,
    id: null,
    target: null,
    type: "info",
  }),
});
const emit = defineEmits(["dismiss"]);
let timeout: NodeJS.Timeout | null = null;

const setMessageTimeout = () => {
  if (props.message.duration === 0) return;
  timeout = setTimeout(dismiss, props.message.duration);
};

onMounted(() => {
  setMessageTimeout();
});

watch(
  () => props.message.count,
  (v) => {
    if (v === 1) return false;
    clearTimeout(timeout!);
    setMessageTimeout();
  }
);
const dismissClick = () => {
  if (!props.message.dismissible) return;
  if (timeout) clearTimeout(timeout);
  dismiss();
};
const dismiss = () => {
  emit("dismiss", props.message);
};

const count = computed(() => {
  if (!props.message.count) return;

  const c = props.message.count;
  if (c > 99) return `99+`;

  return c;
});

const icon = computed(() => {
  switch (props.message.type) {
    case undefined:
    case null:
    case "info":
      return props.message.icon || 'Bold/Info';
    case "success":
      return props.message.icon || 'Bold/Check';
    case "warning":
      return props.message.icon || 'Bold/Warning';
    case "error":
      return props.message.icon || 'Bold/Error';
    default:
      assertNever(props.message.type);
  }
});

const filledIcon = computed(() => {
  switch (props.message.type) {
    case undefined:
    case null:
    case "info":
    case "success":
    case "warning":
      return false
    case "error":
      return true;
    default:
      assertNever(props.message.type);
  }
});
</script>
