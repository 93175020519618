<template>
  <InputText ref="el" placeholder="Select option" readonly class="read-only:text-primary cursor-pointer [&_input]:cursor-pointer [&_img]:cursor-pointer" @click="onClick">
    
    <template #prefix>
      <slot name="prefix"> </slot>
    </template>

    <template #suffix>
      <Icon src="bold/caret_down" size="small" />
    </template>
    <slot></slot>
  </InputText>
</template>

<script setup lang="ts">

const el = ref<HTMLElement | null>(null);
const rawInputWrapper = computed(() => el.value?.wrapper);

const onClick = () => {
  emit('click')
}
const emit = defineEmits(["click"]);

defineExpose({
  el: rawInputWrapper,
});
</script>
