<template>
    <div class="rounded-xl cursor-pointer group relative" :class="small ? 'w-[68px] h-[68px]' : 'h-[82px] w-[82px]'" :style="`background-image: url('${src}'); background-position: center; background-size: cover;`">
        <Button v-if="!small" class="absolute top-1 left-[49px] transition-all duration-300 opacity-0 group-hover:opacity-100" variant="primary" size="small" iconOnly @click="emit('removeFile', file.name)">
            <Icon src="close" size="small" class="text-white" />
        </Button>
    </div>
</template>

<script setup lang="ts">
const props = defineProps<{
    src: string;
    file?: Object;
    small?: boolean;
}>();

const emit = defineEmits(["removeFile"]);

</script>