import * as yup from "yup";
import yupLocaleDa from "@/locales/yup/da";

export default () => {
  const { $i18n } = useNuxtApp();
  const { locale, t } = $i18n;

  if (locale.value === "da") {
    yup.setLocale(yupLocaleDa);
  }

  yup.addMethod(yup.string, "containUppercaseAndLowercase", function (args) {
    return this.test("containUppercase", function (value) {
      const { path, createError } = this;
      if (!value) return true;
      if (!/(?=.*[a-z])(?=.*[A-Z])/.test(value)) {
        return createError({ path, message: ({label}) => t('validations.containerUppercaseAndLowercase', {path: label || path})});
      }

      return true;
    });
  });

  yup.addMethod(yup.mixed, "maxFileSize", function (args) {
    return this.test("maxFileSize", function (value) {
      const { path, createError } = this;
      if (!value) return true;

      const MAX_AVATAR_FILE_SIZE = args * 1024 * 1024;
      if (value.size > MAX_AVATAR_FILE_SIZE) {
        return createError({ path, message: ({label}) => t('validations.maxFileSize', {path: label || path, size: args})});
      }

      return true;
    });
  });

  yup.addMethod(yup.array, "emailList", function (args) {
    return this.test("emailList", function (value) {
      const { path, createError } = this;
      if (!value) return true;

      const emailList = value.filter((email) => !yup.string().email().isValidSync(email));

      if (emailList.length) {
        return createError({ path, message: ({label}) => t('validations.emailList', {path: label || path})});
      }      

      return true;
    });
  });

  yup.addMethod(yup.string, 'requiredIf', function(list, message) {
    return this.test('requiredIf', message, function(value) {
      const { path, createError } = this;
        // check if any in list contain value
        // true : one or more are contains a value
        // false: none contain a value

        let anyHasValue = list.some(value => {
          // return `true` if value is not empty, return `false` if value is empty
          let input = document.querySelector(`input[name="${value}"]`);
          if (!input) {
            return false
          }
          else {
            return Boolean(input.value);
          }
        });
        // returns `CreateError` current value is empty and no value is found, returns `false` if current value is not empty and one other field is not empty.
        return !value && !anyHasValue
          ? createError({ path, message })
          : true;
      });
  });

  yup.addMethod(yup.number, 'requiredIf', function(list, message) {
    return this.test('requiredIf', message, function(value) {
      const { path, createError } = this;
        // check if any in list contain value
        // true : one or more are contains a value
        // false: none contain a value

        let anyHasValue = list.some(value => {
          // return `true` if value is not empty, return `false` if value is empty
          let input = document.querySelector(`input[name="${value}"]`);
          if (!input) {
            return false
          }
          else {
            return Boolean(input.value);
          }
        });
        // returns `CreateError` current value is empty and no value is found, returns `false` if current value is not empty and one other field is not empty.
        return !value && !anyHasValue
          ? createError({ path, message })
          : true;
      });
  });

  return yup;
};
