<template>
  <div class="h-[60vh] overflow-y-scroll w-full px-6 relative">
    <div v-if="products.length !== 0" class="flex gap-3 px-3 py-2 w-full">
      <div class="min-w-9"></div>
      <p class="w-full text-left pr-4 text-quarterary text-body-sm">{{ t("product") }}</p>
      <p v-if="!isDFM" class="min-w-[80px] ml-auto text-right text-quarterary text-body-sm">{{ t("price") }}</p>
    </div>
    <div class="pb-5 max-h-[55vh]">
      <div v-for="(product, index) in products" :key="product.uid">
        <div
          class="mb-1 rounded-lg w-auto cursor-pointer relative transition-all duration-300 hover:bg-00"
          :class="cpsValue === product.uid ? 'bg-00' : ''"
          @click="selectCps(product)"
        >
          <div class="px-3 py-2 gap-3 flex">
            <Avatar
              :entity="{ image: `https://assets.homerunner.com/carrier_icons/${product.carrier}.png` }"
              size="xs"
              class="transition duration-300"
            ></Avatar>
            <p class="text-body-lg-heavy text-secondary mt-1.5">{{ product.title }}</p>
            <p v-if="!isDFM" class="ml-auto w-[80px] text-right text-quarterary text-body-sm mt-2">
              {{ isAccountBusiness() ? product.sales_price_excl_vat : product.sales_price_incl_vat }} {{ product.currency }}
            </p>
          </div>
          <div
            class="overflow-hidden grid transition-all duration-300"
            :class="isDroppoint(product.carrier_product_service) && droppoints.length !== 0 && cpsValue == product.uid ? 'grid-rows-1fr pb-3' : 'grid-rows-0'"
          >
            <div class="pr-3 pl-[60px] overflow-hidden flex gap-2.5 w-full" @click.stop="">
              <InputText class="w-[188px] mb-1" v-model="zip_code" darkBg :label="t('zip_code')" />
              <DropdownSelect class="w-full" v-model="droppoint" :options="droppoints" preSelected :label="t('select_droppoint')" :placeholder="t('select_droppoint')" />
            </div>
          </div>
          <div
            class="overflow-hidden grid transition-all duration-300"
            :class="cpsValue == product.uid && product.carrier_product_service.split('_')[0] == 'dfm' && isDFM ? 'grid-rows-1fr pb-3' : 'grid-rows-0'"
          >
            <div class="pr-3 pl-[60px] overflow-hidden" @click.stop="">
              <label class="text-body-sm-heavy text-left text-secondary">{{ t("shipment_ready_for_pickup") }}</label>
              <div class="flex gap-3 items-center">
                <div class="!max-w-[190px] my-1">
                  <Datepicker
                    name="pickup_date"
                    class="w-full filter-value my-1"
                    :presets="[]"
                    ref="calenderRef"
                    :bottomButtons="false"
                    :requiresConfirmation="false"
                    darkBg
                    calendarIcon
                  />
                </div>
                <div class="flex gap-1 items-center">
                  <p class="text-secondary text-body-default">Kl.</p>
                  <InputTime valueAsDate :minutesInterval="15" class="w-full filter-value max-w-max my-1" name="pickup_time" darkBg />
                </div>
              </div>
            </div>
          </div>
          <Overlay
            v-if="isDroppoint(product.carrier_product_service) && loading && cpsValue == product.uid"
            class="h-full w-full absolute top-0 left-0"
          ></Overlay>
        </div>
        <Divider v-if="index !== products.length - 1" class="mb-1"></Divider>
      </div>
    </div>
    <Overlay v-if="loading && droppoints.length == 0" class="absolute top-0 left-0 h-full w-full"></Overlay>
    <EmptyCarrier @goback="emit('goback')" v-if="products.length == 0 && !loading"></EmptyCarrier>
  </div>
</template>

<script setup lang="ts">
import type { Product } from "~/types/shipment";
import useCps from "../../composables/useCps";
import EmptyCarrier from "./emptyViews/emptyCarrier.vue";
import { mode } from "../../service";

const { t } = useI18n();

const emit = defineEmits(["addExtraServices", "goback", "skipInsurance"]);

const props = defineProps<{
  values: any;
}>();

const zip_code = ref("");

const receiver = computed(() => ({
  street1: props.values.receiver.street1,
  country: props.values.receiver.country,
  zip_code: props.values.receiver.zip_code,
  city: props.values.receiver.city,
  business: props.values.receiver.business
}));

const { products, droppoints, loading, fetchProducts, fetchServicePoints, fetchExtraServices } = await useCps();

const { isAccountBusiness, isDFM } = useAuth();

onMounted(() => {
  const sender = {
    street1: props.values.sender.street1,
    country: props.values.sender.country,
    zip_code: props.values.sender.zip_code,
    city: props.values.sender.city,
  };

  zip_code.value = receiver.value.zip_code;

  fetchProducts(sender, receiver.value, props.values.weight, props.values.height, props.values.width, props.values.length, { return: mode.value == "return" }).then(
    () => {
      if (cpsValue && products.value && products.value.length > 0) {
        let selectedProduct = products.value.find((product: Product) => product.uid === cpsValue.value);
        if (!selectedProduct) return;

        selectCps(selectedProduct);
      }
    }
  );
});

const debounceTimeout = ref(null);

const debounce = async () => {
    if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
    debounceTimeout.value = setTimeout( async () => {
      if (!cpsValue.value) return;
      let cps = products.value.find((product: Product) => product.uid === cpsValue.value)
      if (!cps) return;
      loading.value = true;
      await fetchServicePoints(cps.carrier, receiverObject);
      loading.value = false;
    }, 500); // delay for half second
};

watch(
    () => zip_code.value,
    (value) => {
      if (value && carrier_product_service.value && isDroppoint(carrier_product_service.value)) {
            debounce();
        }
    }
);

const selectCps = async (product: Product) => {
  cpsValue.value = product.uid;
  carrier_product_service.value = product.carrier_product_service;
  carrier_product_title.value = product.title;
  carrier_product_price.value = isAccountBusiness() ? product.sales_price_excl_vat : product.sales_price_incl_vat;
  if (isDroppoint(product.carrier_product_service)) {
    loading.value = true;
    await fetchServicePoints(product.carrier, receiver.value);
  }
  if (product.carrier === "dfm" && isDFM.value) {
    emit("skipInsurance", true);
  } else {
    emit("skipInsurance", false);
  }
  if (product.carrier === "dfm") {
    if (product.service_codes.length > 0) {
      loading.value = true;
      let extraServices = await fetchExtraServices(product.carrier, product.carrier_product_service, product.service_codes);
      emit("addExtraServices", extraServices.data);
    } else {
      emit("addExtraServices", []);
    }
  } else {
    emit("addExtraServices", []);
  }
};

const translateEta = (eta?: string) => {
  // If eta is not provided, return "unknown"
  if (!eta) return t("unknown");

  // Extract the eta span from the eta string
  const etaSpanRaw = eta?.split("{")[1]?.split("}")[0];
  const etaSpan = etaSpanRaw ? t(etaSpanRaw).toLowerCase() : "";

  // Replace the eta span placeholder with the translated eta span
  return `${eta ? eta.replace(`{${etaSpanRaw}}`, etaSpan) : t("unknown")}`;
};

const {
  value: cpsValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(() => "cpsUid", undefined, {
  syncVModel: true,
});

const { value: carrier_product_service } = useField(() => "carrier_product_service", undefined, {
  syncVModel: true,
});

const { value: droppoint } = useField(() => "droppoint", undefined, {
  syncVModel: true,
});

const { value: carrier_product_title } = useField(() => "carrier_product_title", undefined, {
  syncVModel: true,
});

const { value: carrier_product_price } = useField(() => "carrier_product_price", undefined, {
  syncVModel: true,
});
</script>
