<template>
  <div class="flex flex-col gap-6 @container" ref="flowWrapper">
    <div class="flex gap-4 justify-between">
      <div class="flex-col">
        <div class="text-body-lg-heavy settings-flow-header">
          {{ t("settings.checkout.label.title") }}
        </div>
        <div class="text-body-default text-quarterary">
          {{ t("settings.checkout.label.description") }}
        </div>
      </div>

      <Button class="mt-auto" variant="primary" @click="() => createOpen = true">{{ t("create_checkout") }}</Button>
    </div>
    <Divider />

    <div class="flex gap-4 justify-between">
      <div class="flex-col">
        <div class="text-body-lg-heavy settings-flow-header">
          {{ t("settings.checkout.userdefined_parameters.label.title") }}
        </div>
        <div class="text-body-default text-quarterary">
          {{ t("settings.checkout.userdefined_parameters.label.description") }}
        </div>
      </div>

      <Button class="mt-auto" variant="default" @click="() => paramsOpen = true">{{ t("view_parameters") }}</Button>
    </div>
    <Divider />
    <div v-show="hasNoCheckouts" class="w-full py-6 max-w-[481px] mx-auto">
      <EmptyView :title="''" :description="''">
        <template #description>
          <div>
            <p class="text-primary text-title-subsection mb-2">{{ t("no_checkouts") }}</p>
            <p class="text-body-default text-tertiary">{{ t("create_checkout_desc") }}</p>
          </div>
        </template>
        <template #illustration>
          <img :src="illustration" alt="No checkouts illustration" />
        </template>
      </EmptyView>
    </div>
    <div v-if="!hasNoCheckouts">
      <template v-for="(checkout, idx) in checkouts">
        <ListItem :checkout="checkout" class="w-full" @delete="() => removeItem(idx)" @edit="() => editItem(checkout)" @edit:rules="() => editItem(checkout, 'setup_rules')" @duplicate="() => duplicateItem(checkout)"> </ListItem>
        <Divider :spacing="false" class="my-6"/>
      </template>
    </div>
  </div>

  <SetupModal :checkout-id="checkoutId" :open="createOpen" @close="closeSetup" :initial-step="openStep"@reload="fetchCheckouts"/>
  <ParamModal :openParams="paramsOpen" :checkout-id="checkoutId" @close="closeParamSetup" />
</template>

<script setup lang="ts">
import illustration from "~/assets/illustrations/emptyViews/nc-improve-ease-of-use.svg";
import type { ICheckoutIndex, ISettingsFlow } from "../types";
import ListItem from "../components/checkout/ListItem.vue";
import SetupModal from "../components/checkout/SetupModal.vue";
import ParamModal from "../components/checkout/ParamModal.vue";

const props = withDefaults(
  defineProps<{
    currentHeader?: string | null;
  }>(),
  {
    currentHeader: null,
  }
);

const { t } = useI18n();
const createOpen = ref(false);
const openStep = ref<string>();
const paramsOpen = ref(false);
const checkoutId = ref<number | undefined>();

const emits = defineEmits(["update:loading"]);

const loading = ref<boolean>(false);

const checkouts = ref<ICheckoutIndex[]>([]);

const hasNoCheckouts = computed(() => checkouts.value.length === 0 && !loading.value);

const fetchCheckouts = () => {
  setLoading(true);
  return homeFetch("workspace/checkouts")
    .then((response) => {
      if (response?.data) {
        checkouts.value = response.data as ICheckoutIndex[];
      }
    })
    .finally(() => {
      setLoading(false);
    });
};

onMounted(() => {
  fetchCheckouts().then(() => {
    goToCurrentHeader();
  });
});

const removeItem = (idx: number) => {
  checkouts.value.splice(idx, 1);
};

const editItem = (checkout: ICheckoutIndex, step?: string) => {
  checkoutId.value = checkout.id;
  createOpen.value = true;
  openStep.value = step;
};

const closeSetup = () => {
  createOpen.value = false;
  checkoutId.value = null;
  openStep.value = undefined;
};

const closeParamSetup = () => {
  paramsOpen.value = false;
  checkoutId.value = null;
};

const duplicateItem = (checkout: ICheckoutIndex) => {
  $confirm({
    message: t("checkout_duplicate_confirm", { checkout: checkout.name }),
    onConfirm: () => {
      setLoading(true);

      homeFetch(`workspace/checkouts/${checkout.id}/duplicate`, { method: "POST" })
        .then(fetchCheckouts)
        .finally(() => {
          setLoading(false);
        });
    },
    destructive: true,
  });
};

const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

const setLoading = (l: boolean) => {
  loading.value = l;
  emits("update:loading", l);
};
</script>

<script lang="ts">
export const useCheckoutSettings = (): ISettingsFlow => {
  const { t } = useI18n();
  const icon = "regular/CreditCard";
  const title = t("settings.checkout.title");
  const pageTitle = t("settings.checkout.page_title");
  const key = "checkouts";
  const condition = () => !useAuth().isDFM.value;
  const group = "setup";

  return {
    icon,
    pageTitle,
    title,
    key,
    condition,
    group,
  };
};
</script>
