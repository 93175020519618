<template>
  <div
    class="box-content aspect-square rounded-lg items-center justify-center flex text-center overflow-hidden flex-shrink-0 relative select-none"
    :class="{
      'h-5 w-5 rounded-md': size === '3xs',
      'h-7 w-7 rounded-md': size === '2xs',
      'h-9 w-9  rounded-lg': size === 'xs',
      'h-11 w-11 rounded-[10px]': size === 'sm',
      'h-[52px] w-[52px] rounded-xl': size === 'md',
      'h-[60px] w-[60px] rounded-2xl': size === 'lg',
      'h-[68px] w-[68px] rounded-[18px]': size === 'xl',
      'bg-utility-tangerine text-utility-tangerine-darkest': color === 'tangerine' && !image,
      'bg-utility-raspberry text-utility-raspberry-darkest': color === 'raspberry' && !image,
      'bg-utility-lemon text-utility-lemon-darkest': color === 'lemon' && !image,
      'bg-utility-grape text-utility-grape-darkest': color === 'grape' && !image,
      'bg-utility-kiwi text-utility-kiwi-darkest': color === 'kiwi' && !image,
    }"
  >
    <slot>
      <NuxtImg class="h-auto w-full text-center" :src="image" :alt="entity?.name" v-if="image" />
      <div
        class="text-inherit font-bold"
        :class="{
          'text-2xs': size === '3xs',
          'text-xs': size === '2xs',
          'text-base': size === 'xs',
          'text-lg': size === 'sm',
          'text-[21px]': size === 'md',
          'text-2xl': size === 'lg',
          'text-3xl': size === 'xl',
        }"
        v-else
      >
        {{ twoLetterName }}
      </div>
      <div class=" h-full w-full absolute rounded-[inherit]"
      :class="{
        'shadow-avatar-border-xs': size === '3xs',
        'shadow-avatar-border-sm': size === '2xs',
        'shadow-avatar-border-md': size === 'xs',
        'shadow-avatar-border-lg': size === 'sm',
        'shadow-avatar-border-xl': size === 'md' || size === 'lg' || size === 'xl',
      }"
      v-if="!image"></div>
    </slot>
  </div>
</template>

<script setup lang="ts">
  export interface AvatarProps {
    size?: "3xs" | "2xs" | "xs" | "sm" | "md" | "lg" | "xl";
    entity?: {
      name?: string;
      email?: string;
      image?: string | null | File;
    };
    useLetters?: boolean;
    color?: "tangerine" | "raspberry" | "lemon" | "grape" | "kiwi";
    randomColor?: boolean;
    index?: number;
    maxLetters?: 1 | 2;
  }


const props = withDefaults(
  defineProps<AvatarProps>(),
  {
    size: "md",
    useLetters: true,
    color: "grape",
    maxLetters: 2,
  }
);

const image = ref<string | null>(null);

watchEffect(() => {
  let img = props.entity?.image || (props.useLetters ? null : "/images/logo.png");

  if (process.client) {
    if (img instanceof File) {
      //if img is file, convert to url
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result === "string") {
          image.value = result;
        }
      };
      reader.readAsDataURL(img);
    } else {
      image.value = img;
    }
  } else if (img) {
    image.value = img;
  }
});

const color = computed(() => {
  //if randomColor is true, return random color if index is not provided, else return color based on index, if index is too high, start over from 0
  if (props.randomColor) {
    const colors = ["tangerine", "raspberry", "lemon", "grape", "kiwi"];
    if (props.index) return colors[props.index % colors.length];

    //hash the name to get a number
    let hash = props.entity?.name ? props.entity.name.split("").reduce((a, b) => a + b.charCodeAt(0), 0) : 0;

    //reduce the hash to a number between 0 and 4
    let i = 0;
    while (hash > 4) {
      hash = hash % 5;
      i++;
    }

    return colors[hash];
  }

  if (props.color) return props.color;
});

const twoLetterName = computed(() => {
  if (!props.entity?.name && !props.entity?.email) return "";
  const name = props.entity?.name || props.entity?.email;
  //split name on space or - and get first letter of each word
  const nameArray = name.split(/[\s-]/);
  const firstLetter = nameArray[0].charAt(0);

  if (nameArray.length === 1 || props.maxLetters == 1) return firstLetter.toUpperCase();
  const secondLetter = nameArray[1].charAt(0);
  return (firstLetter + secondLetter).toUpperCase();
});
</script>
