export default () => {
  const { t } = useI18n();
  const icon = 'warehouse';
  const color = 'mocca';
  const title = t('boarding.setup_external_warehouse.title');
  const description = t('boarding.setup_external_warehouse.description');
  const key = 'external_warehouse';

  return {
    icon,
    color,
    title,
    description,
    key,
  };
}