import EventBus from "@/plugins/eventbus";
import { inject, ref } from "vue";
import type { INewReturnService } from "./types";
import type { IShipment } from "~/types/shipment";

declare global {
	const $newreturn : INewReturnService
}

export const open = ref(false);

/**
 * A unique identifier to access the provided/injected method
 */
export const NewReturnSymbol = Symbol();

/**
 * Composable which allows accessing the NewReturn service in Composition API
 * @returns NewReturn
 */
export function useNewReturn() {
  const NewReturn = inject(NewReturnSymbol);
  if (!NewReturn) {
    throw new Error("No NewReturn provided!");
  }

  return NewReturn;
}

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const NewReturnService = {
  install: (app) => {
    const NewReturnService: INewReturnService = {
      toggle: () => {
        EventBus.$emit("NEWRETURN_TOGGLE");
      },
      open: () => {
        EventBus.$emit("NEWRETURN_OPEN");
      },
      add: (item) => {
        EventBus.$emit("NEWRETURN_ADD", item);
      },
      close: () => {
        EventBus.$emit("NEWRETURN_CLOSE");
      },
    };
    app.config.globalProperties.$NewReturn = NewReturnService;
    if (typeof window !== "undefined") window.$newreturn = NewReturnService;
    app.provide(NewReturnSymbol, NewReturnService);
  },
};