<template>
  <Modal v-model:open="open" maxWidth="410px" minWidth="410px" :contentPadding="false" :center="true" anchor="top" ref="modal">
    <div
      class="relative"
      :class="{
        'p-2 overflow-auto': !pending && hasPrinters,
        'p-4': !pending && !hasPrinters,
        'min-h-48': pending,
        'max-h-96': !pending && hasPrinters,
      }"
    >
      <Overlay v-if="pending"> </Overlay>
      <template v-else>
        <div v-if="hasPrinters" class="space-y-2">
          <ButtonListSelector
            v-for="printer in printers"
            :key="printer.value"
            @click="() => print(printer.value)"
            size="large"
            :title="printer.label"
            :subtitle="printer.data?.default ? t('default') : ''"
            icon="printer"
          >
          </ButtonListSelector>
        </div>
        <div v-if="!printers.length && !pending">
          <p class="text-secondary text-body-default mb-2">{{ t("print.no_printers") }}</p>
          <p class="text-secondary text-body-default">{{ t("print.to_print") }}</p>
          <div class="flex w-full gap-3 mt-8">
            <Button @click="() => open = false" variant="default" size="default" class="ml-auto">{{ t("cancel") }}</Button>
            <Button @click="() => openSettings()" variant="primary" size="default">{{ t("go_to_settings") }}</Button>
          </div>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script setup lang="ts">
const { t } = useI18n();

const props = defineProps<{
  package_number: string;
  selectAll: boolean;
  selected: string[] | number[];
  filters: { [key: string]: string | number | boolean };
}>();

import { open } from "../service";

const { getPrinters } = usePrinters();

const { data: printers, pending, hasPrinters } = await getPrinters();

const { printLabel, printBulkLabels } = usePrinters();

const print = (printer: any) => {
  if (props.package_number) printLabel(printer, props.package_number);
  else printBulkLabels(printer, props?.selectAll, props?.selected, props?.filters);
};

const openSettings = () => {
  window.$workspace_settings.start('printers');
};
</script>

<style>
.input_title {
  @apply text-left text-body-sm-heavy mb-1 mt-4;
}
</style>
