<template>
  <div class="flex flex-col gap-10 @container" ref="flowWrapper">
    <div class="py-7 @xl:py-14 px-5 @xl:px-10 rounded-xl flex flex-col @xl:flex-row gap-3 @xl:gap-6 items-center justify-between"
    :class="{
      'bg-utility-grape': isPickup,
      'bg-utility-mocca': !isPickup,
    }">
      <div class="flex flex-col gap-1">
        <div class="text-body-default-heavy">{{t('settings.plan.account_is_using')}}</div>
        <div class="text-title-screen text-primary">{{isPickup ? t('pickup') : t('hand_in')}}</div>
      </div>
      <Button variant="primary-link" @click="() => showChangeModal = true"> {{t('settings.plan.change')}} </Button>
    </div>
    <PlanPickup v-if="isPickup" @update:loading="setLoading"/>
    <PlanHandIn v-else />
  </div>
  <SwitchModal v-model:open="showChangeModal" :isPickup="isPickup"/>
</template>

<script setup lang="ts">
import type { ISettingsFlow } from "../types";
import PlanPickup from "../components/Plans/Pickup.vue";
import PlanHandIn from "../components/Plans/HandIn.vue";
import SwitchModal from "../components/Plans/SwitchModal.vue";
const props = withDefaults(
  defineProps<{
    currentHeader?: string | null;
  }>(),
  {
    currentHeader: null,
  }
);
const { t } = useI18n();

const isPickup = computed<boolean>(() => {
  return useAuth().accountLevel.value === 'SMV'
});

const showChangeModal = ref(false);


const emits = defineEmits(["update:loading"]);
const setLoading = (l: boolean) => emits("update:loading", l);

const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

</script>

<script lang="ts">
export const usePlanSettings = (): ISettingsFlow => {
  const { t } = useI18n();
  const icon = "regular/CheckSquareOffset";
  const title = t("settings.plan.title");
  const pageTitle = t("settings.plan.page_title");
  const key = "plan";
  const condition = () => {
    return !useAuth().isDFM.value && useAuth().accountLevel.value !== 'ENTERPRISE' && useAuth().accountLevel.value !== 'SCALE_UP';
  };

  return {
    icon,
    pageTitle,
    title,
    key,
    condition
  };
};
</script>
