<template>
    <div class="relative">
        <label v-if="label" class="text-body-sm-heavy text-left text-secondary">{{ label }}</label>
        <div id="editorWrapper">
            <QuillyEditor
                ref="editor"
                v-model="text"
                :options="options"
                @update:model-value="onModelValueChange"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import Quill from 'quill' // Core build
    const props = defineProps<{
        name: string;
        label?: string;
    }>();

    const editor = ref()
    const model = ref<string>('')

    const text = ref<string>('')

    let quill: Quill | null = null

    const options = ref({
    theme: 'snow',
    bounds: '#editorWrapper',
    modules: {
        toolbar: [
        ['bold', 'italic', 'underline'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }]
        ]
    },
    readOnly: false
    })

    /*const options = {
        bounds: "#editorWrapper",
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline'],
                ['link'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ]
        },
    }*/

    const onModelValueChange = (value: string) => {
        inputValue.value = quill.getSemanticHTML();
    }

    onMounted(() => {
        quill = editor.value?.initialize(Quill)!
        if(inputValue.value) {
            text.value = inputValue.value
        }
    })

    const {
    value: inputValue,
    } = useField(() => props.name, undefined, {
        syncVModel: true,
    });


</script>

<style>
.ql-toolbar.ql-snow {
    border-bottom: none;
    @apply border-x border-t border-00 rounded-t-[10px] bg-surface-lvl-00 !p-0.5;
}
.ql-editor {
    @apply !bg-00 !max-h-[300px] !min-h-[200px] overflow-auto !px-4 text-body-default text-primary border-00 border-x border-t rounded-b-xl placeholder:text-quarterary  hover:placeholder:text-tertiary read-only:text-inherit;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    @apply border-none relative w-full min-h-[100px] min-w-full;
}

.ql-snow.ql-toolbar button svg {
    @apply !text-foreground-secondary !h-5 !w-5 ;
}
.ql-formats {
    @apply !m-0 gap-0.5 max-w-max;
}
.ql-snow.ql-toolbar button {
    @apply !h-7 !w-7 text-secondary hover:!text-primary flex items-center hover:bg-01 active:bg-02 rounded-lg m-0 mr-0.5 transition-all duration-300;
}

.ql-snow.ql-toolbar button.ql-active {
    @apply !bg-02 !text-primary transition-all duration-300;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
    @apply !stroke-foreground-primary transition-all duration-300;
}
.ql-snow.ql-toolbar button.ql-active .ql-fill {
    @apply !fill-foreground-primary transition-all duration-300;
}
.ql-snow.ql-toolbar button .ql-stroke {
    @apply !stroke-foreground-primary transition-all duration-300;
}
.ql-snow.ql-toolbar button .ql-fill {
    @apply !fill-foreground-primary transition-all duration-300;
}
.ql-tooltip .ql-editing {
    @apply !left-0;
}
</style>