<template>
    <div class="flex flex-col gap-5 min-h-0 w-full h-full rounded-xl overflow-auto px-2 pb-3 max-w-[556px] mx-auto">
        <div class="m-auto">
            <p class=" w-full text-center text-secondary text-title-section mb-5">{{ t("select_checkout") }}</p>
            <div v-if="checkouts.checkouts.length !== 0" class="h-full w-full overflow-auto max-h-[400px]">
                <ListSelector v-model="selectedCheckout" @fullOption="val => setCheckout(val)" :values="checkouts.checkouts" radioPosition="top">
                    <template #title="{ value }">
                        <div class="w-full">
                            <p class="text-body-lg-heavy text-secondary">{{ value.name }}</p>
                            <p class="text-tertiary text-body-default">{{ t('created_at') }} {{ DateTime.fromISO(value.created_at).toFormat('DDD') }}</p>
                        </div>
                    </template>
                </ListSelector>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
const { t } = useI18n();
const props = defineProps<{
    checkouts: any[];
}>();

const selectedCheckout = ref(null);

onMounted(() => {
    if (checkout.value) {
        selectedCheckout.value = checkout.value.id;
    }
})

const setCheckout = (val: any) => {
    checkout.value = val.id;
    selectedCheckout.value = val.id;
}

const { value: checkout } = useField(() => "settings.checkout_id", undefined, {
    syncVModel: true,
});
</script>