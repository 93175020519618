<template>
  <div
    @click="openLink(file.link)"
    class="px-3  rounded-lg shadow-surface-lvl-01 shadow-lvl-01 border h-7 border-01 flex items-center w-full bg-surface-lvl-00 transition-all duration-300 hover:bg-00"
    :class="{
        'cursor-pointer': file.link,
        'cursor-default': !file.link,
    }"
    >
    <Icon src="file_text" size="medium" class="mt-0.5 text-secondary"></Icon>
    <p class="text-secondary text-body-default-heavy mx-1.5 truncate">{{ file.name }}</p>
    <p class="text-quarterary text-body-default">{{ formattedSize(file.size) }}</p>
    <div v-if="canRemove" v-on:click.stop class="ml-auto">
      <Icon @click="emit('removeFile', file.name)" src="close" size="small" class="mt-0.5 text-quarterary"></Icon>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  file: {
    type: Object,
    default: () => ({}),
  },
  canRemove: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["removeFile"]);

const openLink = (link: string) => {
  if (!link) return;
  window.open(link, "_blank");
};

const formattedSize = (size: number) => {
  if (!size) {
    return "";
  }
  const units = ["B", "KB", "MB", "GB", "TB"];
  let i = 0;
  while (size >= 1024 && i < units.length - 1) {
    size /= 1024;
    i++;
  }
  return `${size.toFixed(2)} ${units[i]}`;
};
</script>
