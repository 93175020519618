<template>
  <VueFinalModal
    class="flex items-center justify-center p-5"
    v-model="open"
    contentClass="overflow-visible rounded-xl max-w-screen-md w-full"
    overlayClass="bg-modal-overlay"
    overlayTransition="vfm-fade"
    contentTransition="vfm-fade"
    clickToClose
    @closed="onClosed"
    :escToClose="false"
  >
    <div ref="modalContent" class="relative w-full rounded-xl">
      <GlobalSearchContainer
        class="relative w-full gap-1.5 bg-surface-lvl-00 p-1.5 pb-0 shadow-lvl-03"
      >
        <GlobalSearchInput
          v-model="search"
          :show="open"
          ref="searchInput"
          @keydown.down.prevent="() => arrowPress('down')"
          @keydown.up.prevent="() => arrowPress('up')"
        />
        <div
          class="overflow-auto transition-all"
          :style="{
            maxHeight,
          }"
        >
          <Transition name="scale-height" mode="out-in">
            <div v-if="showResults">
              <GlobalSearchCategory
                :category="key as SearchResultCategory"
                v-for="(category, key) in groupedResults"
                :key="key"
              >
                <GlobalSearchResult
                  v-for="result in category"
                  :key="result.id"
                  :result="result"
                  :search="search"
                  @keydown.down.prevent="() => arrowPress('down')"
                  @keydown.up.prevent="() => arrowPress('up')"
                />
              </GlobalSearchCategory>
            </div>
            <div
              v-else-if="showNoResults"
              class="flex h-[182px] w-full flex-col items-center justify-center gap-2"
            >
              <img
                src="~/assets/illustrations/cc-lighthouse.svg"
                alt="No shipments illustration"
              />
              <div class="flex flex-col gap-0.5 text-center">
                <div class="text-body-lg-heavy">
                  {{ t("no_search_results") }}
                </div>
                <div class="text-body-default text-tertiary">
                  {{ t("no_search_results_description") }}
                </div>
              </div>
            </div>
          </Transition>
        </div>

        <div class="sticky bottom-0 z-10 bg-inherit px-4 py-1.5 pb-3">
          <div class="flex items-center gap-8 text-quarterary">
            <div class="flex items-center gap-1.5">
              <div>
                <Icon src="bold/ArrowsDownUp" />
              </div>
              <div class="text-body-default">
                {{ t("select") }}
              </div>
            </div>

            <div class="flex items-center gap-1.5">
              <div>
                <Icon src="bold/ArrowUDownLeft" />
              </div>
              <div class="text-body-default">
                {{ t("open") }}
              </div>
            </div>
            <div class="flex items-center gap-1.5">
              <div
                class="flex h-4 items-center text-body-default-heavy"
              >
                esc
              </div>
              <div class="text-body-default">
                {{ t("close_search") }}
              </div>
            </div>
            <div class="flex items-center gap-1.5">
              <div
                class="flex h-4 items-center text-body-default-heavy"
              >
                ⌘K
              </div>
              <div class="text-body-default">
                {{ t("open_search") }}
              </div>
            </div>
          </div>
        </div>
      </GlobalSearchContainer>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
  import _ from "lodash";
  import { VueFinalModal } from "vue-final-modal";
  import type {
    SearchResult,
    SearchResultCategory,
    SearchResultType,
  } from "~/types/search";

  defineOptions({
    name: "GlobalSearch",
  });

  const { t } = useI18n();

  const open = ref(false);
  const results = ref<SearchResult[] | null>(null);
  const groupedResults = computed<
    Record<SearchResultCategory, SearchResult[]>
  >((): Record<SearchResultCategory, SearchResult[]> => {
    if (results.value === null) {
      if (recentSearches.value?.length) {
        return {
          recent: recentSearches.value,
        };
      }
      return {} as Record<SearchResultCategory, SearchResult[]>;
    }

    return _.groupBy(results.value, "type") as Record<
      SearchResultCategory,
      SearchResult[]
    >;
  });
  const searchInput = ref<HTMLInputElement | null>(null);
  const search = ref("");
  const { accountId } = useAuth();

  const hasSearch = computed(() => search.value.length >= 3);

  const showResults = computed(() => {
    if (!hasSearch.value && recentSearches.value?.length) return true;
    return hasSearch.value && results.value?.length;
  });

  const showNoResults = computed(() => {
    return hasSearch.value && results.value?.length === 0;
  });

  const recentSearches = ref<SearchResult[]>([]);

  const maxHeight = computed(() => {
    if (showResults.value) {
      return "636px";
    } else if (showNoResults.value) {
      return "290px";
    }
    return "102px";
  });
  const onClosed = () => {
    search.value = "";
    results.value = null;

    //remove listener to localstorage
    window.removeEventListener("storage", (e) => {
      if (e.key === "recentSearches") {
        recentSearches.value = getRecentSearches();
      }
    });
  };

  const toggleModal = () => {
    open.value = !open.value;

    if (open.value) {
      recentSearches.value = getRecentSearches();

      //add listener to localstorage
      window.addEventListener("storage", (e) => {
        if (e.key === "recentSearches") {
          recentSearches.value = getRecentSearches();
        }
      });

      setTimeout(() => {
        searchInput.value?.focus();
      });
    } else {
      onClosed();
    }
  };

  const onKeyPress = (e: KeyboardEvent) => {
    //if development environment, use cmd+k to open the modal
    if (accountId.value !== 7) return;
    if (getOS() === "Mac") {
      if (e.key === "k" && e.metaKey) {
        toggleModal();
        e.preventDefault();
      }
    } else {
      if (e.key === "k" && e.ctrlKey) {
        toggleModal();
        e.preventDefault();
      }
    }

    if (e.key === "Escape" && open.value) {
      if (search.value.length > 0) {
        search.value = "";
      } else {
        toggleModal();
      }
      e.preventDefault();
    }
  };

  const arrowPress = (direction: "up" | "down") => {
    if (results.value === null) return;

    const currentActive = document.activeElement as HTMLElement;

    const currentActiveIndex = results.value.findIndex((result) => {
      const identifier = `${result.type}_${result.id}`;
      return identifier === currentActive.dataset["result"];
    });

    if (currentActiveIndex === -1) {
      results.value[0].identifier;
    }

    let nextIndex =
      currentActiveIndex + (direction === "down" ? 1 : -1);
    if (nextIndex < 0) {
      nextIndex = results.value.length - 1;
    } else if (nextIndex >= results.value.length) {
      nextIndex = 0;
    }

    const nextActive = document.querySelector(
      `[data-result="${results.value[nextIndex].type}_${results.value[nextIndex].id}"]`
    ) as HTMLElement;
    nextActive.focus();
  };

  const doSearch = () => {
    if (search.value.length < 3) {
      return;
    }
    homeFetch("search", {
      query: {
        search: search.value,
      },
    }).then(({ data }) => {
      results.value = data;
    });
  };

  const debouncedSearch = _.debounce(doSearch, 300);

  //watch the search value and debounce the search
  watch(search, () => {
    if (search.value.length < 3) {
      results.value = null;
      return;
    }
    debouncedSearch();
  });

  onMounted(() => {
    document.addEventListener("keydown", onKeyPress);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", onKeyPress);
  });

  useRouter().beforeEach(() => {
    if (open.value) {
      toggleModal();
    }
  });
</script>
