<template>
    <div class="flex flex-col gap-6 @container" ref="flowWrapper">
        <div class="flex gap-3 justify-between flex-col w-full">
            <div class="flex-col gap-0.5">
                <div class="text-body-lg-heavy settings-flow-header">
                    {{ t("email") }}
                </div>
            </div>
            <div class="grid grid-cols-2 gap-3 auto-cols-fr">
                <div class="col-span-2">
                    <TickerCheckbox
                        :label="t('user_settings.notifications.confirm_marketing')"
                        v-model="marketingConsent" 
                        @change="onMarketingConsentChange"
                    />
                </div>
            </div>
        </div>
    </div>
    <NotificationWrapper />
</template>

<script setup lang="ts">
import _ from "lodash";
import type { ISettingsFlow } from "../types";
import NotificationWrapper from "../components/NotificationWrapper.vue";
import type { IUser } from "~/types/auth";

const props = withDefaults(
    defineProps<{
        currentHeader?: string | null;
        loading?: boolean;
    }>(),
    {
        currentHeader: null,
    }
);
const { t } = useI18n();
const information = ref<IUser>({} as IUser);
let initialInformation = {} as IUser;

const marketingConsent = computed({
    get: () => !!information.value.marketing_consent,
    set: (value: boolean) => {
        information.value = {
            ...information.value,
            marketing_consent: value ? 1 : 0,
        };
    },
});

const fetchInformation = async () => {
    setLoading(true);
    return homeFetch("settings/profile")
        .then((response) => {
            if (response?.data) {
                information.value = { ...response.data?.profile } as IUser;
                initialInformation = _.cloneDeep(information.value);
            }
        })
        .finally(() => {
            setLoading(false);
        });
};

const onMarketingConsentChange = () => {
    updateInformation();
};

const updateInformation = () => {
    setLoading(true);
    const marketing_consent = marketingConsent.value ? 1 : 0;

    homeFetch("settings/profile/marketing", {
        method: "PUT",
        body: { 
            consent: marketing_consent 
        }
    })
    .then(() => {
        initialInformation = _.cloneDeep(information.value);
    })
    .finally(() => {
        setLoading(false);
    });
};

onMounted(() => {
    fetchInformation().then(() => {
        goToCurrentHeader();
    });
});

const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

const emits = defineEmits(["update:loading"]);
const setLoading = (l: boolean) => emits("update:loading", l);
</script>

<script lang="ts">
export const useNotificationSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const icon = "regular/Notification";
    const title = t("user_settings.notifications.title");
    const pageTitle = t("user_settings.notifications.title");
    const key = "notifications";
    const headers = [t("email")];

    return {
        icon,
        pageTitle,
        title,
        key,
        headers,
    };
};
</script>
