<template>
  <div class="flex flex-col">
    <div class="p-1">
      <div class="flex gap-3 p-3 rounded-xl items-center cursor-pointer hover:bg-00 hover:border-image group" @click="emit('toggle')">
        <Button variant="tertiary" type="button" :active="active" icon-only size="small">
          <Icon
            src="bold/CaretDown"
            class="transform transition-all duration-300"
            :class="{
              '-rotate-90': !active,
            }"
          />
        </Button>
        <Avatar
          :entity="{
            image: getCarrierLogoUrl(shippingMethod.carrier),
          }"
        />
        <div class="flex flex-col flex-grow justify-center self-stretch group-hover:text-primary">
          <div class="text-body-lg-heavy">
            {{ name }}
          </div>
          <div class="text-body-default text-quarterary">
            {{ description }}
          </div>
        </div>
        <div class="flex gap-2 flex-shrink-0 items-center">
          <template v-if="active && rulesHaveChanges">
            <Button variant="destructive" type="button" @click.stop.prevent="undo">
              {{ t("undo_changes") }}
            </Button>
          </template>
          <div class="text-body-default text-tertiary">
            {{ t("n_rules", { rules: shippingMethod.rules.length }, shippingMethod.rules.length) }}
          </div>
        </div>
      </div>
    </div>
    <template v-if="active">
      <Divider :spacing="false"></Divider>
      <div class="px-5 py-2.5 flex gap-3">
        <InputText v-model="name" class="flex-grow" :label="t('name')" />
        <InputText v-model="description" class="flex-grow" :label="t('description')" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";

const props = withDefaults(
  defineProps<{
    active: boolean;
    shippingMethod: ICheckoutShippingMethod;
    name: string | null;
    description: string | null;
  }>(),
  {
    shippingMethod: () => {},
  }
);

const name = defineModel('name');
const description = defineModel('description');

let originalName = ref<string>("");
let originalDescription = ref<string>("");
let orginalRules = ref<IRule[]>([]);

onMounted(() => {
  originalName.value = name.value;
  originalDescription.value = description.value;
  orginalRules.value = props.shippingMethod.rules;
});

const rules = computed(() => {
  return props.shippingMethod.rules;
});

const rulesHaveChanges = computed(() => {
  return (
    !_.isEqual(rules.value, orginalRules.value) ||
    name.value !== originalName.value ||
    description.value !== originalDescription.value
  );
});

const undo = () => {
  name.value = originalName.value;
  description.value = originalDescription.value;

  emit("undo");
};

const { t } = useI18n();
const emit = defineEmits(["toggle", "undo", "save", "update:name", "update:description"]);
</script>
