<template>
  <div
    ref="el"
    class="group-[.has-scrollbar]/bar:text-xs text-sm flex group-[.has-scrollbar]/bar:gap-0 gap-1 font-medium w-fit cursor-pointer whitespace-nowrap group/tab h-full relative items-center"
    :class="{
      'text-secondary': !active,
      'text-primary active': active,
    }"
  >
    <div class="flex gap-1 items-center" tabindex="0">
      <Icon :src="view?.icon" v-if="view?.icon"/>
      <slot name="prefix"></slot>
      <slot></slot>
      <div class="rounded-full border border-02 px-2 text-body-sm font-medium" v-if="count !== undefined ">{{ count }}</div>
    </div>
    <div
      v-if="variant === 'default'"
      class="absolute bottom-0 w-full group-hover/tab:border-02 group-hover/tab:border"
      :class="{
        '!border !border-inversed-00': active,
      }"
    ></div>
  </div>
</template>

<script setup lang="ts">
defineOptions({
  name: "Tab",
});

const el = ref<HTMLElement>();

const props = withDefaults(
  defineProps<{
    variant?: "default" | "nude";
    active?: boolean;
    view?: any;
    count?: number;
  }>(),
  {
    variant: "default",
    active: false,
    view: null,
  }
);

const emits = defineEmits(["active"]);

watchEffect(() => {
  if (props.active) {
    emits("active", el);

    if (process.client) {
      //create a custom event to emit
      const event = new CustomEvent("active", {
        detail: {
          tab: el.value,
        },
      });

      //dispatch the event
      el.value?.dispatchEvent(event);
    }
  }
});

defineExpose({
  el,
});
</script>
