<template>
  <div class="flex flex-col gap-3">
    <div class="flex items-center gap-3">
      <div class="">
        <div class="flex items-center gap-2 mb-1">
          <p class="text-title-subsection truncate text-secondary">{{ portal.name }}</p>
        </div>
        <p Class=" text-quarterary text-body-sm ">
          {{ DateTime.fromISO(portal.created_at).toFormat("DDD") }} <span v-if="isLegacy"> • {{
            t("legacy_return_portal") }}</span>
        </p>
      </div>
      <div class="flex items-center gap-2 ml-auto">
        <Tooltip :text="t('go_to_returnportal')">
          <a :href="portal.endpoint" target="_blank" rel="noopener noreferrer">
            <Button variant="tertiary" iconOnly>
              <Icon src="arrow_up_right" />
            </Button>
          </a>
        </Tooltip>
        <DropdownSelect v-if="!isLegacy" trigger-button-variant="tertiary" @select="val => selectCheckout(val)"
          class="ml-auto mr-2" v-model="selectedCheckout" :triggerText="t('add_checkout')" :options="checkouts" />

        <DropdownActions triggerIconPrefix="bold/DotsThreeVertical" triggerText="" :actions="actions"
          buttonSize="default" />
      </div>
    </div>
    <div v-if="!isLegacy" class="grid grid-cols-3 gap-3">
      <div class="h-24 flex flex-col justify-between border border-00 rounded-xl p-3">
        <p class="text-body-sm text-quarterary">
          {{ t("Webaddress") }}
        </p>
        <Tooltip :text="portal.customer_url ?? portal.endpoint">
          <p class="ml-auto text-secondary text-body-lg-heavy truncate w-full">{{ portal.customer_url ?? portal.endpoint
            }}</p>
        </Tooltip>
      </div>
      <div class="h-24 flex flex-col justify-between border border-00 rounded-xl p-3">
        <p class="text-body-sm text-quarterary">
          {{ t("carriers_activated") }}
        </p>
        <AvatarGroup v-if="mappedCarriers && mappedCarriers.length" :entities="mappedCarriers" size="2xs" />
        <Tooltip v-else :text="t('no_carriers_activated')">
          <p class="ml-auto text-secondary text-body-lg-heavy truncate w-full">{{ t("no_carriers_activated") }}</p>
        </Tooltip>
      </div>
      <div class="h-24 flex flex-col justify-between border border-00 rounded-xl p-3">
        <p class="text-body-sm text-quarterary">
          {{ t("checkout") }}
        </p>
        <Tooltip v-if="portal.settings.checkout" :text="portal.settings.checkout.name">
          <p class="ml-auto text-secondary text-body-lg-heavy truncate w-full">{{ portal.settings.checkout.name }}</p>
        </Tooltip>
        <Tooltip v-else :text="t('no_checkout_added')">
          <p class="ml-auto text-secondary text-body-lg-heavy truncate w-full">{{ t("no_checkout_added") }}</p>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DateTime } from "luxon";
import type { IReturnPortal } from "~/types/returns";

const { t } = useI18n();

const emit = defineEmits(["update", "editPortal"]);

defineOptions({
  name: "ReturnPortalRow",
});

onMounted(() => { });

const props = withDefaults(
  defineProps<{
    portal: IReturnPortal;
    link: string;
    checkouts: { label: string; value: string; icon: string }[];

  }>(),
  {}
);

const isLegacy = computed(() => {
  return !props.portal.settings.smartcheckout;
});
const selectedCheckout = computed(() => {
  return props.portal?.settings?.checkout?.id;
});


const actionLoading = ref<boolean>(false);

const actions = computed(() => {
  return [
    {
      label: t("edit"),
      value: "edit",
      handler: () => emit("editPortal", props.portal),
    },
    {
      label: t("share"),
      value: "share",
      handler: copyLink,
    },
    null,
    {
      label: t("delete"),
      value: "delete",
      handler: deleteReturnPortal,
      destructive: true,
    },
  ];
});

const mappedCarriers = computed(() => {
  if (!props.portal?.settings?.checkout?.shipping_methods) {
    if (returnCarriers.value?.length) {
      return [{ name: returnCarriers.value, image: getCarrierLogoUrl(returnCarriers.value) }];
    }
    return [];
  }
  let carriers = [];
  for (const carrier of props.portal.settings.checkout.shipping_methods) {
    carriers.push({
      name: carrier.carrier_product_service.name,
      image: getCarrierLogoUrl(carrier.carrier_product_service.carrier),
    });
  }
  return carriers;
});

const copyLink = () => {
  copyToClipboard(props.portal.endpoint);
};

const deleteReturnPortal = () => {
  $confirm({
    message: t("return_portal_delete_confirm", { checkout: props.portal.name }),
    onConfirm: () => {
      homeFetch(`workspace/return-portals/${props.portal.uid}`, { method: "DELETE" })
        .then((response) => {
          $toast.add({
            title: t("portal_deleted"),
          });
          emit("update");
        })
        .finally(() => {
          actionLoading.value = false;
        });
    },
    destructive: true,
  });
};

const selectCheckout = (val: number) => {
  let shop = { ...props.shop };
  shop.checkout_id = val;
  homeFetch(`workspace/return-portals/${props.portal.uid}/attach-checkout`, {
    method: "PUT",
    body: {
      checkout_id: val
    }
  }).then((r) => {
    emit('update');
  });
};


const returnCarriers = computed(() => {
  return props.portal.product?.split("_")[0];
});
</script>
