<template>
    <div class="flex flex-col gap-5 min-h-0 w-full rounded-xl overflow-auto px-2 pb-3 max-w-[556px] m-auto realtive">
        <p class=" w-full text-center text-secondary text-title-section">{{ t("set_return_portal_legacy") }}</p>
        <DropdownSelect v-if="countries" :options="countries" size="large" triggerType="button" :label="t('country')" v-model="country" />
        <DropdownSelect v-if="sortedProducts" :options="sortedProducts" size="large" :label="t('product')" v-model="product" triggerType="button" />
        <div class="">
            <label class="text-body-sm-heavy">{{ t('return_portal_price') }}</label>
            <div class="flex gap-2 items-center mt-1">
                <InputText  size="large" class="!max-w-[100px]" name="price" />
                <DropdownSelectCurrency  size="large" class="!max-w-[100px]" v-model="currency" triggerType="button" />
            </div>
        </div>
        <Overlay v-if="loading" class="absolute top-0 left-0 h-full w-full"></Overlay>
    </div>
</template>
<script setup lang="ts">
const { t } = useI18n();
const loading = ref(false);
const products = ref([]);
const countries = ref(null);

const props = defineProps<{
    schema: any;
    values: any;
}>();

onMounted( async () => {
    await fetchCountries();
    await fetchProducts();
})

const fetchProducts = () => {
    loading.value = true;
    homeFetch(`/workspace/return-portals/products`)
    .then((r) => {
        products.value = r.data
    }).catch((r) => {
        loading.value = false;
    }).finally(() => {
        loading.value = false;
    });
}

const fetchCountries = () => {
    loading.value = true;
    homeFetch(`/workspace/return-portals/countries`)
    .then((r) => {
        countries.value = r.data.map((country: any) => {
            return {
                label: country.full_name,
                value: country.iso
            }
        });
    }).catch((r) => {
        loading.value = false;
    }).finally(() => {
        loading.value = false;
    });
}

const sortedProducts = computed(() => {
    if (!products.value[props.values.country]) {
        return null;
    }
    return products.value[props.values.country].map((product: any) => {
        return {
            label: product.title,
            value: product.value
        }
    });
})

const { value: country } = useField(() => "country", undefined, {
  syncVModel: true,
});

const { value: product } = useField(() => "product", undefined, {
  syncVModel: true,
});

const { value: currency } = useField(() => "currency", undefined, {
  syncVModel: true,
});


</script>