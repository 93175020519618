<template>
  <div class="w-full h-full flex items-center justify-center text-center">
    <div class="w-[600px] flex flex-col gap-6 items-center">
      <div v-if="hasIllustration" class="max-w-[413px]">
        <slot name="illustration" />
      </div>
      <div class="flex flex-col gap-2" v-if="hasTitle || hasDescription">
        <h2 class="text-[26px] font-medium text-primary" v-if="hasTitle">
          <slot name="title">
            {{ title }}
          </slot>
        </h2>
        <p class="text-base font-normal text-tertiary" v-if="hasDescription">
          <slot name="description">
            {{ description }}
          </slot>
        </p>
      </div>
      <div class="" v-if="hasActions">
        <slot name="actions" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title: string;
    description: string;
  }>(),
  {
    title: "No data available",
    description: "We could not find any data",
  }
);

const slots = useSlots();

const hasTitle = computed(() => slots.title !== undefined || props.title !== "");
const hasDescription = computed(() => slots.description !== undefined || props.description !== "");
const hasIllustration = computed(() => slots.illustration !== undefined);
const hasActions = computed(() => slots.actions !== undefined);
</script>
