import type {
  SearchResult,
  SearchResultCategory,
} from "~/types/search";

import _ from "lodash";

export const globalSearchResultTitle = (
  result: SearchResult,
  search?: string
): string[] => {
  const title = result.identifier.toString();
  if (!search) {
    return [title];
  }

  const index = title.toLowerCase().indexOf(search.toLowerCase());
  if (index === -1) {
    return [title];
  }

  //split title into 3 parts: before search, search, after search
  return [
    title.slice(0, index),
    title.slice(index, index + search.length),
    title.slice(index + search.length),
  ];
};

export const globalSearchResultSubtitle = (
  result: SearchResult
): string => {
  const resultType = result.type;

  const { t } = useNuxtApp().$i18n;
  switch (resultType) {
    case "shipments":
      return t("global_search.in_shipments");
    case "tickets":
      return t("global_search.in_tickets");
    case "transfers":
      return t("global_search.in_transfers");
    case "documents":
      return t("global_search.in_documents");
    default: {
      return assertNever(resultType);
    }
  }
};

export const globalSearchCategoryIcon = (
  result: SearchResultCategory
): string => {

  switch (result) {
    case "shipments":
      return "custom/regular/forsendelse";
    case "transfers":
      return "regular/ArrowsLeftRight";
    case "tickets":
      return "regular/ClipboardText";
    case "documents":
      return "regular/File";
    case "recent":
      return "regular/ClockCounterClockwise";
    default: {
      return assertNever(result);
    }
  }
};

export const globalSearchCategoryTitle = (
  category: SearchResultCategory
): string => {
  const { t } = useNuxtApp().$i18n;
  switch (category) {
    case "shipments":
      return t("shipment", 99);
    case "tickets":
      return t("ticket", 99);
    case "transfers":
      return t("sorting", 99);
    case "documents":
      return t("document", 99);
    case "recent":
      return t("recent_searches");
    default: {
      return assertNever(category);
    }
  }
};

export const globalSearchResultOnClick = (
  result: SearchResult
): {
  name: string;
  params?: { id: string };
  query?: Record<string, any>;
} | Function => {
  const resultType = result.type;

  switch (resultType) {
    case "shipments":
      return {
        name: "shipments-id",
        params: { id: result.id.toString() },
      };
    case "transfers":
      return {
        name: "sortings-id",
        params: { id: result.id.toString() },
      };

    case "tickets":
      return {
        name: "tickets-id",
        params: { id: result.id.toString() },
      };
    case "documents":
      //@ts-ignore
      return () => downloadDocument(result);
    default: {
      return assertNever(resultType);
    }
  }
};

export const addResultToRecentSearches = (result: SearchResult) => {
  const recentSearches = getRecentSearches();
  let newRecentSearches = recentSearches.filter((search) => !_.isEqual(search, result));
  newRecentSearches.unshift(result);

  if (newRecentSearches.length > 3) {
    newRecentSearches = newRecentSearches.slice(0, 3);
  }

  setRecentSearches(newRecentSearches);
}

export const getRecentSearches = (): SearchResult[] => {
  return JSON.parse(localStorage.getItem("recentSearches") || "[]");
}

export const setRecentSearches = (recentSearches: SearchResult[]) => {
  localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
}

export const checkIfSearchIsRecent = (result: SearchResult): boolean => {
  const recentSearches = getRecentSearches();
  return recentSearches.some((search) => _.isEqual(search, result));
}
