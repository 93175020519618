<template>

<form @submit.prevent="onSubmit">
    <slot name="hidden"></slot>
    <div class="flex mb-2 items-center">
      <slot name="image" />
    </div>
    <slot>
      <FormStep v-for="(schema, idx) in validationSchema">
        <slot :name="`step-${stepKey}`" :schema="schema">
          <FormBuilderVeeStep :schema="schema">
            <template #header>
              <slot :name="`step-${stepKey}-header`" />
            </template>

            <template v-for="field in schemaFields(schema)" #[`${field}`]>
              <slot :name="`step-${stepKey}-${field}`" :schema="schema" :field="field"></slot>
            </template>

            <template v-for="field in schemaFields(schema)" #[`${field}-label`]>
              <slot :name="`step-${stepKey}-${field}-label`" :schema="schema" :field="field"></slot>
            </template>
          </FormBuilderVeeStep>
        </slot>
      </FormStep>
    </slot>

    <div class="mt-5 space-y-3">
      <Button type="submit" class="w-full" :disabled="!canSubmit" variant="primary" size="large" :loading="loading" v-if="hasContinueButton">
        {{ continueButtonText }}
      </Button>

      <Button type="button" class="w-full" variant="default" size="large" @click="skip" v-if="hasSkip">{{ t("skip_this_step") }}</Button>

      <Button v-if="hasPrevious" type="button" class="w-full" @click="goToPrev" variant="tertiary" size="large">{{ t("back") }}</Button>
    </div>
    
  </form>

</template>

<script setup lang="ts">
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    submitButtonText: string,
    validationSchema: Record<string, any>[],
    loading: boolean,
  }>(),
  {
    submitButtonText: "",
    validationSchema: () => [],
    loading: false,
  }
);

const emit = defineEmits(["submit", "exit", "skip"]);
const { currentStepIdx, isLastStep, hasPrevious, hasSkip, stepKey, hasContinueButton, continueButtonText, schemaFields, goToPrev, form, canSubmit, skip } =
  useFormBuilder({
    submitButtonText: props.submitButtonText,
    validationSchema: props.validationSchema,
    emit,
  });

const { values, setValues, setFieldValue } = form;
</script>
