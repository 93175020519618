<template>
    <div class="flex flex-col gap-5 min-h-0 w-full h-full rounded-xl px-2 pb-3 max-w-[556px] mx-auto">
        <p class=" w-full text-center text-secondary text-title-section">{{ t("portal_causes_title") }}</p>
        <div>
            <InputText size="large" v-model="causesString" :placeholder="t('create_more_with_comma')" @keypress.enter.stop.prevent="addCauses(causesString)" >
                <template #suffix>
                    <Button :disabled="causesString == ''" type="button" @click="addCauses(causesString)">{{ t('create_cause') }}</Button>
                </template>
            </InputText>
            <Callout type="ghost" icon="WarningDiamond" class="mt-1">
                {{ t("portal_causes_desc") }}
            </Callout>
        </div>
        <div>
            <p class=" text-quarterary text-body-default mb-1">{{ t("recommended_causes") }}</p>
            <div class="flex flex-wrap gap-1.5">
                <Badge v-for="cause in filteredCauses" :key="cause" class="cursor-pointer" @click="addCauses(cause)">
                    <p class="">{{ cause }}</p>
                </Badge>
            </div>
        </div>
        <div class=" overflow-auto">
            <p class="mb-1 text-quarterary text-body-default">{{ t('selected_causes') }}</p>
            <VueDraggableNext :list="causes" ghost-class="sortable-ghost" class=" overflow-auto">
                <div v-for="(cause, index) in causes">
                    <Divider class="mb-0.5" :class="index !== 0 ? 'mt-0.5': '' " :spacing="false" />
                    <div class="flex items-center gap-3 py-1.5 px-3 cursor-grab">
                        <Icon src="bold/dots_nine" class=""/>
                        <span>{{ cause.text }}</span>
                        <Button type="button" variant="tertiary" size="small" iconOnly class="ml-auto" @click="removeCause(index)">
                            <Icon src="filled/Close" class="text-foreground-secondary"  />
                        </Button>
                    </div>
                </div>
            </VueDraggableNext>
        </div>
    </div>
</template>

<script setup lang="ts">
import { VueDraggableNext } from 'vue-draggable-next'
const { t } = useI18n();
const props = defineProps<{
    schema: any;
    values: any;
}>();
const maxId = ref(1);
const causesString = ref("");
const recomendedCauses = ref([t('recommended_return_causes.to_large'), t('recommended_return_causes.to_small'), t('recommended_return_causes.regret_purchase'), t('recommended_return_causes.wrong_order'), t('recommended_return_causes.wrong_item') ]);
const filteredCauses = computed(() => {
    return recomendedCauses.value.filter((cause) => {
        return !causes.value?.some((c) => c.text == cause);
    });
});

const addCauses = (cause: string) => {
    if (!causes.value) {
        causes.value = [];
    }
    if (cause == "") {
        return;
    }
    if (cause.includes(",")) {
        cause.split(",").forEach((c) => {
            let id = ++maxId.value;
            causes.value.push({id: id, text: c.trim()});
        });
        causesString.value = "";
        return;
    }
    let id = ++maxId.value;
    causes.value.push({id: id, text: cause});
    causesString.value = "";
}
const removeCause = (index: number) => {
    causes.value.splice(index, 1);
}

const {
    value: causes,
} = useField(() => 'settings.causes', undefined, {
    syncVModel: true,
});
</script>