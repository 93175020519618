<template>
  <div class="w-full relative flex items-center gap-3">
    <Button type="button" size="small" :disabled="moveIndex == 1" iconOnly @click="scroll('minus')">
      <Icon src="arrow_left" size="medium" class="" :class="moveIndex == 1 ? 'text-disabled' : 'text-foreground-secondary'"></Icon>
    </Button>
    <div ref="slider" id="slider" class="w-full flex gap-5 transition-all duration-300 snap-x overflow-x-auto scroll-smooth group">
      <div
        class="min-w-[180px] h-auto rounded-lg transition-all duration-300 py-4 px-3 cursor-pointer snap-center"
        :class="selectedIndex == index ? 'shadow-inset-01+01' : 'shadow-inset-00+01 '"
        v-for="(item, index) in items"
        @click="updateSelection(item, index)"
      >
        <slot name="content" :item="item"></slot>
      </div>
    </div>
    <Button type="button" size="small" :disabled="isAtEnd" iconOnly @click="scroll('add')">
      <Icon src="arrow_right" size="medium" class="" :class="isAtEnd ? 'text-disabled' : 'text-foreground-secondary'"></Icon>
    </Button>
  </div>
</template>

<script setup lang="ts">
defineOptions({
  name: "Slider",
});

const props = defineProps<{
  items: Array<any>;
  deselectItem: boolean;
  preSelect?: boolean;
}>();

const moveIndex = ref(1);
const selectedIndex = ref<number | null>(null);
const slider = ref<HTMLElement>();
const sliderWidth = ref(0);
const currentScroll = ref(0);
let resizeObserver: ResizeObserver;

onMounted(() => {
    if(process.client) {
       nextTick(() => {
        if(!slider.value) return;
         //watch the width of the slider
         resizeObserver = new ResizeObserver(() => {
            sliderWidth.value = slider.value.offsetWidth;

            if(moveIndex.value > possibleNumberOfSteps.value) {
              moveIndex.value = possibleNumberOfSteps.value;
            }
        });

        if(props.preSelect) {
          updateSelection(props.items[0], 0);
        }

        resizeObserver.observe(slider.value);
       })
    }
})

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
  }
});

const possibleVisibleItems = computed(() => {
  //calculate the number of items that can be visible in the slider based on the width of the slider
  if (!slider.value) return 0;
  return Math.floor(sliderWidth.value / 180);
});

const possibleNumberOfSteps = computed(() => {
  //calculate the number of steps that can be taken based on the number of items that can be visible
  return Math.ceil(props.items.length / possibleVisibleItems.value);
});

const isAtEnd = computed(() => {
  return moveIndex.value === possibleNumberOfSteps.value;
});

watch(
  () => props.deselectItem,
  () => {
    selectedIndex.value = null;
  }
);

const emit = defineEmits(["update:modelValue"]);

const scroll = (direction: string) => {
  if (!slider.value) return;
  if (direction === "add") {
    moveIndex.value += 1;
    currentScroll.value += 180 * possibleVisibleItems.value
  } else {
    moveIndex.value -= 1;
    currentScroll.value -= 180 * possibleVisibleItems.value
  }

  slider.value.scrollLeft = currentScroll.value;
};

const updateSelection = (item: object, index: number) => {
  emit("update:modelValue", item);
  selectedIndex.value = index;
};
</script>

<style lang="css" scoped>
#slider::-webkit-scrollbar {
  display: none;
}

#slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
