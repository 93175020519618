import { Product, Servicepoint, type Receiver, type Sender } from "~/types/shipment";

export default () => {
    const products = ref<Product[]>([]);
    const droppoints = ref<{ label: string, value: string }[]>([]);
    const loading = ref(false);

    const fetchProducts = (customer: Partial<Sender>, receiver: Partial<Receiver>, weight: string | number, height: string | number, width: string | number, length: string | number, options?: Record<string, any>) => {
        loading.value = true;
        const receiverType = receiver.business ? "business" : "private";

        let queryString = `sender_country=${customer.country}&sender_street1=${customer.street1}&sender_zip_code=${customer.zip_code}&sender_city=${customer.city}&receiver_street1=${receiver.street1}&receiver_country=${receiver.country}&receiver_zip_code=${receiver.zip_code}&receiver_city=${receiver.city}&receiver_type=${receiverType}&weight=${weight}&height=${height}&width=${width}&length=${length}`
        
        if(options.return)
            queryString += "&q=return"
        
        const { t } = useNuxtApp().$i18n;
        return homeFetch(`products?${queryString}`).then((response) => {
            if (response?.data) {
                products.value = response.data as Product[];
                loading.value = false;
            }

            return response;
        }).finally(() => {
            loading.value = false;
        });
    };

    const fetchServicePoints = async (carrier: string, receiver: Partial<Receiver>) => {
        return homeFetch(`servicepoints?carrier=${carrier}&zip_code=${receiver.zip_code}&country_code=${receiver.country}&street=${receiver.street1}`).then((response) => {
            if (response?.data) {
                droppoints.value = (response.data.servicepoints as Servicepoint[]).map((point: Servicepoint) => {
                    const address = point.address!;
                    return {
                        label: `${point.name}, ${address.street}, ${address.zip_code} ${address.city}, afstand ${point.distance} meter`,
                        value: point.servicepoint_id,
                    }
                })
                loading.value = false;
            }
        }).finally(() => {
            loading.value = false;
        });
    }

    const fetchExtraServices = async (carrier: string, cps: string, serviceCodes: Array<[]>) => {
        let serviceCodesString = serviceCodes.join('&service_codes[]=');
        const { data } = await $fetch(`/api/carrier/services?carrier=${carrier}&cps=${cps}&service_codes=${serviceCodesString}`,{
            key: `data:transfer-comments`,
            lazy: true,
            default: () => [],
        })
        loading.value = false;
        return {
            data,
        }
    }

    return {
        products,
        droppoints,
        loading,
        fetchProducts,
        fetchServicePoints,
        fetchExtraServices
    };
};
