<template>
  <div class="flex flex-col gap-6 @container" ref="flowWrapper">
    <div>
      <div class="flex gap-4 justify-between flex-col mb-3">
        <div class="flex-col">
          <div class="text-body-lg-heavy">
            {{ t("settings.integration.api_key.label.title") }}
          </div>
          <div class="text-body-default text-quarterary">
            {{ t("settings.integration.api_key.label.description") }}
          </div>
        </div>
        <InputText :modelValue="username" readonly size="large" :label="t('username')" class="text-secondary">
          <template #button>
            <Button @click="() => copyToClipboard(username, t('settings.integration.username.copied_to_clipboard'))">
              <template #prefix>
                <Icon src="copy" size="medium" />
              </template>
              {{ t("copy") }}
            </Button>
          </template>
        </InputText>
        <InputText :modelValue="token" readonly size="large" :label="t('api_key')" class="text-secondary">
          <template #button>
            <Button @click="() => copyToClipboard(token, t('settings.integration.api_key.copied_to_clipboard'))">
              <template #prefix>
                <Icon src="copy" size="medium" />
              </template>
              {{ t("copy") }}
            </Button>
          </template>
        </InputText>
      </div>
      <div>
        <Button variant="primary-link" @click="toggleAdvanced">
          {{ t("advanced") }}
          <template #suffix>
            <Icon :src="advancedWrapperVisible ? 'CaretUp' : 'CaretDown'" size="small" />
          </template>
        </Button>
        <div class="p-3" :class="{ hidden: !advancedWrapperVisible }" ref="advancedWrapper">
          <div class="text-body-lg-heavy">{{ t("new") }} {{ t("settings.integration.api_key.label.title") }}</div>
          <div class="flex gap-3 justify-between">
            <div class="text-body-default text-quarterary">
              {{ t("settings.integration.api_key.new.description") }}
            </div>
            <Button variant="destructive" @click="generateToken" :loading="generatingToken" class="flex-shrink-0">
              {{ t("settings.integration.api_key.new.generate") }}
            </Button>
          </div>
        </div>
      </div>
    </div>

    <Divider />

    <div class="flex gap-4 justify-between">
      <div class="text-body-lg-heavy">{{ t("documentation") }}</div>
      <Button href="https://docs.homerunner.com/" target="_blank">
        {{ t("read_more") }}
        <template #suffix>
          <Icon src="ArrowUpRight" size="medium" />
        </template>
      </Button>
    </div>
    <NotificationWrapper />

  </div>
</template>

<script setup lang="ts">
import NotificationWrapper from "../components/NotificationWrapper.vue";
import type { ISettingsFlow } from "../types";
const props = withDefaults(
  defineProps<{
    currentHeader?: string | null;
  }>(),
  {
    currentHeader: null,
  }
);
const { t } = useI18n();
const token = ref<string>("");
const username = ref<string>("");
const advancedWrapper = ref<HTMLElement | null>(null);
const advancedWrapperVisible = ref<boolean>(false);
const generatingToken = ref<boolean>(false);

const fetchToken = () => {
  setLoading(true);
  return homeFetch("workspace/integrations/token")
    .then((response) => {
      if (response?.data) {
        token.value = response.data.token;
        username.value = response.data.email;
      }
    })
    .finally(() => {
      setLoading(false);
    });
};

const generateToken = () => {
  const account = useAuth().getAccount();
  $prompt({
    message: t("settings.integration.api_key.new.confirm", {organisation: account!.account.name}),
    placeholder: t("settings.integration.api_key.new.placeholder"),
    prompt: account!.account.name,
    confirmText: t("settings.integration.api_key.new.generate"),
    destructive: true,
    onConfirm: () => {
      generatingToken.value = true;
      homeFetch("workspace/integrations/update-token", {
        method: "put",
      })
        .then((response) => {
          if (response?.data) {
            token.value = response.data;
            $toast.add({
              icon: "check",
              title: t("settings.integration.api_key.new.success"),
              target: "#settings-modal .notification-wrapper",
            });
          }
        })
        .finally(() => {
          generatingToken.value = false;
        });
    }
  });
};

const toggleAdvanced = () => {
  if (advancedWrapper.value) {
    advancedWrapperVisible.value = !advancedWrapperVisible.value;
  }
};

onMounted(() => {
  fetchToken().then(() => {
    goToCurrentHeader();
  });
});

const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

const emits = defineEmits(["update:loading"]);
const setLoading = (l: boolean) => emits("update:loading", l);

</script>

<script lang="ts">
export const useIntegrationSettings = (): ISettingsFlow => {
  const { t } = useI18n();
  const icon = "regular/plug";
  const title = t("settings.integration.title");
  const pageTitle = t("settings.integration.title");
  const key = "integration";
  const condition = () => !useAuth().isDFM.value;

  return {
    icon,
    pageTitle,
    title,
    key,
    condition
  };
};
</script>
