import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_7iiyayj7ew from "/app/modules/dialog/runtime/plugin.ts";
import plugin_58xPIPqJLa from "/app/modules/userSettings/runtime/plugin.ts";
import plugin_De8PEtVzlK from "/app/modules/workspaceSettings/runtime/plugin.ts";
import plugin_CSkeqsKc0z from "/app/modules/createticket/runtime/plugin.ts";
import plugin_vkaTIRAeM0 from "/app/modules/printing/runtime/plugin.ts";
import plugin_RBRENgz87X from "/app/modules/inviteusers/runtime/plugin.ts";
import plugin_eAMkmbTMeq from "/app/modules/newreturn/runtime/plugin.ts";
import plugin_sm8ZcHzXmP from "/app/modules/newshipment/runtime/plugin.ts";
import plugin_HSPC7SS8U2 from "/app/modules/formwizard/runtime/plugin.ts";
import plugin_eexrVgN5tP from "/app/modules/boarding/runtime/plugin.ts";
import plugin_Bz07CbKbST from "/app/modules/loader/runtime/plugin.ts";
import plugin_DvPziDwt5Z from "/app/modules/toast/runtime/plugin.ts";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/app/.nuxt/floating-vue.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import eventbus_8iEFQ4ahH5 from "/app/plugins/eventbus.ts";
import quill_rR0rVhYhH9 from "/app/plugins/quill.ts";
import recaptcha_W4US4xmBV6 from "/app/plugins/recaptcha.js";
import route_client_8A0ENhmQRG from "/app/plugins/route.client.ts";
import vue_final_modal_pML93k5qcp from "/app/plugins/vue-final-modal.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_7iiyayj7ew,
  plugin_58xPIPqJLa,
  plugin_De8PEtVzlK,
  plugin_CSkeqsKc0z,
  plugin_vkaTIRAeM0,
  plugin_RBRENgz87X,
  plugin_eAMkmbTMeq,
  plugin_sm8ZcHzXmP,
  plugin_HSPC7SS8U2,
  plugin_eexrVgN5tP,
  plugin_Bz07CbKbST,
  plugin_DvPziDwt5Z,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  floating_vue_EIcJ7xiw0h,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  eventbus_8iEFQ4ahH5,
  quill_rR0rVhYhH9,
  recaptcha_W4US4xmBV6,
  route_client_8A0ENhmQRG,
  vue_final_modal_pML93k5qcp
]