<template>
    <Modal
        :clickToClose="false"
        v-model:open="modalOpen"
        maxWidth="1020px"
        :contentPadding="false"
        :overlay="false"
        :center="true"
        class="shadow-lvl-05 bg-modal-overlay"
        anchor="top"
        ref="modal"
        :bgset="false"
        @closed="() => emit('close')"
    >
        <div class="w-[454px] max-w-full h-[710px] max-h-full bg-surface-lvl-00 border-2 border-01 overflow-hidden flex flex-col">
            <div class="pl-4 pr-2.5 flex items-center h-12">
                <p class=" text-body-lg-heavy text-secondary">{{t('edit_sender_address', {mode: t(reverseMode).toLocaleLowerCase()})}}</p>
                <Button type="button" class="ml-auto text-foreground-tertiary" iconOnly variant="tertiary" @click="emit('close')">
                    <Icon src="close" size="medium" class="cursor-pointer" />
                </Button>
            </div>
            <div class="p-1.5">
                <div class="p-2.5">
                    <p class="text-body-default text-secondary mb-4">{{ t('edit_sender_address_text', {mode: t(reverseMode).toLocaleLowerCase()})}}</p>
                    <div class="grid grid-cols-6 gap-4 mt-4">
                        <InputText class="col-span-3" v-model="dataCopy.name" :label="t('company_name')" :placeholder="t('name')"></InputText>
                        <InputText class="col-span-3" v-model="dataCopy.attention" :label="t('attention_name')" :placeholder="t('attention_name')"></InputText>
                        <InputText class="col-span-3" v-model="dataCopy.street1" :label="t('street1')" :placeholder="t('address')"></InputText>
                        <InputText class="col-span-3" v-model="dataCopy.street2" :label="t('street2_optional')" :placeholder="t('address')"></InputText>
                        <div class="col-span-6 flex justify-between gap-3">
                            <InputText
                            @update:modelValue="autocity"
                            class="max-w-[120px] w-full"
                            v-model="dataCopy.zip_code"
                            :label="t('zip_code')"
                            :placeholder="t('zip_code')"
                            ></InputText>
                            <InputText class="w-full" v-model="dataCopy.city" :label="t('city')" :placeholder="t('city')"></InputText>
                            <DropdownSelectCountriesWithPrices class="w-full":label="t('country')" v-model="dataCopy.country_code" />
                        </div>
                        <InputText class="col-span-3" v-model="dataCopy.email" :label="t('email')" :placeholder="t('email')"></InputText>
                        <InputPhone :values="phoneValues" @update:model-value="val => updatePhone(val)" class="col-span-3" :label="t('phone')" :placeholder="t('phone')"></InputPhone>
                    </div>
                </div>
                <Divider class="mt-[11px] mb-[11px]" :text="t('or').toLocaleLowerCase()"></Divider>
                <div class="p-2.5">
                    <p class="text-tertiary text-body-default mb-4">{{ t('select_previous_address', {mode: t(reverseMode).toLocaleLowerCase()}) }}</p>
                    <div v-if="LastUsedAddresses.length !== 0">
                        <div v-for="address in LastUsedAddresses" @click="setAddress(address)" class="flex gap-2 items-center px-4 h-9 cursor-pointer rounded-xl hover:bg-02 transition duration-300">
                            <Icon src="clock_counter_clockwise" size="medium" class=" text-foreground-secondary" />
                            <p class=" text-body-default-heavy text-secondary truncate">{{ address.name }}, <span v-if="address.attention">{{ address.attention }}, </span>{{ address.street1 }}, {{ address.zip_code }}, {{ address.city }}, {{getCountryName(address.country) }}</p>
                            <Icon src="arrow_right" size="medium" class=" text-foreground-secondary" />
                        </div>
                    </div>
                    <div v-else>
                        <p class="text-tertiary text-body-default">{{ t('no_previous_address') }}</p>
                    </div>
                </div>
            </div>
            <div class="mt-auto p-4 flex justify-end gap-3">
                <Button type="button" class="" @click="emit('close')">{{ t('undo') }}</Button>
                <Button type="button" class="" variant="primary" @click="overWriteAddress()">{{ t('save_and_overwrite_address') }}</Button>
            </div>
        </div>
    </Modal>
</template>

<script setup lang="ts">
    const props = defineProps<{
        open: boolean;
        mode: string;
        values: any;
    }>();

    const modalOpen = ref(props.open);

    const LastUsedAddresses = ref([]);

    const { t } = useI18n();

    const emit = defineEmits(["close", "updateAddress"]);

    const reverseMode = computed(() => {
        return props.mode === "sender" ? "receiver" : "sender";
    });

    const valueRef = ref(props.values[reverseMode.value]);

    onBeforeMount(() => {
        let savedAddresses = localStorage.getItem('savedAddresses');
        if (savedAddresses) {
            LastUsedAddresses.value = JSON.parse(savedAddresses);
        }
    });

    const phoneValues = ref({
        prefix: valueRef.value.phone_prefix,
        phone_number: valueRef.value.phone,
    });

    const dataCopy = ref({ ...props.values[reverseMode.value], country_code: props.values[reverseMode.value].country });

    const debounceTimeout = ref(null);

    const debounce = () => {
        if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
        debounceTimeout.value = setTimeout(() => {
            autocity();
        }, 500); // delay for half second
    };

    watch(
        () => dataCopy.value?.zip_code,
        (value) => {
            if (value) {
                debounce();
            }
        }
    );

    const updatePhone = (val) => {
        dataCopy.value.phone = val.phone_number;
        dataCopy.value.phone_prefix = val.prefix;
    };

    const autocity = () => {
        homeFetch(`/autocity/?zip_code=${dataCopy.value?.zip_code}&country=${dataCopy.value?.country}`).then((response) => {
            if (response.data.autocity.length > 0) {
                dataCopy.value.city = response.data.autocity[0].city;
            }
        });
    };

    const setAddress = (address) => {
        dataCopy.value = {...address};
        phoneValues.value = {
            prefix: address.phone_prefix,
            phone_number: address.phone,
        };
    };

    const overWriteAddress = () => {
        let savedAddresses = localStorage.getItem('savedAddresses');
        if (!savedAddresses) {
            savedAddresses = '[]';
        }
        if (!savedAddresses.includes(JSON.stringify(dataCopy.value))) {
            let addresses = JSON.parse(savedAddresses);
            if (addresses.length == 3) {
                addresses.shift();
            }
            addresses.push(dataCopy.value);
            localStorage.setItem('savedAddresses', JSON.stringify(addresses));
        }
        emit("updateAddress", dataCopy.value);
        emit("close");
    };
</script>