<template>
  <Result>
    <div class="flex gap-2">
      <div class="text-body-lg text-secondary">
        <slot name="title">
          <span
            v-for="(part, idx) in title"
            :key="part"
            :class="{ 'font-bold': idx === 1 }"
            >{{ part }}</span
          >
        </slot>
      </div>
      <div class="text-body-lg text-quarterary">
        <slot name="subtitle"> {{ subtitle }} </slot>
      </div>
    </div>
  </Result>
</template>

<script setup lang="ts">
  import { NuxtLink } from "#components";
  import type { SearchResult } from "~/types/search";

  defineOptions({
    name: "GlobalSearchResult",
  });

  const props = withDefaults(
    defineProps<{
      icon?: string | false;
      isPrevious?: boolean;
      result: SearchResult;
      search: string;
    }>(),
    {
      icon: "ClockCounterClockwise",
      search: "",
    }
  );

  const { t } = useI18n();
  const search = computed(() => props.search);

  const title = computed(() => {
    return globalSearchResultTitle(props.result, search.value);
  });

  const subtitle = computed(() => {
    return globalSearchResultSubtitle(props.result);
  });

  const onClick = computed(() => {
    return globalSearchResultOnClick(props.result);
  });

  const Result = computed(() => {
    const componentProps = {
      "data-result": `${props.result.type}_${props.result.id}`,
      class:
        "flex h-11 w-full max-w-screen-md items-center gap-2.5 rounded-lg px-2.5 py-2 hover:bg-00 focus:bg-01 focus:outline-none",
    };

    return h("button", {
      ...componentProps,
      onClick: (e: PointerEvent) => onClickWrapper(onClick.value, e),
    });
  });

  const onClickWrapper = (oc: Record<string, any> | Function, e: PointerEvent) => {
    addResultToRecentSearches(props.result);

    if (typeof oc === "function") {
      return oc();
    } else {
      const resolved = useRouter().resolve(oc);
      if(e.metaKey || e.ctrlKey) {
        return window.open(resolved.href, "_blank");
      }
     return useRouter().push(oc);
    }
  };
</script>
