<template>
  <Modal
    :clickToClose="false"
    v-model:open="open"
    maxWidth="1020px"
    :contentPadding="false"
    :center="true"
    class="shadow-lvl-05"
    anchor="top"
    ref="modal"
    :overlay="false"
    @closed="() => emit('close')"
  >
    <div class="w-[1020px] max-w-full h-[756px] max-h-full">
      <FormWizard
        :loading="loading"
        :validation-schema="validationSchema"
        @submit="onSubmit"
        showProgress
        :name="formTitle"
        :stepNames="stepNames"
        headerType="create"
        footerType="create"
        :submitButtonText="t('save')"
        ref="wizard"
        @exit="emit('close')"
        class="w-full h-full flex flex-col [&_.form-step]:mx-auto"
        :class="{
          '[&_.form-step]:max-w-[540px]': wizard?.currentSchema.spec?.meta?.centerContent,
        }"
        hide-title
      >
        <template #step-markets>
          <Markets :mode="mode" />
        </template>
        <template #step-select_delivery_methods>
          <SimpleRuleBuilder @advanced="goToAdvanced" />
        </template>

        <template #step-complete_your_checkout="{ canSubmit }">
          <CompleteCheckout :canSubmit="canSubmit" :mode="mode" />
        </template>

        <template #step-success>
          <Success @advanced="goToAdvanced" @close="emit('close')" />
        </template>

        <template #step-setup_rules>
          <SetupRules />
        </template>

        <template #footer-buttons v-if="wizard && wizard.stepKey == 'setup_rules'">
          <Button type="button" variant="tertiary" :to="{ name: 'checkouts-test' }" target="_blank">
            <template #prefix>
              <Icon src="bold/eye" size="medium" />
            </template>
            {{ t("test_rules_in_fictitious_checkout") }}
            <template #suffix>
              <Icon src="bold/ArrowUpRight" size="medium" />
            </template>
          </Button>

          <Button type="button" variant="default" @click="() => onSubmit(values, true)" :loading="loading">
          
            {{ t("save_and_close") }}
          </Button>
        </template>
      </FormWizard>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import Markets from "./partials/Markets.vue";
import SimpleRuleBuilder from "./partials/SimpleRuleBuilder.vue";
import CompleteCheckout from "./partials/CompleteCheckout.vue";
import Success from "./partials/Success.vue";
import SetupRules from "./partials/SetupRules.vue";
import _ from "lodash";

const open = defineModel("open", { default: false });

const props = defineProps<{
  checkoutId?: number;
  initialStep?: string;
}>();

const loading = ref(false);
const yup = useYup();

const { t } = useI18n();
const emit = defineEmits(["close", "reload"]);
const wizard = ref(null);
const values = computed(() => wizard.value?.values);
const advanced = ref(true);

watch(
  values,
  (v) => {
    if (v) localStorage.setItem("checkoutForm", JSON.stringify(v));
  },
  { deep: true }
);

watch(open, (o) => {
  if (!o) {
    localStorage.removeItem("checkoutForm");
  }
});

watch(wizard, (val, oldVal) => {
  if (val && !oldVal && props.initialStep) {
    nextTick(() => {
      if (props.initialStep === "setup_rules") {
      goToAdvanced();
    } else wizard.value?.goToStepByKeyName(props.initialStep);    });
  }
})

onBeforeUnmount(() => {
  localStorage.removeItem("checkoutForm");
});

const mode = computed(() => (props.checkoutId ? "edit" : "create"));

const formTitle = computed(() => (mode.value === "edit" ? t("edit_checkout") : t("create_checkout")));

const validationSchema = computed(() => {
  let schema: Record<string, any>[] = [
    yup
      .object({
        id: yup.number().optional(),
        markets: yup.array(yup.string()).required().min(1).default([]),
      })
      .meta({ continueButtonText: t("continue"), title: t("select_market"), key: "markets", centerContent: true }),
    yup
      .object({
        shipping_methods: yup.array(yup.object()).required().min(1).default([]),
      })
      .meta({ continueButtonText: t("continue"), title: t("select_delivery_methods"), key: "select_delivery_methods" }),
    yup
      .object({
        name: yup.string().required(),
        description: yup.string().required().default("test"),
        shops: yup.array(yup.string()).default([]),
      })
      .meta({
        continueButtonText: t("continue"),
        title: t("complete_your_checkout"),
        key: "complete_your_checkout",
        centerContent: true,
        submit: true,
      }),
    yup.object({}).meta({
      title: t("create_checkout"),
      key: "success",
      continueButton: false,
      canGoBack: false,
      centerContent: true,
      noFooter: true,
      noHeader: true,
      hideInProgressBar: true,
    }),
  ];

  if (advanced.value)
    schema = [
      ...schema,
      yup.object({}).meta({
        continueButtonText: t("save_rules"),
        continueButton: true,
        canSubmit: true,
        submit: true,
        title: t("setup_rules"),
        key: "setup_rules",
        canGoBack: mode.value == "edit",
        hideInProgressBar: !(mode.value === "edit"),
        hideProgressBar: true,
      }),
    ];

  if (mode.value === "edit") {
    schema = schema.filter((s) => s?.spec?.meta?.key !== "success");
  }

  return schema;
});

const goToAdvanced = () => {
  advanced.value = true;

  nextTick(() => {
    //get index of setup_rules step
    const index = validationSchema.value.findIndex((s) => s?.spec?.meta?.key === "setup_rules");
    wizard.value?.setStep(index);
  });
};

const stepNames = computed(() => validationSchema.value.map((schema) => schema.describe().meta?.title));

const onSubmit = (formData, close?: boolean) => {
  const { markets, id, ...body } = formData;
  //foreach shippingmethod map its carrier_product_service to the carrier_product_service slug
  body.shipping_methods = body.shipping_methods.map((sm) => {
    return {
      ...sm,
      carrier_product_service: sm.carrier_product_service.slug,
    };
  });

  const url = id ? "workspace/checkouts/" + id : "workspace/checkouts";
  const method = id ? "PUT" : "POST";
  if (mode.value === "edit") {
    if (wizard.value?.stepKey === "complete_your_checkout") {
      goToAdvanced();
    } else {
      loading.value = true;
      homeFetch(url, { method, body })
        .then((response) => {
          emit("reload");
          $toast.add({
            title: t("checkout_saved"),
          });

          if (close) {
            emit("close");
          } else {
            fetchCheckout();
          }
        })
        .finally(() => {
          loading.value = false;
        });
    }
  } else {
    loading.value = true;
    homeFetch(url, { method, body })
      .then((response) => {
        if (response?.data) {
          setFormFromCheckout(response.data);
          wizard.value?.goToNext();
          emit("reload");
        }
      })
      .finally(() => {
        loading.value = false;
      });
  }
};

const fetchCheckout = () => {
  loading.value = true;
  return homeFetch("workspace/checkouts/" + props.checkoutId)
    .then((response) => {
      if (response?.data) {
        setFormFromCheckout(response.data);
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const setFormFromCheckout = (checkout) => {
  //first get all rules from all shipping methods on the checkout
  const rules = checkout.shipping_methods.map((sm) => sm.rules).flat();

  let markets = [];

  //check to see if there are rules with no receiver_country filter
  const noCountryFilter = rules.filter((rule) => !rule.filters.receiver_country || _.get(rule.filters.receiver_country, "0.operator") !== "eq");

  //if there are rules with no receiver_country filter, add them to the uniqueMarkets array
  if (noCountryFilter.length > 0) {
    markets.push("GLOBAL");
  }

  const rulesWithCountryFilter = rules.filter((rule) => rule.filters.receiver_country && _.get(rule.filters.receiver_country, "0.operator") === "eq");

  //then get all markets from the rules
  markets = [...markets, ...rulesWithCountryFilter.map((rule) => _.get(rule.filters.receiver_country, "0.value")).filter((market) => market)];

  //finally remove duplicates
  const uniqueMarkets = [...new Set(markets)];

  wizard.value?.resetForm({
    values: {
      ...checkout,
      markets: uniqueMarkets,
      shops: checkout.shops?.map((shop) => shop.id) || [],
    },
  });
  wizard.value?.setTouched({ markets: true });
};

watch(
  () => props.checkoutId,
  (checkoutId) => {
    if (checkoutId) {
      fetchCheckout();
    }
  },
  { immediate: true }
);
</script>
