<template>
  <div class="flex items-center gap-3 bg-00 px-4 py-3.5 rounded-inherit">
    <Icon
      src="MagnifyingGlass"
      size="large"
      class="text-foreground-quarterary"
    />
    <div
      class="global-search_input-wrapper relative w-full bg-inherit"
      :style="{
        '--gradient-display': modelValue ? 'none' : 'block',
        '--gradient-left': gradientLeft + 'px',
        '--gradient-transition-duration':
          gradientTransitionDuration + 'ms',
      }"
    >
      <input
        ref="input"
        type="text"
        class="w-full text-title-body leading-none focus-within:outline-none bg-inherit"
        :placeholder="placeholder"
        v-model="modelValue"
        v-bind="$attrs"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  defineOptions({
    name: "GlobalSearchInput",
    inheritAttrs: false,
  });

  const props = withDefaults(
    defineProps<{
      show?: boolean;
    }>(),
    {
      show: false,
    }
  );

  const calculateTextWidth = (text = "") => {
    if (!input.value) return 0;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) return 0;

    if (!text) {
      text = placeholder.value;
    }

    context.font = getComputedStyle(input.value).font;
    const width = context.measureText(text).width;

    return width;
  };

  const cShow = computed(() => props.show);
  const modelValue = defineModel();
  const input = ref<HTMLInputElement | null>(null);
  const { t } = useI18n();

  const gradientTransitionDuration = ref(0);
  const placeholder = ref(t("search by"));
  const placeholderWords = [
    t("package_number"),
    t("reference"),
    // t("receiver"),
  ];

  const gradientLeft = ref(0);
  let intialGradientLeft = 0;
  const waitToGoBack = 250;

  const setPlaceholder = (index: number) => {
    // Pick the next placeholder word
    const nextWord = placeholderWords[index].toLowerCase();
    const newPlaceholder = t("search by") + " " + nextWord;
    const duration = nextWord.length * 50;

    gradientTransitionDuration.value = duration;
    gradientLeft.value = calculateTextWidth(newPlaceholder);
    placeholder.value = newPlaceholder;

    setTimeout(() => {
      gradientLeft.value = intialGradientLeft;

    }, gradientTransitionDuration.value + waitToGoBack);

    setTimeout(
      () => {
        let nextIndex = index + 1;
        if (nextIndex >= placeholderWords.length) {
          nextIndex = 0;
        }
        setPlaceholder(nextIndex);
      },
      gradientTransitionDuration.value * 2 + waitToGoBack
    );
  };

  watch(
    cShow,
    (value) => {
      if (value) {
        setTimeout(() => {
          gradientLeft.value = calculateTextWidth(placeholder.value);
          const spaceWidth = calculateTextWidth(" ");
          intialGradientLeft = gradientLeft.value - (spaceWidth);
          setPlaceholder(0);
        });
      }
    },
    { immediate: true }
  );

  defineExpose({
    focus() {
      input.value?.focus();
    },
  });
</script>

<style scoped>
  .global-search_input-wrapper::after {
    content: "";
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgb(var(--backgroundColor-00)) 10px, rgb(var(--backgroundColor-00)) 100%);
    position: absolute;
    top: 0;

    left: var(--gradient-left);
    right: 0;
    bottom: 0;
    display: var(--gradient-display);
    transition: left var(--gradient-transition-duration) cubic-bezier(0.2, 0, 0, 1)
    ;
  }
</style>
