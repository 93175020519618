<template>
  <Modal v-model:open="open" maxWidth="1020px" class="shadow-lvl-05" ref="modal">
    <ModalHeader class="border-b-0"> {{ t("settings.plan.change_plan.title") }} </ModalHeader>
    <div></div>
    <div class="flex w-[836px] max-w-full p-[14px] gap-5">
      <div class="flex w-full flex-col gap-6">
        <div class="bg-utility-mocca py-9 px-6 rounded-xl">
          <div class="text-body-default-heavy">
            {{ t("settings.plan.hand_in.description") }}
          </div>
          <div class="text-title-section text-primary">{{ t("hand_in") }}</div>
        </div>
        <div class="px-6 flex flex-col gap-6 h-full">
          <div class="flex flex-col gap-3 flex-1">
            <div class="flex gap-3" v-for="point in handinDefinition.points">
              <Icon src="Check" size="large" />
              <div class="text-body-default">{{ point }}</div>
            </div>
          </div>
          <div class="flex flex-col flex-1 gap-1.5 mb-auto py-2.5">
            <template v-for="(tableEntry, idx) in handinDefinition.table">
              <div class="flex text-body-default text-tertiary gap-2 justify-between">
                <div>{{ tableEntry.left }}</div>
                <div class="text-right">{{ tableEntry.right }}</div>
              </div>
              <Divider v-if="idx !== handinDefinition.table.length - 1" />
            </template>
          </div>
          <Button :disabled="!isPickup" variant="primary" @click="updatePlan" :loading="updating">
            <template #prefix v-if="!isPickup">
              <Icon src="Check" />
            </template>
            {{ !isPickup ? t("activated") : t("settings.plan.hand_in.change_to") }}
          </Button>
        </div>
      </div>
      <Divider rotation="vertical" :spacing="false" />
      <div class="flex w-full flex-col gap-6 h-full">
        <div class="bg-utility-grape py-9 px-6 rounded-xl">
          <div class="text-body-default-heavy">
            {{ t("settings.plan.pickup.description") }}
          </div>
          <div class="text-title-section text-primary">{{ t("pickup") }}</div>
        </div>
        <div class="px-6 flex flex-col gap-6">
          <div class="flex flex-col gap-3 flex-1">
            <div class="flex gap-3" v-for="point in pickupDefinition.points">
              <Icon src="Check" size="large" />
              <div class="text-body-default">{{ point }}</div>
            </div>
          </div>
          <div class="flex flex-col flex-1 gap-1.5 mb-auto py-2.5">
            <template v-for="(tableEntry, idx) in pickupDefinition.table">
              <div class="flex text-body-default text-tertiary gap-2 justify-between">
                <div>{{ tableEntry.left }}</div>
                <div class="text-right">{{ tableEntry.right }}</div>
              </div>
              <Divider v-if="idx !== pickupDefinition.table.length - 1" />
            </template>
          </div>
          <Button :disabled="isPickup" variant="primary" @click="updatePlan" :loading="updating">
            <template #prefix v-if="isPickup">
              <Icon src="Check" />
            </template>
            {{ isPickup ? t("activated") : t("settings.plan.pickup.change_to") }}
          </Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { useHandin } from "./HandIn.vue";
import { usePickup } from "./Pickup.vue";

const modal = ref<HTMLDivElement | null | undefined>();
const props = withDefaults(
  defineProps<{
    isPickup: boolean;
  }>(),
  {
    isPickup: false,
  }
);

const open = defineModel("open", { default: false });
const { t } = useI18n();
const emit = defineEmits(["update:open"]);

const pickupDefinition = ref();
usePickup().then((data) => (pickupDefinition.value = data));
const handinDefinition = useHandin();
const updating = ref(false);

const updatePlan = () => {
  const plan = props.isPickup ? "c2c" : "dfm";
  $confirm({
    title: t("settings.plan.change_plan.confirmation"),
    onConfirm: () => {
      updating.value = true;
      homeFetch(`workspace/plan/`, {
        method: "POST",
        silent: true,
        body: {
          delivery_method: plan,
        },
      })
        .then(useAuth().updateCapabilities)
        .catch((e) => {
          let title = t("error");
          let message = e.message;
          if (e.response?._data?.data?.message) {
            title = e.response._data.data.message;
          }
          if (e.response?._data?.data?.data?.status) {
            message = e.response._data.data.data.status;
          }

          $toast.add({
            group: e.url,
            title,
            text: message,
            type: "error",
          });
        })
        .finally(() => {
          updating.value = false;
          emit("update:open", false);
        });
    },
  });
};
</script>
