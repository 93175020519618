/**
 * Generates a selection object with several methods to manage row selections.
 *
 * @param {{ identifier: string; rows: Ref<any[]> }} options - The selection settings.
 * @returns {Object} A selection object containing several properties and methods for managing row selections.
 */
import { computed, type Ref, ref, toRaw } from "vue";
import type { Pagination } from "~/types/paginate";
export default ({ identifier = "", rows, pagination}: { identifier: string; rows: any[], pagination?: Ref<Pagination> }) => {
  const selected = ref([]);
  const selectAll = ref(false);
  const isIndeterminate = computed(() => {
    if (!selectAll.value) {
      return selected.value.length > 0 && selected.value.length < rows.length;
    } else {
      return selected.value.length > 0;
    }
  });

  /**
   * Toggles the selection of a single row.
   *
   * @param {any} row - The row to toggle selection for.
   * @returns {void}
   */
  const toggleRowSelection = (row) => {
    if (identifier) toggleRowSelectionByIdentifier(row);
    else toggleRowSelectionByObject(row);
  };

  /**
   * Selects or deselects all rows depending on whether they are currently all selected or not.
   *
   * @returns {void}
   */
  const toggleAllRowsSelection = () => {
    selected.value = [];
    selectAll.value = !selectAll.value;
  };

  /**
   * Selects all rows using their identifier field as selection key.
   *
   * @returns {void}
   */
  function toggleAllRowsSelectionByIdentifier() {
    selected.value = rows.map((row) => row[identifier]);
  }

  /**
   * Selects all rows by cloning them and adding them to the selected array.
   *
   * @returns {void}
   */
  function toggleAllRowsSelectionByObject() {
    selected.value = structuredClone(toRaw(rows));
  }

  /**
   * Toggles the selection of a single row by using its identifier field as selection key.
   *
   * @param {any} row - The row to toggle selection for.
   * @returns {void}
   */
  function toggleRowSelectionByIdentifier(row) {
    if (selected.value.includes(row[identifier])) {
      selected.value = selected.value.filter((r) => r !== row[identifier]);
    } else {
      selected.value.push(row[identifier]);
    }
  }

  /**
   * Toggles the selection of a single row by cloning it and adding/removing it from the selected array.
   *
   * @param {any} row - The row to toggle selection for.
   * @returns {void}
   */
  function toggleRowSelectionByObject(row) {
    if (selected.value.includes(row)) {
      selected.value = selected.value.filter((r) => r !== row);
    } else {
      selected.value.push(structuredClone(toRaw(row)));
    }
  }

  /**
   * Clears the current selection.
   *
   * @returns {void}
   */
  const clearRowSelection = () => {
    selected.value = [];
    selectAll.value = false;
  };

  /**
   * A computed property that returns the selected rows. If an identifier was specified in the options, only rows with the selected identities are returned.
   *
   * @returns {Ref<any[]>} The selected rows.
   */
  const selectedRows = computed(() => {
    if (identifier) return selectedRowsByIdentifier();
    else return selected;
  });

  /**
   * A helper function used by `selectedRows` to filter the selected rows based on their identity field.
   *
   * @returns {any[]} The selected rows containing the specified identity.
   */
  function selectedRowsByIdentifier() {
    return rows.filter((row) => selected.value.includes(row[identifier]));
  }

  const count = computed(() => {
    const selectedCount = selected.value.length;
    if(!pagination.value?.total) return selectedCount;

    if(selectAll.value) {
      return pagination.value.total - selectedCount;
    };

    return selectedCount;
  })

  const setSelected = (newlySelected) => {
    if (identifier) {
      selected.value = newlySelected;
    } else {
      //foreach selected item, find the row with the identifier and add it to the selected set
      selected.value = rows.filter((row) => newlySelected.includes(row[identifier])).map((row) => row[identifier]);
    }
  }

  const setSelectedInverse = (newlySelected) => {
    console.log(newlySelected);
    //foreach selected item, remove the row with the identifier from the selected set
    selected.value = selected.value.filter((row) => !newlySelected.includes(row));
  }


  return {
    isIndeterminate,
    selectAll,
    selected,
    setSelected,
    setSelectedInverse,
    selectedRows,
    toggleRowSelection,
    clearRowSelection,
    toggleAllRowsSelection,
    hasSelectedRows: computed(() => selected.value.length > 0),
    numberOfSelectedRows: computed(() => selected.value.length),
    hasSelection: computed(() => selected.value.length > 0 || selectAll.value),
    count,
  };
};
