<template>
    <div v-for="field in service.fields">
        <ListSelector class="mb-6" simple v-if="field.type == 'list'" :name="`services.${service.carrier_key}.${field.key}`" radioPosition="top" :values="field.options" valueKey="key">
            <template #title="{ value }">
                <p class=" text-body-default text-secondary ">{{ value.label }}</p>
            </template>
        </ListSelector>
        <Datepicker
            v-if="field.type === 'date'"
            type="datetime"
            class="w-full filter-value max-w-[200px]"
            :presets="[]"
            default-today
            :label="field.label"
            @update:modelValue="(val) => emit('dateUpdate', {val, service, field })"
            :name="`services.${service.key}[${field.key}]`"
        />
        <InputText v-if="field.type === 'number'" type="number" :name="`services.${service.carrier_key}.${field.key}`" class="w-full max-w-[200px]" :label="field.label">
            <template v-if="field.suffix" #suffix>
                {{ field.suffix }}
            </template>
        </InputText>
    </div>
</template>

<script setup lang="ts">
    const props = defineProps<{
        service: any;
    }>();
    const emit = defineEmits(['dateUpdate']);
</script>