<template>
  <slot name="header">
    <FormStepHeader :title="stepTitle" :subtitle="stepSubtitle" />
  </slot>
  <div class="space-y-3">
    <div v-for="[name, input] in Object.entries(jsonSchema.properties || {})">
      <slot :name="name" :input="input">
        <input v-if="isHidden(name)" :type="inputType(name)" :name="name" hidden />
        <template v-else-if="inputType(name) === 'string' || inputType(name) === 'text' || inputType(name) === 'number'">
          <div>
            <slot :name="`${name}-label`">
              <label :for="name" class="text-body-sm-heavy text-gray-700"> {{ label(name) }} </label>
            </slot>
            <div class="mt-1">
              <Authenticator
                v-if="inputFormat(name) === 'otp'"
                :id="name"
                :name="name"
                :required="isRequired(name)"
                :placeholder="placeholder(name)"
                :otp-length="length(name)"
                :size="size(name)"
              />
              <InputPassword
                v-else-if="inputFormat(name) === 'new-password'"
                :id="name"
                :name="name"
                :required="isRequired(name)"
                :placeholder="placeholder(name)"
                :size="size(name)"
                :min="minLength(name)"
              />

              <InputSubdomain
                v-else-if="inputFormat(name) === 'subdomain'"
                :id="name"
                :name="name"
                :required="isRequired(name)"
                :placeholder="placeholder(name)"
                :size="size(name)"
              />

              <InputText
                v-else
                :id="name"
                :name="name"
                :type="inputFormat(name)"
                :autocomplete="autocomplete(name)"
                :required="isRequired(name)"
                :placeholder="placeholder(name)"
                :prefix="prefix(name)"
                :suffix="suffix(name)"
                :modifiers="modifiers(name)"
                :size="size(name)"
              >
              </InputText>
            </div>
          </div>
        </template>
        <UploaderAvatar
          v-else-if="inputType(name) === 'avatar' || inputType(name) === 'avatar_organisation'"
          :id="name"
          :name="name"
          :required="isRequired(name)"
          :description="description(name)"
          :placeholder="placeholder(name)"
          :type="inputType(name) === 'avatar_organisation' ? 'organisation' : 'user'"
        />
        <template v-else-if="inputType(name) === 'country'">
          <slot :name="`${name}-label`">
            <label :for="name" class="text-body-sm-heavy text-gray-700"> {{ label(name) }} </label>
          </slot>
          <DropdownSelectCountry :id="name" :name="name" :required="isRequired(name)" :placeholder="placeholder(name)" :size="size(name)" />
        </template>
        <template v-else-if="inputType(name) === 'boolean'">
          <TickerCheckbox :name="name">
            <slot :name="`${name}-label`">
              {{ label(name) }}
            </slot>
          </TickerCheckbox>
        </template>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { convertSchema } from "@sodaru/yup-to-json-schema";

const props = defineProps({
  schema: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();

const jsonSchema = convertSchema(props.schema);

const inputType = (name: string) => {
  const type = props.schema.fields[name].type;

  if (type === "mixed") return inputFormat(name);

  return type || "string";
};

const isRequired = (name: string) => {
  return props.schema.fields[name].tests.some((test: any) => test.OPTIONS.name === "required");
};

const placeholder = (name: string) => {
  return props.schema.fields[name].spec.meta?.placeholder;
};

const autocomplete = (name: string) => {
  return props.schema.fields[name].spec.meta?.autocomplete || props.schema.fields[name].spec.meta?.format || name;
};

const inputFormat = (name: string) => {
  return jsonSchema.properties[name].format || props.schema.fields[name].spec.meta?.format || "text";
};

const label = (name: string) => {
  return jsonSchema.properties[name].title || t(name);
};

const stepTitle = computed(() => {
  return props.schema.spec.meta?.title;
});

const stepSubtitle = computed(() => {
  return props.schema.spec.meta?.subtitle;
});

const description = (name: string) => {
  return props.schema.fields[name].spec.meta?.description;
};

const length = (name: string) => {
  return props.schema.fields[name].tests.find((test: any) => test.OPTIONS.name === "length")?.OPTIONS.params.length;
};

const minLength = (name: string) => {
  return props.schema.fields[name].tests.find((test: any) => test.OPTIONS.name === "min")?.OPTIONS.params.min;
};

const size = (name: string) => {
  return props.schema.fields[name].spec.meta?.size;
};

const suffix = (name: string) => {
  return props.schema.fields[name].spec.meta?.suffix;
};

const prefix = (name: string) => {
  return props.schema.fields[name].spec.meta?.prefix;
};

const modifiers = (name: string) => {
  return props.schema.fields[name].spec.meta?.modifiers || [];
};

const isHidden = (name: string) => {
  return props.schema.fields[name].spec.meta?.hidden;
};
</script>
